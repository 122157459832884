import { Card, Typography, Row, Col } from "antd";
import ActionMenu from "./ActionMenu";

const { Text, Paragraph } = Typography;

const CustomCard = ({
  headerIcon,
  title,
  onClick,
  bodyContent,
  actions,
  cardActions,
}) => {
  return (
    <Card
      title={
        <div
          className="display-flex-center"
          style={{
            whiteSpace: "break-spaces",
            height: "3.143em",
          }}
        >
          {headerIcon}
          <Paragraph style={{ margin: "0 0.5em" }} ellipsis={{ rows: 2 }}>
            {title}
          </Paragraph>
        </div>
      }
      extra={actions ? <ActionMenu actions={actions} /> : null}
      size="small"
      headStyle={{
        height: "3.75rem",
      }}
      hoverable={onClick ? true : false}
      onClick={onClick}
      actions={cardActions}
    >
      {bodyContent?.map((item) => (
        <Row style={{ margin: "6px 0" }}>
          <Col className="display-flex-center">
            {item.icon}
            {item.content ? (
              <Text style={{ marginLeft: "5px" }} ellipsis>
                {item.content}
              </Text>
            ) : (
              <Text style={{ color: "grey", marginLeft: "4px" }}>Not Set</Text>
            )}
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default CustomCard;
