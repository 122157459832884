import { useEffect, useState } from "react";
import { Spin } from "antd";
import EntityHospitalsPage from "../../shared-pages/read-only/hospital-and-cases/EntityHospitalsPage";
import distributorService from "../../services/distributorService";
import toastNotification from "../../components/toastNotification";
import utilityService from "../../services/utilityService";

const DistributorHospitalsPage = ({ match }) => {
  const distributorId = match.params?.id;

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDistributorHospitals = async () => {
    const distributor = await distributorService.getDistributor(
      distributorId,
      false,
      true
    );
    if (distributor) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        distributor.hospitals
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      distributor.hospitals = sortedHospitals;

      setHospitalList(distributor.hospitals);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getDistributorHospitals();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityHospitalsPage hospitalList={hospitalList} />
    </Spin>
  );
};
export default DistributorHospitalsPage;
