import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
// import GoogleDrive from "@uppy/google-drive";
// import Dropbox from "@uppy/dropbox";
// import OneDrive from "@uppy/onedrive";
import Webcam from "@uppy/webcam";
import ImageEditor from "@uppy/image-editor";
// import Url from "@uppy/url";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import "@uppy/url/dist/style.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import { ReactComponent as IconClose } from "../assets/icons/iconCloseModal.svg";
import { ReactComponent as ImageIcon } from "../assets/icons/icon_images.svg";
import UppyHEICImageConversion from "../components/UppyHEICImageConversion";

const Uploader = ({
  id,
  customRequest,
  readOnly,
  imageFiles,
  imageLoading,
  editMode
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [maxNumberOfFiles, setMaxNumberOfFiles] = useState(5);
  const [fileId, setFileId] = useState();

  const uppy = useMemo(() => {
    return (
      new Uppy({
        id,
        autoProceed: false,
        allowMulipleUploads: false,
        theme: "dark",
        restrictions: {
          maxFileSize: 500 * 1024 * 1024,
          maxNumberOfFiles: id === "uploadTray" ? 1 : 5,
          allowedFileTypes: [".jpeg", ".jpg", ".png", ".heic", ".bmap"],
        },
      })
        .use(UppyHEICImageConversion, { id: "UppyHEICImageConversion" })
        .use(ImageEditor, {
          id: "ImageEditor",
          quality: 0.8,
          cropperOptions: {
            viewMode: 0,
            background: false,
            responsive: true,
          },
        })
        .use(Webcam, {
          id: "Webcam",
          modes: ["picture"],
        })
        // .use(Dropbox, {
        //   id: "Dropbox",
        //   companionUrl: "https://companion.uppy.io/",
        // })
        // .use(GoogleDrive, {
        //   id: "GoogleDrive",
        //   companionUrl: "https://companion.uppy.io/",
        // })
        // .use(Url, {
        //   id: "Url",
        //   companionUrl: "https://companion.uppy.io/",
        // })
        // .use(OneDrive, {
        //   id: "OneDrive",
        //   companionUrl: "https://companion.uppy.io/",
        // })
        .on("file-added", (file) => {
          setFileId(file.id);
        })
    );
  }, [id]);

  const onchangeUploadedImage = () => {
    if (imageFiles) {
      let numberOfFiles = 5 - imageFiles.length;
      setMaxNumberOfFiles(numberOfFiles);
      uppy.setOptions({ restrictions: { maxNumberOfFiles: numberOfFiles } });
    }
  };

  useEffect(() => {
    onchangeUploadedImage();
  }, [imageFiles]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  const handleClose = () => {
    setIsModalOpen(false);
    uppy.emit("file-editor:complete", (file) => { });
    uppy.reset();
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const uploadImages = () => {
    if (id === "uploadTray") {
      let file = uppy.getFile(fileId);
      customRequest(file);
    } else {
      uppy.getFiles().forEach((file) => {
        customRequest(file);
      });
    }
    handleClose();
  };

  const imageUploadButton = (
    <div style={{ padding: "3rem 8px" }}>
      {imageLoading ? <LoadingOutlined /> : <ImageIcon />}
      <div style={{ marginTop: 8, padding: imageLoading ? "0.1rem 3rem" : "" }}>
        {imageLoading ? "Uploading..." : "Click to upload the image"}
      </div>
    </div>
  );

  return (
    <div>
      {id === "uploadTray" && (
        <button onClick={handleOpen} size="large" type="button" disabled={editMode}>
          {imageUploadButton}
        </button>
      )}
      {id === "uploadInstrument" &&
        maxNumberOfFiles <= 5 &&
        maxNumberOfFiles >= 1 &&
        !readOnly && (
          <Button
            size="middle"
            onClick={handleOpen}
            disabled={readOnly}
            className="add-img-btn"
          >
            + Add Image
          </Button>
        )}
      <Modal
        width={"58%"}
        closeIcon={
          <div
            div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "flex-start",
              paddingTop: "12px",
            }}
          >
            <IconClose />
          </div>
        }
        visible={isModalOpen}
        onCancel={handleClose}
        footer={null}
        centered
        destroyOnClose
      >
        <Button
          onClick={uploadImages}
          style={{
            marginTop: "12px",
            position: "absolute",
            right: "4em",
            top: "0.1em",
            zIndex: "999",
          }}
          type="primary"
        >
          Upload
        </Button>
        <br />
        <br />
        <Dashboard
          inline
          // theme={"dark"}
          proudlyDisplayPoweredByUppy={false}
          hideUploadButton
          hideRetryButton
          hidePauseResumeButton
          hideCancelButton
          hideProgressAfterFinish
          showProgressDetails
          showSelectedFiles
          uppy={uppy}
          plugins={[
            "ImageEditor",
            "UppyHEICImageConversion",
            // "GoogleDrive",
            // "Dropbox",
            // "Url",
            // "OneDrive",
            "Webcam",
          ]}
        />
      </Modal>
    </div>
  );
};

Uploader.propTypes = {
  id: PropTypes.string,
};

export default Uploader;
