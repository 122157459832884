import { Button } from "antd";
import SignedURLImage from "./SignedURLImage";
import { ReactComponent as VideoIcon } from "../assets/icons/iconImageVideo.svg";

const EquipmentImage = (props) => {
  const { imageUrl, fallbackImage, videoUrl, onClickHandler } = props;

  return (
    <div
      className={videoUrl && "icon-overlay-container"}
      style={videoUrl && { width: "100%" }}
    >
      <div className="equipment-image">
        <SignedURLImage
          key={imageUrl}
          fileName={imageUrl}
          onClick={onClickHandler}
          fallback={fallbackImage}
          height="100%"
          width="100%"
        />
        {videoUrl && (
          <div className="icon-overlay-top-left">
            <Button
              icon={<VideoIcon />}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EquipmentImage;
