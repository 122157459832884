import React, { useState } from "react";
import { Row, Col, Typography, List } from "antd";
import ExecutionStepContainer from "../../containers/LiveCase/ExecutionStepContainer";
import SignedURLImage from "../../components/SignedURLImage";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import EquipmentModal from "../../components/EquipmentModal";
import { ReactComponent as CaseCart } from "../../assets/icons/iconCaseCart.svg";

const { Text } = Typography;

const SurgicalStepPreview = ({ step }) => {
  const [isEquipmentModalVisible, setIsEquipmentModalVisible] = useState(false);
  const [equipmentToView, setEquipmentToView] = useState();

  const getSectionNumber = (equipment) => {
    equipment.sectionNumber = [...new Set(equipment.sectionNumber)];
    equipment.sectionNumber.sort((a, b) => {
      if (!a) return 1;
      return a < b ? 1 : -1;
    });
    let equipmentSectionNumber = equipment.sectionNumber;
    if (
      equipment.sectionNumber.length > 1 &&
      isNaN(equipment.sectionNumber[equipment.sectionNumber.length - 1])
    ) {
      const cartSectionNumberArray = equipment.sectionNumber.slice(
        0,
        equipment.sectionNumber.length - 1
      );
      equipmentSectionNumber = [-1, ...cartSectionNumberArray];
    }
    return (
      <span style={{ display: "inline-flex" }}>
        {equipmentSectionNumber.map((el) => (
          <span
            className={
              el > 0 ? "section-number" : "section-number-for-case-cart"
            }
          >
            {el > 0 ? <span>{el}</span> : <CaseCart />}
          </span>
        ))}
      </span>
    );
  };

  const onCloseEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setIsEquipmentModalVisible(false);
  };

  return (
    <>
      <ExecutionStepContainer
        phaseName={step.phase.phaseName}
        label={step.label}
        isCurrentStep={false}
        stepType="Surgical Step"
      >
        <List
          dataSource={step.stepEquipments}
          split={false}
          renderItem={(surgicalEquipment, index) => {
            return (
              <List.Item>
                <Row style={{ width: "100%" }} key={index}>
                  <Col
                    span={4}
                    className="display-flex-center"
                    style={{
                      justifyContent: "center",
                      border: "1px solid rgb(209, 209, 209)",
                      borderRadius: "3px",
                    }}
                  >
                    <SignedURLImage
                      onClick={() => {
                        setEquipmentToView(surgicalEquipment.equipment);
                        setIsEquipmentModalVisible(true);
                      }}
                      fileName={surgicalEquipment.equipment.image[0]}
                      key={surgicalEquipment.equipment.image[0]}
                      width={70}
                      height={50}
                      fallback={equipmentPlaceholder}
                    />
                  </Col>
                  <Col
                    span={20}
                    className="display-grid-center"
                    style={{ paddingLeft: "8px" }}
                  >
                    <Row>
                      <Col span={24}>
                        <Text strong>
                          {surgicalEquipment.equipment.equipmentName}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col span={2}>
                        <Tag color={"orange"}>2</Tag>
                      </Col> */}
                      <Col span={22}>
                        {getSectionNumber(surgicalEquipment.equipment)}
                        <Text>Qty: {surgicalEquipment.quantity}</Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
        {isEquipmentModalVisible && (
          <EquipmentModal
            close={onCloseEquipmentModal}
            show={isEquipmentModalVisible}
            equipment={equipmentToView}
          />
        )}
      </ExecutionStepContainer>
    </>
  );
};

export default SurgicalStepPreview;
