import EquipmentsPage from "../../shared-pages/manufacturer/EquipmentsPage";

const RMEquipmentsPage = ({ match }) => {
  const { id, rmid } = match.params;

  return (
    <EquipmentsPage
      manufacturerId={id}
      url={match.url}
      hasOwnLibrary
      entityId={rmid}
      entityName="regionalManager"
    />
  );
};

export default RMEquipmentsPage;
