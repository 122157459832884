import { Typography } from "antd";
import ExecutionStepContainer from "../../containers/LiveCase/ExecutionStepContainer";

const { Paragraph } = Typography;
const Notes = ({ step }) => {
  return (
    <ExecutionStepContainer
      phaseName={step.phase.phaseName}
      label={step.label}
      isCurrentStep={true}
      stepType="Notes"
    >
      {step.description?.split("\n").map((line) => (
        <Paragraph style={{ margin: 0, color: "white" }}>{line}</Paragraph>
      ))}
    </ExecutionStepContainer>
  );
};
export default Notes;
