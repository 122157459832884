import { Dropdown, Button, Menu } from "antd";
import { ReactComponent as ActionMenuIcon } from "../assets/icons/iconActionMenu.svg";

const ActionMenu = ({ actions }) => {
  return (
    <Dropdown
      key="more"
      overlay={
        <Menu>
          {actions?.map(
            (item, index) =>
              item?.title && (
                <Menu.Item key={index} onClick={item.onClick}>
                  {item.title}
                </Menu.Item>
              )
          )}
        </Menu>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <Button
        icon={<ActionMenuIcon />}
        htmlType="button"
        style={{
          border: "0",
          background: "none",
          boxShadow: "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};

export default ActionMenu;
