import { Fragment, useEffect } from "react";
import TrayGrid from "./TrayGrid";
import {
  layoutForOneLayer,
  layoutForTwoLayer,
  layoutForOneLayer2Wide,
  layoutForTwoLayer2Wide,
} from "../config/grid-layout";

const layerStyle = {
  zIndex: "2",
  fontSize: "1rem",
  backgroundColor: "rgb(229,229,229)",
  textOrientation: "mixed",
  writingMode: "vertical-rl",
  borderRight: "1px solid black",
  textAlign: "center",
  transform: "rotate(180deg)",
  width: "25px",
};

const Grid = (props) => {
  const { tableData, tableMode } = props;
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }, [tableMode]);

  return (
    <Fragment>
      <main>
        <section
          style={{
            margin: "10px 0",
            display: "grid",
            gridTemplateColumns: "99% auto",
          }}
        >
          <TrayGrid
            layerId={0}
            id={1}
            trays={tableData}
            layout={
              tableMode === "3WIDE" ? layoutForOneLayer : layoutForOneLayer2Wide
            }
            count={tableMode === "3WIDE" ? 3 : 2}
            {...props}
          />

          <div style={{ ...layerStyle, height: "171px" }}>Zone-3 </div>
        </section>
        <section
          style={{
            margin: "10px 0",
            display: "grid",
            gridTemplateColumns: "99% auto",
          }}
        >
          <div>
            <TrayGrid
              trays={tableData}
              layerId={1}
              id={tableMode === "3WIDE" ? 4 : 3}
              layout={
                tableMode === "3WIDE"
                  ? layoutForOneLayer
                  : layoutForOneLayer2Wide
              }
              count={tableMode === "3WIDE" ? 3 : 2}
              {...props}
            />
          </div>
          <div style={{ ...layerStyle, height: "171px" }}>Zone-2 </div>
        </section>
        <section
          style={{
            margin: "10px 0",
            display: "grid",
            gridTemplateColumns: "99% auto",
          }}
        >
          <div>
            <TrayGrid
              trays={tableData}
              id={tableMode === "3WIDE" ? 7 : 5}
              layerId={2}
              layout={
                tableMode === "3WIDE"
                  ? layoutForTwoLayer
                  : layoutForTwoLayer2Wide
              }
              count={tableMode === "3WIDE" ? 6 : 4}
              orientation={true}
              {...props}
            />
          </div>
          <div style={{ ...layerStyle, height: "342px" }}>Zone-1 </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Grid;
