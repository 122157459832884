import { ReactComponent as CasesIcon } from "../assets/icons/sider-menu-icons/iconCases.svg";
import { ReactComponent as MyCasesIcon } from "../assets/icons/hospital-menu/iconMyCases.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/sider-menu-icons/iconSetting.svg";
import { ReactComponent as AdminIcon } from "../assets/icons/sider-menu-icons/iconAdmin.svg";
import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { Roles } from "./role-config.json";

const hospitalMenuItems = (hospitalId, distributorId) => {
  const parentPath = `/hospital/${hospitalId}`;
  return [
    {
      name: "Admin Panel",
      path: distributorId
        ? `/distributor/${distributorId}/hospital`
        : "/hospital",
      icon: <BackIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
    {
      name: "My Cases",
      path: `${parentPath}/my-cases`,
      icon: <MyCasesIcon />,
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
    },
    {
      name: "All Cases",
      path: `${parentPath}/cases`,
      icon: <CasesIcon />,
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
    },
    {
      name: "Cases",
      path: `${parentPath}/cases`,
      icon: <CasesIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.MR,
        Roles.DISTRIBUTOR_ADMIN,
      ],
    },
    {
      name: "Hospital Users",
      path: `${parentPath}/user`,
      icon: <AdminIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
    },
    {
      name: "Hospital Setup",
      path: `${parentPath}/setup`,
      icon: <SettingIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
    },
  ];
};

export default hospitalMenuItems;
