import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../containers/MainContainer";
import CardList from "../components/CardList";
import CustomCard from "../components/CustomCard";
import distributorService from "../services/distributorService";
import toastNotification from "../components/toastNotification";
import { ReactComponent as AddressIcon } from "../assets/icons/iconLocation.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/iconPhone.svg";
import { ReactComponent as DistributorColorIcon } from "../assets/icons/colorIconDistributor.svg";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";

const { confirm } = Modal;
const customPageSize = 12;

const DistributorsPage = (props) => {
  const {
    match: { url },
    history,
  } = props;
  const breadCrumb = useBreadCrumbContext();
  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Distributors",
        backBtnPath: "/distributor",
      }
    : {
        title: "Distributors",
        btnText: "Add Distributor",
        onBtnClick: () => {
          history.push("/distributor/new");
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Distributors",
            path: "/distributor/deleted",
          },
        ],
      };

  const [distributorList, setDistributorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentDistributorPage, setCurrentDistributorPage] = useState(1);
  const [distributorCount, setDistributorCount] = useState(0);
  const [distributorSearchText, setDistributorSearchText] = useState();

  const actions = (distributor) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onDistributorRestore(distributor.id);
            },
          },
        ]
      : [
          {
            title: " Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onDistributorEdit(distributor);
            },
          },
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(distributor.id);
            },
          },
        ];
  };

  const cardContent = (distributor) => [
    {
      icon: <AddressIcon />,
      content: distributor.address
        ? (() => {
            const address = [];
            if (distributor.city) address.push(distributor.city);
            if (distributor.state) address.push(distributor.state);
            if (distributor.country) address.push(distributor.country);

            return address.length ? address.join(", ") : null;
          })()
        : null,
    },
    {
      icon: <PhoneIcon fill="#808285" />,
      content: distributor.contactNumber1
        ? distributor.contactNumber1
        : distributor.contactNumber2
        ? distributor.contactNumber2
        : null,
    },
  ];

  const onDistributorEdit = (distributor) => {
    breadCrumb.setBreadCrumb({
      distributor: distributor.distributorName,
    });
    history.push(`/distributor/${distributor.id}/setup`, {
      readOnly: false,
      record: distributor,
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setDistributorSearchText(searchValue);
        const success = await getDistributorCount(searchValue);
        if (success) {
          setCurrentDistributorPage(1);
          await populateDistributorsList(customPageSize, 0, searchValue);
        } else {
          toastNotification("error", "Error fetching distributor records");
        }
        setLoading(false);
      }, 500)
    );
  };

  const onDistributorRestore = async (distributorId) => {
    setLoading(true);
    const res = await distributorService.patchRestoreDistributorManufacturer(distributorId);
    if (res) {
      toastNotification("success", "Distributor Restored");
      populateDistributorsList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the Distributor");
    }
  };

  const showDeleteConfirmation = (distributorId) => {
    confirm({
      title: "Delete Distributor",
      content: "Are you sure you want to delete this Distributor?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteDistributor(distributorId);
      },
    });
  };

  /** API Calls */
  const populateDistributorsList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await distributorService.getDistributors(
      {
        isDeleted: isDeletedPage,
      },
      limit,
      skip,
      null,
      searchValue
    );
    if (data) {
      setDistributorList(data);
    } else {
      toastNotification("error", "Error fetching the Distributor records");
    }
    setLoading(false);
  };

  const deleteDistributor = async (id) => {
    setLoading(true);
    const isDeleted = await distributorService.putDistributor({
      id,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Distributor deleted successfully");
      setCurrentDistributorPage(1);
      await populateDistributorsList(customPageSize, 0);
    } else {
      toastNotification("error", "Error deleting the Distributor");
    }
    setLoading(false);
  };

  const getDistributorCount = async (searchValue) => {
    const count = await distributorService.getDistributorCount(
      isDeletedPage,
      searchValue
    );
    if (count >= 0) {
      setDistributorCount(count);
      return true;
    } else {
      return false;
    }
  };
  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const success = await getDistributorCount();
      if (success) await populateDistributorsList(customPageSize, 0);
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Distributor Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={distributorList}
        loading={loading}
        pagination={{
          pageSize: customPageSize,
          total: distributorCount,
          current: currentDistributorPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentDistributorPage(page);
            await populateDistributorsList(
              pageSize,
              pageSize * (page - 1),
              distributorSearchText
            );
            setLoading(false);
          },
        }}
        renderItem={(distributor) => (
          <List.Item>
            <CustomCard
              headerIcon={<DistributorColorIcon />}
              title={distributor.distributorName}
              actions={actions(distributor)}
              onClick={
                isDeletedPage
                  ? null
                  : () => {
                      breadCrumb.setBreadCrumb({
                        distributor: distributor.distributorName,
                      });
                      history.push(`/distributor/${distributor.id}/hospital`);
                    }
              }
              bodyContent={cardContent(distributor)}
            />
          </List.Item>
        )}
      />
    </MainContainer>
  );
};
export default DistributorsPage;
