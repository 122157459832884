import { Roles } from "./role-config.json";
import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/sider-menu-icons/iconSetting.svg";
import { ReactComponent as EquipmentIcon } from "../assets/icons/sider-menu-icons/iconEquipment.svg";
import { ReactComponent as TrayIcon } from "../assets/icons/distributor-menu/iconTray.svg";
import { ReactComponent as ProcedureIcon } from "../assets/icons/sider-menu-icons/icon_procedures.svg";
import { ReactComponent as HospitalIcon } from "../assets/icons/sider-menu-icons/iconHospitals.svg";

const medicalRepresentativeMenuItems = (
  manufacturerId,
  areaId,
  regionalManagerId,
  medRepId
) => {
  const parentPath = `/manufacturer/${manufacturerId}/area/${areaId}/regional-manager/${regionalManagerId}/medical-representative/${medRepId}`;

  return [
    {
      name: "Regional Manager",
      path: `/manufacturer/${manufacturerId}/area/${areaId}/regional-manager/${regionalManagerId}/medical-representative`,
      icon: <BackIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
      ],
    },
    {
      name: "Hospitals",
      path: `${parentPath}/hospital`,
      icon: <HospitalIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR
      ],
    },
    {
      name: "Procedures",
      path: `${parentPath}/procedure`,
      icon: <ProcedureIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
    },
    {
      name: "Trays",
      path: `${parentPath}/tray`,
      icon: <TrayIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
    },
    {
      name: "Instruments",
      path: `${parentPath}/equipment`,
      icon: <EquipmentIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
    },
    {
      name: "Medical Representatives Setup",
      path: `${parentPath}/setup`,
      icon: <SettingIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR
      ],
    },
  ];
};

export default medicalRepresentativeMenuItems;
