import http from "./httpService";

async function getDistributorEquipments(
  entityId,
  isDeleted,
  limit,
  skip,
  searchValue,
  entityName = "distributor"
) {
  try {
    let api;
    let params = {
      where: {
        [entityName]:
          typeof entityId === "string" ? entityId : { in: entityId },
        isDeleted,
      },
      populate: "distributor",
      limit,
      skip,
    };

    if (searchValue) {
      api = "/equipment/search";
      params.where.equipmentName = { contains: searchValue };
    } else {
      api = "/equipment";
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getEquipment(distributorId, equipmentId) {
  try {
    const res = await http.get(`/equipment/${equipmentId}`, {
      params: {
        where: { distributor: distributorId },
        populate: "distributor",
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getEquipmentsWithOwner(equipmentId) {
  try {
    const res = await http.get(`/equipment`, {
      params: {
        where: { id: { in: equipmentId } },
        populate: "distributor,regionalManager",
        limit: 1000,
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function postEquipment(equipment) {
  try {
    return await http.post("/equipment", equipment);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function patchEquipment(equipment) {
  try {
    return await http.patch(`/equipment/${equipment.id}`, equipment);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const getEquipmentCount = async (
  entityId,
  isDeleted,
  searchText,
  entityName = "distributorId"
) => {
  try {
    const params = {
      [entityName]: entityId,
      isDeleted,
      searchText,
    };
    const res = await http.get("/equipment/count", {
      params,
    });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const getUserEquipmentCount = async (
  userId,
  userType,
  isDeleted,
  searchText
) => {
  const params = {
    [userType]: userId,
    isDeleted,
    searchText,
  };
  const res = await http.dorAxiosGet("/equipment/count", params);
  return res?.data?.count;
};

export default {
  getEquipment,
  getDistributorEquipments,
  postEquipment,
  patchEquipment,
  getEquipmentCount,
  getUserEquipmentCount,
  getEquipmentsWithOwner,
};
