import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Spin,
  Tabs,
} from "antd";
import { debounce } from "lodash";
import MainContainer from "../containers/MainContainer";
import toastNotification from "../components/toastNotification";
import distributorService from "../services/distributorService";
import manufacturerService from "../services/manufacturerService";
import utilityService from "../services/utilityService";
import { ReactComponent as ManufacturerColorIcon } from "../assets/icons/mfc_color.svg";
import { ReactComponent as HospitalColorIcon } from "../assets/icons/colorIconHospital.svg";
import { ReactComponent as HospitalGreyIcon } from "../assets/icons/greyIconManufacturer.svg";
import { ReactComponent as ManufacturerGreyIcon } from "../assets/icons/greyIconManufacturer.svg";
import { useAuthContext } from "../context/AuthContext";
import { Roles } from "../config/role-config.json";
import AssignmentSelection from "../components/AssignmentSelection";

const { confirm } = Modal;
const { Title } = Typography;
const { TabPane } = Tabs;

const DistributorForm = (props) => {
  const { match, history, location } = props;
  const distributorId = match.params?.id;

  const [distributor, setDistributor] = useState();
  const [manufacturerList, setManufacturerList] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [selectedManufacturersId, setSelectedManufacturersId] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(
    !distributorId || location.state?.readOnly === false ? true : false
  );
  const [form] = Form.useForm();
  const authContext = useAuthContext();

  const [timeOut, setTimeOut] = useState(0);
  const [currentManufacturerPage, setCurrentHManufacturerPage] = useState(1);
  const [manufacturerCount, setManufacturerCount] = useState();
  const [manufacturerSearchText, setManufacturerSearchText] = useState();
  const [isManufacturerLoading, setManufacturerLoading] = useState(false);
  const [loadManufacturers, setLoadManufacturers] = useState(false);
  const customPageSize = 30;

  const isSuperAdmin = authContext.user?.role === Roles.DOR_ADMIN;
  const isDistributorAdmin = authContext.user?.role === Roles.DISTRIBUTOR_ADMIN;

  const pageHeaderProps = distributorId
    ? {
        title: "Distributor's Setup",
        btnText:
          isSuperAdmin || isDistributorAdmin
            ? editMode
              ? "Save"
              : "Edit"
            : "",
        btnType:
          isSuperAdmin || isDistributorAdmin
            ? editMode
              ? "primary"
              : "secondary"
            : "",
        topActionMenuItems: isSuperAdmin
          ? [
              {
                title: "Delete",
                path: "",
                onClick: () => {
                  showDeleteConfirmation(distributorId);
                },
              },
            ]
          : null,
      }
    : {
        title: "New Distributor",
        btnText: "Add",
        backBtnPath: "/distributor",
      };

  const onManufacturerSelect = (manufacturerId) => {
    const ids = selectedManufacturersId;
    ids.push(manufacturerId);
    setSelectedManufacturersId([...ids]);

    const selectedManufacturer = manufacturerList.find(
      (manufacturer) => manufacturer.id === manufacturerId
    );
    selectedManufacturers.push(selectedManufacturer);
    setSelectedManufacturers([...selectedManufacturers]);
  };

  const onManufacturerDeselect = (manufacturerId) => {
    setSelectedManufacturersId(
      selectedManufacturersId.filter((x) => x !== manufacturerId)
    );

    setSelectedManufacturers(
      selectedManufacturers.filter(
        (manufacturer) => manufacturer.id !== manufacturerId
      )
    );
  };

  const logValue = debounce((searchText) => {
    setManufacturerSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadManufacturers(false);
    setManufacturerLoading(true);
    setCurrentHManufacturerPage(currentManufacturerPage + 1);
    await populateManufacturersList(
      customPageSize,
      currentManufacturerPage * customPageSize,
      manufacturerSearchText
    );
    setManufacturerLoading(false);
  };

  const handleOpen = () => {
    setCurrentHManufacturerPage(1);
  };

  const handleChange = (value) => {
    setCurrentHManufacturerPage(0);
  };

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentHManufacturerPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadManufacturers &&
      manufacturerList.length !== manufacturerCount &&
      addOptions();
  };

  const getManufacturerCount = async (searchText) => {
    const count = await manufacturerService.getManufacturerCount(
      false,
      searchText
    );
    if (count >= 0) {
      setManufacturerCount(count);
      return true;
    } else {
      return false;
    }
  };

  const onSearchFunction = async (searchValue) => {
    setManufacturerLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setManufacturerSearchText(searchValue);
        const count = await getManufacturerCount(searchValue);
        if (count) {
          setCurrentHManufacturerPage(0);
          await populateManufacturersList(customPageSize, 0, searchValue);
        } else {
          toastNotification("error", "Error fetching manufacturer records");
        }
        setManufacturerLoading(false);
      }, 500)
    );
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : true;
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Distributor",
      content: "Are you sure you want to delete this Distributor?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await distributorService.putDistributor({
          id: distributorId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Distributor deleted successfully");
          history.push("/distributor");
        } else {
          toastNotification("error", "Error deleting the distributor");
        }
      },
    });
  };

  const addDistributor = async (distributorFormValues) => {
    // TODO :: Trim values before submit
    const res = await distributorService.postDistributor(distributorFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Distributor Added");
      history.push("/distributor");
    } else {
      toastNotification("error", "Error adding a new distributor record");
    }
  };

  const editDistributor = async (distributorFormValues) => {
    // TODO :: Trim values before submit
    distributorFormValues.id = distributorId;
    const res = await distributorService.putDistributor(distributorFormValues);
    if (res.status === 200) {
      setEditMode(false);
      toastNotification("success", "Distributor Record Updated");
    } else {
      toastNotification("error", "Error updating the distributor record");
    }
  };

  const getDistributor = async (id) => {
    const distributor = await distributorService.getDistributor(id, true, true);
    if (distributor) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        distributor.hospitals
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      distributor.hospitals = sortedHospitals;

      const filteredManufacturers = utilityService.filterSelectedEntity(
        distributor.manufacturers
      );
      const sortedManufacturers = utilityService.sortSelectedEntity(
        filteredManufacturers
      );
      distributor.manufacturers = sortedManufacturers;

      setDistributor(distributor);
      setSelectedHospitals(sortedHospitals);
      return distributor;
    } else {
      toastNotification("error", "Requested Distributor record not found");
    }
  };

  const onFinish = async (distributorFormValues) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setLoading(true);
      distributorFormValues.manufacturers = selectedManufacturersId;
      if (distributorId) {
        await editDistributor(distributorFormValues);
      } else {
        await addDistributor(distributorFormValues);
      }
      setLoading(false);
    }
  };

  const populateManufacturersList = async (limit, skip, searchValue) => {
    const res = await manufacturerService.getManufacturers(
      false,
      limit,
      skip,
      searchValue
    );
    if (res) {
      setLoadManufacturers(res.length === customPageSize);
      const arr = [...manufacturerList, ...res];
      setManufacturerList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (distributorId) {
        const distributorTemp = await getDistributor(distributorId);
        if (distributorTemp) {
          form.setFieldsValue({ ...distributorTemp });
          if (distributorTemp.manufacturers) {
            setSelectedManufacturersId(
              distributorTemp.manufacturers?.map(
                (manufacturer) => manufacturer.id
              )
            );
            setSelectedManufacturers(distributorTemp.manufacturers);
          }
        }
      }
      setLoading(false);
      const success = await getManufacturerCount();
      if (success) populateManufacturersList(customPageSize, 0);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="distributorAddEditForm"
        onBtnClick={() => {
          form.submit();
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Title level={5}>Details</Title>
                  <Form
                    form={form}
                    name="distributorAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Distributor Name (Required)"
                          name="distributorName"
                          rules={[
                            {
                              required: true,
                              message: "Please input Distributor!",
                            },
                          ]}
                        >
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 1"
                          name="contactNumber1"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "The entered contact number seems to be invalid. Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (xxx) xxx-xxxx"
                            id="contactNumber1"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 2"
                          name="contactNumber2"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (xxx) xxx-xxxx"
                            id="contactNumber2"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Street Address" name="address">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="City" name="city">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item label="State" name="state">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="Country" name="country">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Assigned Manufacturers/Hospitals</Title>
              <Tabs>
                <TabPane tab="Manufacturers" key="m">
                  <AssignmentSelection
                    editMode={isSuperAdmin && editMode}
                    selectedEntityIds={selectedManufacturersId}
                    dataSource={manufacturerList}
                    selectedEntities={selectedManufacturers}
                    optionIcon={ManufacturerColorIcon}
                    showOptionsLoader={isManufacturerLoading}
                    onSelect={onManufacturerSelect}
                    onDeselect={onManufacturerDeselect}
                    onSearch={onSearchFunction}
                    filterOption={handleFilterOption}
                    onDropdownVisibleChange={handleOpen}
                    onChange={handleChange}
                    onPopupScroll={handleMenuScrollToBottom}
                    onInputKeyDown={handleInputChange}
                    tagIcon={ManufacturerColorIcon}
                    emptyText="No Manufacturers added yet"
                    emptyIcon={ManufacturerGreyIcon}
                    entityTitleProperty="distributorName"
                    placeholder="Search by Manufacturer Name"
                  />
                </TabPane>
                <TabPane tab="Hospitals" key="h">
                  <AssignmentSelection
                    showOnlyTags={true}
                    editMode={false}
                    selectedEntities={distributor?.hospitals}
                    tagIcon={HospitalColorIcon}
                    emptyText="No Hospitals added yet"
                    emptyIcon={HospitalGreyIcon}
                    entityTitleProperty="hospitalName"
                  />
                </TabPane>
              </Tabs>
            </Col>
            <Col span={1}></Col>
          </Row>
        </div>
      </MainContainer>
    </Spin>
  );
};

export default DistributorForm;
