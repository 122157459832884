import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Typography,
  Button,
  DatePicker,
  TimePicker,
  Input,
  Select,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as StethoscopeColorIcon } from "../../assets/icons/hospital-case/colorIconStethoscope.svg";
import hospitalService from "../../services/hospitalService";
import { debounce } from "lodash";

const { Title, Text } = Typography;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

const CaseDetailsForm = (props) => {
  const {
    hospitalCase,
    updateCase,
    isPageReadOnly,
    hospitalId,
    hasReadOnlyRights,
    isMyCasesPage,
  } = props;

  const history = useHistory();
  const initialRender = useRef(true);
  const [caseSurgeonList, setCaseSurgeonList] = useState([]);
  const [readOnly, setReadOnly] = useState(isPageReadOnly);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [timeOut, setTimeOut] = useState(0);
  const [currentSurgeonPage, setCurrentSurgeonPage] = useState(1);
  const [surgeonSearchText, setSurgeonSearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadSurgeons, setLoadSurgeon] = useState(false);
  const customPageSize = 30;

  const onEditBtnClick = () => {
    setReadOnly(false);
  };

  const logValue = debounce((searchText) => {
    setSurgeonSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadSurgeon(false);
    setIsLoading(true);
    setCurrentSurgeonPage(currentSurgeonPage + 1);
    await populateSurgeonsList(
      customPageSize,
      currentSurgeonPage * customPageSize,
      surgeonSearchText
    );
    setIsLoading(false);
  };

  const handleOpen = () => {
    setCurrentSurgeonPage(1);
  };

  const handleChange = (value) => {
    setCurrentSurgeonPage(0);
  };

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentSurgeonPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadSurgeons && addOptions();
  };

  const onSearchFunction = async (searchValue) => {
    setIsLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setSurgeonSearchText(searchValue);
        setCurrentSurgeonPage(0);
        await populateSurgeonsList(customPageSize, 0, searchValue);
        setIsLoading(true);
      }, 500)
    );
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  };

  const onFinish = (caseFormValues) => {
    caseFormValues.hospital = hospitalId;
    caseFormValues.dateOfSurgery = caseFormValues.dateOfSurgery
      ? caseFormValues.dateOfSurgery.format(dateFormat)
      : null;
    caseFormValues.timeOfSurgery = caseFormValues.timeOfSurgery
      ? caseFormValues.timeOfSurgery.format(timeFormat)
      : null;

    hospitalCase?.id ? editCase(caseFormValues) : addCase(caseFormValues);
  };

  /** API calls */
  const addCase = async (caseFormValues) => {
    setLoading(true);
    const res = await hospitalService.postCase(caseFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Case added");
      const newCase = {
        ...res.data,
        surgeon: caseSurgeonList.find(
          (surgeon) => surgeon.id === res.data.surgeon
        ),
      };

      history.push(
        `/hospital/${hospitalId}/${isMyCasesPage ? "my-cases" : "cases"}/${
          newCase.id
        }`,
        { record: newCase, readOnly: true }
      );
    } else {
      toastNotification("error", "Error adding a new Case");
    }
    setLoading(false);
  };

  const editCase = async (caseFormValues) => {
    setLoading(true);
    caseFormValues.id = hospitalCase.id;
    const res = await hospitalService.putCase(caseFormValues);
    if (res?.status === 200) {
      toastNotification("success", "Case record updated");
      const updatedCase = {
        ...res.data,
        surgeon: caseSurgeonList.find(
          (surgeon) => surgeon.id === res.data.surgeon
        ),
        procedure: hospitalCase.procedure,
        hospital: hospitalCase.hospital,
      };
      updateCase(updatedCase);
      history.replace({ state: { record: updatedCase } });
      setReadOnly(true);
    } else {
      toastNotification("error", "Error updating the Case record");
    }
    setLoading(false);
  };

  const populateSurgeonsList = async (limit, skip, searchValue) => {
    const res = await hospitalService.getHospitalUsers(
      hospitalId,
      ["SURGEON"],
      false,
      limit,
      skip,
      searchValue
    );
    if (res) {
      setLoadSurgeon(res.length === customPageSize);
      const arr = [...caseSurgeonList, ...res];
      setCaseSurgeonList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    } else {
      toastNotification("error", "Error fetching the surgeon records");
    }
  };

  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (initialRender) {
        await populateSurgeonsList(customPageSize, 0);
        initialRender.current = false;
      }
      if (hospitalCase) {
        form.setFieldsValue({
          ...hospitalCase,
          surgeon: hospitalCase.surgeon?.id,
          dateOfSurgery: hospitalCase.dateOfSurgery
            ? moment(hospitalCase.dateOfSurgery)
            : null,
          timeOfSurgery: hospitalCase.timeOfSurgery
            ? moment(hospitalCase.timeOfSurgery, timeFormat)
            : null,
        });
      }

      setLoading(false);
    })();
  }, [hospitalCase]);

  return (
    <Spin spinning={loading}>
      <Row align="middle">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <Form
            form={form}
            name="caseDetailAddEditForm"
            size="middle"
            colon="false"
            scrollToFirstError="true"
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
          >
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Col className="display-flex-center">
                <Title level={5}>Details</Title>
              </Col>
              <Col>
                {!hasReadOnlyRights &&
                  (readOnly ? (
                    <Button
                      type="secondary"
                      htmlType="button"
                      size="middle"
                      onClick={onEditBtnClick}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="button"
                      size="middle"
                      onClick={() => form.submit()}
                    >
                      { hospitalCase?.id ? "Save" : "Add"}
                    </Button>
                  ))}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item
                  label="Select Surgeon (Required)"
                  name="surgeon"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Surgeon",
                    },
                  ]}
                >
                  <Select
                    disabled={readOnly}
                    showSearch
                    allowClear
                    autoClearSearchValue={false}
                    onDropdownVisibleChange={handleOpen}
                    onChange={handleChange}
                    onSearch={onSearchFunction}
                    onPopupScroll={handleMenuScrollToBottom}
                    onInputKeyDown={handleInputChange}
                    filterOption={handleFilterOption}
                    notFoundContent={
                      <div
                        className="display-flex-center"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<b>No Records Found.</b>}
                        />
                      </div>
                    }
                  >
                    {caseSurgeonList.map((surgeon) => {
                      const title = [
                        "Dr.",
                        surgeon.firstName,
                        surgeon.middleName,
                        surgeon.lastName,
                      ].join(" ");
                      return (
                        <Option
                          value={surgeon.id}
                          key={surgeon.id}
                          title={title}
                        >
                          <div className="display-flex-center">
                            <StethoscopeColorIcon width="22px" />
                            <Text
                              style={{ marginLeft: "6px", color: "#000000" }}
                              ellipsis
                            >
                              {title}
                            </Text>
                          </div>
                        </Option>
                      );
                    })}
                    {isLoading && (
                      <Option value="loading" disabled>
                        Loading more...
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11}>{/* Manufacturer/Distributor */}</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Case Description" name="caseDescription">
                  <Input.TextArea rows={5} disabled={readOnly} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item label="Date of Surgery" name="dateOfSurgery">
                  <DatePicker
                    format={dateFormat}
                    disabled={readOnly}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Time of Surgery" name="timeOfSurgery">
                  <TimePicker
                    showNow={false}
                    format="HH:mm"
                    style={{ width: "100%" }}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item label="Operating Room" name="ORRoom">
                  <Input disabled={readOnly} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseDetailsForm;
