import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  Spin,
  Tag,
  Typography,
  Empty,
} from "antd";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as StethoscopeColorIcon } from "../../assets/icons/hospital-case/colorIconStethoscope.svg";
import { ReactComponent as TagCloseIcon } from "../../assets/icons/iconTagClose.svg";
import { ReactComponent as UserColorIcon } from "../../assets/icons/colorIconUser.svg";
// import { ReactComponent as SearchIcon } from "../../assets/icons/iconSearch.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../assets/icons/checkboxSelected.svg";
import hospitalService from "../../services/hospitalService";
import EmptyDiv from "../../components/EmptyDiv";
import { useRouteMatch } from "react-router-dom";
import { debounce } from "lodash";

const { Option } = Select;
const { Title, Text, Paragraph } = Typography;

const CaseORTeamForm = (props) => {
  const { hospitalCase, hasReadOnlyRights } = props;

  const match = useRouteMatch();
  const [hospitalStaffList, setHospitalStaffList] = useState([]);
  const [selectedORTeam, setSelectedORTeam] = useState([]);
  const [selectedORTeamIds, setSelectedORTeamIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(!match.url.endsWith("/new"));
  const [form] = Form.useForm();

  const [timeOut, setTimeOut] = useState(0);
  const [currentHospitalStaffPage, setCurrentHospitalStaffPage] = useState(1);
  const [hospitalStaffSearchText, setHospitalStaffSearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadHospitalStaff, setLoadHospitalStaff] = useState(false);
  const customPageSize = 30;

  form.setFieldsValue({
    surgeon: hospitalCase.surgeon
      ? [
          "Dr.",
          hospitalCase.surgeon.firstName,
          hospitalCase.surgeon.middleName,
          hospitalCase.surgeon.lastName,
        ].join(" ")
      : null,
  });

  const onEditBtnClick = () => {
    setReadOnly(false);
  };

  const onORTeamUserSelect = (userId) => {
    // add id to selectedIds to maintain state of selected options
    const ids = selectedORTeamIds;
    ids.push(userId);
    setSelectedORTeamIds([...ids]);

    // add user to selectedORTeam to add it to the displayed tag list
    const selectedORTeamUser = {
      user: userId,
      userDetails: hospitalStaffList.find((user) => user.id === userId),
      role: undefined,
      surgeon: hospitalCase.surgeon.id,
    };
    selectedORTeam.push(selectedORTeamUser);
    setSelectedORTeam([...selectedORTeam]);
  };

  const onORTeamUserDeselect = (userId) => {
    // remove id from selectedIds to maintain state of selected options
    setSelectedORTeamIds(selectedORTeamIds.filter((id) => id !== userId));

    // remove user from selectedORTeam to remove it from the displayed tag list
    setSelectedORTeam(selectedORTeam.filter((user) => user.user !== userId));
  };

  const onFinish = async () => {
    setLoading(true);
    const res = await hospitalService.postCaseORTeam(hospitalCase.id, {
      ORTeam: selectedORTeam,
    });
    if (res) {
      toastNotification("success", "ORTeam assigned");
      setReadOnly(true);
    } else {
      toastNotification("error", "Error assigning ORTeam");
    }
    setLoading(false);
  };

  const logValue = debounce((searchText) => {
    setHospitalStaffSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadHospitalStaff(false);
    setIsLoading(true);
    setCurrentHospitalStaffPage(currentHospitalStaffPage + 1);
    await populateHospitalStaffList(
      customPageSize,
      currentHospitalStaffPage * customPageSize,
      hospitalStaffSearchText
    );
    setIsLoading(false);
  };

  const handleOpen = () => {
    setCurrentHospitalStaffPage(1);
  };

  const handleChange = (value) => {
    setCurrentHospitalStaffPage(0);
  };

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentHospitalStaffPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadHospitalStaff && addOptions();
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setHospitalStaffSearchText(searchValue);
        setCurrentHospitalStaffPage(0);
        populateHospitalStaffList(customPageSize, 0, searchValue);
      }, 500)
    );
  };

  const handlefilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  };

  const populateHospitalStaffList = async (limit, skip, searchValue) => {
    setIsLoading(true);
    const res = await hospitalService.getHospitalUsers(
      hospitalCase.hospital.id
        ? hospitalCase.hospital.id
        : hospitalCase.hospital,
      ["HOSPITAL_STAFF"],
      false,
      limit,
      skip,
      searchValue
    );
    if (res) {
      setLoadHospitalStaff(res.length === customPageSize);
      const arr = [...hospitalStaffList, ...res];
      setHospitalStaffList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    } else {
      toastNotification("error", "Error fetching the staff records");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await populateHospitalStaffList(customPageSize, 0);
      const caseORTeam = await hospitalService.getCaseORTeam(hospitalCase.id);
      const ids = [];
      const users = [];
      caseORTeam?.forEach((user) => {
        ids.push(user.user.id);
        users.push({
          user: user.user.id,
          userDetails: user.user,
          role: user.role,
        });
      });
      setSelectedORTeamIds([...ids]);
      setSelectedORTeam([...users]);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row align="middle">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <Row justify="space-between">
            <Col className="display-flex-center">
              <Title level={5} style={{ margin: 0 }}>
                Setup the Team
              </Title>
            </Col>
            <Col>
              {!hasReadOnlyRights &&
                (readOnly ? (
                  <Button
                    type="secondary"
                    htmlType="button"
                    onClick={onEditBtnClick}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button type="primary" htmlType="button" onClick={onFinish}>
                    Save
                  </Button>
                ))}
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Form
                form={form}
                name="caseDetailAddEditForm"
                size="large"
                colon="false"
                scrollToFirstError="true"
                layout="vertical"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item name="surgeon">
                      <Input
                        style={{ width: "100%" }}
                        size="large"
                        disabled
                        prefix={<StethoscopeColorIcon />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text>
                      Select the operating room users and set specific roles
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: "0.5rem" }}>
                  <Col span={24}>
                    <Form.Item>
                      <Select
                        mode="multiple"
                        size="large"
                        style={{ width: "100%" }}
                        allowClear
                        autoClearSearchValue={false}
                        onSelect={onORTeamUserSelect}
                        onDeselect={onORTeamUserDeselect}
                        onDropdownVisibleChange={handleOpen}
                        onChange={handleChange}
                        onSearch={onSearchFunction}
                        onPopupScroll={handleMenuScrollToBottom}
                        onInputKeyDown={handleInputChange}
                        filterOption={handlefilterOption}
                        notFoundContent={
                          <div
                            className="display-flex-center"
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<b>No Records Found.</b>}
                            />
                          </div>
                        }
                        tagRender={() => null}
                        value={selectedORTeamIds}
                        menuItemSelectedIcon={<CheckboxSelectedIcon />}
                        disabled={readOnly}
                      >
                        {hospitalStaffList.map((staffMember, index) => (
                          <Option
                            key={staffMember.id}
                            value={staffMember.id}
                            title={[
                              staffMember.firstName,
                              staffMember.middleName,
                              staffMember.lastName,
                            ].join(" ")}
                          >
                            <div className="display-flex-center">
                              <UserColorIcon width="24px" />
                              <Text style={{ marginLeft: "11px" }} ellipsis>
                                {[
                                  staffMember.firstName,
                                  staffMember.middleName,
                                  staffMember.lastName,
                                ].join(" ")}
                              </Text>
                            </div>
                          </Option>
                        ))}
                        {isLoading && (
                          <Option value="loading" disabled>
                            Loading more...
                          </Option>
                        )}
                      </Select>
                      {/* <SearchIcon /> */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {selectedORTeam.length ? (
                      selectedORTeam.map((user, index) => (
                        <Row key={index} gutter={[0, 8]}>
                          <Col span={24}>
                            <Tag
                              color="#f5f5f5"
                              key={user.userDetails.id}
                              icon={<UserColorIcon width="24px" />}
                              closable={!readOnly}
                              closeIcon={<TagCloseIcon />}
                              onClose={() =>
                                onORTeamUserDeselect(user.userDetails.id)
                              }
                              style={{
                                color: "black",
                                height: "100%",
                              }}
                            >
                              <Row>
                                <Col span={16} className="display-flex-center">
                                  <div
                                    style={{
                                      whiteSpace: "break-spaces",
                                    }}
                                  >
                                    <Paragraph
                                      style={{
                                        margin: "0 8px",
                                        fontSize: "16px",
                                      }}
                                      ellipsis={{ rows: 2 }}
                                    >
                                      {[
                                        user.userDetails.firstName,
                                        user.userDetails.middleName,
                                        user.userDetails.lastName,
                                      ].join(" ")}
                                    </Paragraph>
                                  </div>
                                </Col>
                                <Col span={8}>
                                  <Select
                                    placeholder="Select Role"
                                    style={{ width: "100%" }}
                                    defaultValue={user.role}
                                    onChange={(value) => {
                                      user.role = value;
                                    }}
                                    disabled={readOnly}
                                  >
                                    <Option value="SURGICAL_TECH">
                                      Pilot/Surgical Tech
                                    </Option>
                                    <Option value="NURSE">Nurse</Option>
                                    <Option value="PERI-OPERATIVE">
                                      Peri-Operative User
                                    </Option>
                                  </Select>
                                </Col>
                              </Row>
                            </Tag>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <EmptyDiv emptyText="No members selected yet." />
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseORTeamForm;
