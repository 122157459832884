import { useEffect, useState } from "react";
import { Modal, List, Tabs, Spin } from "antd";
import MainContainer from "../../containers/MainContainer";
import CustomCard from "../../components/CustomCard";
import CardList from "../../components/CardList";
import DropdownFilter from "../../components/DropdownFilter";
import distributorService from "../../services/distributorService";
import procedureService from "../../services/procedureService";
import toastNotification from "../../components/toastNotification";
import utilityService from "../../services/utilityService";
import { ReactComponent as DistributorIcon } from "../../assets/icons/icon_manifacturers.svg";
import { ReactComponent as ManufacturerIcon } from "../../assets/icons/icon_Manufacturer.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/icons/color-icons_procedure.svg";

const { TabPane } = Tabs;
const { confirm } = Modal;
const customPageSize = 12;

const DistributorProcedurePage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const distributorId = params?.id;

  const isDeletedPage = url.split("/").includes("deleted");

  const [activeTabKey, setActiveTabKey] = useState(location.state?.activeTabKey ?? "2");
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [procedureCount, setProcedureCount] = useState(0);
  const [procedureSearchText, setProcedureSearchText] = useState();
  const [procedureList, setProceduresList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(0);
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);

  const actions = (procedure) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        !(procedure.distributor?.isManufacturer) && {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(
              `/distributor/${distributorId}/procedure/${procedure.id}/table-setup`,
              {
                record: procedure,
                tag: "Table Setup",
                pageNumber: currentPage,
                activeTabKey: activeTabKey
              }
            );
          },
        },
        {
          title: "Clone",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            cloneProcedure(
              procedure.id,
              distributorId
            );
          },
        },
        !(procedure.distributor?.isManufacturer) && {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(procedure.id);
          },
        },

      ];
  };

  const cardContent = (procedure) => [
    {
      icon: activeTabKey === "1" ? < ManufacturerIcon /> : <DistributorIcon />,
      content: procedure.distributor
        ? `By ${procedure?.distributor?.distributorName}`
        : null,
    },
    {
      icon: <CalenderIcon fill="#808285" />,
      content: procedure.createdAt
        ? "On " + new Date(procedure.createdAt).toISOString().slice(0, 10)
        : null,
    },
  ];

  const showDeleteConfirmation = (distributorId) => {
    confirm({
      title: "Delete Procedure",
      content: "Are you sure you want to delete this Procedure?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        deleteProcedure(distributorId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    setCurrentPage(1)
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setProcedureSearchText(searchValue);
        await getSetProcedureCountAndPopulate(
          activeTabKey,
          searchValue,
          selectedManufacturerIds,
          1
        );
        setLoading(false);
      }, 500)
    );
  };

  /** MANUFACTURER FILTER */
  const onManufacturerSearch = (searchValue) => {
    setFilterManufacturerData(
      manufacturers.filter((x) =>
        x.distributorName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  const setFilterManufacturerData = (data) => {
    setFilteredManufacturers(
      data?.map((m) => ({
        id: m.id,
        checkboxTitle: m.distributorName,
      }))
    );
  };

  const onManufacturerSelectionChange = async (selectedIds) => {
    setSelectedManufacturerIds(selectedIds);
    setLoading(true);
    if (filterTimeOut) {
      clearTimeout(filterTimeOut);
    }
    setFilterTimeOut(
      setTimeout(async () => {
        await getSetProcedureCountAndPopulate(
          activeTabKey,
          procedureSearchText,
          selectedIds
        );
        setLoading(false);
      }, 800)
    );
  };

  const manufacturerDropdown = (
    <DropdownFilter
      filterTitle="Manufacturer"
      dropdownVisibility={dropdownVisibility}
      onDropdownVisibilityChange={(flag) => setDropdownVisibility(flag)}
      filteredData={filteredManufacturers}
      onSearch={onManufacturerSearch}
      selectedIds={selectedManufacturerIds}
      onSelectionChange={onManufacturerSelectionChange}
    />
  );
  /** MANUFACTURER FILTER END */

  /** API Calls */
  const populateProcedureList = async (
    distributorIds,
    limit,
    skip,
    searchValue
  ) => {
    const data = await distributorService.getProcedures(
      distributorIds,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setProceduresList(data);
    } else {
      toastNotification("error", "Error fetching the Procedure records");
    }
  };

  const deleteProcedure = async (id) => {
    setLoading(true);
    const isDeleted = await distributorService.deleteProcedure(id);
    if (isDeleted) {
      toastNotification("success", "Procedure deleted successfully");
      await getSetProcedureCountAndPopulate(
        activeTabKey,
        null,
        selectedManufacturerIds
      );
    } else {
      toastNotification("error", "Error deleting the Procedure");
    }
    setLoading(false);
  };

  const getSetProcedureCountAndPopulate = async (
    tabKey,
    searchText,
    manufacturerIds,
    currentPageNumber = currentPage
  ) => {
    let ids =
      tabKey === "1"
        ? manufacturerIds?.length === 0
          ? manufacturers?.map((m) => m.id)
          : manufacturerIds
        : distributorId;
    ids = isDeletedPage ? distributorId : ids;

    if (typeof ids === "object" && ids.length === 0) {
      setProceduresList([]);
    } else {
      const success = await distributorService.getProcedureCount(
        ids,
        "global",
        isDeletedPage,
        searchText
      );

      if (success) {
        setProcedureCount(success.count);
        if (success.count > 0)
          await populateProcedureList(ids, customPageSize, customPageSize * (currentPageNumber - 1), searchText);
        else setProceduresList([]);
      } else {
        setProceduresList([]);
        toastNotification("error", "Error fetching Procedure records");
      }
    }
  };

  const getDistributor = async () => {
    const distributor = await distributorService.getDistributor(
      distributorId,
      true
    );
    if (distributor) {
      const filteredManufacturers = utilityService.filterSelectedEntity(distributor.manufacturers);
      setManufacturers(filteredManufacturers);
      setFilterManufacturerData(filteredManufacturers);
      return distributor;
    } else {
      toastNotification("error", "Requested Distributor record not found");
    }
  };
  /** API Calls End */

  const cloneProcedure = async (procedureId, ownerId) => {
    setLoading(true);
    const newProcedure = await procedureService.postCloneProcedure(procedureId, ownerId, "distributor");

    if (newProcedure) {
      history.push(`/distributor/${distributorId}/procedure/${newProcedure.id}`, {
        isCloneProcedure: true
      });
    } else {
      toastNotification("error", "Error cloning the Procedure");
    }
    setLoading(false);
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const distributorTemp = await getDistributor();
      const filteredManufacturers = utilityService.filterSelectedEntity(distributorTemp.manufacturers);
      if (distributorTemp) {
        await getSetProcedureCountAndPopulate(
          activeTabKey,
          null,
          filteredManufacturers?.map((m) => m.id)
        );
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        searchPlaceholderTxt="Search by Procedure Name"
        onSearchFunction={onSearchFunction}
        {...(isDeletedPage
          ? {
            title: "Deleted Procedure",
            backBtnPath: `/distributor/${distributorId}/procedure`,
          }
          : {
            title: "Procedures",
            ...(activeTabKey === "1"
              ? { dropDown: manufacturerDropdown }
              : {
                btnText: "Create Procedure",
                onBtnClick: () => {
                  history.push(`${url}/new`);
                },
                topActionMenuItems: [
                  {
                    title: "Show Deleted Procedures",
                    path: `/distributor/${distributorId}/distributor-procedure/deleted`,
                  },
                ],
              }),
          })}
      >
        {!isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setProcedureSearchText("");
              setCurrentPage(1);
              setActiveTabKey(key);
              await getSetProcedureCountAndPopulate(
                key,
                procedureSearchText,
                selectedManufacturerIds,
                1
              );
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Procedures" key="1" />
            <TabPane tab="My Procedures" key="2" />
          </Tabs>
        )}
        <CardList
          dataSource={procedureList}
          pagination={{
            pageSize: customPageSize,
            total: procedureCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              const ids =
                activeTabKey === "1"
                  ? selectedManufacturerIds.length === 0
                    ? manufacturers.map((m) => m.id)
                    : selectedManufacturerIds
                  : distributorId;
              await populateProcedureList(
                isDeletedPage ? distributorId : ids,
                pageSize,
                pageSize * (page - 1),
                procedureSearchText
              );
              setLoading(false);
            },
          }}
          renderItem={(procedure) => {
            return (
              <List.Item>
                <CustomCard
                  headerIcon={<ProcedureIcon />}
                  title={procedure.procedureName}
                  actions={actions(procedure)}
                  onClick={
                    isDeletedPage ? null : () => {
                      history.push(
                        `/distributor/${distributorId}/procedure/${procedure.id}/table-setup`,
                        {
                          record: procedure,
                          pageNumber: currentPage,
                          activeTabKey: activeTabKey
                        }
                      );
                    }}
                  bodyContent={cardContent(procedure)}
                />
              </List.Item>
            );
          }}
        />
      </MainContainer>
    </Spin>
  );
};
export default DistributorProcedurePage;
