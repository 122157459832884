import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Layout,
  Divider,
  Drawer,
  Typography,
  Spin,
  Select,
  Tag,
  Modal,
} from "antd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import MultiBackend, {
  TouchTransition,
  Preview,
} from "react-dnd-multi-backend";
import {
  layoutForTwoLayer,
  layoutForTwoLayerCopy,
  layoutForTwoLayer2WideCopy,
  layoutForTwoLayer2Wide,
} from "../../config/grid-layout";
import ImageCard from "../../components/ImageCard";
import MainContainer from "../../containers/MainContainer";
import ExecutionLandingPage from "../Distributor/ExecutionLandingPage";
import Grid from "../../components/Grid";
import SignedURLImage from "../../components/SignedURLImage";
import { ReactComponent as IconTrayOrange } from "../../assets/icons/iconTrayOrange.svg";
import { ReactComponent as IconTrayBlack } from "../../assets/icons/iconTrayBlack.svg";
import { ReactComponent as IconTrayWhite } from "../../assets/icons/iconTrayWhite.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/iconEdit.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/iconCloseSmall.svg";
import toastNotification from "../../components/toastNotification";
import distributorService from "../../services/distributorService";
import trayService from "../../services/trayService";
import { Roles } from "../../config/role-config.json";
import { CaseTypes } from "../../config/case-types.json";
import { useAuthContext } from "../../context/AuthContext";
import TableSetupAllEquipments from "../../containers/TableSetup/TableSetupAllInstruments";

const { Paragraph } = Typography;
const { Content } = Layout;
const { CheckableTag } = Tag;
const { Option } = Select;
const { confirm } = Modal;
const customPageSize = 30;

const TableSetupPage = (props) => {
  const { match, history, location } = props;
  const { state } = location;

  const tagsData = ["Table Setup", "Surgical Flow"];

  const procedureId = match.params?.procedureid;
  const procedureEntityId = match.params?.id;

  const { role: userRole, id: userId } = useAuthContext().user;

  const [initialTableData, setInitialTableData] = useState([]);
  const [selectedTag, setSelectedTag] = useState(
    state?.tag ?? "Table Setup"
  );
  const [procedure, setProcedure] = useState();
  const [isProcedureEditable, setIsProcedureEditable] = useState(false);
  const [backBtnPath, setBackBtnPath] = useState(
    location.state?.preview
      ? location.state?.path
      : match.url.replace(`/${procedureId}/table-setup`, "")
  );
  //Tray Management drawer open or close state.
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  //Save Button needs to be enabled or disabled state.Default as there's no change save should be disabled.
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tableMode, setTableMode] = useState("3WIDE");

  //Layout of Zone1 to update the orientation in table data
  const [zone1Setup, setZone1Setup] = useState([]);

  //Data of the table which need to be passed through the child containing data about the trays.
  const [tableData, setTableData] = useState([]);
  const [modifiedTrayDetails, setModifiedTrayDetails] = useState();

  // Get instruments details of all instruments side drawer
  const [allEquipmentDetails, setAllEquipmentDetails] = useState();

  //Drag and Drop HTML5toTouch properties
  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
        preview: true,
      },
      {
        backend: TouchBackend, // Note that you can call your backends with options
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  //Preview generated while dragging image in touch events
  const generatePreview = ({ itemType, item, style }) => {
    const newStyle = {
      ...style,
      width: "20%",
      height: "20%",
      zIndex: "5",
      backgroundColor: "rgb(254,239,232)",
      border: "1px solid rgb(255,181,147)",
    };
    const src = item.src
      ? //Dragging Image from sidebar to layer
      item.src
      : //Dragging image from one tray to another tray
      document.getElementById(item.id).childNodes[0].childNodes[0].src;

    return (
      <div style={newStyle}>
        <SignedURLImage
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          fileName={src}
          key={src}
        />
      </div>
    );
  };

  //To toggle the sidebar
  const toggleDrawer = () => {
    setDrawerVisibility((currentState) => !currentState);
  };

  //To toggle between table setup and execution flow
  const handleChange = (tag) => {
    setSelectedTag(tag);
  };

  //Clear Table Confirmation Modal
  const clearTableConfirmation = () => {
    confirm({
      title: "Clear Table",
      content:
        "This action will clear all the trays arranged over the OR table and move them back to the trays management section.",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        clearTable();
      },
      icon: <></>,
    });
  };

  //Change table Setup Confirmation Model
  const changeTableConfirmation = (value) => {
    confirm({
      title: "Change Table Setup",
      content:
        "This action will clear all the trays arranged over the OR table and move them back to the trays management section.",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        await distributorService.patchProcedure(procedureId, {
          tableMode: value,
        });
        clearTable();
        const tableSetUp = tableData.map((el) => {
          return {
            x: el.x,
            y: el.y,
            w: el.w,
            h: el.h,
            tray: el.tray.id,
            sectionNumber: el.sectionNumber,
            orientation: el.orientation,
          };
        });
        const sendTableData = { tableSetUp };
        await distributorService.postTableSetup(procedureId, sendTableData);
        setTableMode(value);
        setInitialTableData(tableSetUp);
        setZone1Setup([]);
      },
      icon: <></>,
    });
  };

  //On Close button clicked
  const onCloseButtonClick = (src, id) => {
    setTableData(
      tableData.map((el) => {
        if (el.tray.id === id) el.sectionNumber = null;
        return el;
      })
    );
  };

  //Changing Orientation
  const changeOrientation = (layout, id, count, setLayout) => {
    const layoutData = [...layout];
    if (layoutData[id].h === 2) {
      layoutData[id].h = 1;
      layoutData[id].w = layoutData[id].w + 12 / count;
      layoutData[id + count / 2].h = 1;
      layoutData[id + count / 2].w = layoutData[id + count / 2].w + 12 / count;
      layoutData[id + count / 2].x = layoutData[id + count / 2].x - 12 / count;
      layoutData[id + count / 2].y = 1;
    } else {
      layoutData[id].h = 2;
      layoutData[id].w = layoutData[id].w - 12 / count;
      layoutData[id + count / 2].h = 2;
      layoutData[id + count / 2].w = layoutData[id + count / 2].w - 12 / count;
      layoutData[id + count / 2].x = layoutData[id + count / 2].x + 12 / count;
      layoutData[id + count / 2].y = 0;
    }
    const changedLayout = { lg: layoutData };
    let sectionNumber = id + 1 + count;
    let index = tableData.findIndex((el) => el.sectionNumber === sectionNumber);
    const tableDataCopy = [...tableData];
    if (index > -1) {
      tableDataCopy[index] = {
        ...tableDataCopy[index],
        orientation: layoutData[id].h > 1 ? 1 : 0,
      };
    }
    sectionNumber += count / 2;
    index = tableData.findIndex((el) => el.sectionNumber === sectionNumber);
    if (index > -1) {
      tableDataCopy[index] = {
        ...tableDataCopy[index],
        orientation: layoutData[id].h > 1 ? 1 : 0,
      };
    }
    setTableData(tableDataCopy);
    setZone1Setup(changedLayout.lg);
    setLayout(changedLayout);
    layoutForTwoLayer.lg = JSON.parse(JSON.stringify(layoutForTwoLayerCopy.lg));
    layoutForTwoLayer2Wide.lg = JSON.parse(
      JSON.stringify(layoutForTwoLayer2WideCopy.lg)
    );
  };

  //Dragging image from the trays/equipments sidebar
  const dragEnd = (item, monitor) => {
    if (item.trayId) {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        if (dropResult.id === null) return;
        const dropContainer = document.getElementById(dropResult.id);
        const dropContainerChild = dropContainer?.childNodes[0];
        if (dropContainerChild === null) return;
        if (dropContainerChild.childNodes.length > 0) {
          return;
        } else {
          const layerId = dropContainerChild.id.charAt(0);
          const trayNumber = dropContainerChild.id.charAt(
            dropContainerChild.id.length - 1
          );
          const multiplier = tableMode === "3WIDE" ? 3 : 2;
          const sectionNumber =
            parseInt(layerId * multiplier) + parseInt(trayNumber) + 1;
          let orientation = 0;
          if (layerId === "2")
            orientation = zone1Setup[trayNumber]?.h > 1 ? 1 : 0;
          setTableData(
            tableData.map((el) => {
              if (el.tray.id === item.trayId) {
                el.sectionNumber = parseInt(sectionNumber);
                el.orientation = orientation;
              }
              return el;
            })
          );
        }
      }
    } else {
      const dragElement = document.getElementById(item.id);
      //Sometimes exceptions are thrown that's why if conditions

      if (dragElement === null) return;
      const dragImageElement = dragElement.childNodes[0].childNodes[0];
      const dragImageId = dragElement.childNodes[0]?.id;
      const dragImageSrc = dragImageElement?.src;
      if (dragImageSrc === undefined) return;
      const dropResult = monitor.getDropResult();
      if (dropResult === null) return;
      const dropContainer = document.getElementById(dropResult.id);
      const dropContainerChild = dropContainer?.childNodes[0];
      if (dropContainerChild === undefined) return;
      //Swapping Two trays
      if (dropContainerChild.childNodes.length > 0) {
        const dropImageId = dropContainerChild.childNodes[0].id;
        dragImageElement.src =
          dropContainerChild.childNodes[0].childNodes[0].src;
        const dragImageIndex = tableData.findIndex(
          (el) => el.tray.id === dragImageId
        );
        const dropImageIndex = tableData.findIndex(
          (el) => el.tray.id === dropImageId
        );
        const tableDataCopy = [...tableData];
        const dropTray = tableDataCopy[dropImageIndex].tray;
        tableDataCopy[dropImageIndex].tray = tableDataCopy[dragImageIndex].tray;
        tableDataCopy[dragImageIndex].tray = dropTray;

        setTableData(tableDataCopy);
      }
      //Placing trays in a different empty section
      else {
        const layerId = dropContainerChild.id.charAt(0);
        const trayNumber = dropContainerChild.id.charAt(
          dropContainerChild.id.length - 1
        );
        const multiplier = tableMode === "3WIDE" ? 3 : 2;
        const sectionNumber =
          parseInt(layerId) * multiplier + parseInt(trayNumber) + 1;
        let orientation = 0;
        if (layerId === "2")
          orientation = zone1Setup[trayNumber]?.h > 1 ? 1 : 0;
        setTableData(
          tableData.map((el) => {
            if (el.tray.id === dragImageId) {
              el.sectionNumber = parseInt(sectionNumber);
              el.orientation = orientation;
            }
            return el;
          })
        );
      }
    }
  };

  // Function called when user click on manage trays button
  const traySetup = async () => {
    setLoading(true);
    const trayManagementPath = match.url.replace(
      "table-setup",
      "tray-management"
    );

    try {
      if (!saveDisabled) {
        const tableSetUp = tableData.map((el) => ({
          x: el.x,
          y: el.y,
          w: el.w,
          h: el.h,
          tray: el.tray.id,
          sectionNumber: el.sectionNumber,
          orientation: el.orientation,
        }));

        const res = await distributorService.postTableSetup(procedureId, {
          tableSetUp,
        });
        if (res.status === 200) {
          history.push(trayManagementPath, {
            hospitalId: location.state?.hospitalId,
            path: location.state?.path,
            pageNumber: location.state?.pageNumber,
            activeTabKey: location.state?.activeTabKey,
            isCloneProcedure: location.state?.isCloneProcedure,
            tabKey: state?.tabKey
          });
        } else {
          toastNotification("error", "Error saving table setup");
        }
      } else {
        history.push(trayManagementPath, {
          hospitalId: location.state?.hospitalId,
          path: location.state?.path,
          pageNumber: location.state?.pageNumber,
          activeTabKey: location.state?.activeTabKey,
          isCloneProcedure: location.state?.isCloneProcedure,
          tabKey: state?.tabKey
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toastNotification("error", "Something went wrong");
    }
  };

  //Save Table Setup
  const saveTableSetup = async () => {
    setLoading(true);
    const tableSetUp = tableData.map((el) => {
      return {
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
        tray: el.tray.id,
        sectionNumber: el.sectionNumber,
        orientation: el.orientation,
      };
    });
    const sendTableData = { tableSetUp };
    const res = await distributorService.postTableSetup(
      procedureId,
      sendTableData
    );
    if (res.status === 200) {
      setInitialTableData(tableSetUp);
      toastNotification("success", "Table Setup saved");
    } else {
      toastNotification("error", "Unable to save table setup");
    }
    setLoading(false);
  };

  const clearTable = () => {
    const tableDataCopy = tableData.map((el) => {
      el.sectionNumber = null;
      el.orientation = 0;
      return el;
    });

    setTableData(tableDataCopy);
  };

  const getProcedureDetails = async () => {
    const procedureDtl = await distributorService.getProcedure(
      procedureId,
      true
    );
    if (procedureDtl) {
      if (procedureDtl.tableMode) {
        setTableMode(procedureDtl.tableMode);
      }
      setProcedure(procedureDtl);

      setPageAccess(procedureDtl);

      if (procedureDtl.case?.hospital) {
        //TODO :: check if cases or my-cases
        let tempPath = `/hospital/${procedureDtl.case.hospital}/cases/${procedureDtl.case.id}`;
        if (
          [Roles.HOSPITAL_STAFF, Roles.SURGEON].includes(userRole) &&
          location.state?.path
        ) {
          tempPath = location.state?.path;
        }
        setBackBtnPath(tempPath);
      } else if (
        !match.url.includes("distributor") &&
        !match.url.includes("manufacturer")
      ) {
        setBackBtnPath((location.state?.preview || location.state?.showModalPopUp) ? location.state?.path : "/");
      }
    } else toastNotification("error", "Error in fetching data");
  };

  const setPageAccess = (procedureDtl) => {
    if (procedureDtl.case) {
      const usersWhoCanEditCaseProcedure = [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.HOSPITAL_STAFF,
      ];
      if (
        (procedureDtl.case.isStarted === CaseTypes.UPCOMING &&
          usersWhoCanEditCaseProcedure.includes(userRole)) ||
        (userRole === Roles.SURGEON
          && procedureDtl.case.surgeon === userId
          && procedureDtl.case.isStarted !== CaseTypes.COMPLETED)
      )
        setIsProcedureEditable(true);
    } else {
      if (
        procedureDtl.distributor === procedureEntityId &&
        [
          Roles.DOR_ADMIN,
          Roles.DISTRIBUTOR_ADMIN,
          Roles.MR,
          Roles.MANUFACTURER_ADMIN,
        ].includes(userRole)
      )
        setIsProcedureEditable(true);
    }
  };

  // Get table details
  const getTableDetails = async () => {
    try {
      let count = customPageSize;
      let temp = [];

      for (let skipPage = 0; count === customPageSize; skipPage++) {
        const data = await distributorService.getTableSetup(
          procedureId,
          null,
          customPageSize,
          skipPage * customPageSize
        );
        if (data) {
          temp = temp.concat(data);
          count = data.length;
        } else count = 0;
      }
      if (temp.length === 0) {
        setLoading(false);
      }
      temp.forEach((el) => {
        initialTableData.push({
          x: el.x,
          y: el.y,
          w: el.w,
          h: el.h,
          tray: el.tray.id,
          sectionNumber: el.sectionNumber,
          orientation: el.orientation,
        });
      });
      setTableData(temp);
    } catch (err) {
      console.log(err);
    }
  };

  //API Calling
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!procedure) await getProcedureDetails();
      if (selectedTag === "Table Setup")
        await getTableDetails();
      else
        setLoading(false);
    })();
  }, [selectedTag]);

  //Check if table has been modified.If modifies enable save button.
  useEffect(() => {
    const tableSetUp = tableData.map((el) => {
      return {
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
        tray: el.tray.id,
        sectionNumber: el.sectionNumber,
        orientation: el.orientation,
      };
    });
    if (tableData.length > 0) {
      setSaveDisabled(
        JSON.stringify(tableSetUp) === JSON.stringify(initialTableData)
      );
      let trayDetails = {};
      for (const table of tableData) {
        table.tray.sectionNumber = table.sectionNumber
        trayDetails[table.tray?.id] = table.tray
      }
      setModifiedTrayDetails(trayDetails);
    }
  }, [tableData, initialTableData]);

  useEffect(() => {
    const trays = [];
    modifiedTrayDetails && Object.keys(modifiedTrayDetails).forEach(async (key, index) => {
      const trayEquipmentList = await trayService.getTrayEquipments(key);
      trayEquipmentList.forEach((el) => el.sectionNumber = modifiedTrayDetails[key].sectionNumber)
      trays.push({
        tray: modifiedTrayDetails[key],
        equipment: trayEquipmentList
      });
      if (index === Object.keys(modifiedTrayDetails).length - 1) {
        setLoading(false);
      }
    })
    setAllEquipmentDetails(trays);
  }, [modifiedTrayDetails]);

  const editButton = (
    <Button
      ghost={true}
      onClick={() => history.push(
        match.url.replace("/table-setup", ""), {
        pageNumber: state?.pageNumber,
        activeTabKey: state?.activeTabKey,
        tabKey: state?.tabKey,
        isCloneProcedure: location.state?.isCloneProcedure,
      })}
    >
      <EditIcon />
    </Button>
  );

  //Table Setup / Execution Flow Button
  const tag = (
    <div
      style={{
        display: "inherit",
        border: "1px solid black",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag}
          className="table-setup-tags"
          style={
            selectedTag?.indexOf(tag) > -1
              ? { backgroundColor: "#000", color: "white" }
              : null
          }
          checked={selectedTag === tag > -1}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag}
        </CheckableTag>
      ))}
    </div>
  );

  // Trays Management Button
  const traysManagement = (
    <Button className="tray-management-button" onClick={toggleDrawer}>
      <IconTrayOrange />
      Trays Management
    </Button>
  );

  //Title
  const title = (
    <Paragraph
      style={{ margin: "0 0.5em", whiteSpace: "normal" }}
      ellipsis={{ rows: 2 }}
    >
      {procedure?.procedureName}
    </Paragraph>
  );

  //To have a footer if there are trays in the sidebar
  const footer = tableData.some((el) => el.sectionNumber === null) ? (
    <Button onClick={traySetup} type="primary">
      Manage Trays
    </Button>
  ) : null;

  //Drawer
  const drawer = (
    <Drawer
      footer={footer}
      footerStyle={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      zIndex={200}
      title={
        <span style={{ display: "flex", alignItems: "center" }}>
          <IconTrayBlack /> Trays Management
        </span>
      }
      mask={false}
      maskClosable={false}
      onClose={toggleDrawer}
      closeIcon={<CloseIcon />}
      placement="left"
      width="17rem"
      getContainer={false}
      style={{ position: "absolute", height: "calc(100vh - 70px)" }}
      visible={drawerVisibility}
    >
      {tableData.some((el) => el.sectionNumber === null) && (
        <Fragment>
          {tableData.map((el, id) => {
            if (el.sectionNumber === null) {
              return (
                <ImageCard
                  trayId={el.tray.id}
                  key={id}
                  id={id}
                  src={el.tray.image}
                  name={el.tray.trayName}
                  dragEnd={dragEnd}
                />
              );
            }
          })}
        </Fragment>
      )}
      {!tableData.some((el) => el.sectionNumber === null) && (
        <Fragment>
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconTrayWhite />
            <div>No Trays Added yet.</div>
            <div>Start adding now.</div>
            <Button
              style={{ marginRight: "0", marginTop: "20px" }}
              onClick={traySetup}
              type="primary"
            >
              Manage Trays
            </Button>
          </div>
        </Fragment>
      )}
    </Drawer>
  );

  return (
    <>
      <Spin spinning={loading}>
        <div className="table-setup-main-container">
          <MainContainer
            tags={tag}
            subTitle={isProcedureEditable ? editButton : null}
            title={title}
            backBtnPath={backBtnPath}
            routeState={{
              pageNumber: state?.pageNumber,
              activeTabKey: state?.activeTabKey,
              tabKey: state?.tabKey,
              isProcedurePage: true, showModalPopUp: state?.showModalPopUp
            }}
            history={history}
            {...location?.state?.isCloneProcedure && selectedTag === "Surgical Flow" ? {
              btnText: "Finish",
              btnType: "primary",
              onBtnClick: () => {
                history.push(match.url.replace(`/${procedureId}/table-setup`, ""), { activeTabKey: "2" });
              }
            } : {}}
          >
            {selectedTag === "Surgical Flow" ? (
              <ExecutionLandingPage
                isProcedureEditable={isProcedureEditable}
                procedure={procedure}
                showModalPopUp={state?.showModalPopUp}
                pageNumber={state?.pageNumber}
                activeTabKey={state?.activeTabKey}
                tabKey={state?.tabKey}
              />
            ) : (
              <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                <TableSetupAllEquipments allEquipmentDetails={allEquipmentDetails} />
                <Preview>{generatePreview}</Preview>
                <Divider
                  style={{
                    margin: "0px",
                    border: "1px solid rgb(209,209,209)",
                  }}
                />
                <Row style={{ position: "relative" }}>
                  {isProcedureEditable && drawer}
                  {isProcedureEditable && (
                    <Col span={24}>
                      <Row
                        className="table-setup-layout"
                        style={{ height: "63px" }}
                      >
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          style={{ height: "inherit" }}
                        >
                          {traysManagement}
                        </Col>
                        <Col
                          xl={7}
                          lg={7}
                          md={5}
                          sm={6}
                          xs={13}
                          style={{ height: "inherit" }}
                        ></Col>
                        <Col
                          xl={4}
                          lg={5}
                          md={5}
                          sm={5}
                          xs={4}
                          style={{ height: "inherit" }}
                        >
                          <div className="center">
                            <button
                              onClick={clearTableConfirmation}
                              style={{ fontSize: "1rem", margin: "0px" }}
                              className="clear-button"
                              disabled={
                                !tableData.some(
                                  (el) => el.sectionNumber !== null
                                )
                              }
                            >
                              Clear Table
                            </button>
                          </div>
                        </Col>
                        <Col
                          xl={3}
                          lg={4}
                          md={5}
                          sm={4}
                          xs={8}
                          style={{ height: "inherit", padding: "0px 20px" }}
                        >
                          <div className="center">
                            <Select
                              defaultValue="3WIDE"
                              value={tableMode}
                              onChange={async (value) => {
                                const traysOnTable = tableData.some(
                                  (el) => el.sectionNumber !== null
                                );
                                if (!traysOnTable) {
                                  try {
                                    setLoading(true);
                                    await distributorService.patchProcedure(
                                      procedureId,
                                      { tableMode: value }
                                    );
                                    setTableMode(value);
                                    setLoading(false);
                                  } catch (error) {
                                    console.error(error);
                                    setLoading(false);
                                  }
                                } else {
                                  changeTableConfirmation(value);
                                }
                              }}
                            >
                              <Option value="3WIDE">3 Wide Table</Option>
                              <Option value="2WIDE">2 Wide Table</Option>
                            </Select>
                          </div>
                        </Col>
                        <Col
                          xl={2}
                          lg={2}
                          md={2}
                          sm={4}
                          xs={8}
                          style={{ height: "inherit" }}
                        >
                          <div className="center">
                            <Button
                              style={{
                                fontSize: "1rem",
                                margin: "0px",
                                display: "flex",
                                alignItems: "center",
                                width: "80px",
                                justifyContent: "center",
                              }}
                              onClick={saveTableSetup}
                              type="primary"
                              disabled={saveDisabled}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                        <Col
                          xl={2}
                          lg={2}
                          md={2}
                          sm={4}
                          xs={8}
                          style={{ height: "inherit" }}
                        ></Col>
                      </Row>
                    </Col>
                  )}
                  <Col span={24}>
                    <Divider
                      style={{
                        margin: "0px",
                        border: "1px solid rgb(209,209,209)",
                      }}
                    />
                  </Col>
                  <Col
                    span={24}
                    style={{
                      overflow: "scroll",
                      height: "calc(100vh - 85px)",
                      paddingBottom: "30px",
                    }}
                  >
                    <Content>
                      <Row style={{ justifyContent: "center" }}>
                        <Col
                          span={tableMode === "3WIDE" ? 18 : 14}
                          style={{ marginBottom: "30px" }}
                        >
                          <Grid
                            tableData={tableData}
                            tableMode={tableMode}
                            changeOrientation={changeOrientation}
                            dragEnd={dragEnd}
                            onCloseButtonClick={onCloseButtonClick}
                            isProcedureEditable={isProcedureEditable}
                            isCompletedCasesPage={location.state?.isCompletedCasesPage}
                          />
                        </Col>
                      </Row>
                    </Content>
                  </Col>
                </Row>
              </DndProvider>
            )}
          </MainContainer>
        </div>
      </Spin>
    </>
  );
};

export default TableSetupPage;
