import { useState, useEffect } from "react";
import { List, Card, Modal, Typography, Tabs, Spin } from "antd";
import MainContainer from "../../containers/MainContainer";
import ActionMenu from "../../components/ActionMenu";
import SignedURLImage from "../../components/SignedURLImage";
import DropdownFilter from "../../components/DropdownFilter";
import equipmentService from "../../services/equipmentService";
import distributorService from "../../services/distributorService";
import utilityService from "../../services/utilityService";
import toastNotification from "../../components/toastNotification";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const customPageSize = 12;

const DistributorEquipmentsPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const isDeletedPage = url.includes("deleted");
  const distributorId = params?.id;

  const [activeTabKey, setActiveTabKey] = useState(location.state?.activeTabKey ?? "2");
  const [equipmentList, setEquipmentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [equipmentSearchText, setEquipmentSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(0);
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);

  const actions = (equipment) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(`${url}/${equipment.id}`, {
              record: equipment,
              pageNumber: currentPage,
              activeTabKey: activeTabKey
            });
          },
        },
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(equipment.id);
          },
        },
      ];
  };

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        searchValue = searchValue.trim();
        setEquipmentSearchText(searchValue);
        await getSetEquipmentCountAndPopulate(
          activeTabKey,
          searchValue,
          selectedManufacturerIds,
          1
        );
        setLoading(false);
      }, 500)
    );
  };

  /** Manufacturer Filter */
  const onManufacturerSearch = (searchValue) => {
    setFilterManufacturerData(
      manufacturers.filter((x) =>
        x.distributorName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  const setFilterManufacturerData = (data) => {
    setFilteredManufacturers(
      data?.map((m) => ({
        id: m.id,
        checkboxTitle: m.distributorName,
      }))
    );
  };

  const onManufacturerSelectionChange = async (selectedIds) => {
    setSelectedManufacturerIds(selectedIds);
    setLoading(true);
    if (filterTimeOut) {
      clearTimeout(filterTimeOut);
    }
    setFilterTimeOut(
      setTimeout(async () => {
        await getSetEquipmentCountAndPopulate(
          activeTabKey,
          equipmentSearchText,
          selectedIds
        );
        setLoading(false);
      }, 800)
    );
  };

  const manufacturerDropdown = (
    <DropdownFilter
      filterTitle="Manufacturer"
      dropdownVisibility={dropdownVisibility}
      onDropdownVisibilityChange={(flag) => setDropdownVisibility(flag)}
      filteredData={filteredManufacturers}
      onSearch={onManufacturerSearch}
      selectedIds={selectedManufacturerIds}
      onSelectionChange={onManufacturerSelectionChange}
    />
  );
  /** Manufacturer Filter Ends */

  const showDeleteConfirmation = (equipmentId) => {
    confirm({
      title: "Delete Instrument",
      content: "Are you sure you want to delete this Instrument?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteEquipment(equipmentId);
      },
    });
  };

  /** API */
  const populateEquipmentsList = async (
    distributorIds,
    limit,
    skip,
    searchValue
  ) => {
    const data = await equipmentService.getDistributorEquipments(
      distributorIds,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );

    if (data) {
      setEquipmentList(data);
    } else {
      toastNotification("error", "Error fetching the instrument records");
    }
  };

  const deleteEquipment = async (equipmentId) => {
    setLoading(true);
    const isDeleted = await equipmentService.patchEquipment({
      id: equipmentId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Instrument deleted successfully");
      setEquipmentSearchText("");
      await getSetEquipmentCountAndPopulate(
        activeTabKey,
        null,
        selectedManufacturerIds
      );
    } else {
      toastNotification("error", "Error deleting the instrument");
    }
    setLoading(false);
  };

  const getDistributor = async () => {
    const distributor = await distributorService.getDistributor(
      distributorId,
      true
    );
    if (distributor?.manufacturers) {
      const filteredManufacturers = utilityService.filterSelectedEntity(distributor.manufacturers);
      setManufacturers(filteredManufacturers);
      setFilterManufacturerData(filteredManufacturers);
      return distributor;
    } else {
      toastNotification("error", "Requested Distributor record not found");
    }
  };

  const getSetEquipmentCountAndPopulate = async (
    tabKey,
    searchText,
    manufacturerIds,
    currentPageNumber = currentPage
  ) => {
    let ids =
      tabKey === "1"
        ? manufacturerIds.length === 0
          ? manufacturers.map((m) => m.id)
          : manufacturerIds
        : distributorId;
    ids = isDeletedPage ? distributorId : ids;

    if (typeof ids === "object" && ids.length === 0) {
      setEquipmentList([]);
    } else {
      const success = await equipmentService.getEquipmentCount(
        ids,
        isDeletedPage,
        searchText
      );

      if (success !== false) {
        setEquipmentCount(success);
        if (success > 0)
          await populateEquipmentsList(ids, customPageSize, customPageSize * (currentPageNumber - 1), searchText);
        else setEquipmentList([]);
      } else {
        setEquipmentList([]);
        toastNotification("error", "Error fetching Instrument records");
      }
    }
  };

  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const distributorTemp = await getDistributor();
      const filteredManufacturers = utilityService.filterSelectedEntity(distributorTemp.manufacturers);
      await getSetEquipmentCountAndPopulate(
        activeTabKey,
        null,
        filteredManufacturers?.map((m) => m.id)
      );
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Instrument Name"
      onSearchFunction={onSearchFunction}
      {...(isDeletedPage
        ? {
          title: "Deleted Instruments",
          backBtnPath: `/distributor/${distributorId}/equipment`,
        }
        : {
          title: "Instruments",
          ...(activeTabKey === "1"
            ? { dropDown: manufacturerDropdown }
            : {
              btnText: "Add Instrument",
              onBtnClick: () => {
                history.push(`${url}/new`);
              },
              topActionMenuItems: [
                {
                  title: "Show Deleted Instruments",
                  path: `${url}/deleted`,
                },
              ],
            }),
        })}
    >
      <Spin spinning={loading}>
        {!isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setActiveTabKey(key);
              setCurrentPage(1)
              await getSetEquipmentCountAndPopulate(
                key,
                equipmentSearchText,
                selectedManufacturerIds,
                1
              );
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Instruments" key="1" />
            <TabPane tab="My Instruments" key="2" />
          </Tabs>
        )}
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 3,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 6,
          }}
          dataSource={equipmentList}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            responsive: true,
            pageSize: customPageSize,
            total: equipmentCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              const ids =
                activeTabKey === "1"
                  ? selectedManufacturerIds.length === 0
                    ? manufacturers.map((m) => m.id)
                    : selectedManufacturerIds
                  : distributorId;
              await populateEquipmentsList(
                isDeletedPage ? distributorId : ids,
                pageSize,
                pageSize * (page - 1),
                equipmentSearchText
              );
              setLoading(false);
            },
          }}
          renderItem={(equipment) => {
            return (
              <List.Item>
                <Card
                  onClick={
                    isDeletedPage ? null : () => {
                    history.push(`${url}/${equipment.id}`, {
                      isPageReadOnly: true,
                      record: equipment,
                      pageNumber: currentPage,
                      activeTabKey: activeTabKey
                    });
                  }}
                  bordered={false}
                  cover={
                    <div
                      className="icon-overlay-container"
                      style={{ width: "100%", height: "176px" }}
                    >
                      <SignedURLImage
                        fileName={equipment.image[0]}
                        height="100%"
                        width="100%"
                        fallback={equipmentPlaceholder}
                        key={equipment.image[0]}
                      />
                      {!equipment.distributor?.isManufacturer && (
                        <div className="icon-overlay">
                          <ActionMenu actions={actions(equipment)} />
                        </div>
                      )}
                    </div>
                  }
                  preview="false"
                  bodyStyle={{ padding: "0" }}
                />
                <Paragraph
                  style={{ marginTop: "0.5em", marginBottom: "1em" }}
                  ellipsis={{ rows: 2 }}
                >
                  {equipment.equipmentName}
                </Paragraph>
              </List.Item>
            );
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default DistributorEquipmentsPage;
