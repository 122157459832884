import React, { useState } from "react";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import routes, { BreadCrumbData } from "./routes/index.routes";
import HomePageContainer from "./containers/HomePageContainer";
import MiniAppContainer from "./containers/MiniAppContainer";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import AuthorizedRoute from "./components/AuthorizedRoute";
import PageNotFound from "./pages/PageNotFound";
import authService from "./services/authService";
import { Roles } from "./config/role-config.json";
import { AuthContext } from "./context/AuthContext";
import { BreadCrumbContext } from "./context/BreadCrumbContext";
import { HospitalContext } from "./context/HospitalContext";
import "antd/dist/antd.less";
import "./App.less";

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const setAuthContext = (user) => setUser(user);

  const [breadCrumb, setBreadCrumb] = useState("");
  const [hospitalDistributors, setHospitalDistributors] = useState([]);

  const getRoleBasedHomePage = () => {
    switch (user.role) {
      case Roles.DOR_ADMIN:
        return "/hospital";
      case Roles.HOSPITAL_ADMIN:
        return user.hospital ? `/hospital/${user.hospital[0].id}/cases` : null;
      case Roles.HOSPITAL_STAFF:
        return user.hospital
          ? `/hospital/${user.hospital[0].id}/my-cases`
          : null;
      case Roles.SURGEON:
        return user.hospital
          ? `/hospital/${user.hospital[0].id}/my-cases`
          : null;
      case Roles.DISTRIBUTOR_ADMIN:
        return user.distributor
          ? `/distributor/${user.distributor}/hospital`
          : null;
      case Roles.MR:
        return user.distributor
          ? `distributor/${user.distributor}/hospital`
          : null;
      case Roles.MANUFACTURER_ADMIN:
        return user.distributor
          ? `/manufacturer/${user.distributor}/hospital`
          : null;
      case Roles.AREA_MANAGER:
        return user.area?.manufacturer
          ? `/manufacturer/${user.area.manufacturer}/area/${user.area.id}/hospital`
          : null;
      case Roles.REGIONAL_MANAGER:
        return user.area?.manufacturer
          ? `/manufacturer/${user.area.manufacturer}/area/${user.area.id}/regional-manager/${user.id}/hospital`
          : null;
      case Roles.MMR:
        return user?.area && user.regionalManager && user.manufacturer
          ? `/manufacturer/${user.manufacturer}/area/${user.area.id}/regional-manager/${user.regionalManager}/medical-representative/${user.id}/hospital`
          : null;
      default:
        return null;
    }
  };

  return (
    <div className="App" style={{ height: "100vh" }}>
      <AuthContext.Provider value={{ user, setAuthContext }}>
        <BreadCrumbContext.Provider value={{ breadCrumb, setBreadCrumb }}>
          <HospitalContext.Provider
            value={{ hospitalDistributors, setHospitalDistributors }}
          >
            <BrowserRouter>
              <Switch>
                <Route path="/page-not-found" exact component={PageNotFound} />
                {routes.publicRoutes.map(({ path, component, exact }) => (
                  <PublicRoute
                    key={path}
                    path={path}
                    component={component}
                    exact={exact}
                  />
                ))}
                <ProtectedRoute
                  path="*"
                  render={() => (
                    <Switch>
                      <Route
                        exact
                        path={[
                          "*/procedure/new",
                          "*/procedure/:procedureid/*",
                          "*/procedure/:procedureid",
                          "*/case/:caseid/live-case",
                        ]}
                      >
                        <MiniAppContainer>
                          <Switch>
                            {routes.miniAppRoutes.map(
                              (
                                {
                                  path,
                                  component,
                                  exact,
                                  rolesAccess,
                                  redirection,
                                },
                                index
                              ) => (
                                <AuthorizedRoute
                                  key={index}
                                  path={path}
                                  component={component}
                                  exact={exact}
                                  rolesAccess={rolesAccess}
                                  redirection={redirection}
                                />
                              )
                            )}
                          </Switch>
                        </MiniAppContainer>
                      </Route>
                      <Route exact path="/">
                        <Redirect to={getRoleBasedHomePage()} />
                      </Route>
                      <Route path="*">
                        <HomePageContainer>
                          <Switch>
                            {routes.privateRoutes.map(
                              (
                                {
                                  path,
                                  component,
                                  exact,
                                  rolesAccess,
                                  redirection,
                                },
                                index
                              ) => (
                                <AuthorizedRoute
                                  key={index}
                                  path={path}
                                  component={component}
                                  exact={exact}
                                  rolesAccess={rolesAccess}
                                  redirection={redirection}
                                />
                              )
                            )}
                            <Route path="*">
                              <Redirect to="/page-not-found" />
                            </Route>
                          </Switch>
                        </HomePageContainer>
                      </Route>
                    </Switch>
                  )}
                />
              </Switch>
            </BrowserRouter>
            <BreadCrumbData />
          </HospitalContext.Provider>
        </BreadCrumbContext.Provider>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
