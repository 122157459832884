import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { ReactComponent as HospitalIcon } from "../assets/icons/sider-menu-icons/iconHospitals.svg";
import { ReactComponent as TrayIcon } from "../assets/icons/distributor-menu/iconTray.svg";
import { ReactComponent as EquipmentIcon } from "../assets/icons/sider-menu-icons/iconEquipment.svg";
import { ReactComponent as ProcedureIcon } from "../assets/icons/sider-menu-icons/icon_procedures.svg";
import { ReactComponent as AreaIcon } from "../assets/icons/sider-menu-icons/iconArea.svg";
import { ReactComponent as AdminIcon } from "../assets/icons/sider-menu-icons/iconAdmin.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/sider-menu-icons/iconSetting.svg";
import { Roles } from "./role-config.json";

const manufacturerMenuItems = (manufacturerId) => {
  const parentPath = `/manufacturer/${manufacturerId}`;
  return [
    {
      name: "Admin Panel",
      path: "/manufacturer",
      icon: <BackIcon />,
      rolesAccess: [Roles.DOR_ADMIN],
    },
    {
      name: "Hospitals",
      path: `${parentPath}/hospital`,
      icon: <HospitalIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Procedures",
      path: `${parentPath}/procedure`,
      icon: <ProcedureIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Trays",
      path: `${parentPath}/tray`,
      icon: <TrayIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Instruments",
      path: `${parentPath}/equipment`,
      icon: <EquipmentIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Areas",
      path: `${parentPath}/area`,
      icon: <AreaIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Manufacturer Admins",
      path: `${parentPath}/user`,
      icon: <AdminIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Manufacturer Setup",
      path: `${parentPath}/setup`,
      icon: <SettingIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
  ];
};

export default manufacturerMenuItems;
