import { useEffect, useState } from "react";
import { Row, Col, Spin, List, Input, Typography, Tabs } from "antd";
import { Link, withRouter, useHistory, useRouteMatch } from "react-router-dom";
import ProcedureList from "../../components/ProcedureList";
import CustomCard from "../../components/CustomCard";
import DropdownFilter from "../../components/DropdownFilter";
import toastNotification from "../../components/toastNotification";
import hospitalService from "../../services/hospitalService";
import distributorService from "../../services/distributorService";
import { ReactComponent as ProcedureColorIcon } from "../../assets/icons/hospital-case/color-icons_procedure.svg";
import { ReactComponent as DistributorIcon } from "../../assets/icons/hospital-case/icon_manifacturers.svg";
import { ReactComponent as ManufacturerIcon } from "../../assets/icons/smallManufacturerGrey.svg";
import utilityService from "../../services/utilityService";

const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;
const customPageSize = 8;

const GlobalProcedureLibraryPage = ({
  closeModel,
  hospitalCase,
  updateCase,
  showModalPopUp,
  pageNumber,
  tabKey
}) => {
  const history = useHistory();
  const routes = useRouteMatch();

  const [globalProcedureList, setGlobalProcedureList] = useState([]);
  const [currentProcPage, setCurrentProcPage] = useState(1);
  const [procedureCount, setProcedureCount] = useState(0);
  const [procedureSearchText, setProcedureSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [manufacturers, setManufacturers] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [regionalManagerIds, setRegionalManagerIds] = useState([]);
  const [regionalManager, setRegionalManager] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRmIds, setSelectedRmIds] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilterTags, setSelectedFilterTags] = useState([
    "Manufacturer",
    "Distributor",
  ]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [filterSearchText, setFilterSearchText] = useState();
  const [filteredIds, setFilteredIds] = useState([]);
  const [filteredDcMfcIds, setFilteredDcMfcIds] = useState([]);
  const [filteredRmIds, setFilteredRmIds] = useState([]);

  const formFilterData = (data) => {
    // get DC, MFC ids and other info based on filter
    setFilteredIds(data.map(x => {
      return {
        id: x.id,
        isManufacturer: x.isManufacturer,
        distributorName: x.distributorName,
      }
    }
    ))
    setFilteredData(
      data.map((x) => ({
        id: x.id,
        checkboxTitle: (
          <Row style={{ flexWrap: "nowrap" }}>
            <Col style={{ marginTop: "3px" }}>
              {x.isManufacturer ? (
                <ManufacturerIcon height="19px" />
              ) : (
                  <DistributorIcon height="19px" />
                )}
            </Col>
            <Col>
              <Row>
                {/* Temporary fix done. Correct css later */}
                <Text style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  wordBreak: "break-all",
                  width: x.distributorName.length > 45 ? "65%" : ""
                }}>
                  {x.distributorName}</Text>
              </Row>
              <Row>
                <Text style={{ color: "grey", fontSize: "12px" }}>
                  {x.isManufacturer ? "Manufacturer" : "Distributor"}
                </Text>
              </Row>
            </Col>
          </Row>
        ),
      }))
    );
  };

  const onSelectProcedure = async (procedureId) => {
    setLoading(true);
    const data = await distributorService.postAttachProcedure(
      procedureId,
      hospitalCase.id
    );
    if (data) {
      toastNotification("success", "Procedure attached");
      const updatedCase = { ...hospitalCase, procedure: data };
      updateCase(updatedCase);
      history.replace({ state: { record: updatedCase, pageNumber: pageNumber, tabKey: tabKey } });
      closeModel();
    } else {
      toastNotification("error", "Error attaching the Procedure");
    }
    setLoading(false);
  };

  const onProcedureSearchFunction = async (searchValue) => {
    if (activeTabKey === "1") {
      setLoading(true);
      if (timeOut) {
        clearTimeout(timeOut);
      }
      setTimeOut(
        setTimeout(async () => {
          searchValue = searchValue.trim();
          setProcedureSearchText(searchValue);
          // Get RM listed of selected MFC
          await getSetCountAndPopulateProcedures(selectedIds, searchValue, selectedIds.length
            ? JSON.stringify(selectedRmIds) : JSON.stringify(regionalManagerIds));
          setLoading(false);
        }, 500)
      );
    }
  };

  const onProcedurePageChange = async (page, pageSize) => {
    setLoading(true);
    setCurrentProcPage(page);
    // if any filtered tag is selected then populate procedure with pagination
    if (selectedFilterTags.length === 1) {
      await populateProcedures(
        selectedIds.length ? selectedIds : filteredDcMfcIds,
        pageSize,
        pageSize * (page - 1),
        procedureSearchText,
        selectedIds.length ? selectedRmIds : filteredRmIds
      );
    } else {
      let ids = selectedIds;
      if (!selectedIds.length)
        ids = distributors.concat(manufacturers).map((x) => x.id);
      await populateProcedures(
        ids,
        pageSize,
        pageSize * (page - 1),
        procedureSearchText,
        selectedIds.length ? selectedRmIds : regionalManagerIds
      );
    }
    setLoading(false);
  };

  /** Distributor/Manufacturer Filter */
  const onFilterSelectionChange = async (selected_ids) => {
    setSelectedIds(selected_ids);
    // Get RM listed of selected MFC
    const rmIds = getRegionalManagerOfMFC(selected_ids);
    setSelectedRmIds(rmIds);
    setLoading(true);
    if (filterTimeOut) {
      clearTimeout(filterTimeOut);
    }
    setFilterTimeOut(
      setTimeout(async () => {
        await getSetCountAndPopulateProcedures(selected_ids, null, selected_ids.length
          ? JSON.stringify(rmIds) : JSON.stringify(regionalManagerIds));
        setLoading(false);
      }, 800)
    );
  };

  // Get RM list of selected MFCs
  const getRegionalManagerOfMFC = (selected_ids) => {
    const rmIds = [];
    for (const rm of regionalManager) {
      selected_ids.includes(rm.manufacturerId) && rmIds.push(...rm.regionalManagerId)
    }
    return rmIds;
  }

  const updateFilterData = (selectedTags, searchValue) => {
    const data =
      selectedTags.length === 1
        ? selectedTags.includes("Manufacturer")
          ? manufacturers
          : distributors
        : manufacturers.concat(distributors);
    formFilterData(
      searchValue
        ? data.filter((x) =>
          x.distributorName.toLowerCase().includes(searchValue)
        )
        : data
    );
    setLoading(false);
  };

  const onFilterSearch = (searchValue) => {
    searchValue = searchValue.trim().toLowerCase();
    setFilterSearchText(searchValue);
    updateFilterData(selectedFilterTags, searchValue);
  };

  const onFilterTagSelectionChange = (tag, checked) => {
    if (!loading) {
      setLoading(true);
      setSelectedIds([]);
      const selectedTags = checked
        ? [...selectedFilterTags, tag]
        : selectedFilterTags.filter((t) => t !== tag);

      setSelectedFilterTags(selectedTags);
      updateFilterData(selectedTags, filterSearchText);
    }
  };
  /** Distributor/Manufacturer Filter End */

  /** API Calls */

  const getSetCountAndPopulateProcedures = async (ids, searchValue, regionalManagerId = []) => {
    if (!ids.length) {
      ids = distributors.concat(manufacturers).map((x) => x.id);
    }
    const success = await distributorService.getProcedureCount(
      ids,
      "global",
      false,
      searchValue,
      regionalManagerId
    );

    if (success) {
      setProcedureCount(success.count);
      setCurrentProcPage(1);
      if (success.count > 0)
        await populateProcedures(ids, customPageSize, 0, searchValue, JSON.parse(regionalManagerId));
      else setGlobalProcedureList([]);
    } else {
      setGlobalProcedureList([]);
      toastNotification("error", "Error fetching Procedure records");
    }
  };

  const populateProcedures = async (ids, limit, skip, searchValue, regionalManagerId = []) => {
    setLoading(true);
    const data = await hospitalService.getHospitalProcedures(
      ids,
      limit,
      skip,
      searchValue,
      regionalManagerId
    );

    if (data) {
      setGlobalProcedureList(data);
    } else {
      toastNotification("error", "Error fetching the Procedure records");
    }
    setLoading(false);
  };

  const getDistributorsAndManufacturers = async () => {
    const hospital = await hospitalService.getHospital(
      hospitalCase.hospital?.id
        ? hospitalCase.hospital.id
        : hospitalCase.hospital
    );

    if (hospital) {
      const filteredDistributors = utilityService.filterSelectedEntity(hospital.distributors);
      setDistributors(filteredDistributors);
      const filteredManufacturer = utilityService.filterSelectedEntity(hospital.manufacturers);
      const manufacturerIds = filteredManufacturer?.map((m) => m.id);
      const manufacturersTemp = filteredManufacturer;

      const distributorsTemp = await distributorService.getDistributors(
        {
          id: { in: filteredDistributors?.map((d) => d.id) },
          isDeleted: false,
        },
        filteredDistributors?.length,
        0,
        "manufacturers"
      );

      for (const d of distributorsTemp) {
        for (const m of d.manufacturers) {
          if (!manufacturerIds.includes(m.id) && !m.isDeleted) {
            manufacturerIds.push(m.id);
            manufacturersTemp.push(m);
          }
        }
      }

      setManufacturers(manufacturersTemp);

      // Get RM mapped with MFC
      const regionalManagers = await hospitalService.getRegionalManagers(JSON.stringify(manufacturerIds), hospital.id);

      const regionalManagerIds = [];
      // Update regionalManagers object by adding manufacturer name to be displayed on card
      for (const rm of regionalManagers) {
        for (const mfc of manufacturersTemp) {
          if (mfc.id === rm.manufacturerId) {
            rm.manufacturerName = mfc.distributorName;
          }
        }
        regionalManagerIds.push(...(rm?.regionalManagerId));
      }

      setRegionalManager(regionalManagers);
      setRegionalManagerIds(regionalManagerIds);

      const distributorAndManufacturers =
        filteredDistributors?.concat(manufacturersTemp);
      
        if (distributorAndManufacturers?.length) {
        const ids = distributorAndManufacturers.map((x) => x.id);
        formFilterData(distributorAndManufacturers);
        return { ids, regionalManagerIds };
      } else {
        setProcedureCount(0);
        setGlobalProcedureList([]);
        setFilteredData([]);
        return false;
      }
    }
  };

  const filterProcedures = async () => {
    const filteredMfcIds = [];
    const distributorAndManufacturerIds = [];
    for (const itr of filteredIds) {
      if (itr.isManufacturer)
        filteredMfcIds.push(itr.id)
      distributorAndManufacturerIds.push(itr.id);
    }
    const regionalManagers = selectedFilterTags.includes('Manufacturer') ?
      await hospitalService.getRegionalManagers(JSON.stringify(filteredMfcIds), routes.params?.id) : [];

    const regionalManagerIds = [];
    // Update regionalManagers object by adding manufacturer name to be displayed on card
    for (const rm of regionalManagers) {
      for (const mfc of filteredIds) {
        if (mfc.id === rm.manufacturerId) {
          rm.manufacturerName = mfc.distributorName;
        }
      }
      regionalManagerIds.push(...rm?.regionalManagerId);
    }
    setFilteredDcMfcIds(distributorAndManufacturerIds);
    setFilteredRmIds(regionalManagerIds);

    await getSetCountAndPopulateProcedures(distributorAndManufacturerIds, null, JSON.stringify(regionalManagerIds))
  }
  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { ids, regionalManagerIds } = await getDistributorsAndManufacturers();
      if (ids) {
        await getSetCountAndPopulateProcedures(ids, null, JSON.stringify(regionalManagerIds));
      }
      setLoading(false);
    })();
  }, []);

  useEffect(async () => {
    // if any filter tag is selected, filter procedures accordingly
    if (filteredIds.length > 0)
      await filterProcedures();
  }, [selectedFilterTags])

  return (
    <Spin spinning={loading}>
      {/* Currently we don't need tabs for all procedure and assigned procedure */}
      {/* <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setActiveTabKey(key);
              if (key === "2") {
                setProcedureCount(0);
                setGlobalProcedureList([]);
              } else {
                await getSetCountAndPopulateProcedures(selectedIds, null, selectedIds.length
                  ? JSON.stringify(selectedRmIds) : JSON.stringify(regionalManagerIds));
              }
              setLoading(false);
            }}
          >
            <TabPane tab="All Procedures" key="1" />
            <TabPane tab="Assigned Procedures" key="2" />
          </Tabs>
        </Col>
      </Row> */}
      <Row justify="space-between">
        <Col>
          <DropdownFilter
            filterTitle="Manufacturers/Distributor"
            dropdownVisibility={dropdownVisibility}
            onDropdownVisibilityChange={(flag) => setDropdownVisibility(flag)}
            filteredData={filteredData}
            selectedIds={selectedIds}
            onSelectionChange={onFilterSelectionChange}
            onSearch={onFilterSearch}
            tagData={["Manufacturer", "Distributor"]}
            selectedTags={selectedFilterTags}
            onTagSelectionChange={onFilterTagSelectionChange}
          />
        </Col>
        <Col>
          <Search
            placeholder="Search by Procedure Name"
            onSearch={onProcedureSearchFunction}
            onChange={(e) => onProcedureSearchFunction(e.target.value)}
            allowClear
            key="searchInput"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <ProcedureList
            dataSource={globalProcedureList}
            pagination={{
              pageSize: customPageSize,
              total: procedureCount,
              current: currentProcPage,
              onChange: async (page, pageSize) => {
                onProcedurePageChange(page, pageSize);
              },
            }}
            renderItem={(procedure) => {
              // Get MFC name of a RM whose procedure is listed
              if (procedure.regionalManager) {
                for (const rm of regionalManager) {
                  if (rm.regionalManagerId.includes(procedure.regionalManager.id))
                    procedure.regionalManager.manufacturerName = rm.manufacturerName;
                }
              }
              return (
                <List.Item>
                  <CustomCard
                    headerIcon={<ProcedureColorIcon width="45px" />}
                    title={procedure.procedureName}
                    cardActions={[
                      <Link
                        to={{
                          pathname: `/procedure/${procedure.id}/table-setup`,
                          state: {
                            tag: "Table Setup",
                            preview: true,
                            path: history.location.pathname,
                            showModalPopUp: showModalPopUp,
                            pageNumber: history.location.state?.pageNumber,
                            tabKey: history.location.state?.tabKey
                          },
                        }}
                        style={{ color: "#1d1d1d" }}
                      >
                        Preview
                      </Link>,
                      <a
                        onClick={() => onSelectProcedure(procedure.id)}
                        style={{ color: "#f26524" }}
                      >
                        Select
                      </a>,
                    ]}
                    bodyContent={[
                      {
                        icon: procedure.regionalManager ?
                          <ManufacturerIcon />
                          : procedure.distributor?.isManufacturer ? (
                            <ManufacturerIcon />
                          ) : (
                              <DistributorIcon />
                            ),
                        content: procedure.regionalManager
                          ? procedure.regionalManager.manufacturerName
                          : procedure.distributor?.distributorName,
                      },
                    ]}
                  />
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default withRouter(GlobalProcedureLibraryPage);
