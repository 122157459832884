import { useEffect, useState } from "react";
import { Spin } from "antd";
import EntityHospitalsPage from "../../shared-pages/read-only/hospital-and-cases/EntityHospitalsPage";
import toastNotification from "../../components/toastNotification";
import areaService from "../../services/areaService";
import utilityService from "../../services/utilityService";

const AreaHospitalsPage = ({ match }) => {
  const areaId = match.params?.areaid;

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAreaHospitals = async () => {
    const area = await areaService.getArea(areaId);

    if (area?.hospitals) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        area.hospitals
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      area.hospitals = sortedHospitals;
      
      setHospitalList(area.hospitals);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getAreaHospitals();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityHospitalsPage hospitalList={hospitalList} />
    </Spin>
  );
};
export default AreaHospitalsPage;
