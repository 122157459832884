import { Layout } from "antd";
import MainHeader from "../components/MainHeader";
import SideBarContainer from "./SideBarContainer";
import BreadCrumb from "../components/BreadCrumb";

const { Content } = Layout;

const HomePageContainer = (props) => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MainHeader />
      <Layout>
        <SideBarContainer {...props} />
        <Layout>
          <div
            style={{
              backgroundImage: "linear-gradient(#e6e6e6, #f7f7f7)",
              height: "28px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "12px",
            }}
          >
            <BreadCrumb />
          </div>
          <Content style={{ background: "#ffffff" }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HomePageContainer;
