import { useEffect, useState } from "react";
import { Modal, Typography } from "antd";
import { Link } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import ContentTable from "../../components/ContentTable";
import ActionMenu from "../../components/ActionMenu";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as MobileIcon } from "../../assets/icons/iconMobile.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/iconPhone.svg";
import manufacturerService from "../../services/manufacturerService";
import userServices from "../../services/userServices";
import { useBreadCrumbContext } from "../../context/BreadCrumbContext";
import { RolesDisplay } from "../../config/role-config.json";

const { confirm } = Modal;
const { Text } = Typography;
const customPageSize = 10;

const ManufacturerUsersPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;
  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");
  const manufacturerId = params.id ? params.id : null;

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Manufacturer Deleted Users",
      backBtnPath: `/manufacturer/${manufacturerId}/user`,
    }
    : {
      title: "Manufacturer Users",
      btnText: "Add User",
      onBtnClick: () => {
        history.push(`${url}/new`);
      },
      topActionMenuItems: [
        {
          title: "Show Deleted Users",
          path: `${url}/deleted`,
        },
      ],
    };

  const [usersList, setUsersList] = useState();
  const [loading, setLoading] = useState(true);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [userCount, setUserCount] = useState(0);
  const [userSearchText, setUserSearchText] = useState();
  const { setBreadCrumb } = useBreadCrumbContext();

  const actions = (record) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            onUserRestore(record);
          }
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            setBreadCrumb((prev) => ({
              ...prev,
              user: `${record.firstName ?? ""} ${record.lastName ?? ""
                }`.trim(),
            }));
            history.push(`${url}/${record.id}`, {
              record,
              editMode: true,
              pageNumber: currentPage
            });
          },
        },
        ...(!record.isActive
          ? [
            {
              title: "Resend Invite",
              onClick: (menuItemProps) => {
                menuItemProps.domEvent.stopPropagation();
                sendActivationLink(record.email);
              },
            },
          ]
          : []),
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(record.id);
          },
        },
      ];
  };

  const columnsList = [
    {
      title: "User's Name & Role",
      dataIndex: "firstName",
      key: "firstName",
      render: (name, record) => {
        const hasName = name || record.middleName || record.lastName;
        const userName = hasName ? (
          <Text strong>
            {name} {record.middleName} {record.lastName}
          </Text>
        ) : (
            <Text style={{ color: "grey" }}>Not Set</Text>
          );
        return (
          <>
            {isDeletedPage ? (
              <>{userName}</>
            ) : (
                <Link
                  to={{
                    pathname: `/manufacturer/${manufacturerId}/user/${record.id}`,
                    state: { record, editMode: false, pageNumber: currentPage },
                  }}
                  onClick={() =>
                    setBreadCrumb((prev) => ({
                      ...prev,
                      user: `${record.firstName ?? ""} ${record.lastName ?? ""
                        }`.trim(),
                    }))
                  }
                >
                  {userName}
                </Link>
              )}
            <br />
            <Text style={{ fontSize: "0.9em" }}>
              {RolesDisplay[record.role]}
            </Text>
            {!record.isActive && (
              <>
                <br />
                <Text style={{ color: "#db9f2e", fontSize: "0.9em" }}>
                  Activation Pending
                </Text>
              </>
            )}
          </>
        );
      },
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Contact",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber, { officeNumber }) => (
        <>
          {mobileNumber && (
            <span className="display-flex-center">
              <MobileIcon width="1.3em" />
              {mobileNumber}
            </span>
          )}
          {officeNumber && (
            <span className="display-flex-center">
              <PhoneIcon fill="#1d1d1d" width="1.3em" />
              {officeNumber}
            </span>
          )}
          {!mobileNumber && !officeNumber && (
            <Text style={{ color: "grey", marginLeft: "0.4em" }}>Not Set</Text>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "4rem",
      render: (value, record) => <ActionMenu actions={actions(record)} />,
    },
  ];

  const onUserRestore = async (user) => {
    setLoading(true);
    const res = await userServices.patchRestoreUser(user.id);
    
    if (res) {
      toastNotification("success", "User Restored");
      populateManufacturerUsersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the user");
    }
  };
  
  const sendActivationLink = async (email) => {
    setLoading(true);
    const res = await userServices.postResendUserActivationLink(email);
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
    setLoading(false);
  };

  const showDeleteConfirmation = (userId) => {
    confirm({
      title: "Delete Manufacturer User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      centered: true,
      cancelText: "Cancel",
      onOk() {
        deleteManufacturerUser(userId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        searchValue = searchValue.trim();
        setUserSearchText(searchValue);
        setCurrentPage(1);
        getSetUserCountAndPopulate(searchValue);
      }, 500)
    );
  };

  /** API Calls */
  const populateManufacturerUsersList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await manufacturerService.getManufacturerUsers(
      manufacturerId,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setUsersList(data);
    } else {
      toastNotification("error", "Error fetching User records");
    }
    setLoading(false);
  };

  const deleteManufacturerUser = async (id) => {
    setLoading(true);
    const isDeleted = await userServices.deleteUser(id);
    if (isDeleted) {
      toastNotification("success", "Manufacturer User deleted successfully");
      await populateManufacturerUsersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error deleting the user record");
    }
    setLoading(false);
  };

  const getSetUserCountAndPopulate = async (searchText) => {
    setLoading(true);
    const success = await userServices.getUsersCount(
      "distributor",
      manufacturerId,
      isDeletedPage,
      searchText,
      true
    );

    if (success !== false) {
      setUserCount(success);
      if (success > 0)
        await populateManufacturerUsersList(customPageSize, customPageSize * (currentPage - 1), searchText);
      else setUsersList([]);
    } else {
      toastNotification("error", "Error fetching user records");
    }
    setLoading(false);
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetUserCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by First Name, Last Name or Email"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <ContentTable
        data={usersList}
        columnsList={columnsList}
        pagination={{
          pageSize: customPageSize,
          total: userCount,
          current: currentPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentPage(page);
            await populateManufacturerUsersList(
              pageSize,
              pageSize * (page - 1),
              userSearchText
            );
            setLoading(false);
          },
        }}
        loading={loading}
      />
    </MainContainer>
  );
};

export default ManufacturerUsersPage;
