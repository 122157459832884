import { Modal, Row, Typography } from "antd";
import EquipmentCarousel from "./EquipmentCarousel";
import { ReactComponent as IconClose } from "../assets/icons/iconCloseModal.svg";

const { Paragraph } = Typography;
const EquipmentModal = ({ equipment, show, close, slideIndex }) => {
  return (
    <Modal
      width={"70%"}
      closeIcon={
        <div
          div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "flex-start",
            paddingTop: "12px",
          }}
        >
          <IconClose />
        </div>
      }
      title={
        equipment && (
          <>
            {equipment.equipmentName}
            {/* <Row>
              <Tag color={"orange"}>{step.id}</Tag>
            </Row> */}
            <Row>
              <Paragraph style={{ fontSize: "14px", margin: "5px 0 0" }}>
                {equipment?.description}
              </Paragraph>
            </Row>
          </>
        )
      }
      visible={show}
      onCancel={close}
      footer={null}
      centered
      destroyOnClose
    >
      <EquipmentCarousel equipment={equipment} slideIndex={slideIndex} />
    </Modal>
  );
};

export default EquipmentModal;
