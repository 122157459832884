import { useState, useEffect } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Spin } from "antd";
import ExecutionFlowContainer from "../../../containers/LiveCase/ExecutionFlowContainer";
import ExecutionStartPage from "../../../pages/LiveCase/ExecutionStartPage";
import NoExecutionFlowPage from "../../../pages/LiveCase/NoExecutionFlowPage";
import liveCaseService from "../../../services/liveCaseService";
import distributorService from "../../../services/distributorService";
import hospitalService from "../../../services/hospitalService";
import procedureService from "../../../services/procedureService";
import toastNotification from "../../../components/toastNotification";
import { ExecutionStepsContext } from "../../../context/ExecutionStepsContext";

const LiveCaseFlowPage = ({ caseId }) => {
  const location = useLocation();
  const history = useHistory();
  const path = location.state?.path;

  const {
    params: { procedureid: procedureId },
  } = useRouteMatch();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [executionSteps, setExecutionSteps] = useState([]);
  const [tableSetup, setTableSetup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [procedureName, setProcedureName] = useState("");
  const [allEquipments, setAllEquipments] = useState([]);
  const [caseProcedureId, setCaseProcedureId] = useState();

  let currentStepEquipmentPage = 0;
  let stepEquipmentList = [];
  let currentChecklistItemPage = 0;
  let checklistItemList = [];

  const customPageSize = 30;

  /** Functional */
  const onDecreaseStepIndex = () => {
    if (currentStepIndex !== 0) {
      const newStepIndex = currentStepIndex - 1;
      updateNewIndex(newStepIndex);
    }
  };

  const onIncreaseStepIndex = () => {
    if (executionSteps[currentStepIndex + 1]) {
      const newStepIndex = currentStepIndex + 1;
      updateNewIndex(newStepIndex);
    } else {
      history.push(path);
    }
  };

  const updateNewIndex = (newStepIndex) => {
    // Update state to render new step
    setCurrentStepIndex(newStepIndex);
  };

  /** Functional Ends */

  /** API Calls */

  const getAndSetProcedureAndCaseDetails = async () => {
    const caseDetails = caseId && await hospitalService.getCase(caseId);
    const procedureDetails = !caseDetails && await procedureService.getProcedure(procedureId);
    if (caseDetails?.procedure) {
      setCaseProcedureId(caseDetails.procedure.id);
      setProcedureName(caseDetails.procedure.procedureName);
      return caseDetails.procedure.id;
    } else if (procedureDetails) {
      setCaseProcedureId(procedureDetails.id);
      setProcedureName(procedureDetails.procedureName);
      return procedureDetails.id;
    }
    return false;
  };

  const getExecutionSteps = async (equipmentDetails, procId) => {
    const tempExecutionSteps = await liveCaseService.getStepDetails(procId);
    if (tempExecutionSteps) {
      for (const step of tempExecutionSteps) {
        switch (step.stepType) {
          case "SURGICAL_STEP":
            const stepEquipments = await populateSurgicalSteps(step.id);
            for (let el of stepEquipments) {
              const equipment = equipmentDetails.filter(
                (e) => e.equipment.id === el.equipment.id
              );
              if (equipment.length > 0)
                el.equipment.sectionNumber =
                  equipment[0].equipment.sectionNumber;
            }
            if (stepEquipments) step.stepEquipments = stepEquipments;
            break;
          case "CHECKLIST":
            const checklistItems = await populateChecklistItems(step.id);
            if (checklistItems) step.checklistItems = checklistItems;
            break;
        }
      }

      setExecutionSteps(tempExecutionSteps);
      return tempExecutionSteps;
    } else {
      toastNotification("error", "Error in fetching data");
    }
  };

  const populateSurgicalSteps = async (stepId) => {
    const equipmentDetails = await liveCaseService.getSurgicalSteps(
      stepId,
      customPageSize,
      currentStepEquipmentPage * customPageSize
    );
    stepEquipmentList = [...stepEquipmentList, ...equipmentDetails];
    if (equipmentDetails.length < customPageSize) {
      currentStepEquipmentPage = 0;
      const res = stepEquipmentList;
      stepEquipmentList = [];
      return res;
    }
    currentStepEquipmentPage++;
    return populateSurgicalSteps(stepId);
  };

  const populateChecklistItems = async (stepId) => {
    const items = await liveCaseService.getChecklistItems(
      stepId,
      customPageSize,
      currentChecklistItemPage * customPageSize
    );
    checklistItemList = [...checklistItemList, ...items];
    if (items.length < customPageSize) {
      currentChecklistItemPage = 0;
      const res = checklistItemList;
      checklistItemList = [];
      return res;
    }
    currentChecklistItemPage++;
    return populateChecklistItems(stepId);
  };
  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const procId = await getAndSetProcedureAndCaseDetails();
      if (procId) {
        //Get Table Setup Details. Needed if users joins a case as it would directly start from execution flow
        const data = await distributorService.getTableSetup(procId);
        setTableSetup(data);

        if (data) {
          //To get tray with equipment details
          let trayIds = data.map((el) => el.tray.id);
          const res = await distributorService.getTrayDetails(trayIds);
          if (res) {
            let equipmentDetails = res.data?.map((el) => {
              const tray = data.filter((e) => e.tray.id === el.tray.id);
              el.equipment.gridNumber = parseInt(tray[0].sectionNumber);
              el.equipment.name = el.equipment.equipmentName.toLowerCase();
              return el;
            });

            equipmentDetails.forEach((el) => {
              const trays = equipmentDetails.filter(
                (e) => e.equipment.id === el.equipment.id
              );
              const sectionNumber = trays.map((el) => el.equipment.gridNumber);
              el.equipment.sectionNumber = sectionNumber;
            });

            equipmentDetails = equipmentDetails.filter(
              (el, i, a) =>
                a.findIndex((e) => e.equipment.id === el.equipment.id) === i
            );
            setAllEquipments(equipmentDetails);

            // Get Execution Steps
            const tempExecutionSteps = await getExecutionSteps(
              equipmentDetails,
              procId
            );

            if (tempExecutionSteps?.length > 0) {
              // Sort by phases and then steps
              tempExecutionSteps.sort(
                (stepA, stepB) =>
                  stepA.phase.index.localeCompare(stepB.phase.index) ||
                  stepA.index.localeCompare(stepB.index)
              );
            } else {
              setCurrentStepIndex(-1);
            }
          }
        }
      }
      setLoading(false);
    })();
  }, []);

  return (

    <Spin
      spinning={loading}
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="dor-live-case">
        <ExecutionStepsContext.Provider
          value={{
            stepIndexObj: {
              currentStepIndex,
              onNext: onIncreaseStepIndex,
              onPrevious: onDecreaseStepIndex,
            },
            allEquipments,
            executionSteps,
            caseId,
            caseProcedureId,
            hasReadOnlyRights: true,
          }}
        >
          {loading ? (
            <></>
          ) : currentStepIndex >= 0 ? (
            <ExecutionFlowContainer isReadOnly={true} />
          ) : executionSteps.length === 0 ? (
            <NoExecutionFlowPage isReadOnly={true} />
          ) : (
                  <ExecutionStartPage
                    caseId={caseId}
                    firstStepId={executionSteps[0].id}
                    allTraysVerified
                  />
                )}
        </ExecutionStepsContext.Provider>
      </div>
    </Spin>
  );
};

export default LiveCaseFlowPage;
