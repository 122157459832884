import EquipmentForm from "../../shared-pages/manufacturer/EquipmentForm";

const RMEquipmentForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { equipmentid: equipmentId, rmid: rmId } = params;

  return (
    <EquipmentForm
      entityId={rmId}
      entityName="regionalManager"
      equipmentId={equipmentId}
      equipment={state?.record}
      isReadOnly={state?.isPageReadOnly}
    />
  );
};

export default RMEquipmentForm;
