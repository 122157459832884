import { useState } from "react";
import { Button, Layout, Typography, Row, Col, Drawer } from "antd";
import { useLocation } from "react-router-dom";
import { useExecutionStepsContext } from "../../context/ExecutionStepsContext";
import AllEquipmentsDrawer from "./AllEquipmentsDrawer";
import { ReactComponent as CloseIcon } from "../../assets/icons/iconCloseSmall.svg";

const { Footer } = Layout;
const { Text } = Typography;

const LiveCaseFooter = ({isReadOnly}) => {
  const {
    stepIndexObj: { currentStepIndex, onNext, onPrevious },
    executionSteps,
  } = useExecutionStepsContext();

  const location = useLocation();
  const stepDetails = location.state?.stepDetails;
  const readOnly = location.state?.readOnly;

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const closeDrawerHandler = () => {
    setIsDrawerVisible(false);
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  return (
    <Footer className="execution-flow-footer">
      <Row>
        <Col
          span={10}
          offset={7}
          className="display-flex-center"
          style={{ justifyContent: "center" }}
        >
          <Button
            htmlType="button"
            type="secondary"
            onClick={onPrevious}
            disabled={currentStepIndex <= 0}
          >
            Previous
          </Button>
          <Text strong style={{ background: "#feefe8", padding: "19px" }}>
            {currentStepIndex + 1}
          </Text>
          <Button
            htmlType="button"
            type="primary"
            onClick={onNext}
            disabled={
              executionSteps.length - 1 === currentStepIndex &&
              (stepDetails?.isStarted === "FINISHED" || isReadOnly)
            }
          >
            {executionSteps.length - 1 === currentStepIndex
              ? "Complete"
              : "Next"}
          </Button>
        </Col>
        <Col
          span={3}
          offset={4}
          style={{
            borderLeft: "1px solid rgb(209,209,209)",
            justifyContent: "center",
            cursor: "pointer",
          }}
          className="display-flex-center"
          onClick={showDrawer}
        >
          All Instruments
        </Col>
      </Row>
      <Drawer
        title="All Instruments"
        onClose={closeDrawerHandler}
        visible={isDrawerVisible}
        width={"45vw"}
        closeIcon={<CloseIcon />}
      >
        <AllEquipmentsDrawer />
      </Drawer>
    </Footer>
  );
};

export default LiveCaseFooter;
