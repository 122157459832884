import { useState, useEffect } from "react";
import { List, Card, Row, Col, Modal, Typography } from "antd";
import MainContainer from "../../containers/MainContainer";
import ActionMenu from "../../components/ActionMenu";
import areaService from "../../services/areaService";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as AreaColorIcon } from "../../assets/icons/colorIconArea.svg";
import { useBreadCrumbContext } from "../../context/BreadCrumbContext";

const { confirm } = Modal;
const { Paragraph } = Typography;
const customPageSize = 12;

const ManufacturerAreasPage = (props) => {
  const {
    match: { url, params },
    history,
  } = props;

  const isDeletedPage = url.includes("deleted");
  const manufacturerId = params?.id;

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Areas",
        backBtnPath: `/manufacturer/${manufacturerId}/area`,
      }
    : {
        title: "Areas",
        btnText: "Add Area",
        onBtnClick: () => {
          history.push(`${url}/new`);
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Areas",
            path: `${url}/deleted`,
          },
        ],
      };

  const [areaList, setAreaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [areaCount, setAreaCount] = useState(0);
  const [areaSearchText, setAreaSearchText] = useState();
  const { setBreadCrumb } = useBreadCrumbContext();

  const actions = (area) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              setBreadCrumb((prev) => ({
                ...prev,
                area: area.areaName,
              }));
              history.push(`${url}/${area.id}/setup`, {
                record: area,
                editMode: true,
              });
            },
          },
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(area.id);
            },
          },
        ];
  };

  const showDeleteConfirmation = (areaId) => {
    confirm({
      title: "Delete Area",
      content: "Are you sure you want to delete this Area?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteArea(areaId);
      },
    });
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setLoading(true);
        searchValue = searchValue.trim();
        setAreaSearchText(searchValue);
        await getSetAreaCountAndPopulate(searchValue);
        setLoading(false);
      }, 500)
    );
  };

  /** API */
  const populateAreaList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await areaService.getAreas(
      manufacturerId,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setAreaList(data);
    } else {
      toastNotification("error", "Error fetching the Area records");
    }
    setLoading(false);
  };

  const deleteArea = async (areaId) => {
    setLoading(true);
    const isDeleted = await areaService.patchArea({
      id: areaId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Area deleted successfully");
      await getSetAreaCountAndPopulate();
      setCurrentPage(1);
    } else {
      toastNotification("error", "Error deleting the Area");
    }
    setLoading(false);
  };

  const getSetAreaCountAndPopulate = async (searchText) => {
    const success = await areaService.getAreaCount(
      manufacturerId,
      isDeletedPage,
      searchText
    );

    if (success !== false) {
      setAreaCount(success);
      if (success > 0) await populateAreaList(customPageSize, 0, searchText);
      else setAreaList([]);
    } else {
      toastNotification("error", "Error fetching Area records");
    }
  };
  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetAreaCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Area Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 3,
          md: 3,
          lg: 4,
          xl: 4,
          xxl: 6,
        }}
        dataSource={areaList}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          responsive: true,
          pageSize: customPageSize,
          total: areaCount,
          current: currentPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentPage(page);
            await populateAreaList(
              pageSize,
              pageSize * (page - 1),
              areaSearchText
            );
            setLoading(false);
          },
        }}
        loading={loading}
        renderItem={(area) => {
          return (
            <List.Item>
              <Card
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                        setBreadCrumb((prev) => ({
                          ...prev,
                          area: area.areaName,
                        }));
                        history.push(`${url}/${area.id}/hospital`);
                      }
                }
                bodyStyle={{
                  padding: "6px",
                }}
                hoverable
              >
                <Row>
                  <Col className="display-flex-center" span={5}>
                    <AreaColorIcon height="44px" width="44px" />
                  </Col>
                  <Col className="display-flex-center" span={16}>
                    <Paragraph ellipsis={{ rows: 2 }} style={{ margin: "0" }}>
                      {area.areaName}
                    </Paragraph>
                  </Col>
                  <Col className="display-flex-center" span={3}>
                    <ActionMenu actions={actions(area)} />
                  </Col>
                </Row>
              </Card>
            </List.Item>
          );
        }}
      />
    </MainContainer>
  );
};

export default ManufacturerAreasPage;
