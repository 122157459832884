import TrayFrom from "../../shared-pages/manufacturer/TrayForm";

const AreaTrayForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { trayid: trayId, areaid: areaId, id: manufacturerId} = params;

  return (
    <TrayFrom
      entityId={areaId}
      entityName="area"
      trayId={trayId}
      tray={state?.record}
      manufacturerId={manufacturerId}
    />
  );
};

export default AreaTrayForm;
