import { Row, Col, Typography, Button, Spin, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SignedURLImage from "./SignedURLImage";
import { ReactComponent as ImgErrorIcon } from "../assets/icons/iconImgError.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/iconDeleteImage.svg";
import { ReactComponent as RetryIcon } from "../assets/icons/iconRetry.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/icon_play.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/iconTagClose.svg";

const { Text } = Typography;

const UploadItem = ({
  fileItem,
  onRemoveItem,
  isImage,
  onRetryUpload,
  showModalPopUp,
  readOnly,
  fallbackImg,
}) => {
  const render1stColumn = () => {
    switch (fileItem.status) {
      case "done":
        return isImage ? (
          <SignedURLImage
            fileName={fileItem.url}
            height={60}
            width={96}
            onClick={showModalPopUp}
            key={fileItem.url}
            fallback={fallbackImg}
            className="fit-image"
          />
        ) : (
          <>
            <video className="upload-list-video" poster={fileItem.url} />
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100%",
                height: "inherit",
              }}
            ></div>
            <div style={{ position: "absolute" }}>
              <PlayIcon
                height="25px"
                width="25px"
                onClick={() => showModalPopUp("video", fileItem.url)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        );
      case "uploading":
        return <Spin indicator={<LoadingOutlined />} />;
      case "error":
        return (
          <div className="upload-img-fail-box">
            <ImgErrorIcon />
          </div>
        );
    }
  };

  const render2ndColumn = () => {
    switch (fileItem.status) {
      case "done":
        return (
          <div style={{ width: "96%", paddingLeft: "12px" }}>
            <Text style={{ color: "rgb(128, 130, 133)" }}>{fileItem.name}</Text>
          </div>
        );
      case "uploading":
        return (
          <div style={{ width: "96%", paddingLeft: "12px" }}>
            <Text ellipsis>{fileItem.name}</Text>
            <br />
            <Progress
              percent={fileItem.percent}
              showInfo={false}
              strokeColor={{
                "0%": "rgb(242,101,36)",
                "100%": "rgb(242,101,36)",
              }}
              strokeWidth={3}
            />
          </div>
        );
      case "error":
        return (
          <Row style={{ width: "100%", height: "100%" }}>
            <Col span={20}>
              <Text ellipsis>{fileItem.name}</Text>
            </Col>
            <Col
              span={4}
              style={{
                borderLeft: "solid 1px rgb(209, 209, 209)",
                justifyContent: "center",
              }}
            >
              {!readOnly && (
                <Button
                  icon={<CloseIcon />}
                  style={{ border: "none", boxShadow: "none" }}
                  onClick={() => onRemoveItem(fileItem, true)}
                />
              )}
            </Col>
          </Row>
        );
    }
  };

  const render3rdColumn = () => {
    let icon, onClick;
    switch (fileItem.status) {
      case "done":
        icon = <DeleteIcon />;
        onClick = () => {
          onRemoveItem(fileItem);
        };
        break;
      // case "uploading":
      //   icon = <CloseIcon />;
      //   onClick = () => onRemoveItem(fileItem, true);
      //   break;
      case "error":
        icon = <RetryIcon />;
        onClick = () => onRetryUpload(fileItem, isImage);
        break;
    }

    return (
      <Button
        icon={icon}
        style={{ border: "none", boxShadow: "none" }}
        onClick={onClick}
      />
    );
  };

  return (
    <div className="upload-item-box">
      <Row style={{ height: "100%" }}>
        <Col span={6} style={{ justifyContent: "center" }}>
          {render1stColumn()}
        </Col>
        <Col span={15}>{render2ndColumn()}</Col>
        {!readOnly && (
          <Col
            span={3}
            style={{
              justifyContent: "center",
              borderLeft: "solid 1px rgb(209, 209, 209)",
            }}
          >
            {render3rdColumn()}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default UploadItem;
