import { useEffect, useState } from "react";
import { Button } from "antd";
import TrayModal from "./TrayModal";
import SignedURLImage from "./SignedURLImage";
import TrayPopUpPage from "../pages/LiveCase/TrayPopUpPage";
import { ReactComponent as VerifiedIcon } from "../assets/icons/icon_verified.svg";
import { TrayVerificationContext } from "../context/TrayVerificationContext";
import trayPlaceholder from "../assets/tray-placeholder.svg";

const TrayGridContainer = (props) => {
  const {
    id,
    grid,
    rotation,
    tray,
    onChangeVerificationStatus,
    isProcedureEditable,
    isCompletedCasesPage,
  } = props;

  const src = tray?.src;
  const trayId = tray?.trayId;
  const verificationStatus = tray?.verificationStatus;

  const [trayPopUpVisible, setTrayPopUpVisible] = useState(false);
  const [trayModal, setTrayModal] = useState(false);
  const [selectedTrayId, setSelectedTrayId] = useState(" ");
  const [status, setStatus] = useState();

  const closeModal = () => {
    setTrayModal(false);
  };
  const closeModalHandler = () => {
    setTrayPopUpVisible(false);
  };

  const style = {
    VERIFIED: {
      border: "0",
      color: "white",
      backgroundColor: "rgb(0,186,79)",
    },
    VERIFICATION_PENDING: {
      border: "1px solid rgb(29,29,29)",
      color: "black",
      backgroundColor: "white",
    },
    NOT_VERIFIED: {
      border: "1px solid rgb(29,29,29)",
      color: "black",
      backgroundColor: "white",
    },
  };

  const display = {
    VERIFIED: {
      display: (
        <div style={{ display: "flex", justifyContent: "Center" }}>
          <VerifiedIcon />
          <span style={{ marginLeft: "5px" }}>Verified</span>
        </div>
      ),
    },
    VERIFICATION_PENDING: {
      display: "Verify",
    },
    NOT_VERIFIED: {
      display: "Verify",
    },
  };

  const rotatedImgStyles = () => {
    const imgElement = document.getElementById(trayId);

    return imgElement
      ? {
          width: imgElement.offsetHeight,
          height: imgElement.offsetWidth,
          transformOrigin: "left top",
          transform: "rotate(90deg) translateY(-100%)",
        }
      : {};
  };

  const trayContainer =
    src || trayId ? (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "rgb(245,245,245)",
        }}
        id={id + grid}
      >
        <SignedURLImage
          onClick={() => {
            setTrayModal(true);
          }}
          id={trayId}
          key={trayId}
          fileName={src}
          draggable="false"
          style={{
            ...(rotation ? rotatedImgStyles() : {}),
          }}
          fallback={trayPlaceholder}
        />
        {(isProcedureEditable ||
          status === "VERIFIED" ||
          isCompletedCasesPage) && (
          <Button
            htmlType="button"
            type="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setTrayPopUpVisible(true);
              setSelectedTrayId(trayId);
            }}
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              margin: "0.8em",
              borderRadius: 5,
              color: style[status]?.color,
              backgroundColor: style[status]?.backgroundColor,
              borderColor: style[status]?.border,
              zIndex: "200",
            }}
          >
            {display[status]?.display}
          </Button>
        )}
        {trayPopUpVisible && (
          <TrayPopUpPage
            show={trayPopUpVisible}
            close={closeModalHandler}
            trayId={selectedTrayId}
            onChangeVerificationStatus={onChangeVerificationStatus}
            showButton={isProcedureEditable}
          />
        )}
        {trayModal && (
          <TrayModal show={trayModal} close={closeModal} trayId={trayId} />
        )}
      </div>
    ) : (
      <div style={{ position: "absolute", height: "100%" }} id={id + grid} />
    );

  useEffect(() => {
    setStatus(verificationStatus);
  }, [verificationStatus]);

  return (
    <TrayVerificationContext.Provider value={{ status, setStatus }}>
      <div id={id + grid}>{trayContainer}</div>
    </TrayVerificationContext.Provider>
  );
};

export default TrayGridContainer;
