import ProcedureFrom from "../../shared-pages/manufacturer/ProcedureForm";

const RMProcedureForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { procedureid: procedureId, rmid: rmId, id } = params;
  return (
    <ProcedureFrom
      entityId={rmId}
      entityName="regionalManager"
      procedureId={procedureId}
      procedure={state?.record}
      manufacturerId={id}
    />
  );
};

export default RMProcedureForm;

