import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Typography,
  Select,
  Spin,
  Divider,
  Tag,
} from "antd";
import { debounce } from "lodash";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import AssignmentSelection from "../../components/AssignmentSelection";
import userServices from "../../services/userServices";
import medicalRepService from "../../services/medicalRepService";
import utilityService from "../../services/utilityService";
import { useAuthContext } from "../../context/AuthContext";
import { Roles, RolesDisplay } from "../../config/role-config.json";
import { ReactComponent as HospitalColorIcon } from "../../assets/icons/colorIconHospital.svg";
import { ReactComponent as HospitalGreyIcon } from "../../assets/icons/greyIconManufacturer.svg";

const { confirm } = Modal;
const { Title, Text } = Typography;
const customPageSize = 30;

const MedicalRepresentativeForm = ({ match, history, location }) => {
  const {
    mmrid: mrId,
    rmid: rmId,
    areaid: areaId,
    id: manufacturerId,
  } = match.params;

  const pathToRMList = `/manufacturer/${manufacturerId}/area/${areaId}/regional-manager/${rmId}/medical-representative`;

  const authContext = useAuthContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [medicalRepresentative, setMedicalRepresentative] = useState();
  const [editMode, setEditMode] = useState(!mrId || location.state?.editMode);
  const [regionalManagers, setRegionalManagers] = useState([]);

  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalIds, setSelectedHospitalIds] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitalSearchTimeOut, setHospitalSearchTimeOut] = useState(0);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const [hospitalCount, setHospitalCount] = useState();
  const [hospitalSearchText, setHospitalSearchText] = useState();
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [loadHospitals, setLoadHospitals] = useState(false);

  const isMMR = authContext.user?.role === Roles.MMR;

  const pageHeaderProps = mrId
    ? {
        title: medicalRepresentative
          ? [
              medicalRepresentative.firstName,
              medicalRepresentative.middleName,
              medicalRepresentative.lastName,
            ].join(" ")
          : "Edit Medical Representative",
        btnText: editMode ? "Save" : "Edit",
        btnType: editMode ? "primary" : "secondary",
        topActionMenuItems: !isMMR
          ? [
              {
                title: "Delete",
                path: "",
                onClick: () => {
                  showDeleteConfirmation(mrId);
                },
              },
            ]
          : null,
      }
    : {
        title: "New Medical Representative",
        btnText: "Add",
        backBtnPath: pathToRMList,
      };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Medical Representative",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await userServices.deleteUser(mrId);
        if (isDeleted) {
          toastNotification(
            "success",
            "Medical Representative deleted successfully"
          );
          history.push(pathToRMList);
        } else {
          toastNotification(
            "error",
            "Error deleting the Medical Representative record!"
          );
        }
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const onFinish = async (mrFormValues) => {
    setLoading(true);
    mrFormValues.hospital = selectedHospitalIds;
    if (mrId) {
      await editMR(mrFormValues);
    } else {
      await addMR(mrFormValues);
    }

    const sortedHospitals =
      utilityService.sortSelectedEntity(selectedHospitals);
    setSelectedHospitals(sortedHospitals);
    setLoading(false);
  };

  /** Hospital Assignment */

  const handleInputChange = (searchText) => {
    debounce((searchText) => {
      setHospitalSearchText(searchText);
    }, 250)();
    if (searchText.length === 0) setCurrentHospitalPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadHospitals && hospitalList.length !== hospitalCount && addOptions();
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : true;
  };

  const onSearchHospital = async (searchValue) => {
    if (hospitalCount >= 30) {
      setHospitalLoading(true);
      if (hospitalSearchTimeOut) {
        clearTimeout(hospitalSearchTimeOut);
      }
      setHospitalSearchTimeOut(
        setTimeout(async () => {
          searchValue = searchValue.trim();
          setHospitalSearchText(searchValue);
          await populateHospitalList(customPageSize, 0, searchValue);
          setHospitalLoading(false);
        }, 500)
      );
    } else return true;
  };

  const onHospitalSelect = (hospitalId) => {
    const ids = selectedHospitalIds;
    ids.push(hospitalId);
    setSelectedHospitalIds([...ids]);

    const selectedHospital = hospitalList.find(
      (hospital) => hospital.id === hospitalId
    );
    selectedHospitals.push(selectedHospital);
    setSelectedHospitals([...selectedHospitals]);
  };

  const onHospitalDeselect = (hospitalId) => {
    setSelectedHospitalIds(selectedHospitalIds.filter((x) => x !== hospitalId));

    setSelectedHospitals(
      selectedHospitals.filter((hospital) => hospital.id !== hospitalId)
    );
  };

  const addOptions = async () => {
    setLoadHospitals(false);
    setHospitalLoading(true);
    setCurrentHospitalPage(currentHospitalPage + 1);
    await populateHospitalList(
      customPageSize,
      currentHospitalPage * customPageSize,
      hospitalSearchText
    );
    setHospitalLoading(false);
  };
  /** Hospital Assignment End */

  /** API Calls */
  const addMR = async (mrFormValues) => {
    mrFormValues.password = "password123";
    const res = await userServices.postUser(mrFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Medical Representative added");
      history.push(pathToRMList);
    } else if (res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new Medical Representative");
    }
  };

  const editMR = async (mrFormValues) => {
    mrFormValues.id = mrId;
    const res = await userServices.putUser(mrFormValues);

    if (res.status === 200) {
      toastNotification("success", "Medical Representative record updated");
      setEditMode(false);
    } else {
      toastNotification(
        "error",
        "Error updating the Medical Representative record"
      );
    }
  };

  const getMedicalRepresentative = async () => {
    const data = await userServices.getUser(mrId, {
      populate: "hospital",
      where: { isDeleted: false },
    });
    if (data) {
      setMedicalRepresentative(data);
      if (data.hospital) {
        const filteredHospitals = utilityService.filterSelectedEntity(
          data.hospital
        );
        const sortedHospitals =
          utilityService.sortSelectedEntity(filteredHospitals);
        setSelectedHospitals(sortedHospitals);
        setSelectedHospitalIds(filteredHospitals.map((h) => h.id));
      }
      form.setFieldsValue(data);
    } else {
      toastNotification(
        "error",
        "Requested Medical Representative record not found"
      );
    }
  };

  const getRegionalManagers = async () => {
    const data = await medicalRepService.getRegionalManagers(
      areaId,
      [Roles.REGIONAL_MANAGER],
      false,
      1000,
      0
    );
    if (data) {
      setRegionalManagers(data);
    } else {
      toastNotification("error", "Error fetching the Regional Manager records");
    }
  };

  const populateHospitalList = async (limit, skip, searchValue) => {
    setHospitalLoading(true);
    const hospitals = await medicalRepService.getRegionalManagerHospitals(rmId);
    if (hospitals) {
      setLoadHospitals(hospitals.length === customPageSize);
      const arr = [
        ...new Map(
          [...hospitalList, ...hospitals].map((item) => [item["id"], item])
        ).values(),
      ];

      setHospitalList(arr);
      setHospitalCount(arr.length);
      setCurrentHospitalPage(0);
    }
    setHospitalLoading(false);
  };

  const sendActivationLink = async (email) => {
    const res = await userServices.postResendUserActivationLink(email);
    if (res?.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };
  /** API Calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getRegionalManagers();
      if (mrId) {
        const mrTemp = await getMedicalRepresentative();
        form.setFieldsValue({ ...mrTemp });
      } else {
        form.setFieldsValue({ regionalManager: rmId });
      }
      populateHospitalList(customPageSize, 0, null);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="medicalRepAddEditForm"
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row>
          <Col span={14} style={{ marginTop: "12px" }}>
            <Row align="middle">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 22, offset: 1 }}
              >
                <Form
                  form={form}
                  name="medicalRepAddEditForm"
                  id="medicalRepAddEditForm"
                  size="large"
                  colon="false"
                  scrollToFirstError="true"
                  onFinish={onFinish}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Details</Title>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Email (Required)"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not a valid Email!",
                          },
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: david@mail.com"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="Role (Required)"
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: "Please select a role!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a role"
                          disabled={!editMode}
                        >
                          <Select.Option value={Roles.MMR}>
                            {RolesDisplay.MMR}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Regional Manager (Required)"
                        name="regionalManager"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Regional Manager!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Regional Manager"
                          disabled={!editMode}
                        >
                          {regionalManagers?.map((rm) => (
                            <Select.Option value={rm.id} key={rm.id}>
                              {[rm.firstName, rm.middleName, rm.lastName].join(
                                " "
                              )}
                              <Text
                                style={{ color: "grey", paddingLeft: "8px" }}
                              >
                                {`(${rm.email})`}
                              </Text>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item label="Title" name="title">
                        <Input
                          placeholder="Example: Sr. VP"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item label="First Name" name="firstName">
                        <Input
                          placeholder="Example: Chadwick"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item label="Middle Name" name="middleName">
                        <Input
                          placeholder="Example: Joseph"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item label="Last Name" name="lastName">
                        <Input
                          placeholder="Example: Boseman"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Mobile Number"
                        name="mobileNumber"
                        rules={[
                          {
                            pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                            message: "Please follow the format (xxx) xxx-xxxx",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: (203) 683-3272"
                          id="mobileNumber"
                          onChange={onContactNumberInputChange}
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="Office Number"
                        name="officeNumber"
                        rules={[
                          {
                            pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                            message: "Please follow the format (xxx) xxx-xxxx",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: (966) 922-5419"
                          id="officeNumber"
                          onChange={onContactNumberInputChange}
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {medicalRepresentative &&
                        !medicalRepresentative.isActive && (
                          <Tag
                            color="gold"
                            icon={<MailFilled color="#db9e2e" />}
                            className="activation-link"
                          >
                            <Row>
                              <Col span={8}>
                                <Text> User activation is pending </Text>
                              </Col>
                              <Col
                                span={8}
                                offset={8}
                                style={{ textAlign: "end" }}
                              >
                                <a
                                  style={{ color: "inherit" }}
                                  onClick={(e) => {
                                    sendActivationLink(
                                      medicalRepresentative.email
                                    );
                                  }}
                                >
                                  Resend Invite Link
                                </a>
                              </Col>
                            </Row>
                          </Tag>
                        )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={8} style={{ marginTop: "12px" }}>
            <Title level={5}>Assigned Hospitals</Title>
            <AssignmentSelection
              editMode={!isMMR && editMode}
              selectedEntityIds={selectedHospitalIds}
              dataSource={hospitalList}
              selectedEntities={selectedHospitals}
              optionIcon={HospitalColorIcon}
              showOptionsLoader={hospitalLoading}
              onSelect={onHospitalSelect}
              onDeselect={onHospitalDeselect}
              onSearch={onSearchHospital}
              filterOption={handleFilterOption}
              onDropdownVisibleChange={() => setCurrentHospitalPage(1)}
              onChange={() => setCurrentHospitalPage(0)}
              onPopupScroll={handleMenuScrollToBottom}
              onInputKeyDown={handleInputChange}
              tagIcon={HospitalColorIcon}
              emptyText="No Hospitals added yet"
              emptyIcon={HospitalGreyIcon}
              entityTitleProperty="hospitalName"
              placeholder="Search by Hospital Name"
            />
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default MedicalRepresentativeForm;
