import http from "./httpService";

const postCloneProcedure = async (procedureId, ownerId, ownerName= "distributor") => {
  const res = await http.dorAxiosPost(`procedure/${procedureId}/clone`, {
    [ownerName+"Id"]: ownerId,
  })
  return res?.data ?? false;
};

async function getProcedure(procedureId) {
  const params = { populate: "case,clonedFrom" };
  const res = await http.dorAxiosGet(`/procedure/${procedureId}`, params);
  return res ? res.data : false;
}

async function getDistributorProcedures(
  entityId,
  isDeleted,
  limit,
  skip,
  searchValue,
  entityName = "distributor"
) {
  try {
    let api;
    let params = {
      where:{
        [entityName]:
          typeof entityId === "string" ? entityId : { in: entityId },
        isDeleted,
      },
      populate: "distributor,regionalManager",
      limit,
      skip,
    };

    if (searchValue) {
      api = "/procedure/search";
      params.where.procedureName = { contains: searchValue };
    } else {
      api = "/procedure";
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const getProcedureCount = async (ids, procedureType, isDeleted, searchText) => {
  try {
    const res = await http.get("/procedure/count", {
      params: { id: ids?.toString(), procedureType, isDeleted, searchText },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function postProcedure(procedure) {
  try {
    return await http.post("/procedure", procedure);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function putProcedure(procedure) {
  try {
    return await http.patch(`/procedure/${procedure.id}`, procedure);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const patchProcedure = async (id, data) => {
  try {
    const res = await http.patch(`/procedure/${id}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

async function deleteProcedure(id) {
  try {
    await http.patch(`/procedure/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

// Table setup APIs
const getTableSetup = async (procedureId, trayId, limit, skip) => {
  try {
    const res = await http.get("/TableSetUpAndVerification", {
      params: {
        procedure: procedureId,
        tray: trayId,
        populate: "tray",
        limit,
        skip,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const postTableSetup = async (procedureId, tableSetUp) => {
  try {
    const res = await http.post(
      `/procedure/${procedureId}/tableSetUp`,
      tableSetUp
    );
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

async function getTrayEquipmentsList(
  entityId,
  manufacturerId,
  isDeleted,
  limit,
  skip,
  searchValue,
  entityName = "distributor"
) {
  try {
    let api;
    let params = {
      where: { [entityName]:
          typeof entityId === "string" ? entityId : { in: entityId },
           isDeleted },
      populate: "distributor",
      limit,
      skip,
    };

    if(entityName === 'regionalManager'){ 
      params.where = { or: [{ regionalManager: entityId },{distributor: manufacturerId }], isDeleted}
      params.populate = "distributor,regionalManager"
    }

    if (searchValue) {
      api = "/tray/search";
      params.where.trayName = { contains: searchValue };
    } else {
      api = "/tray";
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

export default {
  postCloneProcedure,
  getProcedure,
  getProcedureCount,
  getDistributorProcedures,
  postProcedure,
  putProcedure,
  patchProcedure,
  deleteProcedure,
  getTableSetup,
  postTableSetup,
  getTrayEquipmentsList
};
