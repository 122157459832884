import { Fragment, useEffect, useState } from "react";
import { useDrop, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import TrayModal from "./TrayModal";
import SignedURLImage from "./SignedURLImage";
import { ReactComponent as CloseIcon } from "../assets/icons/iconClose.svg";
import trayPlaceholder from "../assets/tray-placeholder.svg";

const TrayCard = (props) => {
  const {
    id,
    grid,
    dragEnd,
    onCloseButtonClick,
    tray,
    rotation,
    isProcedureEditable,
  } = props;

  const { src, trayId } = tray ? tray : {};

  const style = {
    height: "100%",
    width: "100%",
    position: "absolute",
    bottom: "0",
  };
  const [trayModal, setTrayModal] = useState(false);
  const [imgStyle, setImgStyle] = useState({});

  const closeModal = () => {
    setTrayModal(false);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "IMAGE",
    drop: (item) => ({
      id: id + "drop" + grid,
      item: item,
      name: id,
      src: src,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id: id + "drag" + grid, type: "IMAGE", src: src },
    end: (item, monitor) => dragEnd(item, monitor),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isActive = canDrop && isOver;
  let backgroundColor;
  if (isActive) {
    backgroundColor = "#eee";
  }

  const rotatedImgStyles = () => {
    const imgElement = document.getElementById(trayId);

    return imgElement
      ? {
          width: imgElement.offsetHeight,
          height: imgElement.offsetWidth,
          transformOrigin: "left top",
          transform: "rotate(90deg) translateY(-100%)",
        }
      : {};
  };

  const opacity = isDragging ? 0.4 : 1;
  const dropEnable = isProcedureEditable ? drop : null;
  const dragEnable = isProcedureEditable ? drag : null;

  const dragContainer =
    src || trayId ? (
      <Fragment>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "rgb(245,245,245)",
            opacity,
            display: "flex",
          }}
          ref={dragEnable}
          id={id + "drag" + grid}
        >
          <SignedURLImage
            onClick={() => {
              setTrayModal(true);
            }}
            id={trayId}
            key={trayId}
            fileName={src}
            style={imgStyle}
            fallback={trayPlaceholder}
          />
          {isProcedureEditable && (
            <span
              onClick={() => onCloseButtonClick(src, trayId)}
              style={{
                position: "absolute",
                margin: "10px 10px",
                right: "0",
              }}
            >
              <CloseIcon />
            </span>
          )}
        </div>
      </Fragment>
    ) : (
      <div
        style={{ position: "absolute", height: "100%", opacity }}
        ref={dragEnable}
        id={id + "drag" + grid}
      />
    );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    setImgStyle(rotation ? rotatedImgStyles() : {});
  }, [trayId, rotation]);

  return (
    <Fragment>
      <div
        ref={dropEnable}
        style={{ ...style, backgroundColor }}
        id={id + "drop" + grid}
      >
        {dragContainer}
      </div>
      {trayModal ? (
        <TrayModal show={trayModal} close={closeModal} trayId={trayId} />
      ) : null}
    </Fragment>
  );
};

export default TrayCard;
