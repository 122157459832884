import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Form, Input, Row, Col, Button, Modal, Spin, Divider } from "antd";
import FormSelect from "./Partial/FormSelect";
import toastNotification from "../../components/toastNotification";
import executionService from "../../services/executionService";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_close.svg";

const { confirm } = Modal;

const CheckListPage = ({
  addNewItem,
  updateCurrentItem,
  editRecord,
  updatedData,
  //saveFinalData,
  hideItem,
  getPhaseId,
  currentIndex,
  parentIndex,
  staticItems,
  getIndexOnCreate,
  isPageReadOnly,
}) => {
  const [tempItem, setTempItem] = useState([]);
  const [tempItemList, setTempItemList] = useState([]);
  const [form] = Form.useForm();
  const stepId = editRecord.phase;
  const [loading, setLoading] = useState(false);
  const [subItems, setSubItems] = useState([]);

  const getIndexOfItem = (data, index) => {
    if (index === 0) {
      return "a";
    } else {
      return data.index + "z";
    }
  };

  const addCheckListPhase = async (phaseFormValues) => {
    setLoading(true);
    let index = 0;
    if (phaseFormValues.items) {
      for (const item of phaseFormValues.items) {
        let data = {
          name: item.name,
        };
        const itemIndex = getIndexOfItem(tempItemList[index - 1], index);
        index++;
        data.index = itemIndex;
        const result = await executionService.postItem(data);
        tempItemList.push(result.data);
        setTempItemList([...tempItemList]);
        tempItem.push(result.data.id);
        setTempItem([...tempItem]);
      }
      phaseFormValues.items = tempItem;
    }
    phaseFormValues.stepType = "CHECKLIST";
    phaseFormValues.phase = getPhaseId();
    phaseFormValues.index = getIndexOnCreate(
      staticItems,
      "STEP",
      parentIndex,
      currentIndex
    );
    const res = await executionService.postStep(phaseFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Checklist Added");
      let item = {
        id: new Date().getTime(),
        phase: res.data.id,
        content: res.data.label,
        original: res.data,
        current: currentIndex,
        parent: parentIndex,
      };
      addNewItem(item);
      //saveFinalData(item);
    } else {
      toastNotification("error", "Error adding a new checklist record");
    }
    setLoading(false);
  };

  const EditCheckListPhase = async (phaseFormValues) => {
    setLoading(true);
    let itemIds = [];
    if (phaseFormValues.items) {
      let index = 0;
      for (const item of phaseFormValues.items) {
        let data = {
          name: item.name,
          isDeleted: false,
        };
        let id = false;
        if (subItems[index] === undefined) {
          const itemIndex = getIndexOfItem(tempItemList[index - 1], index);
          data.index = itemIndex;
          const result = await executionService.postItem(data);
          id = result.data.id;
          itemIds.push(id);
          index++;
          tempItemList.push(result.data);
          setTempItemList([...tempItemList]);
        } else {
          id = subItems[index].id;
          const result = await executionService.updateCheckListItem(data, id);
          itemIds.push(id);
          index++;
          tempItemList.push(result.data);
          setTempItemList([...tempItemList]);
        }
      }
    }
    phaseFormValues.items = itemIds;
    phaseFormValues.stepType = "CHECKLIST";
    phaseFormValues.phase = editRecord.original.phase;
    const res = await executionService.putStep(
      phaseFormValues,
      editRecord.phase
    );
    if (res.status === 200) {
      toastNotification("success", "CheckList Updated");
      updateCurrentItem(null);
      updatedData();
    } else {
      toastNotification("error", "Error adding a updated checklist record");
    }
    setLoading(false);
  };
  const onFinish = (phaseFormValues) => {
    //Check if items are added or not in a checklist step
    if (phaseFormValues.items?.length > 0) {
      if (editRecord.phase) {
        EditCheckListPhase(phaseFormValues);
      } else {
        addCheckListPhase(phaseFormValues);
      }
    } else {
      toastNotification("error", "Please add an item for the checklist step.");
    }
  };

  /** Delete checklist */
  const deletePhase = async (id) => {
    setLoading(true);
    const isDeleted = await executionService.deleteStep(editRecord.phase);
    if (isDeleted) {
      toastNotification("success", "CheckList deleted successfully");
      updateCurrentItem(null);
      updatedData();
    } else {
      toastNotification("error", "Error deleting the check list");
    }
    setLoading(false);
  };

  const showDeleteConfirmation = (id) => {
    confirm({
      title: <h2 className="delete-notes">Delete CheckList</h2>,
      content: (
        <div>
          <div>
            Tapping on "Confirm" would delete this not from the surgical flow.
          </div>
          <div>Are you sure you want to continue?</div>
        </div>
      ),
      okText: "Confirm",
      cancelText: "Cancel",
      icon: <></>,
      centered: true,
      onOk() {
        deletePhase(id);
      },
    });
  };
  const getItem = async (stepId) => {
    const res = await executionService.getCheckListItem(stepId);
    if (res) {
      setSubItems(res);
      form.setFieldsValue({ items: res });
    } else {
      toastNotification("error", "Error fetching the item records");
    }
  };
  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue(editRecord.original);
      getItem(stepId);
    }
  }, []);
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        name="checkListStepsAddEditForm"
        id="checkListStepsAddEditForm"
        size="large"
        colon="false"
        scrollToFirstError="true"
        onFinish={onFinish}
        layout="vertical"
      >
        <Row className="sidebar-header">
          <Col span={12}>
            <FormSelect
              updateCurrentItem={updateCurrentItem}
              selectedValue="CHECKLIST"
              editRecord={editRecord}
            />
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {!isPageReadOnly && (
              <Form.Item>
                {editRecord && (
                  <Button
                    style={{ marginRight: "0" }}
                    type="secondary"
                    onClick={() => {
                      showDeleteConfirmation(editRecord.phase);
                    }}
                    className="delete-btn-none"
                  >
                    Delete
                  </Button>
                )}
                {!editRecord && (
                  <Button
                    style={{ marginRight: "0" }}
                    type="default"
                    htmlType="button"
                    className="delete-btn-none"
                    onClick={hideItem}
                  >
                    Close
                  </Button>
                )}
                <Button
                  style={{ marginRight: "0" }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{
            margin: "-5px",
          }}
        />
        <Row className="margin-top-notes">
          <Col span={2}></Col>
          <Col span={21}>
            <Form.Item
              label="Label (Required)"
              name="label"
              required
              rules={[
                {
                  required: true,
                  message: "Please input label name!",
                },
              ]}
            >
              <Input placeholder="Checklist Title" disabled={isPageReadOnly} />
            </Form.Item>

            <Form.Item label="Items" className="checklist-item">
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => {
                      const removeItem = async () => {
                        remove(field.name);
                        if (editRecord) {
                          if (subItems[field.name] === undefined) {
                            remove(field.name);
                          } else {
                            let id = subItems[field.name].id;
                            const isDeleted =
                              await executionService.deleteCheckListItem(id);
                            if (isDeleted) {
                              toastNotification(
                                "success",
                                "Item deleted successfully"
                              );
                            } else {
                              toastNotification(
                                "error",
                                "Error deleting the phase"
                              );
                            }
                            setLoading(false);
                          }
                        }
                      };
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Form.Item
                            {...field}
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey]}
                          >
                            <Input
                              style={{ width: "450px" }}
                              placeholder="Checklist Item"
                              disabled={isPageReadOnly}
                            />
                          </Form.Item>

                          {!isPageReadOnly && (
                            <span className="remove-icon">
                              <CloseIcon onClick={removeItem} />
                            </span>
                          )}
                        </div>
                      );
                    })}
                    {!isPageReadOnly && (
                      <Form.Item className="checklist-input">
                        <Button
                          style={{
                            textAlign: "center",
                            background: "none",
                            marginLeft: "0",
                          }}
                          type="secondary"
                          className="bg-none"
                          onClick={() => add()}
                          block
                          icon={<PlusIcon />}
                        >
                          Add Item
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
          <Col span={1}></Col>
        </Row>
        <Divider
          type="horizontal"
          style={{
            margin: "-5px",
          }}
        />
      </Form>
    </Spin>
  );
};

export default CheckListPage;
