import TrayEquipmentPage from "../../shared-pages/editable-mini-app/TrayEquipmentPage";

const AreaTrayEquipmentPage = (props) => {
  const {
    match: { params }
  } = props;

  const { procedureid: procedureId, rmid: rmId, id } = params;

  return (
    <TrayEquipmentPage
      procedureId={procedureId}
      manufacturerId={id}
    />
  );
};

export default AreaTrayEquipmentPage;
