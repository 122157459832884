import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Spin,
  List,
  Modal,
  Tabs,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import CustomCard from "../../components/CustomCard";
import CardList from "../../components/CardList";
import procedureService from "../../services/procedureService";
import utilityService from "../../services/utilityService";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as ManufacturerIcon } from "../../assets/icons/icon_Manufacturer.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/icons/color-icons_procedure.svg";
import { ReactComponent as RegionalManagerIcon } from "../../assets/icons/icon_regional-manager.svg";

const { TabPane } = Tabs;
const { confirm } = Modal;
const customPageSize = 12;

const ProceduresPage = ({
  manufacturerId,
  url,
  hasOwnLibrary,
  entityId,
  entityName,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isDeletedPage = url.split("/").includes("deleted");

  const [activeTabKey, setActiveTabKey] = useState(
    entityName === "area" ? "1" : location.state?.activeTabKey ?? "2"
  );
  const [currentPage, setCurrentPage] = useState(
    location.state?.pageNumber ?? 1
  );
  const [procedureCount, setProcedureCount] = useState(0);
  const [procedureSearchText, setProcedureSearchText] = useState();
  const [procedureList, setProceduresList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);

  const actions = (procedure) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        !(procedure.distributor?.isManufacturer) && {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(`${url}/${procedure.id}/table-setup`, {
              record: procedure,
              tag: "Table Setup",
              isPageReadOnly: false,
              pageNumber: currentPage,
              activeTabKey: activeTabKey,
            });
          },
        },
        {
          title: "Clone",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();

            if (entityName === "regionalManager")
              cloneProcedure(
                procedure.id, entityId, "regionalManager"
              );
            else
              cloneProcedure(
                procedure.id,
                manufacturerId,
                "distributor"
              );
          },
        },
        !(procedure.distributor?.isManufacturer) && {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(procedure.id);
          },
        },
      ];
  };

  const cardContent = (procedure) => [
    {
      icon: procedure.distributor ? (
        <ManufacturerIcon />
      ) : (
        <RegionalManagerIcon />
      ),
      content: procedure.distributor
        ? `By ${procedure?.distributor?.distributorName}`
        : `By ${utilityService.getRegionalManagerName(
          procedure.regionalManager
        )}`,
    },
    {
      icon: <CalenderIcon fill="#808285" />,
      content: procedure.createdAt
        ? "On " + new Date(procedure.createdAt).toISOString().slice(0, 10)
        : null,
    },
  ];

  const showDeleteConfirmation = (procedureId) => {
    confirm({
      title: "Delete Procedure",
      content: "Are you sure you want to delete this Procedure?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        deleteProcedure(procedureId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        searchValue = searchValue.trim();
        setProcedureSearchText(searchValue);
        await getSetProcedureCountAndPopulate(
          activeTabKey,
          searchValue,
          1
        );
        setLoading(false);
      }, 500)
    );
  };

  /** API Calls */
  const populateProcedureList = async (
    id,
    limit,
    skip,
    searchValue,
    entityName
  ) => {
    const data = await procedureService.getDistributorProcedures(
      id,
      isDeletedPage,
      limit,
      skip,
      searchValue,
      entityName
    );

    if (data) {
      setProceduresList(data);
    } else {
      toastNotification("error", "Error fetching the Procedure records");
    }
  };

  const deleteProcedure = async (id) => {
    setLoading(true);
    const isDeleted = await procedureService.deleteProcedure(id);
    if (isDeleted) {
      toastNotification("success", "Procedure deleted successfully");
      setProcedureSearchText("");
      await getSetProcedureCountAndPopulate(
        activeTabKey,
        null
        // selectedManufacturerIds
      );
    } else {
      toastNotification("error", "Error deleting the Procedure");
    }
    setLoading(false);
  };

  const getSetProcedureCountAndPopulate = async (
    tabKey,
    searchText,
    currentPageNumber = currentPage
    // manufacturerIds
  ) => {
    const success = await procedureService.getProcedureCount(
      isDeletedPage && entityId
        ? entityId
        : tabKey === "1"
          ? manufacturerId
          : entityId,
      tabKey === "1" ? "global" : "regionalmanager",
      isDeletedPage,
      searchText
    );

    if (success) {
      setProcedureCount(success.count);
      if (hasOwnLibrary && (tabKey === "2" || isDeletedPage)) {
        await populateProcedureList(
          entityId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "regionalManager"
        );
      } else
        await populateProcedureList(
          manufacturerId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "distributor"
        );
    } else {
      setProceduresList([]);
      toastNotification("error", "Error fetching Procedure records");
    }
  };

  /** API Calls End */

  const cloneProcedure = async (procedureId, ownerId, ownerName) => {
    setLoading(true);

    const newProcedure = await procedureService.postCloneProcedure(
      procedureId,
      ownerId,
      ownerName
    );

    if (newProcedure) {
      toastNotification("success", "Procedure cloned successfully");
      history.push(url + '/' + newProcedure.id, {
        isCloneProcedure: true
      });
    } else {
      toastNotification("error", "Error cloning the Procedure");
    }
    setLoading(false);
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetProcedureCountAndPopulate(
        activeTabKey,
        null
      );
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        searchPlaceholderTxt="Search by Procedure Name"
        onSearchFunction={onSearchFunction}
        {...(isDeletedPage
          ? {
            title: "Deleted Procedure",
            backBtnPath: url.replace("-library/deleted", ""),
          }
          : {
            title: "Procedures",
            ...(activeTabKey === "2" && {
              btnText: "Create Procedure",
              onBtnClick: () => {
                history.push(`${url}/new`);
              },
            }),
            topActionMenuItems: [
              {
                title: "Show Deleted Procedures",
                path: `${url}-library/deleted`,
              },
            ],
          })}
      >
        {hasOwnLibrary && !isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setProcedureSearchText("");
              setCurrentPage(1);
              setActiveTabKey(key);
              await getSetProcedureCountAndPopulate(
                key,
                procedureSearchText,
                1
              );
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Procedures" key="1" />
            <TabPane
              tab={
                url.includes("medical-representative")
                  ? "Regional Manager's Procedures"
                  : "My Procedures"
              }
              key="2"
            />
          </Tabs>
        )}
        <CardList
          dataSource={procedureList}
          pagination={{
            pageSize: customPageSize,
            total: procedureCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              await populateProcedureList(
                activeTabKey === "1" ? manufacturerId : entityId,
                pageSize,
                pageSize * (page - 1),
                procedureSearchText,
                activeTabKey === "1" ? "distributor" : "regionalManager"
              );
              setLoading(false);
            },
          }}
          renderItem={(procedure) => {
            return (
              <List.Item>
                <CustomCard
                  headerIcon={<ProcedureIcon />}
                  title={procedure.procedureName}
                  actions={actions(procedure)}
                  onClick={
                    isDeletedPage
                      ? null
                      : () => {
                        history.push(`${url}/${procedure.id}/table-setup`, {
                          record: procedure,
                          isPageReadOnly: true,
                          pageNumber: currentPage,
                          activeTabKey: activeTabKey,
                        });
                      }
                  }
                  bodyContent={cardContent(procedure)}
                />
              </List.Item>
            );
          }}
        />
      </MainContainer>
    </Spin>
  );
};
export default ProceduresPage;
