import TableSetupPage from "../../shared-pages/editable-mini-app/TableSetupPage";
import ProcedureMainPage from "../../shared-pages/read-only/mini-app/ProcedureMainPage";

const AreaTableSetup = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { procedureid: procedureId, rmid: rmId, id } = params;

  return (
    state?.isCloneProcedure ?
      <TableSetupPage
        entityId={rmId}
        entityName="area"
        procedureId={procedureId}
        manufacturerId={id}
        state={state}
      /> :
      <ProcedureMainPage />
  );
};

export default AreaTableSetup;
