import { Roles } from "../config/role-config.json";
import DistributorsPage from "../pages/DistributorsPage";
import DistributorForm from "../pages/DistributorForm";
import HospitalsPage from "../pages/HospitalsPage";
import HospitalForm from "../pages/HospitalForm";
import ManufacturersPage from "../pages/ManufacturersPage";
import ManufacturerForm from "../pages/ManufacturerForm";
import AdminsPage from "../pages/AdminsPage";
import AdminForm from "../pages/AdminForm";
import { BreadCrumbData } from "./index.routes";

const adminRoutes = {
  Routes: [
    /** Admin */
    {
      path: "/admin/deleted",
      rolesAccess: [Roles.DOR_ADMIN],
      breadcrumb: "Deleted",
      exact: true,
      component: AdminsPage,
    },
    {
      path: "/admin/new",
      rolesAccess: [Roles.DOR_ADMIN],
      breadcrumb: "New Admin",
      exact: true,
      component: AdminForm,
    },
    {
      path: "/admin/:id",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.id} {...props} />
      ),
      component: AdminForm,
    },
    {
      path: "/admin",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "DinamicOR Admins",
      component: AdminsPage,
    },
    /** Hospitals */
    {
      path: "/hospital/deleted",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: HospitalsPage,
    },
    {
      path: "/hospital/new",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "New Hospital",
      component: HospitalForm,
    },
    // For Breadcrumb
    {
      path: "/hospital/:id",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="hospital" id={props.match.params.id} />
      ),
      redirection: "setup",
    },
    {
      path: "/hospital",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Hospitals",
      component: HospitalsPage,
    },
    /** Distributors */
    {
      path: "/distributor/deleted",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorsPage,
    },
    {
      path: "/distributor/new",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "New Distributor",
      component: DistributorForm,
    },
    // For Breadcrumb
    {
      path: "/distributor/:id",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="distributor" id={props.match.params.id} />
      ),
      redirection: "setup",
    },
    {
      path: "/distributor",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Distributors",
      component: DistributorsPage,
    },
    /** Manufacturer */
    {
      path: "/manufacturer/deleted",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Manufacturer",
      component: ManufacturersPage,
    },
    {
      path: "/manufacturer/new",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "New Manufacturer",
      component: ManufacturerForm,
    },
    // For Breadcrumb
    {
      path: "/manufacturer/:id",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="manufacturer" id={props.match.params.id} />
      ),
      redirection: "setup",
    },
    {
      path: "/manufacturer",
      rolesAccess: [Roles.DOR_ADMIN],
      exact: true,
      breadcrumb: "Manufacturers",
      component: ManufacturersPage,
    },
  ],
  MiniAppRoutes: [],
};

export default adminRoutes;
