import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  // Divider,
  // Empty,
  Spin,
} from "antd";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
// import hospitalService from "../../services/hospitalService";
// import distributorService from "../../services/distributorService";
import utilityService from "../../services/utilityService";
// import { ReactComponent as HospitalColorIcon } from "../../assets/icons/colorIconHospital.svg";
// import { ReactComponent as TagCloseIcon } from "../../assets/icons/iconTagClose.svg";
// import { ReactComponent as CheckboxSelectedIcon } from "../../assets/icons/checkboxSelected.svg";

const { Title, Text } = Typography;
const { confirm } = Modal;

const DistributorUserForm = (props) => {
  const { match, history, location } = props;
  const distributorUserId = match.params.userid ? match.params.userid : null;
  const distributorId = match.params.id ? match.params.id : null;
  let distributorUser = location.state?.record;

  //TODO :: Check if invalid distributor id
  const pathToDistributorUsers = `/distributor/${distributorId}/user`;

  // const [hospitalIds, setHospitalIds] = useState([]);
  // const [hospitalsList, setHospitalsList] = useState([]);
  // const [selectedHospitalsId, setSelectedHospitalsId] = useState([]);
  // const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [timeOut, setTimeOut] = useState(0);
  // const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  // const [hospitalCount, setHospitalCount] = useState();
  // const [isLoading, setIsLoading] = useState(false);
  // const [hospitalSearchText, setHospitalSearchText] = useState();
  // const [loadHospitals, setLoadHospitals] = useState(false);
  // const customPageSize = 30;

  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(
    !distributorUserId || location.state?.editMode
  );

  const pageHeaderProps = distributorUserId
    ? {
      title: "Edit Distributor User",
      btnText: editMode ? "Save" : "Edit",
      btnType: editMode ? "primary" : "secondary",
      topActionMenuItems: [
        {
          title: "Delete Distributor User",
          path: "",
          onClick: () => {
            showDeleteConfirmation(distributorUserId);
          },
        },
      ],
    }
    : {
      title: "New Distributor User",
      btnText: "Add",
    };

  // const onHospitalSelect = (hospitalId) => {
  //   const ids = selectedHospitalsId;
  //   ids.push(hospitalId);
  //   setSelectedHospitalsId([...ids]);

  //   const selectedHospital = hospitalsList.find(
  //     (hospital) => hospital.id === hospitalId
  //   );
  //   selectedHospitals.push(selectedHospital);
  //   setSelectedHospitals([...selectedHospitals]);
  // };

  // const onHospitalDeselect = (hospitalId) => {
  //   setSelectedHospitalsId(selectedHospitalsId.filter((x) => x !== hospitalId));

  //   setSelectedHospitals(
  //     selectedHospitals.filter((hospital) => hospital.id !== hospitalId)
  //   );
  // };

  // const logValue = debounce((searchText) => {
  //   setHospitalSearchText(searchText);
  // }, 250);

  // const addOptions = async () => {
  //   setLoadHospitals(false);
  //   setIsLoading(true);
  //   setCurrentHospitalPage(currentHospitalPage + 1);
  //   await populateHospitalsList(
  //     customPageSize,
  //     currentHospitalPage * customPageSize,
  //     hospitalSearchText
  //   );
  //   setIsLoading(false);
  // };

  // const handleOpen = () => {
  //   setCurrentHospitalPage(1);
  // };

  // const handleChange = (value, options) => {
  //   setCurrentHospitalPage(0);
  // };

  // const handleInputChange = (value) => {
  //   logValue(value);
  //   if (value.length === 0) setCurrentHospitalPage(1);
  // };

  // const handleMenuScrollToBottom = () => {
  //   {
  //     loadHospitals && hospitalsList.length !== hospitalCount && addOptions();
  //   }
  // };

  // const getHospitalCount = async (searchText) => {
  //   const count = await hospitalService.getHospitalCount(
  //     false,
  //     searchText,
  //     hospitalIds
  //   );
  //   if (count >= 0) {
  //     setHospitalCount(count);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const onSearchFunction = async (searchValue) => {
  //   if (timeOut) {
  //     clearTimeout(timeOut);
  //   }
  //   setTimeOut(
  //     setTimeout(async () => {
  //       searchValue = searchValue.trim();
  //       setHospitalSearchText(searchValue);
  //       const count = await getHospitalCount(searchValue);
  //       if (count) {
  //         setCurrentHospitalPage(0);
  //         populateHospitalsList(customPageSize, 0, searchValue);
  //       } else {
  //         toastNotification("error", "Error fetching hospital records");
  //       }
  //     }, 500)
  //   );
  // };

  // const handlefilterOption = (input, option) => {
  //   return option.value !== "loading"
  //     ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //     : false;
  // };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Distributor User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        setLoading(true);
        const isDeleted = await userServices.deleteUser(distributorUserId);
        if (isDeleted) {
          toastNotification("success", "Distributor user deleted successfully");
          history.push(pathToDistributorUsers);
        } else {
          toastNotification("error", "Error deleting the user");
        }
        setLoading(false);
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const addDistributorUser = async (distributorUserFormValues) => {
    // TODO :: Trim values before submit
    distributorUserFormValues.password = "password123";
    distributorUserFormValues.distributor = distributorId;
    const res = await userServices.postUser(distributorUserFormValues);
    if (res.status === 200) {
      toastNotification("success", "New distributor user added");
      history.push(pathToDistributorUsers);
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new user record");
    }
  };

  const editDistributorUser = async (distributorUserFormValues) => {
    // TODO :: Trim values before submit
    const res = await userServices.putUser(distributorUserFormValues);
    if (res.status === 200) {
      setEditMode(false);
      toastNotification("success", "Distributor User Record Updated");
      history.push(pathToDistributorUsers, { pageNumber: location.state?.pageNumber });
    } else {
      toastNotification("error", "Error updating the user record");
    }
  };

  const getDistributorUser = async (id) => {
    const data = await userServices.getUser(id);
    if (data) {
      distributorUser = data;
    } else {
      toastNotification("error", "Requested user record not found");
    }
  };

  // const getDistributorAndSetHospitals = async () => {
  //   const data = await distributorService.getDistributor(distributorId);
  //   if (data) {
  //     const ids = data.hospitals.map((hospital) => hospital.id);
  //     setHospitalIds(ids);
  //     setHospitalCount(ids.length);
  //     return ids;
  //   } else {
  //     toastNotification("error", "Requested distributor not found");
  //   }
  // };

  const onFinish = async (distributorUserFormValues) => {
    setLoading(true);

    if (distributorUserId) {
      distributorUserFormValues.id = distributorUserId;
      await editDistributorUser(distributorUserFormValues);
    } else {
      await addDistributorUser(distributorUserFormValues);
    }
    setLoading(false);
  };

  const sendActivationLink = async (e) => {
    const res = await userServices.postResendUserActivationLink(
      distributorUser.email
    );
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  // const populateHospitalsList = async (limit, skip, searchValue, hIds) => {
  //   const hIdsTemp = hIds ? hIds : hospitalIds;
  //   setIsLoading(true);
  //   const res = await hospitalService.getHospitals(
  //     false,
  //     limit,
  //     skip,
  //     searchValue,
  //     hIdsTemp
  //   );
  //   if (res) {
  //     setLoadHospitals(res.length === customPageSize);
  //     const arr = [...hospitalsList, ...res];
  //     setHospitalsList([
  //       ...new Map(arr.map((item) => [item["id"], item])).values(),
  //     ]);
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (distributorUserId) {
        if (!distributorUser) {
          await getDistributorUser(distributorUserId);
        }
        if (distributorUser) {
          form.setFieldsValue({ ...distributorUser });
          // distributorUser.hospital =
          //   await userServices.getDistributorUserHospitals(
          //     distributorUserId,
          //     distributorId,
          //     false
          //   );

          // if (distributorUser.hospital) {
          //   setSelectedHospitalsId(
          //     distributorUser.hospital.map((hospital) => hospital.id)
          //   );
          //   setSelectedHospitals(distributorUser.hospital);
          // }
        }
      }

      // const hIds = await getDistributorAndSetHospitals();
      // if (hIds) populateHospitalsList(customPageSize, 0, null, hIds);
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      formName="distributorUserAddEditForm"
      backBtnPath={pathToDistributorUsers}
      routeState={{ pageNumber: location.state?.pageNumber }}
      onBtnClick={() => {
        if (editMode) form.submit();
        else setEditMode(true);
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14} offset={5}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Form
                    form={form}
                    name="distributorUserAddEditForm"
                    id="distributorUserAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Details</Title>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item
                          label="Email (Required)"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not a valid Email!",
                            },
                            {
                              required: true,
                              message: "Please input your Email!",
                            },
                          ]}
                        >
                          <Input placeholder="Example: david@mail.com" disabled={!editMode} />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Role (Required)"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Please select a role!",
                            },
                          ]}
                        >
                          <Select placeholder="Select a role" disabled={!editMode}>
                            <Select.Option value="DISTRIBUTOR_ADMIN">
                              Distributor Admin
                            </Select.Option>
                            <Select.Option value="MR">
                              Medical Representative
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="First Name" name="firstName">
                          <Input placeholder="Example: Chadwick" disabled={!editMode} />
                          {/* disabled={readOnlyView} /> */}
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item label="Middle Name" name="middleName">
                          <Input placeholder="Example: Joseph" disabled={!editMode} />
                          {/* disabled={readOnlyView} /> */}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="Last Name" name="lastName">
                          <Input placeholder="Example: Boseman" disabled={!editMode} />
                          {/* disabled={readOnlyView} /> */}
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Mobile Number"
                          name="mobileNumber"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (203) 683-3272"
                            id="mobileNumber"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11}>
                        <Form.Item
                          label="Office Number"
                          name="officeNumber"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (966) 922-5419"
                            id="officeNumber"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {distributorUser && !distributorUser.isActive && (
                          <Tag
                            color="gold"
                            icon={<MailFilled color="#db9e2e" />}
                            className="activation-link"
                          >
                            <Row>
                              <Col span={8}>
                                <Text> User activation is pending </Text>
                              </Col>
                              <Col
                                span={8}
                                offset={8}
                                style={{ textAlign: "end" }}
                              >
                                <a
                                  style={{ color: "inherit" }}
                                  onClick={(e) => {
                                    sendActivationLink(e);
                                  }}
                                >
                                  Resend Invite Link
                                </a>
                              </Col>
                            </Row>
                          </Tag>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            {/* <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Assigned Hospitals</Title>
              <Select
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                showSearch
                allowClear
                autoClearSearchValue={false}
                onSelect={onHospitalSelect}
                onDeselect={onHospitalDeselect}
                onDropdownVisibleChange={handleOpen}
                onChange={handleChange}
                onSearch={onSearchFunction}
                onPopupScroll={handleMenuScrollToBottom}
                onInputKeyDown={handleInputChange}
                filterOption={handlefilterOption}
                tagRender={() => null}
                value={selectedHospitalsId}
                menuItemSelectedIcon={<CheckboxSelectedIcon />}
                notFoundContent={
                  <div
                    className="display-flex-center"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<b>No Records Found.</b>}
                    />
                  </div>
                }
              >
                {hospitalsList.map((hospital) => (
                  <Option key={hospital.id} title={hospital.hospitalName}>
                    <div className="display-flex-center">
                      <HospitalColorIcon />
                      <Text style={{ marginLeft: "7px" }} ellipsis>
                        {hospital.hospitalName}
                      </Text>
                    </div>
                  </Option>
                ))}
                {isLoading && (
                  <Option value="loading" disabled>
                    Loading more...
                  </Option>
                )}
              </Select>
              <div className="custom-tag-list-item">
                {selectedHospitals.map((hospital, index) => (
                  <Row key={index} gutter={[0, 8]}>
                    <Col span={24}>
                      <Tag
                        color="#f5f5f5"
                        key={hospital.value}
                        icon={
                          <HospitalColorIcon
                            style={{ width: "2.5em", height: "2.5em" }}
                          />
                        }
                        closable
                        closeIcon={<TagCloseIcon />}
                        onClose={() => onHospitalDeselect(hospital.id)}
                      >
                        <Text style={{ marginLeft: "0.4em" }}>
                          {hospital.hospitalName}
                        </Text>
                      </Tag>
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
            <Col span={1}></Col> */}
          </Row>
        </div>
      </Spin>
    </MainContainer>
  );
};

export default DistributorUserForm;
