import http from "./httpService";

async function postTray(tray) {
  try {
    return await http.post("/tray", tray);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function patchTray(tray) {
  try {
    return await http.patch(`/tray/${tray.id}`, tray);
  } catch (exception) {
    console.log(exception.response);
    return false;
  }
}

async function getTray(distributorId, trayId) {
  try {
    const res = await http.get(`/tray/${trayId}`, {
      params: {
        where: {
          id: trayId,
          distributor: distributorId,
        },
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getDistributorTrays(
  entityId,
  isDeleted,
  limit,
  skip,
  searchValue,
  entityName = "distributor"
) {
  try {
    let api;
    let params = {
      where: {
        [entityName]:
          typeof entityId === "string" ? entityId : { in: entityId },
        isDeleted,
      },
      populate: "distributor",
      limit,
      skip,
    };

    if (searchValue) {
      api = "/tray/search";
      params.where.trayName = { contains: searchValue };
    } else {
      api = "/tray";
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getTrayEquipments(trayId) {
  try {
    const res = await http.get("/equipmenttray", {
      params: {
        where: {
          tray: trayId,
        },
        populate: "equipment",
        limit: 1000,
      },
    });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

const getTrayCount = async (entityId, isDeleted, searchText, entityName = "distributorId") => {
  try {
    const params = {
      [entityName]: entityId,
      isDeleted,
      searchText,
    };
    const res = await http.get("/tray/count", {
      params,
    });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

export default {
  postTray,
  patchTray,
  getDistributorTrays,
  getTray,
  getTrayEquipments,
  getTrayCount,
};
