import React, { useState, useEffect } from "react";
import { Typography, Tag } from "antd";
import MainContainer from "../../../containers/MainContainer";
import TableSetup from "./TableSetup";
import toastNotification from "../../../components/toastNotification";
import ExecutionLandingPage from "../../../pages/Distributor/ExecutionLandingPage";
import distributorService from "../../../services/distributorService";
import { useRouteMatch, useLocation } from "react-router-dom";

const { Paragraph } = Typography;
const { CheckableTag } = Tag;

const ProcedureMainPage = () => {
  const { params, url } = useRouteMatch();
  const { state } = useLocation();
  const { procedureid: procedureId } = params;

  const tagsData = ["Table Setup", "Surgical Flow"];
  const isProcedureEditable = false;

  const [selectedTag, setSelectedTag] = useState("Table Setup");
  const [procedure, setProcedure] = useState();
  const [tableMode, setTableMode] = useState("3WIDE");
  // const [isCaseProcedure, setIsCaseProcedure] = useState(false);
  const [backBtnPath, setBackBtnPath] = useState("/");

  const tag = (
    <div
      style={{
        display: "inherit",
        border: "1px solid black",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag}
          className="table-setup-tags"
          style={
            selectedTag?.indexOf(tag) > -1
              ? { backgroundColor: "#000", color: "white" }
              : null
          }
          checked={selectedTag === tag > -1}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag}
        </CheckableTag>
      ))}
    </div>
  );

  const title = (
    <Paragraph
      style={{ margin: "0 0.5em", whiteSpace: "normal" }}
      ellipsis={{ rows: 2 }}
    >
      {procedure?.procedureName}
    </Paragraph>
  );

  const handleChange = (tag) => {
    setSelectedTag(tag);
  };

  const getProcedureDetails = async () => {
    const procedureDtl = await distributorService.getProcedure(
      procedureId,
      true
    );

    if (procedureDtl) {
      setProcedure(procedureDtl);
      setTableMode(procedureDtl.tableMode);
      if (procedureDtl.case) {
        // setIsCaseProcedure(true);
        setBackBtnPath(
          url.replace(`/procedure/${procedureId}/table-setup`, "")
        );
      } else {
        // setIsCaseProcedure(false);
        setBackBtnPath(url.replace(`/${procedureId}/table-setup`, ""));
      }
    } else toastNotification("error", "Something went wrong");
  };

  useEffect(() => {
    if (!procedure) getProcedureDetails();
  }, [selectedTag]);

  return (
    <div className="table-setup-main-container">
      <MainContainer
        routeState={{
          isProcedurePage: true,
          pageNumber: state?.pageNumber,
          caseType: state?.caseType
        }}
        tags={tag}
        title={title}
        backBtnPath={backBtnPath}
      >
        {selectedTag === "Surgical Flow" ? (
          <ExecutionLandingPage
            procedure={procedure}
            isProcedureEditable={isProcedureEditable}
          />
        ) : (
          <TableSetup
            tableMode={tableMode}
            procedureId={procedureId}
            isProcedureEditable={isProcedureEditable}
          />
        )}
      </MainContainer>
    </div>
  );
};

export default ProcedureMainPage;
