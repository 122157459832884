import { useState, useEffect } from "react";
import { Form, Input, Modal, Row, Col, Typography, Spin, Divider } from "antd";
import { debounce } from "lodash";
import MainContainer from "../../containers/MainContainer";
import AssignmentSelection from "../../components/AssignmentSelection";
import toastNotification from "../../components/toastNotification";
import areaService from "../../services/areaService";
import utilityService from "../../services/utilityService";
import manufacturerService from "../../services/manufacturerService";
import { useAuthContext } from "../../context/AuthContext";
import { ReactComponent as HospitalGreyIcon } from "../../assets/icons/greyIconManufacturer.svg";
import { ReactComponent as HospitalColorIcon } from "../../assets/icons/colorIconHospital.svg";
import { Roles } from "../../config/role-config.json";

const { confirm } = Modal;
const { Title } = Typography;

const ManufacturerAreaForm = (props) => {
  const { match, history, location } = props;
  const manufacturerId = match.params?.id;
  const areaId = match.params?.areaid;
  const backBtnPath = `/manufacturer/${manufacturerId}/area`;
  const customPageSize = 30;

  const { role: userRole } = useAuthContext().user;

  const isAreaManager = userRole === Roles.AREA_MANAGER;

  const [area, setArea] = useState(location.state?.record);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(!areaId || location.state?.editMode);
  const [initialHospitalIds, setInitialHospitalIds] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalIds, setSelectedHospitalIds] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitalSearchTimeOut, setHospitalSearchTimeOut] = useState(0);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const [hospitalCount, setHospitalCount] = useState();
  const [hospitalSearchText, setHospitalSearchText] = useState();
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [loadHospitals, setLoadHospitals] = useState(false);

  const pageHeaderProps = areaId
    ? {
        title: area?.areaName ? area.areaName : "Area Details",
        btnText: editMode ? "Save" : "Edit",
        btnType: editMode ? "primary" : "secondary",
        topActionMenuItems: !isAreaManager && [
          {
            title: "Delete",
            path: "",
            onClick: () => {
              showDeleteConfirmation(areaId);
            },
          },
        ],
      }
    : {
        title: "New Area",
        btnText: "Add",
        backBtnPath,
      };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Area",
      content: "Are you sure you want to delete this Area?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        setLoading(true);
        const isDeleted = await areaService.patchArea({
          id: areaId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Area deleted successfully");
          history.push(backBtnPath);
        } else {
          toastNotification("error", "Error deleting the Area");
        }
        setLoading(false);
      },
    });
  };

  /** Hospitals Assignment */

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentHospitalPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadHospitals && hospitalList.length !== hospitalCount && addOptions();
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : true;
  };

  const onSearchHospital = async (searchValue) => {
    if (hospitalCount >= 30) {
      setHospitalLoading(true);
      if (hospitalSearchTimeOut) {
        clearTimeout(hospitalSearchTimeOut);
      }
      setHospitalSearchTimeOut(
        setTimeout(async () => {
          searchValue = searchValue.trim();
          setHospitalSearchText(searchValue);
          await populateHospitalList(customPageSize, 0, searchValue);
          setHospitalLoading(false);
        }, 500)
      );
    } else return true;
  };

  const onHospitalSelect = (hospitalId) => {
    const ids = selectedHospitalIds;
    ids.push(hospitalId);
    setSelectedHospitalIds([...ids]);

    const selectedHospital = hospitalList.find(
      (hospital) => hospital.id === hospitalId
    );
    selectedHospitals.push(selectedHospital);
    setSelectedHospitals([...selectedHospitals]);
  };

  const onHospitalDeselect = (hospitalId) => {
    setSelectedHospitalIds(selectedHospitalIds.filter((x) => x !== hospitalId));

    setSelectedHospitals(
      selectedHospitals.filter((hospital) => hospital.id !== hospitalId)
    );
  };

  const logValue = debounce((searchText) => {
    setHospitalSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadHospitals(false);
    setHospitalLoading(true);
    setCurrentHospitalPage(currentHospitalPage + 1);
    await populateHospitalList(
      customPageSize,
      currentHospitalPage * customPageSize,
      hospitalSearchText
    );
    setHospitalLoading(false);
  };
  /** Hospitals Assignment End */

  const onFinish = async (areaFormValues) => {
    setLoading(true);
    areaFormValues.hospitals = selectedHospitalIds;
    if (areaId) {
      await editArea(areaFormValues);
    } else {
      await addArea(areaFormValues);
    }

    const sortedHospitals =
      utilityService.sortSelectedEntity(selectedHospitals);
    setSelectedHospitals(sortedHospitals);

    setLoading(false);
  };

  /** API calls */

  const addArea = async (area) => {
    area.manufacturer = manufacturerId;
    const res = await areaService.postArea(area);
    if (res) {
      toastNotification("success", "New Area added");
      history.push(backBtnPath);
    } else {
      toastNotification("error", "Error adding a new Area record");
    }
  };

  const editArea = async (area) => {
    // TODO :: Trim values before submit
    area.id = areaId;

    const removedHIds = initialHospitalIds.filter(
      (hId) => !area.hospitals.includes(hId)
    );
    if (removedHIds.length)
      areaService.patchUnassignAreaHospitals(areaId, removedHIds);

    const res = await areaService.patchArea(area);
    if (res) {
      setEditMode(false);
      toastNotification("success", "Area record updated");
      setInitialHospitalIds(area.hospitals);
      history.replace({ state: { record: res } });
    } else {
      toastNotification("error", "Error updating the Area record");
    }
  };

  const getArea = async () => {
    const data = await areaService.getArea(areaId);
    if (data) {
      setArea(data);

      if (data?.hospitals) {
        const filteredHospitals = utilityService.filterSelectedEntity(
          data.hospitals
        );
        const sortedHospitals =
          utilityService.sortSelectedEntity(filteredHospitals);
        const hIds = sortedHospitals.map((hospital) => hospital.id);
        setSelectedHospitals(sortedHospitals);
        setSelectedHospitalIds(hIds);
        setInitialHospitalIds(hIds);
      }
      form.setFieldsValue(data);
    } else {
      toastNotification("error", "Requested Area record not found");
    }
  };

  const populateHospitalList = async (limit, skip, searchValue) => {
    setHospitalLoading(true);
    const res = await manufacturerService.getManufacturerHospitals(
      manufacturerId,
      limit,
      skip,
      searchValue,
      hospitalList.map((h) => String(h.id))
    );
    if (res) {
      setLoadHospitals(res.length === customPageSize);
      const arr = [
        ...new Map(
          [...hospitalList, ...res].map((item) => [item["id"], item])
        ).values(),
      ];

      setHospitalList(arr);
      setHospitalCount(arr.length);
      setCurrentHospitalPage(0);
    }
    setHospitalLoading(false);
  };
  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (areaId) {
        await getArea();
      }
      populateHospitalList(customPageSize, 0, null);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="areaAddEditForm"
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row>
          <Col span={14} style={{ marginTop: "12px" }}>
            <Row align="middle">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 22, offset: 1 }}
              >
                <Title level={5}>Details</Title>
                <Form
                  form={form}
                  name="areaAddEditForm"
                  size="large"
                  colon="false"
                  scrollToFirstError="true"
                  onFinish={onFinish}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Area Name (Required)"
                        name="areaName"
                        rules={[
                          {
                            required: true,
                            message: "Please input Area name!",
                          },
                        ]}
                      >
                        <Input disabled={!editMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Description" name="description">
                        <Input.TextArea rows={5} disabled={!editMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={8} style={{ marginTop: "12px" }}>
            <Title level={5}>Assigned Hospitals</Title>
            <AssignmentSelection
              title="Assigned Hospitals"
              editMode={!isAreaManager && editMode}
              selectedEntityIds={selectedHospitalIds}
              dataSource={hospitalList}
              selectedEntities={selectedHospitals}
              optionIcon={HospitalColorIcon}
              showOptionsLoader={hospitalLoading}
              onSelect={onHospitalSelect}
              onDeselect={onHospitalDeselect}
              onSearch={onSearchHospital}
              filterOption={handleFilterOption}
              onDropdownVisibleChange={() => setCurrentHospitalPage(1)}
              onChange={() => setCurrentHospitalPage(0)}
              onPopupScroll={handleMenuScrollToBottom}
              onInputKeyDown={handleInputChange}
              tagIcon={HospitalColorIcon}
              emptyText="No Hospitals added yet"
              emptyIcon={HospitalGreyIcon}
              entityTitleProperty="hospitalName"
              placeholder="Search by Hospital Name"
            />
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default ManufacturerAreaForm;
