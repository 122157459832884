import {
  Row,
  Col,
  Divider,
  Typography,
  Card,
  List,
  Input,
  Spin,
  Empty,
} from "antd";
import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import TrayModal from "../../components/TrayModal.jsx";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as AddIcon } from "../../assets/icons/icon_add.svg";
import { ReactComponent as GreenButtonIcon } from "../../assets/icons/greenButton.svg";
import { ReactComponent as RegionalManagerGreyIcon } from "../../assets/icons/icon_regional-manager.svg";
import { ReactComponent as ManufacturerGreyIcon } from "../../assets/icons/icon_Manufacturer.svg";
import procedureService from "../../services/procedureService";
import utilityService from "../../services/utilityService";
import noTray from "../../assets/Images/trays.png";
import trayPlaceholder from "../../assets/tray-placeholder.svg";

const { Search } = Input;
const { Paragraph, Text } = Typography;
const customPageSize = 30;

const TrayEquipmentPage = ({
  entityId,
  entityName = "distributor",
  procedureId,
  manufacturerId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const tableSetupPath = url.replace("tray-management", "table-setup");
  const isFromHospital = !(
    history.location.pathname.includes("manufacturer") ||
    history.location.pathname.includes("distributor")
  );

  const pageHeaderProps = {
    title: "Trays Management",
    btnText: "Done",
    btnType: "secondary",
    onBtnClick: () => {
      addSelectedTrayEquipments(tableSetUpId);
    },
  };

  const [procedureOwnerId, setProcedureOwnerId] = useState(
    entityId ?? manufacturerId
  );
  const [selectedTrays, setSelectedTrays] = useState([]);
  const [selectedTrayIds, setSelectedTrayIds] = useState([]);
  const [trayEquipmentsList, setTrayEquipmentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableSetUpId, setTableSetUpId] = useState([]);
  const [initialSelectedTray, setInitialSelectedTray] = useState([]);
  const [timeOut, setTimeOut] = useState(0);
  const [trayModal, setTrayModal] = useState(false);
  const [trayId, setTrayId] = useState();
  const [removedTraysId, setRemovedTraysId] = useState([]);
  const closeModal = () => {
    setTrayModal(false);
  };

  const selectTray = (tray) => {
    const showTray = selectedTrays;
    showTray.push(tray);
    setSelectedTrays([...showTray]);

    const handleButton = selectedTrayIds;
    handleButton.push(tray.id); //making an array of selected trays for handling button toggle
    setSelectedTrayIds([...handleButton]);

    //changing to array of object for post api
    const trayIdList = [...tableSetUpId];
    trayIdList.push({ tray: tray.id });
    setTableSetUpId([...trayIdList]);
  };

  const populateTraysEquipmentsList = async (id, searchValue) => {
    let count = customPageSize;
    let temp = [];
    for (let skipPage = 0; count === customPageSize; skipPage++) {
      const data = await procedureService.getTrayEquipmentsList(
        id,
        manufacturerId,
        false,
        customPageSize,
        skipPage * customPageSize,
        searchValue,
        entityName
      );
      if (data) {
        count = data.length;
        temp = temp.concat(data);
      } else {
        count = 0;
        toastNotification("error", "Error fetching the user record");
      }
    }
    setTrayEquipmentsList(temp);
  };

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        await populateTraysEquipmentsList(procedureOwnerId, searchValue);
        setLoading(false);
      }, 500)
    );
  };

  const addSelectedTrayEquipments = async (selectedTrayEquipmentIds) => {
    setLoading(true);

    const tableSetUp = selectedTrayEquipmentIds;
    const removedTrays = removedTraysId;
    const sendTableData = {
      tableSetUp,
      removedTrays,
    };
    const res = await procedureService.postTableSetup(
      procedureId,
      sendTableData
    );
    if (res.status === 200) {
      toastNotification("success", "Tray selected");
      history.push(tableSetupPath, {
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        isCloneProcedure: location.state?.isCloneProcedure,
      });
    } else {
      toastNotification("error", "Error adding a new tray");
    }
    setLoading(false);
  };

  const removeTray = (trayId) => {
    if (removedTraysId.indexOf(trayId) < 0) {
      setRemovedTraysId([...removedTraysId, trayId]);
    }
    setSelectedTrays(selectedTrays.filter((tray) => tray.id !== trayId));
    setSelectedTrayIds(selectedTrayIds.filter((id) => id !== trayId));
    setTableSetUpId(
      tableSetUpId.filter((id) => {
        return id.tray !== trayId;
      })
    );
  };

  const getSelectedTrayEquipments = async () => {
    setLoading(true);
    let count = customPageSize;
    let temp = [];
    for (let skipPage = 0; count === customPageSize; skipPage++) {
      const data = await procedureService.getTableSetup(
        procedureId,
        null,
        customPageSize,
        skipPage * customPageSize
      );
      if (data) {
        count = data.length;
        temp = temp.concat(data);
      } else {
        count = 0;
        toastNotification("error", "Error fetching the user record");
      }
    }
    setInitialSelectedTray(temp);
    setLoading(false);
  };

  const fetchProcedure = async () => {
    const procedureDtl = await procedureService.getProcedure(
      procedureId,
      false,
      true
    );
    if (procedureDtl) {
      const id = procedureDtl[entityName]
        ? procedureDtl[entityName]
        : procedureDtl.clonedFrom?.[entityName];
      setProcedureOwnerId(id);

      return id;
    } else return false;
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      let pOwnerId = procedureOwnerId;
      if (!procedureOwnerId && procedureId) {
        pOwnerId = await fetchProcedure();
      }
      await populateTraysEquipmentsList(pOwnerId);
      await getSelectedTrayEquipments();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const initialState = [];
    const initialId = [];
    const buttonID = [];

    initialSelectedTray.forEach((key) => {
      const distributor = trayEquipmentsList.filter(
        (el) => el.id === key.tray.id
      );
      key.tray.distributor = distributor[0]?.distributor;
      key.tray.regionalManager = distributor[0]?.regionalManager;
      initialState.push(key.tray);
      buttonID.push(key.tray.id);
    });

    initialSelectedTray.forEach((el) => {
      initialId.push({
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
        tray: el.tray.id,
        sectionNumber: el.sectionNumber,
        orientation: el.orientation,
      });
    });
    setSelectedTrays(initialState);
    setTableSetUpId(initialId);
    setSelectedTrayIds(buttonID);
  }, [initialSelectedTray]);

  const actions = (tray) => {
    return [
      {
        title: "Remove Tray",
        onClick: (menuItemProps) => {
          menuItemProps.domEvent.stopPropagation();
          removeTray(tray.id);
        },
      },
    ];
  };

  return (
    <Spin spinning={loading}>
      <div className="tray-done-button">
        <MainContainer
          formName="Tray/EquipmentManagement"
          backBtnPath={tableSetupPath}
          routeState={{
            pageNumber: location.state?.pageNumber,
            activeTabKey: location.state?.activeTabKey,
            isCloneProcedure: location.state?.isCloneProcedure,
          }}
          divider={true}
          {...pageHeaderProps}
        >
          <div className="tray-equipment">
            <Row>
              <Col
                span={6}
                style={{
                  marginLeft: "4%",
                  marginTop: "2%",
                  height: "calc(100vh - 70px)",
                  position: "sticky",
                  overflow: "scroll",
                }}
              >
                <Row style={{ marginBottom: "5%" }}>
                  <Text style={{ fontSize: "17px" }}>
                    Search Trays from library
                  </Text>
                </Row>
                <Row className="search-row" style={{ marginBottom: "2%" }}>
                  <Search
                    placeholder="Search by Tray Name"
                    style={{ width: "97%" }}
                    onSearch={onSearchFunction}
                    onChange={(e) => onSearchFunction(e.target.value)}
                    allowClear
                  />
                </Row>
                <List
                  dataSource={trayEquipmentsList}
                  renderItem={(tray) => {
                    return (
                      <List.Item>
                        <div style={{ width: "100%" }}>
                          <Row
                            key={tray.id}
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                            className="search-row"
                          >
                            <Col className="display-flex-center" span={7}>
                              <SignedURLImage
                                fileName={tray.image}
                                resizeMode="contain"
                                width={"100%"}
                                height={"50px"}
                                key={tray.id}
                                onClick={() => {
                                  setTrayModal(true);
                                  setTrayId(tray.id);
                                }}
                                fallback={trayPlaceholder}
                              />
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: "0 0",
                              }}
                              span={12}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Paragraph
                                  style={{ margin: "0 0.4em" }}
                                  ellipsis={{ rows: 2 }}
                                >
                                  {tray.trayName}
                                </Paragraph>
                                <Paragraph
                                  style={{
                                    margin: "0 0.4em",
                                    color: "grey",
                                    fontSize: "0.8rem",
                                  }}
                                  ellipsis={{ rows: 2 }}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    {tray?.distributor?.distributorName ? (
                                      <ManufacturerGreyIcon />
                                    ) : (
                                      <RegionalManagerGreyIcon />
                                    )}
                                    {tray?.distributor?.distributorName ||
                                      utilityService.getRegionalManagerName(
                                        tray?.regionalManager
                                      )}
                                  </span>
                                </Paragraph>
                              </div>
                            </Col>
                            <Col
                              span={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {selectedTrayIds.includes(tray.id) ? (
                                <button
                                  key={tray.id}
                                  type="secondary"
                                  onClick={() => removeTray(tray.id)}
                                  size={10}
                                  className="plus-button"
                                  style={{ border: "none" }}
                                >
                                  <GreenButtonIcon />
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    selectTray(tray);
                                  }}
                                  className="plus-button"
                                  key={tray.id}
                                  type="secondary"
                                  size={10}
                                >
                                  <AddIcon />
                                </button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </List.Item>
                    );
                  }}
                />
              </Col>
              <Col>
                <Divider
                  type="vertical"
                  style={{
                    height: "calc(100vh - 60px)",
                    margin: "0 15px 0 0",
                  }}
                />
              </Col>
              <Col
                span={16}
                style={{
                  paddingTop: "2%",
                  overflow: "scroll",
                  height: "calc(100vh - 70px)",
                  position: "sticky",
                }}
              >
                <Row style={{ marginBottom: "2%" }}>
                  <Text style={{ fontSize: "17px" }}> Case Trays </Text>
                </Row>
                {selectedTrays.length !== 0 ? (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 3,
                      xl: 3,
                      xxl: 4,
                    }}
                    dataSource={selectedTrays}
                    pagination={{
                      pageSize: 12,
                      hideOnSinglePage: true,
                    }}
                    renderItem={(tray) => {
                      return (
                        <List.Item>
                          <Card
                            bordered={false}
                            cover={
                              <div
                                style={{
                                  width: "100%",
                                  height: "176px",
                                  backgroundColor: "#F4F4F4",
                                  border: "1px",
                                  borderColor: "color",
                                }}
                              >
                                <SignedURLImage
                                  key={tray.image}
                                  fileName={tray.image}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  onClick={() => {
                                    setTrayModal(true);
                                    setTrayId(tray.id);
                                  }}
                                  fallback={trayPlaceholder}
                                />

                                <div
                                  className="overlay-button"
                                  style={{ padding: "0.15em" }}
                                >
                                  <ActionMenu actions={actions(tray)} />
                                </div>
                              </div>
                            }
                            preview="false"
                            bodyStyle={{
                              padding: "0",
                            }}
                            size="small"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Paragraph
                                style={{ margin: "0 0.4em" }}
                                ellipsis={{ rows: 2 }}
                              >
                                {tray.trayName}
                              </Paragraph>
                              <Paragraph
                                style={{
                                  margin: "0 0.4em",
                                  color: "grey",
                                  fontSize: "0.8rem",
                                }}
                                ellipsis={{ rows: 2 }}
                              >
                                {tray?.distributor?.distributorName ||
                                  utilityService.getRegionalManagerName(
                                    tray?.regionalManager
                                  )}
                              </Paragraph>
                            </div>
                          </Card>
                        </List.Item>
                      );
                    }}
                  />
                ) : (
                  <Empty
                    image={noTray}
                    imageStyle={{
                      alignItems: "centre",
                      marginTop: "25%",
                      marginLeft: "36.5%",
                      width: "200px",
                      height: "170px",
                    }}
                    description={
                      <span style={{ fontWeight: "bold", paddingTop: "70px" }}>
                        <Text
                          style={{
                            fontSize: "large",
                            font: "70px",
                          }}
                        >
                          Start adding trays from left.
                        </Text>
                      </span>
                    }
                  ></Empty>
                )}
              </Col>
            </Row>
          </div>
        </MainContainer>
      </div>
      {trayModal ? (
        <TrayModal
          show={trayModal}
          close={closeModal}
          trayId={trayId}
          viewFrom={isFromHospital ? "hospital" : ""}
        />
      ) : null}
    </Spin>
  );
};
export default TrayEquipmentPage;
