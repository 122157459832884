import { PageHeader, Button, Dropdown, Input, Menu, Divider } from "antd";
import { Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { ReactComponent as MenuIcon } from "../assets/icons/iconMenu.svg";
import { ReactComponent as BackIcon } from "../assets/icons/iconHeaderBack.svg";
import { ReactComponent as PresentationViewOn } from "../assets/icons/switchOn.svg";
import { ReactComponent as PresentationViewOff } from "../assets/icons/switchOff.svg";
const { Search } = Input;

const MainContainer = (props) => {
  const {
    children,
    title,
    subTitle,
    backBtnPath,
    topActionMenuItems,
    searchPlaceholderTxt,
    onSearchFunction,
    dropDown,
    /** Main Button */
    btnText,
    onBtnClick,
    btnType,
    /** Main Button */
    formName, //in case you want to submit a form on main btn click
    divider,
    tags,
    miniAppButton,
    btnClear,
    onClearBtnClick,
    routeState,
    livecase,
    previewModeDetails
  } = props;

  const history = useHistory(useHistory);
  const {
    allowPreviewMode,
    isPreviewMode,
    setIsPreviewMode
  } = previewModeDetails ?? {};

  /** Useful When Data View */

  const getTopActionMenu = () => (
    <Menu>
      {topActionMenuItems.map((action, index) => {
        return (
          <Menu.Item key={index}>
            <Link
              to={{
                pathname: action.path,
              }}
              onClick={(e) => {
                if (action.onClick) {
                  e.preventDefault();
                  action.onClick();
                }
              }}
            >
              {action.title}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const extraTablePageHeaderContent = [
    dropDown ? dropDown : null,
    allowPreviewMode ? (
      <div style={{ display: "flex", flexDirection: "row", marginRight: "0.6rem", alignItems: "center" }}>
        <span style={{ marginRight: "0.5rem" }}>Presentation View</span>
        {isPreviewMode
          ? <PresentationViewOn onClick={() => setIsPreviewMode(false)} />
          : <PresentationViewOff onClick={() => setIsPreviewMode(true)} />}
      </div>) : null,
    searchPlaceholderTxt ? (
      <Search
        placeholder={searchPlaceholderTxt}
        onSearch={onSearchFunction}
        onChange={(e) => onSearchFunction(e.target.value)}
        allowClear
        key="searchInput"
      />
    ) : null,
    btnText ? (
      <Button
        type={btnType ? btnType : "primary"}
        htmlType="button"
        onClick={onBtnClick}
        key="actionBtn"
        form={formName ? formName : null}
      >
        {btnText}
      </Button>
    ) : null,
    btnClear ? (
      <Button
        type="secondary"
        htmlType="button"
        onClick={onClearBtnClick}
        key="clearActionBtn"
        className="delete-btn-none"
        style={{
          marginRight: "0",
        }}
        form={formName ? formName : null}
      >
        {btnClear}
      </Button>
    ) : null,
    miniAppButton ? (
      <Button
        type={btnType === "primary" ? "primary" : "secondary"}
        htmlType="button"
        onClick={onBtnClick}
        key="actionBtn"
        form={formName ? formName : null}
      >
        {miniAppButton}
      </Button>
    ) : null,
    topActionMenuItems ? (
      <Dropdown
        overlay={getTopActionMenu()}
        key="topActionMenu"
        placement="bottomRight"
      >
        <Button ghost>
          <MenuIcon />
        </Button>
      </Dropdown>
    ) : null,
    livecase ? livecase : null,
  ];

  /** Useful When Data View */
  return (
    <PageHeader
      subTitle={subTitle}
      title={title}
      backIcon={<BackIcon height="30px" />}
      onBack={
        backBtnPath
          ? () => {
            history.push(backBtnPath, routeState);
          }
          : false
      }
      extra={extraTablePageHeaderContent}
      tags={tags}
      livecase={livecase}
    >
      {divider && <Divider style={{ margin: "0" }} />}
      {children}
    </PageHeader>
  );
};

MainContainer.propTypes = {
  backBtnPath: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default MainContainer;
