import { useState, useEffect } from "react";
import { List, Card, Modal, Typography, Tabs, Spin } from "antd";
import MainContainer from "../../containers/MainContainer";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import DropdownFilter from "../../components/DropdownFilter";
import toastNotification from "../../components/toastNotification";
import trayService from "../../services/trayService";
import distributorService from "../../services/distributorService";
import utilityService from "../../services/utilityService";
import trayPlaceholder from "../../assets/Images/placeholder-image_trays.svg";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const customPageSize = 12;

const DistributorTraysPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const isDeletedPage = url.includes("deleted");
  const distributorId = params?.id;

  const [activeTabKey, setActiveTabKey] = useState(location.state?.activeTabKey ?? "2");
  const [trayList, setTrayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [trayCount, setTrayCount] = useState(0);
  const [traySearchText, setTraySearchText] = useState();
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturerIds, setSelectedManufacturerIds] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(0);

  const actions = (tray) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(`${url}/${tray.id}`, {
              record: tray,
              pageNumber: currentPage,
              activeTabKey: activeTabKey
            });
          },
        },
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(tray.id);
          },
        },
      ];
  };

  const showDeleteConfirmation = (trayId) => {
    confirm({
      title: "Delete Tray",
      content: "Are you sure you want to delete this tray?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteTray(trayId);
      },
    });
  };

  /** Manufacturer Filter */

  const onManufacturerSearch = (searchValue) => {
    setFilterManufacturerData(
      manufacturers.filter((x) =>
        x.distributorName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  const onManufacturerSelectionChange = async (selectedIds) => {
    setSelectedManufacturerIds(selectedIds);
    setLoading(true);
    if (filterTimeOut) {
      clearTimeout(filterTimeOut);
    }
    setFilterTimeOut(
      setTimeout(async () => {
        await getSetTrayCountAndPopulate(
          activeTabKey,
          traySearchText,
          selectedIds
        );
        setLoading(false);
      }, 800)
    );
  };

  const setFilterManufacturerData = (data) => {
    setFilteredManufacturers(
      data?.map((m) => ({
        id: m.id,
        checkboxTitle: m.distributorName,
      }))
    );
  };

  const manufacturerDropdown = (
    <DropdownFilter
      filterTitle="Manufacturer"
      dropdownVisibility={dropdownVisibility}
      onDropdownVisibilityChange={(flag) => setDropdownVisibility(flag)}
      filteredData={filteredManufacturers}
      onSearch={onManufacturerSearch}
      selectedIds={selectedManufacturerIds}
      onSelectionChange={onManufacturerSelectionChange}
    />
  );

  /** Manufacturer Filter End */

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1)
        searchValue = searchValue.trim();
        setTraySearchText(searchValue);
        await getSetTrayCountAndPopulate(
          activeTabKey,
          searchValue,
          selectedManufacturerIds,
          1
        );
        setLoading(false);
      }, 500)
    );
  };

  /** API */
  const getSetTrayCountAndPopulate = async (
    tabKey,
    searchText,
    manufacturerIds,
    currentPageNumber = currentPage
  ) => {
    let ids =
      tabKey === "1"
        ? manufacturerIds.length === 0
          ? manufacturers.map((m) => m.id)
          : manufacturerIds
        : distributorId;
    ids = isDeletedPage ? distributorId : ids;

    if (typeof ids === "object" && ids.length === 0) {
      setTrayList([]);
    } else {
      const success = await trayService.getTrayCount(
        ids,
        isDeletedPage,
        searchText
      );

      if (success !== false) {
        setTrayCount(success);
        if (success > 0)
          await populateTraysList(ids, customPageSize, customPageSize * (currentPageNumber - 1), searchText);
        else setTrayList([]);
      } else {
        setTrayList([]);
        toastNotification("error", "Error fetching Tray records");
      }
    }
  };

  const populateTraysList = async (
    distributorIds,
    limit,
    skip,
    searchValue
  ) => {
    const data = await trayService.getDistributorTrays(
      distributorIds,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setTrayList(data);
    } else {
      toastNotification("error", "Error fetching the tray records");
    }
  };

  const deleteTray = async (trayId) => {
    setLoading(true);
    const isDeleted = await trayService.patchTray({
      id: trayId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Tray deleted successfully");
      setCurrentPage(1);
      setTraySearchText();
      await getSetTrayCountAndPopulate(
        activeTabKey,
        null,
        selectedManufacturerIds
      );
    } else {
      toastNotification("error", "Error deleting the Tray");
    }
    setLoading(false);
  };

  const getDistributor = async () => {
    const distributor = await distributorService.getDistributor(
      distributorId,
      true
    );
    if (distributor?.manufacturers) {
      const filteredManufacturers = utilityService.filterSelectedEntity(distributor.manufacturers);
      setManufacturers(filteredManufacturers);
      setFilterManufacturerData(filteredManufacturers);
      return distributor;
    } else {
      toastNotification("error", "Requested Distributor record not found");
    }
  };

  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const distributorTemp = await getDistributor();
      const filteredManufacturers = utilityService.filterSelectedEntity(distributorTemp.manufacturers);
      await getSetTrayCountAndPopulate(
        activeTabKey,
        null,
        filteredManufacturers?.map((m) => m.id)
      );
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Tray Name"
      onSearchFunction={onSearchFunction}
      {...(isDeletedPage
        ? {
          title: "Deleted Trays",
          backBtnPath: `/distributor/${distributorId}/tray`,
        }
        : {
          title: "Trays",
          ...(activeTabKey === "1"
            ? { dropDown: manufacturerDropdown }
            : {
              btnText: "Add Tray",
              onBtnClick: () => {
                history.push(`${url}/new`);
              },
              topActionMenuItems: [
                {
                  title: "Show Deleted Trays",
                  path: `${url}/deleted`,
                },
              ],
            }),
        })}
    >
      <Spin spinning={loading}>
        {!isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setActiveTabKey(key);
              setCurrentPage(1);
              await getSetTrayCountAndPopulate(
                key,
                traySearchText,
                selectedManufacturerIds,
                1
              );
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Trays" key="1" />
            <TabPane tab="My Trays" key="2" />
          </Tabs>
        )}
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 3,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 6,
          }}
          dataSource={trayList}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            responsive: true,
            pageSize: customPageSize,
            total: trayCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              const ids =
                activeTabKey === "1"
                  ? selectedManufacturerIds.length === 0
                    ? manufacturers.map((m) => m.id)
                    : selectedManufacturerIds
                  : distributorId;
              await populateTraysList(
                isDeletedPage ? distributorId : ids,
                pageSize,
                pageSize * (page - 1),
                traySearchText
              );
              setLoading(false);
            },
          }}
          renderItem={(tray) => {
            return (
              <List.Item>
                <Card
                  onClick={
                    isDeletedPage ? null : () => {
                    history.push(`${url}/${tray.id}`, {
                      record: tray,
                      isPageReadOnly: true,
                      pageNumber: currentPage,
                      activeTabKey: activeTabKey
                    });
                  }}
                  bordered={false}
                  cover={
                    <div
                      className="icon-overlay-container"
                      style={{ width: "100%", height: "176px" }}
                    >
                      <SignedURLImage
                        fileName={tray.image}
                        height="100%"
                        width="100%"
                        key={tray.image}
                        fallback={trayPlaceholder}
                      />
                      {!tray?.distributor.isManufacturer && (
                        <div className="icon-overlay">
                          <ActionMenu actions={actions(tray)} />
                        </div>
                      )}
                    </div>
                  }
                  preview="false"
                  bodyStyle={{ padding: "0" }}
                />
                <Paragraph
                  style={{ marginTop: "0.5em", marginBottom: "1em" }}
                  ellipsis={{ rows: 2 }}
                >
                  {tray.trayName}
                </Paragraph>
              </List.Item>
            );
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default DistributorTraysPage;
