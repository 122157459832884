import { Route, Switch } from "react-router-dom";
import SideBar from "../components/SideBar";
import distributorMenuItems from "../config/distributor-menu-items";
import hospitalMenuItems from "../config/hospital-menu-items";
import mainMenuItems from "../config/admin-menu-items";
import manufacturerMenuItems from "../config/manufacturer-menu-items";
import areaMenuItems from "../config/area-menu-items";
import regionalManagerMenuItems from "../config/regional-manager-menu-items";
import medicalRepresentativeMenuItems from "../config/medical-representative-menu-items";
import hospitalReadonlyMenuItems from "../config/hospital-readonly-menu-items";
import { useAuthContext } from "../context/AuthContext";

const SideBarContainer = () => {
  const authContext = useAuthContext();
  const renderMenuItems = (menuItems, menuActiveKey) => (
    <SideBar menuItems={menuItems} matchMenuKey={menuActiveKey} />
  );

  return (
    <>
      <Switch>
        <Route
          path={[
            "/hospital/new",
            "/hospital/deleted",
            "/distributor/new",
            "/distributor/deleted",
            "/manufacturer/new",
            "/manufacturer/deleted",
            "/admin/deleted",
            "/admin/new",
            "/admin/:id",
          ]}
          exact
          render={({ location: { pathname } }) => {
            return renderMenuItems(
              mainMenuItems,
              pathname.split("/").splice(0, 2).join("/")
            );
          }}
        />
        <Route
          path={[
            "/distributor/:id/hospital/:hospitalid/case/*",
            "/distributor/:id/hospital/:hospitalid/case",
            "/manufacturer/:id/*/hospital/:hospitalid/case/*",
            "/manufacturer/:id/*/hospital/:hospitalid/case",
            "/manufacturer/:id/hospital/:hospitalid/case/*",
            "/manufacturer/:id/hospital/:hospitalid/case",
          ]}
          render={({
            location: { pathname },
            match: {
              params: { hospitalid: hospitalId },
            },
          }) => {
            const urlArray = pathname.split("/");
            const parentPath = pathname.substring(
              0,
              pathname.indexOf("hospital") + 8
            );
            const index = urlArray.indexOf(hospitalId) + 2;
            return renderMenuItems(
              hospitalReadonlyMenuItems(parentPath, hospitalId),
              urlArray.splice(0, index).join("/")
            );
          }}
        />
        <Route
          path={[
            "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/new",
            "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/deleted",
          ]}
          exact
          render={({ match: { params }, location: { pathname } }) => {
            const { id: manufacturerId, areaid: areaId, rmid: rmId } = params;

            return renderMenuItems(
              regionalManagerMenuItems(manufacturerId, areaId, rmId),
              pathname.split("/").splice(0, 6).join("/")
            );
          }}
        />
        <Route
          path="/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid"
          render={({ match: { params }, location: { pathname } }) => {
            const {
              id: manufacturerId,
              areaid: areaId,
              rmid: regionalManagerId,
              mmrid: medicalRepId,
            } = params;

            return renderMenuItems(
              medicalRepresentativeMenuItems(
                manufacturerId,
                areaId,
                regionalManagerId,
                medicalRepId
              ),
              pathname.split("/").splice(0, 10).join("/")
            );
          }}
        />
        <Route
          path={[
            "/manufacturer/:id/area/:areaid/regional-manager/new",
            "/manufacturer/:id/area/:areaid/regional-manager/deleted",
          ]}
          exact
          render={({ match: { params }, location: { pathname } }) => {
            const { id: manufacturerId, areaid: areaId } = params;

            return renderMenuItems(
              areaMenuItems(manufacturerId, areaId),
              pathname.split("/").splice(0, 6).join("/")
            );
          }}
        />
        <Route
          path="/manufacturer/:id/area/:areaid/regional-manager/:rmid"
          render={({ match: { params }, location: { pathname } }) => {
            const {
              id: manufacturerId,
              areaid: areaId,
              rmid: regionalManagerId,
            } = params;
            return renderMenuItems(
              regionalManagerMenuItems(
                manufacturerId,
                areaId,
                regionalManagerId
              ),
              pathname.split("/").splice(0, 8).join("/")
            );
          }}
        />
        <Route
          path={[
            "/manufacturer/:id/area/new",
            "/manufacturer/:id/area/deleted",
          ]}
          exact
          render={({ match: { params }, location: { pathname } }) => {
            const { id } = params;

            return renderMenuItems(
              manufacturerMenuItems(id),
              pathname.split("/").splice(0, 4).join("/")
            );
          }}
        />
        <Route
          path="/manufacturer/:id/area/:areaid"
          render={({ match: { params }, location: { pathname } }) => {
            const { id: manufacturerId, areaid: areaId } = params;
            return renderMenuItems(
              areaMenuItems(manufacturerId, areaId),
              pathname.split("/").splice(0, 6).join("/")
            );
          }}
        />
        <Route
          path="/manufacturer/:id"
          render={({ match: { params }, location: { pathname } }) => {
            const { id } = params;
            return renderMenuItems(
              manufacturerMenuItems(id),
              pathname.split("/").splice(0, 4).join("/")
            );
          }}
        />
        <Route
          path="/distributor/:id"
          render={({ match: { params }, location: { pathname } }) => {
            const { id } = params;
            return renderMenuItems(
              distributorMenuItems(id),
              pathname.split("/").splice(0, 4).join("/")
            );
          }}
        />
        <Route
          path="/hospital/:id"
          render={({ match: { params }, location: { pathname } }) => {
            const { id } = params;
            return renderMenuItems(
              hospitalMenuItems(id, authContext?.user?.distributor),
              pathname.split("/").splice(0, 4).join("/")
            );
          }}
        />
        <Route
          path="*"
          render={({ location: { pathname } }) => {
            return renderMenuItems(mainMenuItems, pathname);
          }}
        />
      </Switch>
    </>
  );
};

export default SideBarContainer;
