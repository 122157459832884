import { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { List } from "antd";
import MainContainer from "../../../containers/MainContainer";
import CardList from "../../../components/CardList";
import CustomCard from "../../../components/CustomCard";
import { ReactComponent as AddressIcon } from "../../../assets/icons/iconLocation.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/iconPhone.svg";
import { ReactComponent as HospitalColorIcon } from "../../../assets/icons/colorIconHospital.svg";

const customPageSize = 12;

const EntityHospitalsPage = ({ hospitalList }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [filteredHospitalList, setFilteredHospitalList] = useState();
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);

  const cardContent = (hospital) => [
    {
      icon: <AddressIcon />,
      content: hospital.address
        ? (() => {
            const address = [];
            if (hospital.city) address.push(hospital.city);
            if (hospital.state) address.push(hospital.state);
            if (hospital.country) address.push(hospital.country);

            return address.length ? address.join(", ") : null;
          })()
        : null,
    },
    {
      icon: <PhoneIcon fill="#808285" />,
      content: hospital.contactNumber1
        ? hospital.contactNumber1
        : hospital.contactNumber2
        ? hospital.contactNumber2
        : null,
    },
  ];

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        const temp = hospitalList.filter((h) =>
          h.hospitalName.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredHospitalList(temp);
        setLoading(false);
      }, 500)
    );
  };

  useEffect(() => {
    setFilteredHospitalList(hospitalList);
  }, [hospitalList]);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Hospital Name"
      onSearchFunction={onSearchFunction}
      title="Hospitals"
    >
      <CardList
        dataSource={filteredHospitalList}
        loading={loading}
        pagination={{
          pageSize: customPageSize,
          // total: hospitalCount,
          // current: currentHospitalPage,
          // onChange: async (page, pageSize) => {
          //   setLoading(true);
          //   setCurrentHospitalPage(page);
          //   await populateHospitalsList(
          //     pageSize,
          //     pageSize * (page - 1),
          //     hospitalSearchText
          //   );
          //   setLoading(false);
          // },
        }}
        renderItem={(hospital) => (
          <List.Item>
            <CustomCard
              headerIcon={<HospitalColorIcon />}
              title={hospital.hospitalName}
              onClick={() => {
                history.push(`${url}/${hospital.id}/case`);
              }}
              bodyContent={cardContent(hospital)}
            />
          </List.Item>
        )}
      />
    </MainContainer>
  );
};
export default EntityHospitalsPage;
