import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../../containers/MainContainer";
import CardList from "../../components/CardList";
import CustomCard from "../../components/CustomCard";
import distributorService from "../../services/distributorService";
import procedureService from "../../services/procedureService";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as ManufacturerIcon } from "../../assets/icons/icon_Manufacturer.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/icons/color-icons_procedure.svg";

const { confirm } = Modal;
const customPageSize = 12;

const ManufacturerProceduresPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const manufacturerId = params?.id;
  const isDeletedPage = url.includes("deleted");

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Deleted Procedure",
      backBtnPath: `/manufacturer/${manufacturerId}/procedure`,
    }
    : {
      title: "Procedures",
      btnText: "Create Procedure",
      onBtnClick: () => {
        history.push(`${url}/new`);
      },
      topActionMenuItems: [
        {
          title: "Show Deleted Procedures",
          path: `/manufacturer/${manufacturerId}/manufacturer-procedure/deleted`,
        },
      ],
    };

  const [procedureCount, setProcedureCount] = useState(0);
  const [procedureSearchText, setProcedureSearchText] = useState();
  const [procedureList, setProceduresList] = useState([]);
  const [currentProcedurePage, setCurrentProcedurePage] = useState(location.state?.pageNumber ?? 1);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);

  const cardContent = (procedure) => [
    {
      icon: <ManufacturerIcon />,
      content: procedure.distributor
        ? `By ${procedure?.distributor?.distributorName}`
        : null,
    },
    {
      icon: <CalenderIcon fill="#808285" />,
      content: procedure.createdAt
        ? "On " + new Date(procedure.createdAt).toISOString().slice(0, 10)
        : null,
    },
  ];
  const getActionMenu = (procedure) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: null,
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(
              `/manufacturer/${procedure?.distributor?.id}/procedure/${procedure.id}/table-setup`,
              {
                record: procedure,
                tag: "Table Setup",
                pageNumber: currentProcedurePage
              }
            );
          },
        },
        {
          title: "Clone",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            cloneProcedure(procedure.id);
          },
        },
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(procedure.id);
          },
        },
      ];
  };

  const showDeleteConfirmation = (procedureId) => {
    confirm({
      title: "Delete Procedure",
      content: "Are you sure you want to delete this Procedure?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        deleteProcedure(procedureId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    setCurrentProcedurePage(1)
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setProcedureSearchText(searchValue);
        await getSetProcedureCountAndPopulate(searchValue, 1);
        setLoading(false);
      }, 500)
    );
  };

  /** API calls */
  const populateProcedureList = async (limit, skip, searchValue) => {
    const data = await distributorService.getProcedures(
      manufacturerId,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setProceduresList(data);
    } else {
      toastNotification("error", "Error fetching the procedure records");
    }
  };

  const deleteProcedure = async (id) => {
    setLoading(true);
    const isDeleted = await distributorService.deleteProcedure(id);
    if (isDeleted) {
      toastNotification("success", "Procedures Deleted Successfully");
      await getSetProcedureCountAndPopulate();
    } else {
      toastNotification("error", "Error deleting the Procedure");
    }
    setLoading(false);
  };

  const getSetProcedureCountAndPopulate = async (searchValue, currentPageNumber = currentProcedurePage) => {
    const success = await distributorService.getProcedureCount(
      manufacturerId,
      "global",
      isDeletedPage,
      searchValue
    );

    if (success) {
      setProcedureCount(success.count);
      if (success.count > 0) {
        await populateProcedureList(customPageSize, customPageSize * (currentPageNumber - 1), searchValue);
      } else setProceduresList([]);
    } else {
      toastNotification("error", "Error fetching procedures");
    }
  };

  const cloneProcedure = async (procedureId) => {
    setLoading(true);
    const newProcedure = await procedureService.postCloneProcedure(
      procedureId,
      manufacturerId
    );
    if (newProcedure) {
      toastNotification("success", "Procedure cloned successfully");
      history.push(`/manufacturer/${manufacturerId}/procedure/${newProcedure.id}`, {
        isCloneProcedure: true
      });
    } else {
      toastNotification("error", "Error cloning the Procedure");
    }
    setLoading(false);
  };

  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetProcedureCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Procedures Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={procedureList}
        loading={loading}
        renderItem={(procedure) => {
          return (
            <List.Item>
              <CustomCard
                headerIcon={<ProcedureIcon />}
                title={procedure.procedureName}
                actions={getActionMenu(procedure)}
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                      history.push(
                        `/manufacturer/${procedure?.distributor?.id}/procedure/${procedure.id}/table-setup`,
                        {
                          record: procedure,
                          pageNumber: currentProcedurePage
                        }
                      );
                    }
                }
                bodyContent={cardContent(procedure)}
              />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: customPageSize,
          total: procedureCount,
          current: currentProcedurePage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentProcedurePage(page);
            await populateProcedureList(
              pageSize,
              pageSize * (page - 1),
              procedureSearchText
            );
            setLoading(false);
          },
        }}
      />
    </MainContainer>
  );
};
export default ManufacturerProceduresPage;
