import { useState, useEffect } from "react";
import distributorService from "../../services/distributorService";
import trayService from "../../services/trayService";
import { useTableSetupContext } from "../../context/TableSetupContext";
import { Row, Col, Layout, Spin } from "antd";
import Grid from "../../components/Grid";
import TableSetupAllEquipments from "../../containers/TableSetup/TableSetupAllInstruments";

const { Content } = Layout;

const CaseTableSetupPage = (props) => {
  const { procedureId, tableMode, isProcedureEditable, isCompletedCasesPage } = props;

  const { tableSetup, setTableSetup } = useTableSetupContext();

  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [modifiedTrayDetails, setModifiedTrayDetails] = useState();

  // Get instruments details of all instruments side drawer
  const [allEquipmentDetails, setAllEquipmentDetails] = useState();

  const onChangeVerificationStatus = (verificationstatus, id) => {
    setTableData(
      tableData.map((el) => {
        if (el.tray.id === id) el.verificationStatus = verificationstatus;
        return el;
      })
    );
    setTableSetup(tableData, () => { });
  };

  // Get table details
  const getTableDetails = async () => {
    setLoading(true);
    let data = tableSetup;
    if (tableSetup.length === 0) {
      data = await distributorService.getTableSetup(procedureId);
    }
    let trayDetails = {};
    for (const table of data) {
      table.tray.sectionNumber = table.sectionNumber
      trayDetails[table.tray?.id] = table.tray
    }
    if (data.length === 0) {
      setLoading(false);
    }
    setModifiedTrayDetails(trayDetails);
    setTableData(data);
  };

  //API Calling
  useEffect(() => {
    (async () => {
      try {
        await getTableDetails();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const trays = [];
    modifiedTrayDetails && Object.keys(modifiedTrayDetails).forEach(async (key, index) => {
      const trayEquipmentList = await trayService.getTrayEquipments(key);
      trayEquipmentList.forEach((el) => el.sectionNumber = modifiedTrayDetails[key].sectionNumber)
      trays.push({
        tray: modifiedTrayDetails[key],
        equipment: trayEquipmentList
      });
      if (index === Object.keys(modifiedTrayDetails).length - 1) {
        setLoading(false);
      }
    })
    setAllEquipmentDetails(trays);
  }, [modifiedTrayDetails]);

  return (
    <Spin spinning={loading}>
      <div
        className="table-setup-main-container"
        style={{ overflow: "auto", height: "calc(100vh - 70px)" }}
      >
        <TableSetupAllEquipments allEquipmentDetails={allEquipmentDetails} />
        <Row>
          <Col span={24}>
            <Content>
              <Row style={{ justifyContent: "center" }}>
                <Col
                  span={tableMode === "3WIDE" ? 18 : 14}
                  pull={1}
                  style={{ marginBottom: "30px" }}
                >
                  <Grid
                    startCase="true"
                    tableData={tableData}
                    tableMode={tableMode}
                    onChangeVerificationStatus={onChangeVerificationStatus}
                    isProcedureEditable={isProcedureEditable}
                    isCompletedCasesPage={isCompletedCasesPage}
                  />
                </Col>
              </Row>
            </Content>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default CaseTableSetupPage;
