import http from "./httpService";

async function getUser(id, params) {
  try {
    const res = await http.get(`/user/${id}`, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function postUser(user) {
  try {
    return await http.post("/user", user);
  } catch (exception) {
    console.log(exception);
    return exception.response.data;
  }
}

async function putUser(user) {
  try {
    return await http.patch(`/user/${user.id}`, user);
  } catch (exception) {
    console.log(exception);
    return exception.response.data;
  }
}

const patchRestoreUser = async (userId) => {
  try {
    const res = await http.patch(`/user/restore/${userId}`);
    return res?.status === 200;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const getUsersCount = async (
  userType,
  id,
  isDeleted,
  searchText,
  isManufacturer
) => {
  try {
    const params = {
      userType,
      isDeleted,
      searchText,
      id,
      isManufacturer,
    };
    const res = await http.get("/user/count", {
      params,
    });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function postResendUserActivationLink(email) {
  try {
    return await http.post(`/user/resendActivationLink`, { email });
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function deleteUser(id) {
  try {
    await http.patch(`/user/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getDistributorUserHospitals(userId, distributorId, isDeleted) {
  try {
    const res = await http.get("/user", {
      params: {
        where: { id: userId, distributor: distributorId, isDeleted },
        populate: "hospital",
      },
    });
    return res.data[0].hospital;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getAdminUsers(userRoles, isDeleted, limit, skip, searchValue) {
  try {
    let api;
    let params = {
      where: {
        role: { in: userRoles },
        isDeleted,
      },
      limit,
      skip,
    };

    if (searchValue) {
      api = "/user/search";
      params.where.or = [
        { firstName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { email: { contains: searchValue } },
      ];
    } else {
      api = "/user";
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

async function getMMRUsers(
  regionalManagerId,
  isDeleted,
  role,
  limit,
  skip,
  searchValue
) {
  let api;
  let params = {
    limit,
    skip,
    sort: "createdAt DESC",
  };

  if (searchValue) {
    api = "/user/search";
    params.where = {
      or: [
        { firstName: { contains: searchValue } },
        { email: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
      ],
      regionalManager: regionalManagerId,
      isDeleted,
      role,
    };
  } else {
    api = "/user";
    params = {
      area: regionalManagerId,
      role,
      isDeleted,
      ...params,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

export default {
  getUser,
  postUser,
  putUser,
  patchRestoreUser,
  getUsersCount,
  deleteUser,
  postResendUserActivationLink,
  getDistributorUserHospitals,
  getAdminUsers,
  getMMRUsers,
};
