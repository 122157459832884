import { useEffect, useState } from "react";
import { Modal, Spin, List, Tabs, Collapse, Button, Select } from "antd";
import Moment from "react-moment";
import MainContainer from "../../containers/MainContainer";
import CardList from "../../components/CardList";
import CustomCard from "../../components/CustomCard";
import hospitalService from "../../services/hospitalService";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as CaseDescriptionIcon } from "../../assets/icons/hospital-case/caseDes.svg";
import { ReactComponent as CalenderIcon } from "../../assets/icons/hospital-case/calendar.svg";
import { ReactComponent as SurgeonColorIcon } from "../../assets/icons/colorIconSurgeons.svg";
import { ReactComponent as CollapseArrowIcon } from "../../assets/icons/icon_arrow.svg";
import { ReactComponent as ExpandArrowIcon } from "../../assets/icons/icon_expandArrow.svg";
import { useAuthContext } from "../../context/AuthContext";
import { Roles } from "../../config/role-config.json";
import { CaseTypes } from "../../config/case-types.json";
import "../../styles/cases-styles.less";
import "antd/dist/antd.css";

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const customPageSize = 12;

const dateFormat = "YYYY-MM-DD";

const HospitalCasesPage = (props) => {
  const {
    match: { url, params },
    history,
    location,
  } = props;

  const authContext = useAuthContext();

  const isDeletedPage = url.includes("deleted");
  const isCompletedCasesPage = url.includes("completed");
  const isMyCasesPage = url.includes("my-cases");

  const surgeonId =
    authContext.user.role === Roles.SURGEON ? authContext.user.id : null;
  const hospitalStaffId =
    authContext.user.role === Roles.HOSPITAL_STAFF ? authContext.user.id : null;

  const hasReadOnlyRights =
    authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
    authContext.user.role === Roles.MR;

  const hospitalId = params.id ? params.id : null;

  let variableURL = url;

  const pageHeaderProps = () => {
    if (isDeletedPage) {
      return {
        title: "Deleted Cases",
        backBtnPath: isMyCasesPage
          ? `/hospital/${hospitalId}/my-cases`
          : `/hospital/${hospitalId}/cases`,
      };
    } else if (isCompletedCasesPage) {
      return {
        title: "Completed Cases",
        backBtnPath: isMyCasesPage
          ? `/hospital/${hospitalId}/my-cases`
          : `/hospital/${hospitalId}/cases`,
      };
    } else {
      return {
        title: "Cases",
        btnText: hasReadOnlyRights ? null : "Create Case",
        onBtnClick: () => {
          history.push(`${url}/new`, {
            readOnly: false,
          });
        },
        topActionMenuItems: [
          {
            title: "Show Completed Cases",
            path: `${url}/completed`,
          },
          {
            title: "Show Deleted Cases",
            path: `${url}/deleted`,
          },
        ],
      };
    }
  };

  const [caseList, setCaseList] = useState([]);
  const [caseType, setCaseType] = useState(
    (() => {
      if (isCompletedCasesPage) return CaseTypes.COMPLETED;
      if (isDeletedPage) return null;
      return location.state?.tabKey ?? CaseTypes.UPCOMING;
    })()
  );
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [sortBySurgeon, setSortBySurgeon] = useState(false);
  const [surgeonList, setSurgeonList] = useState([]);
  const [casesCount, setCasesCount] = useState(0);
  const [casesSearchText, setCasesSearchText] = useState();
  const [caseListBySurgeon, setCaseListBySurgeon] = useState();
  const [activeKey, setActiveKey] = useState();
  const [sortBySurgeonPageData, setSortBySurgeonPageData] = useState();
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);

  /** Render Components */
  const actions = (caseData) => {
    const items = [];

    if (caseData.isStarted === CaseTypes.LIVE && !isDeletedPage) {
      items.splice(1, 0, {
        title: "Join Case",
        onClick: (e) => {
          e.domEvent.stopPropagation();
          redirectToLiveCase(caseData);
        },
      });
    } else if (caseData.isStarted === CaseTypes.COMPLETED && !isDeletedPage) {
      items.splice(1, 0, {
        title: "View Case",
        onClick: (e) => {
          e.domEvent.stopPropagation();
          redirectToLiveCase(caseData);
        },
      });
    } else {
      if (!hasReadOnlyRights) {
        if (isDeletedPage) {
          items.push({
            title: "Restore",
            onClick: () => {
              setLoading(true);
              onCaseRestore(caseData.id);
            },
          });
        } else if (caseData.isStarted === CaseTypes.UPCOMING) {
          items.push({
            title: "Edit",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              history.push(`${variableURL}/${caseData.id}`, {
                readOnly: false,
                record: caseData,
                pageNumber: currentPage,
                tabKey: caseType,
                isCompletedCasesPage: isCompletedCasesPage
              });
            },
          });
          if (caseData.procedure) {
            items.splice(1, 0, {
              title: "Start Case",
              onClick: (e) => {
                e.domEvent.stopPropagation();
                confirmOnStartCase(caseData);
              },
            });
          }
          items.push({
            title: "Delete",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              showDeleteConfirmation(caseData.id);
            },
          });
        }
      }
    }

    return items;
  };

  const cardContent = (hospitalCase) => [
    {
      icon: <CaseDescriptionIcon />,
      content: hospitalCase.procedure?.procedureName
        ? hospitalCase.procedure.procedureName
        : null,
    },
    {
      icon: <CalenderIcon />,
      content:
        hospitalCase.dateOfSurgery || hospitalCase.timeOfSurgery ? (
          <>
            {hospitalCase.dateOfSurgery && (
              <>
                <Moment format={dateFormat}>
                  {hospitalCase.dateOfSurgery}
                </Moment>
                {hospitalCase.timeOfSurgery && " | "}
              </>
            )}
            {hospitalCase.timeOfSurgery && hospitalCase.timeOfSurgery}
          </>
        ) : null,
    },
  ];
  /** Render Components End */

  const showDeleteConfirmation = (caseId) => {
    confirm({
      title: "Delete Hospital Case",
      content: "Are you sure you want to delete this Case?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        setLoading(true);
        const isDeleted = await hospitalService.putCase({
          id: caseId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Case deleted successfully");
          await populateCasesList(caseType, customPageSize, 0, casesSearchText);
        } else {
          toastNotification("error", "Error deleting the case");
        }
        setLoading(false);
      },
    });
  };

  const onSearchFunction = async (searchValue) => {
    searchValue = searchValue.trim();
    await setCasesSearchText(searchValue);
    if (sortBySurgeon) {
      populateSurgeonByCases(caseType, searchValue);
      return;
    }
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        const success = await getAndSetCasesCount(caseType, searchValue);
        if (success) {
          populateCasesList(caseType, customPageSize, 0, searchValue);
        } else {
          toastNotification("error", "Error fetching case records");
        }
      }, 500)
    );
  };

  const onSortCasesChange = async (sortType) => {
    setActiveKey();
    setSortBySurgeonPageData();
    setCaseListBySurgeon();
    setCurrentPage(1);
    if (sortType === "surgeon") {
      setSortBySurgeon(true);
      populateSurgeonByCases(caseType, casesSearchText);
    } else {
      setSortBySurgeon(false);
      populateCasesList(caseType, customPageSize, 0, casesSearchText);
      await getAndSetCasesCount(caseType, casesSearchText);
    }
  };

  const casesSortDropdown = (
    <Select onChange={onSortCasesChange} defaultValue="latestCases">
      <Select.Option value="latestCases">Sort by Latest Cases</Select.Option>
      <Select.Option value="surgeon">Sort by Surgeons</Select.Option>
    </Select>
  );

  const redirectToLiveCase = (caseData) => {
    history.push(`/procedure/${caseData.procedure.id}/live-case`, {
      stepDetails: {
        caseId: caseData.id,
        currentScreen: caseData.currentScreen, // "STEP" -> Execution Flow OR null -> Table Setup
        currentStepId: caseData.currentStep, // null -> Start Page OR guid -> render that step
        isStarted: caseData.isStarted,
        pageNumber: currentPage,
        tabKey: caseType
      },
      path: location.pathname,
      readOnly: (() => {
        switch (caseData.isStarted) {
          case CaseTypes.UPCOMING:
            return false;
          case CaseTypes.LIVE:
            return hasReadOnlyRights;
          case CaseTypes.COMPLETED:
            return true;
          default:
            return false;
        }
      })(),
    });
  };

  const confirmOnStartCase = (caseData) => {
    confirm({
      title: "You are about to start the case.",
      content:
        "Tapping on “Confirm” would initialize the case steps. Are you sure you want to continue?",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      icon: null,
      async onOk() {
        const res = hospitalService.putCase({
          id: caseData.id,
          isStarted: "STARTED",
        });
        if (res) redirectToLiveCase(caseData);
        else toastNotification("error", "Failed to start the case");
      },
    });
  };

  const onTabChange = async (activeTabKey) => {
    setActiveKey();
    setSortBySurgeonPageData();
    setCaseListBySurgeon();
    setCurrentPage(1);
    sortBySurgeon
      ? populateSurgeonByCases(activeTabKey, casesSearchText)
      : populateCasesList(activeTabKey, customPageSize, 0, casesSearchText);
    await getAndSetCasesCount(activeTabKey, casesSearchText);
    setCaseType(activeTabKey);
  };

  const callback = (key) => {
    const surgeonId = key[key.length - 1];
    setActiveKey(key);
    populateCasesBySurgeon(caseType, surgeonId, customPageSize, 0);
  };

  const onLoadMore = async (surgeonId) => {
    setLoading(true);
    await populateCasesBySurgeon(
      caseType,
      surgeonId,
      customPageSize,
      (sortBySurgeonPageData ? sortBySurgeonPageData[surgeonId].pageSize : 0) *
      customPageSize
    );
    setLoading(false);
  };

  /** API Calls */
  const populateCasesBySurgeon = async (caseType, surgeonId, limit, skip) => {
    setLoading(true);
    let data;
    if (isMyCasesPage && hospitalStaffId) {
      // Logged in user has role HOSPITAL_STAFF
      const params = {
        surgeon: surgeonId,
        caseType: caseType,
        limit: limit,
        skip: skip,
      };

      data = await hospitalService.getHospitalUserCasesBySurgeon(
        hospitalStaffId,
        params
      );
    } else {
      const params = {
        hospital: hospitalId,
        surgeon: surgeonId,
        isDeleted: isDeletedPage,
        limit: limit,
        skip: skip,
      };
      if (!isDeletedPage) params.isStarted = caseType;

      data = await hospitalService.getHospitalCasesBySurgeon(params);
    }
    if (data) {
      let surgeonPageData = {};
      surgeonPageData[surgeonId] = {
        loadCases: data.length === customPageSize,
        pageSize:
          sortBySurgeonPageData && sortBySurgeonPageData[surgeonId]
            ? sortBySurgeonPageData[surgeonId].pageSize + 1
            : 1,
      };
      const pageData = { ...sortBySurgeonPageData, ...surgeonPageData };
      setSortBySurgeonPageData(pageData);

      let surgeonData = {};
      surgeonData[surgeonId] =
        skip > 0 ? [...caseListBySurgeon[surgeonId], ...data] : data;
      const casesData = { ...caseListBySurgeon, ...surgeonData };
      setCaseListBySurgeon(casesData);
    } else {
      toastNotification("error", "Error fetching the case records by surgeon");
    }
    setLoading(false);
  };

  const populateSurgeonByCases = async (caseType, searchValue) => {
    setLoading(true);

    let data;
    if (isMyCasesPage && hospitalStaffId) {
      // Logged in user has role HOSPITAL_STAFF
      data = await hospitalService.getHospitalUserSurgeons(
        hospitalStaffId,
        caseType
      );
    } else {
      data = await hospitalService.getHospitalSurgeons(hospitalId, caseType);
    }
    if (data) {
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        setSurgeonList(
          data.filter(
            (s) =>
              (s.firstName &&
                s.firstName.toLowerCase().indexOf(searchValue) !== -1) ||
              (s.middleName &&
                s.middleName.toLowerCase().indexOf(searchValue) !== -1) ||
              (s.lastName &&
                s.lastName.toLowerCase().indexOf(searchValue) !== -1) ||
              "Dr.".toLowerCase().indexOf(searchValue) !== -1
          )
        );
        setLoading(false);
        return;
      }
      setSurgeonList(data);
    } else {
      toastNotification("error", "Error fetching the case surgeon records");
    }
    setLoading(false);
  };

  const populateCasesList = async (caseType, limit, skip, searchValue) => {
    setLoading(true);

    let data;
    if (isMyCasesPage && hospitalStaffId) {
      // Logged in user has role HOSPITAL_STAFF
      data = await hospitalService.getHospitalUserCases(
        hospitalStaffId,
        caseType,
        isDeletedPage,
        searchValue,
        null,
        limit,
        skip
      );
    } else {
      const whereCondition = {
        hospital: hospitalId,
        isDeleted: isDeletedPage,
      };

      if (searchValue) {
        whereCondition.caseType = caseType;
        whereCondition.searchText = searchValue;
      } else {
        if (!isDeletedPage) whereCondition.isStarted = caseType;
      }

      if (isMyCasesPage && surgeonId) {
        whereCondition.surgeon = surgeonId;
      }

      data = await hospitalService.getHospitalCases(
        whereCondition,
        limit,
        skip
      );
    }
    if (data) {
      setCaseList(data);
    } else {
      toastNotification("error", "Error fetching the case records");
    }
    setLoading(false);
  };

  const getAndSetCasesCount = async (currentCaseType, searchText) => {
    setLoading(true);
    let count;
    const params = {
      hospital: hospitalId,
      isDeleted: isDeletedPage,
      caseType: currentCaseType,
      searchText,
    };

    if (searchText) {
      let res;
      if (isMyCasesPage && hospitalStaffId) {
        res = await hospitalService.getHospitalUserCases(
          hospitalStaffId,
          caseType,
          isDeletedPage,
          searchText,
          true
        );
      } else {
        params.count = true;
        if (isMyCasesPage) {
          params.surgeon = surgeonId;
        }
        res = await hospitalService.getHospitalCases(params);
      }
      count = res.count;
    } else {
      if (isMyCasesPage) {
        params.userId = surgeonId ? surgeonId : hospitalStaffId;
      }
      params.userType = isMyCasesPage ? authContext.user.role : Roles.DOR_ADMIN;
      count = await hospitalService.getCasesCount(params);
    }

    if (count >= 0) {
      setCasesCount(count);
      return true;
    }
    setLoading(false);
    return false;
  };

  const onCaseRestore = async (caseId) => {
    const res = await hospitalService.patchRestoreCase(caseId);
    if (res) {
      toastNotification("success", "Case Restored");

      const success = await getAndSetCasesCount(caseType);
      if (success) {
        populateCasesList(null, customPageSize, 0);
      }
    } else {
      toastNotification("error", "Error restoring the ");
    }
  };
  /** API Calls End */

  useEffect(() => {
    (async () => {
      const success = await getAndSetCasesCount(caseType);
      if (success) {
        if (isDeletedPage) populateCasesList(null, customPageSize, customPageSize * (currentPage - 1));
        else if (isCompletedCasesPage)
          populateCasesList(CaseTypes.COMPLETED, customPageSize, customPageSize * (currentPage - 1));
        else populateCasesList(caseType, customPageSize, customPageSize * (currentPage - 1));
      }
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        searchPlaceholderTxt={
          isMyCasesPage && surgeonId ? "" : "Search by Surgeon's Name"
        }
        onSearchFunction={onSearchFunction}
        dropDown={
          (isMyCasesPage && surgeonId) || isDeletedPage || isCompletedCasesPage
            ? false
            : casesSortDropdown
        }
        {...pageHeaderProps()}
      >
        {!isDeletedPage && !isCompletedCasesPage && (
          <Tabs onChange={onTabChange} className="cases-section-tab" defaultActiveKey={location.state?.tabKey ?? CaseTypes.UPCOMING}>
            <TabPane tab="Upcoming Cases" key={CaseTypes.UPCOMING} />
            <TabPane tab="Live Cases" key={CaseTypes.LIVE} />
          </Tabs>
        )}
        {sortBySurgeon ? (
          <Collapse
            activeKey={activeKey}
            onChange={callback}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <CollapseArrowIcon style={{ marginTop: "-0.5em" }} />
              ) : (
                  <ExpandArrowIcon style={{ marginTop: "-0.5em" }} />
                )
            }
            ghost
          >
            {surgeonList.map((surgeon) => (
              <Panel
                key={surgeon.id}
                header={
                  <div className="mytextdiv">
                    <div className="mytexttitle">
                      {[
                        "Dr.",
                        surgeon.firstName,
                        surgeon.middleName,
                        surgeon.lastName,
                      ].join(" ")}
                    </div>
                    <div className="textDivider"></div>
                  </div>
                }
              >
                {caseListBySurgeon ? (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 4,
                      xl: 4,
                      xxl: 6,
                    }}
                    dataSource={caseListBySurgeon[surgeon.id]}
                    loadMore={
                      !loading &&
                      sortBySurgeonPageData[surgeon.id] &&
                      sortBySurgeonPageData[surgeon.id].loadCases && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: 12,
                            height: 32,
                            lineHeight: "32px",
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onLoadMore(surgeon.id);
                            }}
                            type="primary"
                          >
                            Show more results
                          </Button>
                        </div>
                      )
                    }
                    renderItem={(hospitalCase) => {
                      return (
                        <List.Item>
                          <CustomCard
                            headerIcon={<SurgeonColorIcon />}
                            title={
                              hospitalCase.surgeon &&
                              [
                                "Dr.",
                                hospitalCase.surgeon.firstName,
                                hospitalCase.surgeon.middleName,
                                hospitalCase.surgeon.lastName,
                              ].join(" ")
                            }
                            actions={actions(hospitalCase)}
                            onClick={() => {
                              if (isCompletedCasesPage)
                                variableURL = url.replace("/completed", "");
                              else if (isDeletedPage)
                                variableURL = url.replace("/deleted", "");
                              history.push(
                                `${variableURL}/${hospitalCase.id}`,
                                {
                                  readOnly: true,
                                  record: hospitalCase,
                                  pageNumber: currentPage,
                                  tabKey: caseType,
                                  isCompletedCasesPage: isCompletedCasesPage
                                }
                              );
                            }}
                            bodyContent={cardContent(hospitalCase)}
                          />
                        </List.Item>
                      );
                    }}
                  />
                ) : (
                    <></>
                  )}
              </Panel>
            ))}
          </Collapse>
        ) : (
            <CardList
              dataSource={caseList}
              renderItem={(hospitalCase) => {
                return (
                  <List.Item>
                    <CustomCard
                      headerIcon={<SurgeonColorIcon />}
                      title={
                        hospitalCase.surgeon &&
                        [
                          "Dr.",
                          hospitalCase.surgeon.firstName,
                          hospitalCase.surgeon.middleName,
                          hospitalCase.surgeon.lastName,
                        ].join(" ")
                      }
                      actions={actions(hospitalCase)}
                      onClick={
                        hospitalCase.isDeleted
                          ? null
                          : () => {
                            if (isCompletedCasesPage)
                              variableURL = url.replace("/completed", "");
                            else if (isDeletedPage)
                              variableURL = url.replace("/deleted", "");
                            history.push(`${variableURL}/${hospitalCase.id}`, {
                              readOnly: true,
                              record: hospitalCase,
                              pageNumber: currentPage,
                              tabKey: caseType,
                              isCompletedCasesPage: isCompletedCasesPage
                            });
                          }
                      }
                      bodyContent={cardContent(hospitalCase)}
                    />
                  </List.Item>
                );
              }}
              pagination={{
                pageSize: customPageSize,
                total: casesCount,
                current: currentPage,
                onChange: async (page, pageSize) => {
                  setLoading(true);
                  await populateCasesList(
                    caseType,
                    pageSize,
                    pageSize * (page - 1),
                    casesSearchText
                  );
                  setCurrentPage(page);
                  setLoading(false);
                },
              }}
            />
          )}
      </MainContainer>
    </Spin>
  );
};

export default HospitalCasesPage;
