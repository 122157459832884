import { Empty } from "antd";

const EmptyElement = (props) => {
  const { image, description, children, elementStyle, imageStyle } = props;
  return (
    <Empty
      image={image}
      description={description}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        ...elementStyle,
      }}
      imageStyle={imageStyle}
    >
      {children}
    </Empty>
  );
};

export default EmptyElement;
