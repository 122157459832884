import { useState, useEffect } from "react";
import { Modal, Typography, Divider, Spin } from "antd";
import ExecutionFlowContainer from "../../containers/LiveCase/ExecutionFlowContainer";
import LiveCaseContainer from "../../containers/LiveCase/LiveCaseContainer";
import TableSetupLiveCase from "./CaseTableSetupPage";
import ExecutionStartPage from "./ExecutionStartPage";
import NoExecutionFlowPage from "./NoExecutionFlowPage";
import liveCaseService from "../../services/liveCaseService";
import distributorService from "../../services/distributorService";
import hospitalService from "../../services/hospitalService";
import toastNotification from "../../components/toastNotification";
import { ExecutionStepsContext } from "../../context/ExecutionStepsContext";
import { useAuthContext } from "../../context/AuthContext";
import { Roles } from "../../config/role-config.json";
import { TableSetupContext } from "../../context/TableSetupContext";

const { Text } = Typography;

const LiveCasePage = (props) => {
  const {
    match: {
      params: { procedureid: caseProcedureId },
    },
    location,
    history,
  } = props;

  const path = location.state?.path;
  const {
    currentStepId = null,
    currentScreen = null,
    caseId = null,
  } = location.state?.stepDetails ? location.state?.stepDetails : {};

  const authContext = useAuthContext();
  const hasReadOnlyRights =
    authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
    authContext.user.role === Roles.MR;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [executionSteps, setExecutionSteps] = useState([]);
  const [tableSetup, setTableSetup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [procedureName, setProcedureName] = useState("");
  const [tableMode, setTableMode] = useState("3WIDE");
  const [selectedTag, setSelectedTag] = useState("Table Setup");
  const [allEquipments, setAllEquipments] = useState([]);
  const [allTraysVerified, setAllTraysVerified] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  const [continueWOTrayVerification, setContinueWOTrayVerification] =
    useState(allTraysVerified);

  let currentStepEquipmentPage = 0;
  let stepEquipmentList = [];
  let currentChecklistItemPage = 0;
  let checklistItemList = [];
  const customPageSize = 30;

  /** Functional */
  const handleTagSelectionChange = (tag) => {
    const numberOfVerifiedTrays = tableSetup.filter(
      (t) => t.sectionNumber && t.verificationStatus === "VERIFIED"
    );
    const tableSetupTrays = tableSetup.filter((t) => t.sectionNumber);
    setAllTraysVerified(
      tableSetupTrays.length === numberOfVerifiedTrays.length
    );
    setSelectedTag(tag);
  };

  const onDecreaseStepIndex = () => {
    if (currentStepIndex !== 0) {
      const newStepIndex = currentStepIndex - 1;
      updateNewIndex(newStepIndex);
    }
  };

  const onIncreaseStepIndex = () => {
    if (executionSteps[currentStepIndex + 1]) {
      const newStepIndex = currentStepIndex + 1;
      updateNewIndex(newStepIndex);
    } else {
      confirmEndCase();
    }
  };

  const updateNewIndex = (newStepIndex) => {
    // Update state to render new step
    setCurrentStepIndex(newStepIndex);

    // submit updated step id to backend
    if (!hasReadOnlyRights && caseDetails?.isStarted !== "FINISHED")
      submitUpdatedStepId(executionSteps[newStepIndex].id);

    // set location state to maintain the step id on page refresh
    const locationState = location?.state;
    if (location.state) {
      locationState.stepDetails.currentStepId = executionSteps[newStepIndex].id;
      history.replace({ state: locationState });
    }
  };

  const confirmEndCase = () => {
    Modal.confirm({
      title: (
        <Text strong style={{ color: "#f26524" }}>
          You are about to complete the Surgery
        </Text>
      ),
      content: `Please take Surgeon’s consent before completing the surgery and then click
      on “Complete”.`,
      okText: "Complete",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      width: "550px",
      onOk: async () => {
        if (!hasReadOnlyRights) {
          await hospitalService.putCase({
            id: caseId,
            isStarted: "FINISHED",
          });
        }
        history.push(path);
      },
    });
  };
  /** Functional Ends */

  /** API Calls */
  const submitUpdatedStepId = async (newStepId) => {
    await liveCaseService.nextPreviousButton(caseId, newStepId);
  };

  const getAndSetProcedureAndCaseDetails = async () => {
    const data = await distributorService.getProcedure(caseProcedureId, true);
    if (data) {
      if (currentScreen === "STEP" && data.case.isStarted !== "FINISHED")
        setSelectedTag("Surgical Flow");
      setProcedureName(data.procedureName);
      setCaseDetails(data.case);
      setContinueWOTrayVerification(data.case.isStarted === "FINISHED");
      setTableMode(data.tableMode);
    }
    return data.case;
  };

  const getExecutionSteps = async (equipmentDetails) => {
    try {
      const tempExecutionSteps = await liveCaseService.getStepDetails(
        caseProcedureId
      );

      for (const step of tempExecutionSteps) {
        switch (step.stepType) {
          case "SURGICAL_STEP":
            const stepEquipments = await populateSurgicalSteps(step.id);
            for (let el of stepEquipments) {
              const equipment = equipmentDetails.filter(
                (e) => e.equipment.id === el.equipment.id
              );
              if (equipment.length > 0)
                el.equipment.sectionNumber =
                  equipment[0].equipment.sectionNumber;
            }
            if (stepEquipments) step.stepEquipments = stepEquipments;
            break;
          case "CHECKLIST":
            const checklistItems = await populateChecklistItems(step.id);
            if (checklistItems) step.checklistItems = checklistItems;
            break;
          default:
            break;
        }
      }

      setExecutionSteps(tempExecutionSteps);
      return tempExecutionSteps;
    } catch (error) {
      console.log(error);
      toastNotification("error", "Error in fetching data");
    }
  };

  const populateSurgicalSteps = async (stepId) => {
    const equipmentDetails = await liveCaseService.getSurgicalSteps(
      stepId,
      customPageSize,
      currentStepEquipmentPage * customPageSize
    );
    stepEquipmentList = [...stepEquipmentList, ...equipmentDetails];
    if (equipmentDetails.length < customPageSize) {
      currentStepEquipmentPage = 0;
      const res = stepEquipmentList;
      stepEquipmentList = [];
      return res;
    }
    currentStepEquipmentPage++;
    return populateSurgicalSteps(stepId);
  };

  const populateChecklistItems = async (stepId) => {
    const items = await liveCaseService.getChecklistItems(
      stepId,
      customPageSize,
      currentChecklistItemPage * customPageSize
    );
    checklistItemList = [...checklistItemList, ...items];
    if (items.length < customPageSize) {
      currentChecklistItemPage = 0;
      const res = checklistItemList;
      checklistItemList = [];
      return res;
    }
    currentChecklistItemPage++;
    return populateChecklistItems(stepId);
  };
  /** API Calls End */

  useEffect(() => {
    (async () => {
      setLoading(true);

      const caseData = await getAndSetProcedureAndCaseDetails();

      //Get Table Setup Details. Needed if users joins a case as it would directly start from execution flow
      const data = await distributorService.getTableSetup(caseProcedureId);
      setTableSetup(data);
      //check all trays Verified
      const numberOfVerifiedTrays = data.filter(
        (t) => t.verificationStatus === "VERIFIED"
      );
      if (data.length === numberOfVerifiedTrays.length) {
        setAllTraysVerified(true);
      }
      //To get tray with equipment details
      let trayIds = data.map((el) => el.tray.id);
      const res = await distributorService.getTrayDetails(trayIds);

      let equipmentDetails = res.data;

      equipmentDetails = equipmentDetails.map((el) => {
        const tray = data.filter((e) => e.tray.id === el.tray.id);
        el.equipment.gridNumber = parseInt(tray[0].sectionNumber);
        el.equipment.name = el.equipment.equipmentName.toLowerCase();
        return el;
      });

      equipmentDetails.forEach((el) => {
        const trays = equipmentDetails.filter(
          (e) => e.equipment.id === el.equipment.id
        );
        const sectionNumber = trays.map((el) => el.equipment.gridNumber);
        el.equipment.sectionNumber = sectionNumber;
      });

      equipmentDetails = equipmentDetails.filter(
        (el, i, a) =>
          a.findIndex((e) => e.equipment.id === el.equipment.id) === i
      );
      setAllEquipments(equipmentDetails);

      // Get Execution Steps
      const tempExecutionSteps = await getExecutionSteps(equipmentDetails);

      if (tempExecutionSteps) {
        if (tempExecutionSteps.length > 0) {
          // Sort by phases and then steps
          tempExecutionSteps.sort(
            (stepA, stepB) =>
              stepA.phase.index.localeCompare(stepB.phase.index) ||
              stepA.index.localeCompare(stepB.index)
          );

          // Set the current step index
          if (caseData?.isStarted !== "FINISHED") {
            const id = tempExecutionSteps.findIndex(
              (step) => step.id === currentStepId
            );
            setCurrentStepIndex(id);
          }
        } else {
          setCurrentStepIndex(-1);
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <LiveCaseContainer
      procedureName={procedureName}
      selectedTag={selectedTag}
      handleTagSelectionChange={handleTagSelectionChange}
      backBtnPath={path}
      routeState={{
        pageNumber: location.state?.pageNumber,
        tabKey: location.state?.tabKey,
        isCompletedCasesPage: location.state.isCompletedCasesPage
      }}
    >
      <Divider
        style={{
          margin: "0",
          border: "1px solid rgb(209,209,209)",
        }}
      />
      <div className="dor-live-case">
        <TableSetupContext.Provider value={{ tableSetup, setTableSetup }}>
          {selectedTag === "Surgical Flow" ? (
            <ExecutionStepsContext.Provider
              value={{
                stepIndexObj: {
                  currentStepIndex,
                  onNext: onIncreaseStepIndex,
                  onPrevious: onDecreaseStepIndex,
                },
                allEquipments,
                executionSteps,
                caseId,
                caseProcedureId,
                setContinue: setContinueWOTrayVerification,
                hasReadOnlyRights:
                  hasReadOnlyRights || caseDetails?.isStarted === "FINISHED",
              }}
            >
              {loading ? (
                <Spin
                  spinning={loading}
                  style={{
                    backgroundColor: "#000e1a",
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ) : currentStepIndex >= 0 ? (
                <ExecutionFlowContainer />
              ) : allTraysVerified || continueWOTrayVerification ? (
                executionSteps.length > 0 ? (
                  <ExecutionStartPage
                    caseId={caseId}
                    firstStepId={executionSteps[0].id}
                    allTraysVerified={true}
                  />
                ) : (
                    <NoExecutionFlowPage />
                  )
              ) : (
                      <ExecutionStartPage caseId={caseId} allTraysVerified={false} />
                    )}
            </ExecutionStepsContext.Provider>
          ) : (
              <TableSetupLiveCase
                tableMode={tableMode}
                procedureId={caseProcedureId}
                isProcedureEditable={
                  !hasReadOnlyRights && caseDetails?.isStarted !== "FINISHED"
                }
                isCompletedCasesPage={location.state.isCompletedCasesPage}
              />
            )}
        </TableSetupContext.Provider>
      </div>
    </LiveCaseContainer >
  );
};

export default LiveCasePage;
