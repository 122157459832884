import TraysPage from "../../shared-pages/manufacturer/TraysPage";

const RMTraysPage = ({ match }) => {
  const { id, rmid } = match.params;

  return <TraysPage 
    manufacturerId={id}
    url={match.url}
    hasOwnLibrary
    entityId={rmid}
    entityName="regionalManager"
  />;
};

export default RMTraysPage;
