import React from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Input, Row, Col, Spin, Divider, Typography } from "antd";

import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import distributorService from "../../services/distributorService";
import procedureService from "../../services/procedureService";
import userService from "../../services/userServices";
import utilityService from "../../services/utilityService";
import { ReactComponent as ManufacturerColorIcon } from "../../assets/icons/colorIconsGreyManufacturers.svg";
import { ReactComponent as RegionalManagerColorIcon } from "../../assets/icons/color-icons_regions.svg";

const { Text } = Typography;

const ProcedureForm = ({
  entityId,
  entityName,
  procedureId,
  procedure,
  manufacturerId,
}) => {
  const [clonedOwner, setClonedOwner] = useState(null);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const pageHeaderProps = procedureId
    ? location?.state?.isCloneProcedure
      ? {
        title: procedure?.procedureName ?? "Cloned Procedure",
        btnText: "Continue",
        btnType: "primary",
      }
      : {
        title: "Edit Procedure",
        btnText: "Save",
        btnType: "primary",
      }
    : {
      title: "New Procedure",
      btnText: "Create",
    };
  let backPath =
    url.includes("new") && !procedureId
      ? url.replace("/new", "")
      : url + "/table-setup";

  const createProcedure = async (procedureFormValues) => {
    procedureFormValues.regionalManager = entityId;
    const res = await procedureService.postProcedure(procedureFormValues);
    if (res.status === 200 && res.data?.id) {
      toastNotification("success", "New Procedure created");
      history.push(url.replace("new", `${res.data.id}/table-setup`), {
        tag: "Table Setup",
        create: true,
      });
    } else {
      toastNotification("error", "Error adding a new Procedure record");
    }
  };

  const editProcedure = async (procedureFormValues) => {
    procedureFormValues.id = procedureId;
    const res = await procedureService.putProcedure(procedureFormValues);
    if (res.status === 200) {
      history.push(backPath, {
        tag: "Table Setup",
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        isCloneProcedure: location.state?.isCloneProcedure,
      });
    } else {
      toastNotification("error", "Error updating the Procedure record");
    }
  };

  const getProcedure = async (id) => {
    const procedureTemp = await procedureService.getProcedure(id);
    if (procedureTemp) {
      form.setFieldsValue(procedureTemp);
      if (procedureTemp.clonedFrom?.distributor) {
        await getClonedOwner(procedureTemp.clonedFrom.distributor, true);
      } else if (procedureTemp.clonedFrom?.[entityName]) {
        await getClonedOwner(procedureTemp.clonedFrom[entityName], false);
      }
    } else {
      toastNotification("error", "Requested Procedure record not found");
    }
  };

  const getClonedOwner = async (id, isDistributor) => {
    const clonedOwnerTemp = isDistributor
      ? await distributorService.getDistributor(id)
      : await userService.getUser(id);
    if (clonedOwnerTemp) {
      setClonedOwner(clonedOwnerTemp);
    } else {
      toastNotification("error", "Error fetching Procedure record");
    }
  };

  const onFinish = async (procedureFormValues) => {
    setLoading(true);
    if (procedureId) {
      procedureFormValues.id = procedureId;
      await editProcedure(procedureFormValues);
    } else {
      await createProcedure(procedureFormValues);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (procedureId) {
        await getProcedure(procedureId);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      formName="ProcedureAddEditForm"
      backBtnPath={backPath}
      routeState={{
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        isCloneProcedure: location.state?.isCloneProcedure
      }}
      onBtnClick={() => {
        form.submit();
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <div style={{ marginTop: "2rem" }}>
          <Row align="center">
            <Col
              xs={{ span: 23 }}
              sm={{ span: 19 }}
              md={{ span: 15 }}
              lg={{ span: 11 }}
              xl={{ span: 11 }}
            >
              <Form
                form={form}
                name="ProcedureAddEditForm"
                id="ProcedureAddEditForm"
                size="large"
                colon="false"
                scrollToFirstError="true"
                onFinish={onFinish}
                layout="vertical"
                className="procedure"
                requiredMark={false}
              >
                <Form.Item
                  label="Procedure Name (Required)"
                  name="procedureName"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Procedure name",
                    },
                  ]}
                >
                  <Input placeholder="Example: New - Airway Balloon Dilation" />
                </Form.Item>

                <Form.Item
                  label="Procedure Description"
                  name="procedureDescription"
                >
                  <Input.TextArea
                    placeholder="A description on how this procedure works."
                    rows={7}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row align="center">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 16, offset: 1 }}
              lg={{ span: 12, offset: 1 }}
              xl={{ span: 12, offset: 1 }}
            >
              {clonedOwner && (
                <>
                  <Row>
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}>
                      {clonedOwner.isManufacturer ? (
                        <ManufacturerColorIcon />
                      ) : (
                        <RegionalManagerColorIcon />
                      )}
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text type="secondary" style={{ fontSize: "12px" }}>
                          Created By
                        </Text>
                        <Text>
                          {clonedOwner.distributorName
                            ? clonedOwner.distributorName
                            : utilityService.getRegionalManagerName(
                              clonedOwner
                            )}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </MainContainer>
  );
};
export default ProcedureForm;
