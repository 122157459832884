import { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Empty, Typography, Spin, Space } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import GlobalProcedureLibraryPage from "./GlobalProcedureLibraryPage";
import toastNotification from "../../components/toastNotification";
import distributorService from "../../services/distributorService";
import hospitalService from "../../services/hospitalService";
import utilityService from "../../services/utilityService";
import noProcedure from "../../assets/icons/hospital-case/Empty-state-Surgery.png";
import { ReactComponent as ProcedureColorIcon } from "../../assets/icons/color-icons_procedure.svg";
import { useHospitalContext } from "../../context/HospitalContext";

const { Text } = Typography;
const CaseProcedureForm = (props) => {
  const { hospitalCase, updateCase, hasReadOnlyRights, showModalPopUp = false } = props;

  const { hospitalDistributors } = useHospitalContext();
  const history = useHistory();
  const location = useLocation();
  const [globalProcedurePopUpVisible, setGlobalProcedurePopUpVisible] =
    useState(showModalPopUp);
  const [caseProcedureDistributor, setCaseProcedureDistributor] = useState();

  const [loading, setLoading] = useState(false);

  const onProcedureEdit = () => {
    const distributorIds = hospitalDistributors.map((el) => el.id);
    history.push(`/procedure/${hospitalCase.procedure.id}/table-setup`, {
      readOnly: false,
      path: history.location.pathname,
      distributorId: caseProcedureDistributor.distributor?.id || caseProcedureDistributor.regionalManager?.id,
      tag: "Table Setup",
      distributorIds: distributorIds,
      hospitalId: hospitalCase.hospital.id,
      pageNumber: location.state?.pageNumber,
      tabKey: location.state?.tabKey,
      isCloneProcedure: location.state?.isCloneProcedure,
    });
  };

  const onProcedureView = () => {
    history.push(`/procedure/${hospitalCase.procedure.id}/case/${hospitalCase.id}/live-case/preview`,
      {
        path: history.location.pathname,
        pageNumber: location.state?.pageNumber,
        tabKey: location.state?.tabKey,
        isCloneProcedure: location.state?.isCloneProcedure,
      });
  };

  const closeProcedurePopUp = () => {
    setGlobalProcedurePopUpVisible(false);
  };

  const fetchAndSetUpdatedCase = async () => {
    setLoading(true);
    const caseData = await getCase();
    history.replace({
      state: {
        record: caseData, pageNumber: location.state?.pageNumber,
        tabKey: location.state?.tabKey
      }
    });
    setLoading(false);
  };

  /** API Calls */
  const onProcedureDetach = async () => {
    setLoading(true);
    const isDetached = await distributorService.postDetachProcedure(
      hospitalCase.procedure.id,
      hospitalCase.id
    );

    if (isDetached) {
      await fetchAndSetUpdatedCase();
      toastNotification("success", "Procedure Detached");
    } else {
      toastNotification("error", "Error detaching the Procedure");
    }
    setLoading(false);
  };

  const getCase = async () => {
    const data = await hospitalService.getCase(hospitalCase.id);
    if (data) {
      updateCase(data);
      return data;
    } else {
      toastNotification("error", "Case details could not be fetched");
    }
  };

  const getCaseProcedureDistributor = async (hospitalCase) => {
    const data = await distributorService.getProcedureDistributor(
      hospitalCase.procedure.clonedFrom
    );
    if (data) {
      const result = data.regionalManager?.id && await hospitalService.getManufacturers(JSON.stringify([data.regionalManager.id]));
      data.distributorName = result?.length ? result[0].manufacturer.distributorName : null
      setCaseProcedureDistributor(data);
    } else {
      toastNotification("error", "Error fetching the case Procedure");
    }
  };
  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (hospitalCase?.procedure)
        await getCaseProcedureDistributor(hospitalCase);
      setLoading(false);
    })();
  }, [hospitalCase?.procedure]);

  return (
    <Spin spinning={loading}>
      {hospitalCase?.procedure ? (
        <Row>
          <Col span={14} offset={5}>
            <div
              style={{
                borderRadius: "15px",
                border: "2px solid #f4f4f4",
                padding: "20px",
                marginTop: "25px",
              }}
            >
              <Row justify="space-between">
                <Col className="display-flex-center" span={hasReadOnlyRights ? 21 : 16}>
                  <ProcedureColorIcon fill="#feefe8" />
                  <div style={{ marginLeft: "16px" }}>
                    <Row>
                      <Col span={24}>
                        <Text strong>
                          {hospitalCase.procedure.procedureName}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Text style={{ fontSize: "12px" }}>
                          {caseProcedureDistributor?.distributor
                            ? `by "${caseProcedureDistributor.distributor.distributorName}"`
                            : (caseProcedureDistributor?.regionalManager && caseProcedureDistributor.distributorName)
                              ? `by "${caseProcedureDistributor.distributorName}"`
                              : ""}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="display-flex-center">
                  {hasReadOnlyRights ? (
                    <Button
                      onClick={() => {
                        history.push(
                          `/procedure/${hospitalCase.procedure.id}/table-setup`,
                          {
                            readOnly: true,
                            tag: "Table Setup",
                            path: history.location.pathname,
                            hospitalId: hospitalCase.hospital.id,
                            pageNumber: location.state?.pageNumber,
                            tabKey: location.state?.tabKey,
                            isCloneProcedure: location.state?.isCloneProcedure,
                          }
                        );
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    <Space>
                      <Button
                        htmlType="button"
                        type="secondary"
                        onClick={onProcedureView}
                      >
                        View
                      </Button>
                      <Button
                        htmlType="button"
                        type="secondary"
                        onClick={onProcedureEdit}
                      >
                        Edit
                      </Button>
                      <Button
                        htmlType="button"
                        type="secondary"
                        onClick={onProcedureDetach}
                      >
                        Detach
                      </Button>
                    </Space>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      ) : (
        hospitalCase &&
        !hospitalCase.procedure && (
          <Empty
            image={noProcedure}
            imageStyle={{ alignItems: "centre", marginTop: "10%" }}
            description={
              <span>
                <Text>No procedure attached yet.</Text>
                {!hasReadOnlyRights && (
                  <>
                    <br />
                    <Text>Select a procedure from the library</Text>
                  </>
                )}
              </span>
            }
          >
            {!hasReadOnlyRights && (
              <Button
                type="primary"
                key="actionBtn"
                htmlType="button"
                onClick={() => {
                  setGlobalProcedurePopUpVisible(true);
                }}
              >
                Select Template
              </Button>
            )}
          </Empty>
        )
      )}

      {/** Global Procedure List Modal */}
      <Modal
        title="Select Procedure"
        visible={globalProcedurePopUpVisible}
        onCancel={closeProcedurePopUp}
        footer={null}
        width={1200}
        centered
        destroyOnClose
        bodyStyle={{ paddingTop: "5px" }}
      >
        <GlobalProcedureLibraryPage
          closeModel={closeProcedurePopUp}
          hospitalCase={hospitalCase}
          updateCase={updateCase}
          showModalPopUp={true}
          pageNumber={location.state?.pageNumber}
          tabKey={location.state?.tabKey}
        />
      </Modal>
      {/** Global Procedure List Modal ends*/}
    </Spin>
  );
};

export default CaseProcedureForm;
