import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useAuthContext } from "../context/AuthContext";
import authService from "../services/authService";
import { version } from "../../package.json";

const { Sider } = Layout;

const SideBar = ({ menuItems, matchMenuKey }) => {
  const authContext = useAuthContext();

  const [collapsed, setCollapse] = useState(false);
  const [backendVersion, setBackendVersion] = useState("");

  const onCollapse = (isCollapsed) => {
    setCollapse(isCollapsed);
  };

  const getBackendVersion = async () => {
    const backendVersion = await authService.getBackendVersion();
    setBackendVersion(backendVersion);
  };
  useEffect(() => {
    getBackendVersion();
  }, []);

  return (
    <Sider
      collapsible
      onCollapse={onCollapse}
      collapsed={collapsed}
      breakpoint="lg"
      id="dorNavSider"
    >
      <Menu mode="inline" theme="dark" selectedKeys={[matchMenuKey]}>
        {menuItems.map(({ path, icon, name, rolesAccess }) => {
          if (rolesAccess.includes(authContext.user.role))
            return (
              <Menu.Item key={path} icon={icon} className="display-flex-center">
                {name}
                <Link to={path} />
              </Menu.Item>
            );
        })}
      </Menu>
      {!collapsed && (
        <span className="sidebar-versions">
          Version: F-{version} | B-{backendVersion}
        </span>
      )}
    </Sider>
  );
};

export default SideBar;
