import { Roles } from "../config/role-config.json";
import DistributorUsersPage from "../pages/Distributor/DistributorUsersPage";
import DistributorUserForm from "../pages/Distributor/DistributorUserForm";
import DistributorEquipmentsPage from "../pages/Distributor/DistributorEquipmentsPage";
import DistributorEquipmentForm from "../pages/Distributor/DistributorEquipmentForm";
import DistributorTraysPage from "../pages/Distributor/DistributorTraysPage";
import DistributorTrayFrom from "../pages/Distributor/DistributorTrayForm";
import DistributorProcedurePage from "../pages/Distributor/DistributorProcedurePage";
import DistributorForm from "../pages/DistributorForm";
import DistributorHospitalsCasePage from "../pages/Distributor/DistributorHospitalsCasePage";
import CaseForm from "../shared-pages/read-only/hospital-and-cases/CasesForm";
import DistributorHospitalsPage from "../pages/Distributor/DistributorHospitalsPage";
import CreateNewProcedurePage from "../pages/Distributor/CreateNewProcedurePage";
import LiveCaseMainPage from "../shared-pages/read-only/mini-app/LiveCaseMainPage";
import ExecutionFlow from "../shared-pages/read-only/mini-app/ExecutionFlow";
import ProcedureMainPage from "../shared-pages/read-only/mini-app/ProcedureMainPage";
import TrayEquipmentPage from "../pages/MiniApp/TrayEquipmentPage";
import ExecutionFlowPage from "../pages/Distributor/ExecutionFlowPage";
import TableSetupPage from "../pages/MiniApp/TableSetupPage";
import { BreadCrumbData } from "./index.routes";

const distributorRoutes = {
  Routes: [
    /** ReadOnly Hospital Case */
    {
      path: "/distributor/:id/hospital/:hospitalid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="hospital" id={props.match.params.hospitalid} />
      ),
      component: DistributorHospitalsCasePage,
      redirection: "case",
    },
    {
      path: "/distributor/:id/hospital/:hospitalid/case/completed",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Completed",
      component: DistributorHospitalsCasePage,
    },
    {
      path: "/distributor/:id/hospital/:hospitalid/case/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorHospitalsCasePage,
    },
    {
      path: "/distributor/:id/hospital/:hospitalid/case/:caseid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Case Details",
      component: CaseForm,
    },
    {
      path: "/distributor/:id/hospital/:hospitalid/case",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Cases",
      component: DistributorHospitalsCasePage,
    },
    {
      path: "/distributor/:id/hospital",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Hospitals",
      component: DistributorHospitalsPage,
    },
    /** Distributor Users */
    {
      path: "/distributor/:id/user/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorUsersPage,
    },
    {
      path: "/distributor/:id/user/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN],
      exact: true,
      breadcrumb: "New Distributor User",
      component: DistributorUserForm,
    },
    {
      path: "/distributor/:id/user/:userid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.userid} {...props} />
      ),
      component: DistributorUserForm,
    },
    {
      path: "/distributor/:id/user",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN],
      exact: true,
      breadcrumb: "Distributor Users",
      component: DistributorUsersPage,
    },
    /** Distributor Equipments */
    {
      path: "/distributor/:id/equipment/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorEquipmentsPage,
    },
    {
      path: "/distributor/:id/equipment/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "New Instrument",
      component: DistributorEquipmentForm,
    },
    {
      path: "/distributor/:id/equipment/:equipmentid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Instrument Details",
      component: DistributorEquipmentForm,
    },
    {
      path: "/distributor/:id/equipment",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Instruments",
      component: DistributorEquipmentsPage,
    },
    /** Distributor Tray */
    {
      path: "/distributor/:id/tray/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorTraysPage,
    },
    {
      path: "/distributor/:id/tray/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "New Tray",
      component: DistributorTrayFrom,
    },
    {
      path: "/distributor/:id/tray/:trayid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Tray Details",
      component: DistributorTrayFrom,
    },
    {
      path: "/distributor/:id/tray",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Trays",
      component: DistributorTraysPage,
    },
    /** Procedure  */
    {
      path: "/distributor/:id/procedure",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Procedures",
      component: DistributorProcedurePage,
    },
    // for breadcrumb
    {
      path: "/distributor/:id/distributor-procedure",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Procedures",
      component: DistributorProcedurePage,
    },
    {
      path: "/distributor/:id/distributor-procedure/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      breadcrumb: "Deleted",
      component: DistributorProcedurePage,
    },
    {
      path: "/distributor/:id/setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: DistributorForm,
    },
  ],
  MiniAppRoutes: [
    /** Procedure */
    {
      path: "/distributor/:id/procedure/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: CreateNewProcedurePage,
    },
    {
      path: "/distributor/:id/procedure/:procedureid/table-setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: TableSetupPage,
    },
    {
      path: "/distributor/:id/procedure/:procedureid/tray-management",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: TrayEquipmentPage,
    },
    {
      path: "/distributor/:id/procedure/:procedureid/execution-flow/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/distributor/:id/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/distributor/:id/procedure/:procedureid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: CreateNewProcedurePage,
    },
    /** ReadOnly Hospital Case Procedure */
    {
      path: "/distributor/:id/hospital/:hospitalid/case/:caseid/procedure/:procedureid/table-setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: ProcedureMainPage,
    },
    {
      path: "/distributor/:id/hospital/:hospitalid/case/:caseid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: ExecutionFlow,
    },
    /** ReadOnly Hospital Live Case */
    {
      path: "/distributor/:id/hospital/:hospitalid/case/:caseid/live-case",
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
      exact: true,
      component: LiveCaseMainPage,
    },
  ],
};
export default distributorRoutes;
