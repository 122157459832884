import { Typography, Row, Col } from "antd";
import EmptyElement from "../../components/EmptyElement";
import CompletedTask from "../../assets/Images/CompletedTask.svg";

const { Text } = Typography;

const LastPage = () => {
  return (
    <EmptyElement image={CompletedTask} description={false}>
      <Row>
        <Col span={24}>
          <Text strong>Great! Hope, everything went well.</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text>That was the last one.</Text>
        </Col>
      </Row>
    </EmptyElement>
  );
};

export default LastPage;
