import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  Divider,
  Button,
  InputNumber,
  Empty,
  Spin,
  Space,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import SignedURLImage from "../../components/SignedURLImage";
import toastNotification from "../../components/toastNotification";
import Uploader from "../../components/Uploader";
import EquipmentModal from "../../components/EquipmentModal";
import TrayFormModal from "../../components/TrayFormModal";
import trayService from "../../services/trayService";
import distributorService from "../../services/distributorService";
import equipmentService from "../../services/equipmentService";
import mediaService from "../../services/mediaService";
import { ReactComponent as TagCloseIcon } from "../../assets/icons/iconTagClose.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/iconDelete.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../assets/icons/checkboxSelected.svg";
import { ReactComponent as ManufacturerColorIcon } from "../../assets/icons/colorIconsGreyManufacturers.svg";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import trayPlaceholder from "../../assets/Images/placeholder-image_trays.svg";
import { debounce } from "lodash";
import utilityService from "../../services/utilityService";

const { confirm } = Modal;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DistributorTrayFrom = (props) => {
  const {
    match: { params },
    history,
    location: { state },
  } = props;
  const customPageSize = 30;

  const { trayid: trayId, id: distributorId } = params;

  //TODO :: Check if invalid distributor id
  const pathToDistributorTrays = `/distributor/${distributorId}/tray`;

  const [tray, setTray] = useState(state?.record);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(state?.isPageReadOnly);
  const [imageName, setImageName] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);
  const [imageRequiredStatus, setImageRequiredStatus] = useState({
    validateStatus: "success",
    errorMsg: null,
  });
  const [equipment, setEquipment] = useState();
  const [showEquipmentModal, setEquipmentModal] = useState(false);
  const [showTrayModal, setShowTrayModal] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [manufacturerIds, setManufacturerIds] = useState([]);
  const [selectedEquipmentsId, setSelectedEquipmentsId] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [currentEquipmentPage, setCurrentEquipmentPage] = useState(1);
  const [equipmentCount, setEquipmentCount] = useState();
  const [equipmentSearchText, setEquipmentSearchText] = useState();
  const [loadEquipments, setLoadEquipments] = useState(false);

  const [form] = Form.useForm();

  const overlayMediaDelete = (
    <div className="icon-overlay">
      <Button
        icon={<DeleteIcon />}
        onClick={() => {
          deleteTrayImage();
        }}
        style={{ padding: 0 }}
      />
    </div>
  );

  const closeEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setEquipmentModal(false);
  };

  const customImageUpload = async (file) => {
    setImageLoading(true);
    let fileName;
    if (file.size > 30000000) {
      fileName = await mediaService.uploadMultipartImage(file);
    } else {
      fileName = await mediaService.uploadImageUsingPresignedUrl(file);
    }
    if (fileName) {
      setImageName(fileName);
      setImageRequiredStatus({
        validateStatus: "success",
        errorMsg: null,
      });
    } else {
      setImageName(null);
      setImageLoading(false);
      setImageRequiredStatus({
        validateStatus: "error",
        errorMsg: "Upload failed",
      });
    }
  };

  const onFinish = async (trayFormValues) => {
    setLoading(true);

    //Validate if tray image is missing
    if (imageName) {
      trayFormValues.image = imageName;
    } else trayFormValues.image = "";

    trayFormValues.distributor = distributorId;
    trayFormValues.equipments = selectedEquipments.map((trayEquipment) => ({
      equipment: trayEquipment.equipment.id,
      quantity: trayEquipment.quantity,
    }));

    //delete previously uploaded images if any
    for (const img of imagesToBeDeleted) {
      await mediaService.deleteMedia(img);
    }
    setImagesToBeDeleted([]);

    if (trayId) {
      trayFormValues.id = trayId;
      await editTray(trayFormValues, true);
    } else {
      await addTray(trayFormValues);
    }
    setLoading(false);
  };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Tray",
      content: "Are you sure you want to delete this tray?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await trayService.patchTray({
          id: trayId,
          isDeleted: true,
        });

        if (isDeleted) {
          toastNotification("success", "Tray deleted successfully");
          history.push(pathToDistributorTrays);
        } else {
          toastNotification("error", "Error deleting the tray");
        }
      },
    });
  };

  const pageHeaderProps = trayId
    ? {
        title: tray.trayName ? tray.trayName : "Tray Details",
        ...(tray?.distributor?.isManufacturer
          ? {}
          : {
              btnText: readOnly ? "Edit" : "Save",
              btnType: readOnly ? "Ghost" : "",
              topActionMenuItems: [
                {
                  title: "Delete Tray",
                  path: "",
                  onClick: () => {
                    showDeleteConfirmation(trayId);
                  },
                },
              ],
            }),
      }
    : {
        title: "New Tray",
        btnText: "Add",
      };

  /** Tray Equipments */

  const onEquipmentSelect = (equipmentId) => {
    // add id to selectedIds to maintain state of selected options
    const ids = [...selectedEquipmentsId];
    ids.push(equipmentId);
    setSelectedEquipmentsId(ids);

    // add equipment to selectedEquipments to add it to the displayed tag list
    const selectedEquipment = {
      equipment: equipmentsList.find(
        (equipment) => equipment.id === equipmentId
      ),
      quantity: 1,
    };
    selectedEquipments.push(selectedEquipment);
    setSelectedEquipments([...selectedEquipments]);
  };

  const onEquipmentDeselect = (equipmentId) => {
    // remove id from selectedIds to maintain state of selected options
    setSelectedEquipmentsId(
      selectedEquipmentsId.filter((id) => id !== equipmentId)
    );

    // remove equipment from selectedEquipments to remove it from the displayed tag list
    setSelectedEquipments(
      selectedEquipments.filter(
        (trayEquipment) => trayEquipment.equipment.id !== equipmentId
      )
    );
  };

  const logValue = debounce((searchText) => {
    setEquipmentSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadEquipments(true);
    setCurrentEquipmentPage(currentEquipmentPage + 1);
    await populateEquipmentsList(
      [distributorId, ...manufacturerIds],
      customPageSize,
      currentEquipmentPage * customPageSize,
      equipmentSearchText
    );
    setLoadEquipments(false);
  };

  const handleOpen = () => {
    setCurrentEquipmentPage(1);
  };

  const handleChange = (value) => {
    setCurrentEquipmentPage(0);
  };

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentEquipmentPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadEquipments && equipmentsList.length !== equipmentCount && addOptions();
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  };

  const onSearchEquipmentFunction = async (searchValue) => {
    setLoadEquipments(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setEquipmentSearchText(searchValue);
        await getSetEquipmentCountAndPopulate(
          [distributorId, ...manufacturerIds],
          searchValue
        );
        setLoadEquipments(false);
      }, 500)
    );
  };

  /** Tray Equipments End */

  /** API */
  const addTray = async (trayFormValues) => {
    // TODO :: Trim values before submit
    const res = await trayService.postTray(trayFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Tray added");
      history.push(pathToDistributorTrays);
    } else {
      toastNotification("error", "Error adding a new Tray");
    }
  };

  const editTray = async (trayFormValues) => {
    const res = await trayService.patchTray(trayFormValues);
    if (res.status === 200) {
      toastNotification("success", "Tray record updated");
      setReadOnly(true);
    } else {
      toastNotification("error", "Error updating the Tray");
    }
  };

  const fetchTray = async () => {
    const data = await trayService.getTray(distributorId, trayId);
    if (data) {
      setTray(data);
      return data;
    } else {
      toastNotification("error", "Requested Tray record not found");
      history.push(pathToDistributorTrays);
    }
  };

  const deleteTrayImage = async () => {
    setImageName(null);
    setImagesToBeDeleted((deleteArray) => {
      deleteArray.push(imageName);
      return [...deleteArray];
    });
    setImageLoading(false);
    return true;
  };

  const getDistributor = async () => {
    const distributor = await distributorService.getDistributor(
      distributorId,
      true,
      false
    );
    if (distributor) {
      return distributor;
    } else {
      toastNotification("error", "Requested Distributor record not found");
      return false;
    }
  };

  const getSetEquipmentCountAndPopulate = async (
    distributorIds,
    searchText
  ) => {
    const count = await equipmentService.getEquipmentCount(
      distributorIds,
      false,
      searchText
    );
    if (count !== false) {
      setEquipmentCount(count);
      setCurrentEquipmentPage(0);
      if (count > 0) {
        await populateEquipmentsList(
          distributorIds,
          customPageSize,
          0,
          searchText
        );
      } else {
        setEquipmentsList([]);
      }
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  const populateEquipmentsList = async (
    distributorIds,
    limit,
    skip,
    searchValue
  ) => {
    const distributorEquipments =
      await equipmentService.getDistributorEquipments(
        distributorIds,
        false,
        limit,
        skip,
        searchValue
      );

    if (distributorEquipments) {
      setLoadEquipments(distributorEquipments.length === customPageSize);
      const arr = [...equipmentsList, ...distributorEquipments];
      setEquipmentsList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
  };

  const fetchTrayEquipmentDetails = async (distributor) => {
    const trayEquipments = await trayService.getTrayEquipments(trayId);
    if (trayEquipments) {
      const equipmentIds = [];
      const newTrayEquipments = trayEquipments.map((te) => {
        equipmentIds.push(te.equipment.id);

        te.equipment.distributor =
          te.equipment.distributor === distributorId
            ? distributor
            : distributor.manufacturers?.find(
                (m) => m.id === te.equipment.distributor
              );
        return te;
      });

      setSelectedEquipments(newTrayEquipments);
      setSelectedEquipmentsId(equipmentIds);
    } else {
      toastNotification("error", "Error fetching Tray Instruments");
    }
  };
  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      let trayTemp;
      const distributor = await getDistributor();
      const filteredManufacturers = utilityService.filterSelectedEntity(
        distributor.manufacturers
      );
      const mIds = filteredManufacturers?.map((m) => m.id);
      setManufacturerIds(mIds);

      if (trayId) {
        trayTemp = tray ? tray : await fetchTray();
        if (trayTemp) {
          form.setFieldsValue(trayTemp);
          setImageName(trayTemp.image);
          await fetchTrayEquipmentDetails(distributor);
        }
      }
      if (!trayId || !trayTemp?.distributor?.isManufacturer) {
        getSetEquipmentCountAndPopulate([distributorId, ...mIds]);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      formName="distributorTrayAddEditForm"
      backBtnPath={pathToDistributorTrays}
      routeState={{
        pageNumber: state?.pageNumber,
        activeTabKey: state?.activeTabKey,
      }}
      onBtnClick={() => {
        if (readOnly) {
          setReadOnly(false);
        } else {
          form.submit();
        }
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Form
                    form={form}
                    name="distributorTrayAddEditForm"
                    id="distributorTrayAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Tray Details</Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Tray Name (Required)"
                          name="trayName"
                          rules={[
                            {
                              required: true,
                              message: "Please input tray name!",
                            },
                          ]}
                        >
                          <Input disabled={readOnly} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Tray Description" name="description">
                          <TextArea
                            disabled={readOnly}
                            style={{ resize: "none" }}
                            rows={4}
                            autoSize={false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Supported formats: jpeg, jpg, png, heic."
                          name="imageItem"
                          disabled={readOnly}
                        >
                          <div className="icon-overlay-container">
                            {imageName ? (
                              <div style={{ height: "12rem", width: "14rem" }}>
                                <SignedURLImage
                                  fileName={imageName}
                                  height="100%"
                                  width="100%"
                                  draggable="false"
                                  fallback={trayPlaceholder}
                                  onClick={() => setShowTrayModal(true)}
                                />
                                {!readOnly && overlayMediaDelete}
                              </div>
                            ) : (
                              <Uploader
                                id="uploadTray"
                                imageLoading={imageLoading}
                                customRequest={(file) =>
                                  customImageUpload(file)
                                }
                                editMode={readOnly ? true : false}
                              />
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  {tray?.distributor?.isManufacturer && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Divider />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2}>
                          <ManufacturerColorIcon />
                        </Col>
                        <Col>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Text type="secondary" style={{ fontSize: "12px" }}>
                              Created By
                            </Text>
                            <Text>{tray?.distributor?.distributorName}</Text>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Instruments</Title>
              {!tray?.distributor?.isManufacturer && (
                <Select
                  mode="multiple"
                  size="large"
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  autoClearSearchValue={false}
                  onSelect={onEquipmentSelect}
                  onDeselect={onEquipmentDeselect}
                  onDropdownVisibleChange={handleOpen}
                  onChange={handleChange}
                  onSearch={onSearchEquipmentFunction}
                  onPopupScroll={handleMenuScrollToBottom}
                  onInputKeyDown={handleInputChange}
                  filterOption={handleFilterOption}
                  tagRender={() => null}
                  value={selectedEquipmentsId}
                  disabled={readOnly}
                  menuItemSelectedIcon={<CheckboxSelectedIcon />}
                  notFoundContent={
                    <div
                      className="display-flex-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<b>No Records Found.</b>}
                      />
                    </div>
                  }
                >
                  {equipmentsList.map((equipment, index) => (
                    <Option key={equipment.id} title={equipment.equipmentName}>
                      <div className="display-flex-center">
                        <SignedURLImage
                          className="fit-image"
                          fileName={equipment.image[0]}
                          height="42px"
                          width="60px"
                          fallback={equipmentPlaceholder}
                          isTrayFormInstruments={true}
                        />
                        <Space
                          direction="vertical"
                          style={{ marginLeft: "10px" }}
                          size={2}
                        >
                          <Text ellipsis strong>
                            {equipment.equipmentName}
                          </Text>
                          <Text
                            ellipsis
                            style={{ color: "grey", fontSize: "12px" }}
                          >
                            {equipment.distributor.distributorName}
                          </Text>
                        </Space>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
              <div style={{ marginTop: "24px" }}>
                {selectedEquipments.map((trayEquipment, index) => (
                  <Row key={index} gutter={[0, 8]}>
                    <Col span={24}>
                      <Tag
                        color="#f5f5f5"
                        key={trayEquipment.id}
                        icon={
                          <SignedURLImage
                            onClick={() => {
                              setEquipment(trayEquipment.equipment);
                              setEquipmentModal(true);
                            }}
                            fallback={equipmentPlaceholder}
                            fileName={trayEquipment.equipment.image[0]}
                            height="50px"
                            width="75px"
                            className="fit-image"
                            isTrayFormInstruments={true}
                          />
                        }
                        closable={!readOnly}
                        closeIcon={<TagCloseIcon />}
                        onClose={() =>
                          onEquipmentDeselect(trayEquipment.equipment.id)
                        }
                        style={{ height: "60px" }}
                      >
                        <Row>
                          <Col
                            span={17}
                            offset={1}
                            className="display-flex-center"
                            style={{ width: "100%" }}
                          >
                            <Space direction="vertical" size={2}>
                              <Text
                                ellipsis
                                strong
                                style={{ fontSize: "14px", width: "14em" }}
                              >
                                {trayEquipment.equipment.equipmentName}
                              </Text>
                              <Text
                                ellipsis
                                style={{
                                  color: "grey",
                                  fontSize: "12px",
                                  width: "14em",
                                }}
                              >
                                {
                                  trayEquipment.equipment?.distributor
                                    ?.distributorName
                                }
                              </Text>
                            </Space>
                          </Col>
                          <Col
                            span={readOnly ? 4 : 6}
                            offset={readOnly ? 2 : 0}
                            className="display-flex-center"
                          >
                            {readOnly ? (
                              <Text>Qty: {trayEquipment.quantity}</Text>
                            ) : (
                              <InputNumber
                                disabled={readOnly}
                                style={{ width: "5em" }}
                                defaultValue={trayEquipment.quantity}
                                min={1}
                                max={100}
                                onChange={(value) => {
                                  trayEquipment.quantity = value;
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Tag>
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
            <Col span={1} />
          </Row>
        </div>
      </Spin>
      {showEquipmentModal && (
        <EquipmentModal
          show={showEquipmentModal}
          close={closeEquipmentModal}
          equipment={equipment}
        />
      )}
      {showTrayModal && (
        <TrayFormModal
          show={showTrayModal}
          close={() => setShowTrayModal(false)}
          tray={
            trayId
              ? tray
              : {
                  trayName: "New Tray",
                }
          }
          imageName={imageName}
        />
      )}
    </MainContainer>
  );
};

export default DistributorTrayFrom;
