import React, { Fragment, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Typography } from "antd";
import TrayModal from "./TrayModal";
import SignedURLImage from "./SignedURLImage";
import trayPlaceholder from "../assets/tray-placeholder.svg";

const { Paragraph } = Typography;

const ImageCard = (props) => {
  let { src, name, id, dragEnd, trayId } = props;

  const [trayModal, setTrayModal] = useState(false);

  const closeModal = () => {
    setTrayModal(false);
  };

  id = id.toString();
  const [{ isDragging }, drag, preview] = useDrag({
    item: { trayId, src, id, name, type: "IMAGE" },
    end: (item, monitor) => dragEnd(item, monitor),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const opacity = isDragging ? 0.4 : 1;
  return (
    <Fragment>
      <div
        id={id}
        ref={drag}
        style={{
          width: "100%",
          height: "180px",
          backgroundColor: "rgb(244,244,244)",
          opacity,
        }}
      >
        <SignedURLImage
          fileName={src}
          id={trayId}
          key={trayId}
          alt={name}
          onClick={() => {
            setTrayModal(true);
          }}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          fallback={trayPlaceholder}
        />
      </div>
      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
        <Paragraph
          style={{
            lineHeight: "1.3",
            marginBottom: "0",
          }}
          ellipsis={{ rows: 2 }}
        >
          {name}
        </Paragraph>
      </div>
      {trayModal ? (
        <TrayModal show={trayModal} close={closeModal} trayId={trayId} />
      ) : null}
    </Fragment>
  );
};

export default ImageCard;
