import React, { useEffect, useState } from "react";
import { ReactComponent as BackButtonIcon } from "../assets/icons/icon_back.svg";
import {
  Typography,
  Divider,
  Row,
  Col,
  List,
  Spin,
  Button,
  Modal,
  Empty,
} from "antd";
import DistributorServices from "../services/distributorService";
import EquipmentService from "../services/equipmentService";
import HospitalService from "../services/hospitalService";
import EquipmentCarousel from "./EquipmentCarousel";
import SignedURLImage from "./SignedURLImage";
import { ReactComponent as IconClose } from "../assets/icons/iconCloseModal.svg";
import equipmentPlaceholder from "../assets/equipment-placeholder.svg";

const { Paragraph, Text } = Typography;

const TrayModal = ({ show, close, trayId, viewFrom }) => {
  const [tray, setTray] = useState([]);
  const [trayFullView, setTrayFullView] = useState(false);
  const [equipmentFullView, setEquipmentFullView] = useState(false);
  const [equipmentOwnerDetails, setEquipmentOwnerDetails] = useState();
  const [equipmentId, setEquipmentId] = useState();
  const [loading, setLoading] = useState(false);
  const [onlyTray, setOnlyTray] = useState([]);

  const getTrayDetails = async () => {
    setLoading(true);
    const data = await DistributorServices.getTrayDetails(trayId);
    let equipmentIds = [];
    data.data.map((equipment) => {
      equipmentIds.push(equipment.equipment.id);
    });
    const equipmentWithOwnerDetails =
      await EquipmentService.getEquipmentsWithOwner(equipmentIds);
    const equipmentWithOwnerDetailsObject = {};
    equipmentWithOwnerDetails.map((equipment) => {
      equipmentWithOwnerDetailsObject[equipment.id] =
        equipment.distributor || equipment.regionalManager;
    });
    setEquipmentOwnerDetails(equipmentWithOwnerDetailsObject);
    const trayData = await DistributorServices.getTrayOwnerData(trayId);

    if (data?.status === 200) {
      // Get MFC name to display along with RM's tray name
      if (trayData.data.regionalManager && viewFrom == "hospital") {
        const mfcOfRegionalManager = await HospitalService.getManufacturers(
          JSON.stringify([trayData.data.regionalManager.id])
        );
        trayData.data.manufacturerName =
          mfcOfRegionalManager[0].manufacturer?.distributorName;
      } else if (trayData.data.regionalManager) {
        trayData.data.manufacturerName =
          trayData.data.regionalManager.title +
          " " +
          trayData.data.regionalManager.firstName +
          " " +
          trayData.data.regionalManager.middleName +
          " " +
          trayData.data.regionalManager.lastName;
      }
      setTray(data.data);
      setOnlyTray(trayData.data);
      setLoading(false);
    } else {
      setLoading(false);
      return false;
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrayDetails();
  }, []);

  const hideFullTrayView = () => {
    setTrayFullView(false);
  };

  return (
    <Modal
      wrapClassName="tray-pop-up"
      closeIcon={
        !loading && (
          <div
            div
            style={{ display: "flex", height: "100%", alignItems: "flex-end" }}
          >
            <IconClose />
          </div>
        )
      }
      destroyOnClose
      title={
        equipmentFullView ? (
          <Row>
            <Col span={1}>
              <Button
                type="link"
                onClick={() => {
                  const video = document.getElementById("videoEquipment");
                  if (video) {
                    video.pause();
                    video.currentTime = 0;
                  }
                  setEquipmentFullView(false);
                }}
                icon={<BackButtonIcon />}
              />
            </Col>
            <Col style={{ marginLeft: "20px" }}>
              <Row>
                <h3
                  style={{
                    color: "#f26524",
                    fontSize: "1.2em",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  {equipmentId.equipmentName}
                </h3>
              </Row>
              <Row
                style={{
                  fontSize: "0.8em",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {onlyTray?.trayName}
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={13}>
              <Row>
                <h3
                  style={{
                    color: "#f26524",
                    fontSize: "1.2em",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  {onlyTray?.trayName}
                </h3>
              </Row>
              <Row style={{ fontSize: "0.8em", color: "black" }}>
                {/* {!loading && tray.length >= 0 && tray.length}
                  {!loading ? tray.length > 1 ?  ` Instruments` : ` Instrument` : ""} */}
                {!loading &&
                  (onlyTray?.distributor?.distributorName ||
                    onlyTray?.manufacturerName)}
              </Row>
            </Col>
            <Col
              span={10}
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></Col>
          </Row>
        )
      }
      visible={show}
      onCancel={close}
      closable={loading ? false : true}
      footer={null}
      width={"90%"}
      centered={true}
    >
      <Spin spinning={loading}>
        {trayFullView ? (
          <div style={{ height: "100%" }}>
            <Row style={{ padding: "30px 20px", height: "70vh" }}>
              {onlyTray?.image && (
                <SignedURLImage
                  fileName={onlyTray?.image}
                  key={onlyTray?.image}
                  height="100%"
                  width="100%"
                  zoomable
                />
              )}
            </Row>

            <Row type="flex" align="middle">
              <Button
                htmlType="button"
                type="secondary"
                onClick={hideFullTrayView}
                style={{
                  borderRadius: 5,
                  margin: "0% 45% 0% 45%",
                  borderColor: "#000000",
                }}
              >
                Back to Instruments List
              </Button>
            </Row>
          </div>
        ) : equipmentFullView ? (
          <EquipmentCarousel equipment={equipmentId} showDescription={true} />
        ) : (
          <Row style={{ height: "100%" }}>
            <Col
              span={12}
              style={{
                paddingRight: "1em",
                paddingLeft: "24px",
                height: "100%",
              }}
            >
              <div style={{ paddingTop: "12px" }}>
                <Row style={{ height: "15rem" }}>
                  {onlyTray?.image && (
                    <SignedURLImage
                      fileName={onlyTray?.image}
                      key={onlyTray?.image}
                      height="100%"
                      width="100%"
                      style={{ objectFit: "contain" }}
                      onClick={() => setTrayFullView(true)}
                    />
                  )}
                </Row>
                {onlyTray?.description && (
                  <Row style={{ marginTop: "20px" }}>
                    <span style={{ color: "rgb(128,130,133)" }}>
                      Description
                    </span>
                  </Row>
                )}
                <Row style={{ textAlign: "justify" }}>
                  {onlyTray?.description}
                </Row>
              </div>
              <Divider className="divider-style" type="vertical" style={{}} />
            </Col>
            <Col span={12} style={{ height: "100%", overflow: "auto" }}>
              {tray.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={tray}
                  renderItem={(item, key) => (
                    <List.Item
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 10px 0 20px",
                        }}
                      >
                        {item.equipment ? (
                          <SignedURLImage
                            fileName={item.equipment.image[0]}
                            key={item.equipment.image[0]}
                            onClick={() => {
                              setEquipmentFullView(true);
                              setEquipmentId(item.equipment);
                            }}
                            height="45px"
                            width="60px"
                            className="fit-image"
                            fallback={equipmentPlaceholder}
                          />
                        ) : (
                          <Text style={{ color: "grey" }}>Not Set</Text>
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          <Paragraph
                            style={{
                              fontWeight: "bold",
                              lineHeight: "1.3",
                              marginBottom: "0",
                            }}
                            ellipsis={{ rows: 2 }}
                          >
                            {item.equipment ? (
                              item.equipment.equipmentName
                            ) : (
                              <Text style={{ color: "grey" }}>Not Set</Text>
                            )}
                          </Paragraph>
                          <Text style={{ color: "grey", fontSize: "0.8rem" }}>
                            {
                              /* {onlyTray?.distributor?.distributorName ||
                                  onlyTray?.manufacturerName}
                                   */
                              item.equipment && item.equipment.distributor
                                ? equipmentOwnerDetails[item.equipment.id]
                                    .distributorName
                                : item.equipment &&
                                  item.equipment.regionalManager &&
                                  viewFrom === "hospital"
                                ? onlyTray?.manufacturerName
                                : equipmentOwnerDetails[item.equipment.id]
                                    .title +
                                  " " +
                                  equipmentOwnerDetails[item.equipment.id]
                                    .firstName +
                                  " " +
                                  equipmentOwnerDetails[item.equipment.id]
                                    .middleName +
                                  " " +
                                  equipmentOwnerDetails[item.equipment.id]
                                    .lastName
                            }
                          </Text>
                        </div>
                        <span
                          style={{
                            backgroundColor: "rgb(254,239,232)",
                            marginRight: "20px",
                            alignSelf: "center",
                            padding: "3px 6px",
                          }}
                        >
                          Qty: {item.quantity}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  image={equipmentPlaceholder}
                  description={<></>}
                  imageStyle={{
                    height: "15em",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Col>
          </Row>
        )}
      </Spin>
    </Modal>
  );
};

export default TrayModal;
