import { useState, useEffect } from "react";
import { Typography, Modal, List, Row } from "antd";
import { Stream } from "@cloudflare/stream-react";
import ExecutionStepContainer from "../../containers/LiveCase/ExecutionStepContainer";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import EquipmentModal from "../../components/EquipmentModal";
import EquipmentImage from "../../components/EquipmentImage";
import mediaService from "../../services/mediaService";
import { ReactComponent as CaseCart } from "../../assets/icons/iconCaseCart.svg";

const { Paragraph } = Typography;

const SurgicalStep = ({ step }) => {
  const [isEquipmentModalVisible, setIsEquipmentModalVisible] = useState(false);
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false);
  const [tutorialVideoSignedToken, setTutorialVideoSignedToken] =
    useState(null);
  const [equipmentToView, setEquipmentToView] = useState();

  const getSectionNumber = (equipment) => {
    equipment.sectionNumber = [...new Set(equipment.sectionNumber)];
    equipment.sectionNumber.sort((a, b) => {
      if (!a) return 1;
      return a < b ? 1 : -1;
    });
    let equipmentSectionNumber = equipment.sectionNumber;
    if (
      equipment.sectionNumber.length > 1 &&
      isNaN(equipment.sectionNumber[equipment.sectionNumber.length - 1])
    ) {
      const cartSectionNumberArray = equipment.sectionNumber.slice(
        0,
        equipment.sectionNumber.length - 1
      );
      equipmentSectionNumber =
        cartSectionNumberArray.length < 4
          ? [-1, ...cartSectionNumberArray]
          : cartSectionNumberArray;
    }
    return equipmentSectionNumber.map((el, i) => {
      if (i < 4) {
        return (
          <span
            className={
              el > 0 ? "section-number" : "section-number-for-case-cart"
            }
          >
            {el > 0 ? <span>{el}</span> : <CaseCart />}
          </span>
        );
      }
    });
  };

  const onCloseEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setIsEquipmentModalVisible(false);
  };

  const openTutorialModel = () => {
    setIsTutorialModalVisible(true);
  };

  const closeTutorialModel = () => {
    setIsTutorialModalVisible(false);
  };

  const buttonText =
    step.video && step.description
      ? "See Description & Tutorial"
      : step.video && !step.description
        ? "See Tutorial"
        : !step.video && step.description
          ? "See Description"
          : "";

  useEffect(() => {
    (async () => {
      if (step.video?.result) {
        const signedToken = await mediaService.getVideoSignedToken(
          step.video.result.uid
        );
        setTutorialVideoSignedToken(signedToken);
      } else {
        setTutorialVideoSignedToken(null);
      }
    })();
  }, [step]);

  return (
    <>
      <ExecutionStepContainer
        phaseName={step.phase.phaseName}
        label={step.label}
        isCurrentStep={true}
        stepType="Surgical Step"
        onSeeTutorial={openTutorialModel}
        btnText={buttonText}
      >
        <List
          dataSource={step.stepEquipments}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          renderItem={(surgicalEquipment) => (
            <div className="live-case-surgical-step">
              <List.Item>
                <div className="equipment-image">
                  <div
                    style={{
                      zIndex: "2",
                      position: "absolute",
                      marginTop: "-10px",
                      marginLeft: "-5px",
                      display: "inline-flex",
                      justifyContent: "Center",
                      backgroundColor: "#f26524",
                      color: "white",
                      padding: "0 8px",
                      borderRadius: "10px",
                    }}
                  >
                    Qty: {surgicalEquipment.quantity}
                  </div>
                  <div
                    style={{
                      zIndex: "2",
                      position: "absolute",
                      right: "10px",
                      marginTop: "5px",
                      display: "inline-flex",
                    }}
                  >
                    {getSectionNumber(surgicalEquipment.equipment)}
                  </div>
                  <div
                    style={{
                      zIndex: "2",
                      position: "absolute",
                      right: "10px",
                      marginTop: "5px",
                      display: "inline-flex",
                    }}
                  >
                    {getSectionNumber(surgicalEquipment.equipment)}
                  </div>
                  <EquipmentImage
                    imageUrl={surgicalEquipment.equipment.image[0]}
                    videoUrl={surgicalEquipment.equipment.video[0]}
                    onClickHandler={() => {
                      setEquipmentToView(surgicalEquipment.equipment);
                      setIsEquipmentModalVisible(true);
                    }}
                    fallbackImage={equipmentPlaceholder}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Paragraph
                    strong
                    style={{ color: "white", margin: "8px 0 0" }}
                    ellipsis={{ rows: 2 }}
                  >
                    {surgicalEquipment.equipment.equipmentName}
                  </Paragraph>
                </div>
              </List.Item>
            </div>
          )}
        />
      </ExecutionStepContainer>
      {isEquipmentModalVisible && (
        <EquipmentModal
          close={onCloseEquipmentModal}
          show={isEquipmentModalVisible}
          equipment={equipmentToView}
        />
      )}
      <Modal
        title={
          <>
            <Row>
              <b>
                {step.video && step.description
                  ? "Description & Tutorial"
                  : step.video && !step.description
                    ? "Tutorial"
                    : !step.video && step.description
                      ? "Description"
                      : ""}
              </b>
            </Row>
            {step.description && (
              <Row>
                <Paragraph style={{ margin: "8px 0 0", fontSize: "14px" }}>
                  {step.description}
                </Paragraph>
              </Row>
            )}
          </>
        }
        visible={isTutorialModalVisible}
        onCancel={closeTutorialModel}
        footer={null}
        width={1000}
        centered
        destroyOnClose={true}
        bodyStyle={{
          borderRadius: "0 0 20px 20px",
          display: !step.video || !tutorialVideoSignedToken ? "none" : "block",
        }}
        className={
          tutorialVideoSignedToken
            ? "tutorial-description-surgical-modal"
            : "only-description-surgical-modal"
        }
      >
        {step.video && tutorialVideoSignedToken && (
          <div id="iframe-cover" style={{ height: "65vh" }}>
            <Stream controls src={tutorialVideoSignedToken} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default SurgicalStep;
