import EquipmentForm from "../../shared-pages/manufacturer/EquipmentForm";

const AreaEquipmentForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { equipmentid: equipmentId, areaid: areaId } = params;

  return (
    <EquipmentForm
      entityId={areaId}
      entityName="area"
      equipmentId={equipmentId}
      equipment={state?.record}
    />
  );
};

export default AreaEquipmentForm;
