import { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { Row, Col, Button, Empty, Typography, Spin } from "antd";
import toastNotification from "../../../components/toastNotification";
import distributorService from "../../../services/distributorService";
import hospitalService from "../../../services/hospitalService";
import { ReactComponent as ProcedureColorIcon } from "../../../assets/icons/color-icons_procedure.svg";
import noProcedure from "../../../assets/icons/hospital-case/Empty-state-Surgery.png";

const { Text } = Typography;

const CaseProcedureForm = ({ hospitalCase }) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const [caseProcedureDistributor, setCaseProcedureDistributor] = useState();
  const [loading, setLoading] = useState(false);

  const getCaseProcedureDistributor = async (hospitalCase) => {
    setLoading(true);
    const data = await distributorService.getProcedureDistributor(
      hospitalCase.procedure.clonedFrom
    );
    if (data) {
      const result = data.regionalManager?.id && await hospitalService.getManufacturers(JSON.stringify([data.regionalManager.id]));
      data.distributorName = result?.length ? result[0].manufacturer.distributorName : null
      setCaseProcedureDistributor(data);
    } else {
      toastNotification("error", "Error fetching the Procedure details");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hospitalCase?.procedure) getCaseProcedureDistributor(hospitalCase);
  }, [hospitalCase]);

  return (
    <Spin spinning={loading}>
      {hospitalCase?.procedure ? (
        <Row>
          <Col span={14} offset={5}>
            <div
              style={{
                borderRadius: "15px",
                border: "2px solid #f4f4f4",
                padding: "20px",
                marginTop: "25px",
              }}
            >
              <Row justify="space-between">
                <Col className="display-flex-center">
                  <ProcedureColorIcon fill="#feefe8" />
                  <div style={{ marginLeft: "16px" }}>
                    <Row>
                      <Col span={24}>
                        <Text strong>
                          {hospitalCase.procedure.procedureName}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Text style={{ fontSize: "12px" }}>
                          {caseProcedureDistributor?.regionalManager
                            ? `by "${caseProcedureDistributor.distributorName}"`
                            : caseProcedureDistributor?.distributor
                              ? `by "${caseProcedureDistributor.distributor.distributorName}"`
                              : ""}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col className="display-flex-center">
                  <Button
                    onClick={() => {
                      history.push(
                        `${url}/procedure/${hospitalCase.procedure.id}/table-setup`,
                        {
                          pageNumber: location.state?.pageNumber,
                          caseType: location.state?.caseType
                        }
                      );
                    }}
                  >
                    View
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      ) : (
          hospitalCase &&
          !hospitalCase.procedure && (
            <Empty
              image={noProcedure}
              imageStyle={{ alignItems: "centre", marginTop: "10%" }}
              description={
                <span>
                  <Text>No procedure attached yet.</Text>
                </span>
              }
            />
          )
        )}
    </Spin>
  );
};

export default CaseProcedureForm;
