import { useEffect, useState } from "react";
import { Image, Spin } from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import mediaService from "../services/mediaService";

const SignedURLImage = (props) => {
  const { fileName, zoomable, fallback } = props;
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    (async () => {
      if (fileName) {
        const url = await mediaService.loadImage(fileName);
        setImageUrl(url);
      } else {
        setImageUrl(fallback);
      }
    })();
  }, []);

  return zoomable ? (
    <TransformWrapper>
      {/* {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <div className="tools">
            <button onClick={() => zoomIn()}>+</button>
            <button onClick={() => zoomOut()}>-</button>
            <button onClick={() => resetTransform()}>x</button>
          </div> */}
      <TransformComponent>
        <Image
          {...props}
          src={imageUrl}
          placeholder={
            <Spin spinning={true} wrapperClassName="img-loader"></Spin>
          }
          preview={false}
        />
      </TransformComponent>
      {/* </>
      )} */}
    </TransformWrapper>
  ) : (
    <Image
      {...props}
      src={imageUrl}
      placeholder={
        <Spin
          spinning={true}
          wrapperClassName={
            props.isTrayFormInstruments ? "instrument-list-image" : "img-loader"
          }
        ></Spin>
      }
      preview={false}
    />
  );
};

export default SignedURLImage;
