import React, { useState, useEffect } from "react";
import { Row, Spin } from "antd";
import Carousel from "@brainhubeu/react-carousel";
import SignedURLImage from "./SignedURLImage";
import VideoStream from "./VideoStream";
import mediaService from "../services/mediaService";
import { ReactComponent as ArrowLeft } from "../assets/icons/leftArrow.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/rightArrow.svg";
import equipmentPlaceholder from "../assets/equipment-placeholder.svg";
import "@brainhubeu/react-carousel/lib/style.css";

const EquipmentCarousel = ({ equipment, showDescription, slideIndex }) => {
  const [value, setValue] = useState(slideIndex ? slideIndex : 0);
  const [videoSignedTokens, setVideoSignedTokens] = useState([]);
  const [images, setImages] = useState([]);
  const [streamRefs, setStreamRefs] = useState([]);
  const [loading, setLoading] = useState(false);

  function onChange(value) {
    for (const ref of streamRefs) {
      if (ref.current) ref.current.pause();
    }
    setValue(value);
  }

  const renderCarouselItems = () => {
    const items = [];

    if (equipment.image?.length > 0) {
      for (const image of images) {
        items.push(
          <div style={{ width: "100%", height: "100%" }}>
            <SignedURLImage
              fileName={image}
              key={image}
              zoomable
              fallback={equipmentPlaceholder}
            />
          </div>
        );
      }
    }

    if (equipment.video?.length > 0) {
      for (const token of videoSignedTokens) {
        items.push(
          <div id="iframe-cover" style={{ height: "100%" }}>
            <VideoStream
              src={token}
              addRefs={(ref) => {
                streamRefs.push(ref);
                setStreamRefs([...streamRefs]);
              }}
            />
          </div>
        );
      }
    }
    return items;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      let imageArray = [];
      if (equipment.image && Array.isArray(equipment.image)) {
        for (const image of equipment.image) {
          imageArray.push(image);
        }
      } else if (equipment.image) {
        imageArray.push(equipment.image);
      }

      setImages(imageArray);

      let signedTokens = [];
      if (equipment.video && Array.isArray(equipment.video)) {
        for (const videoResult of equipment.video) {
          signedTokens.push(
            await mediaService.getVideoSignedToken(videoResult.result.uid)
          );
        }
      } else if (equipment.video) {
        signedTokens.push(
          await mediaService.getVideoSignedToken(equipment.video.result.uid)
        );
      }
      setVideoSignedTokens(signedTokens);
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      {showDescription && (
        <Row style={{ textAlign: "justify", margin: "0 24px 1em 24px" }}>
          {equipment.description && (
            <span style={{ color: "rgb(128,130,133)" }}>Description</span>
          )}
          <div style={{ width: "100%", paddingRight: "50px" }}>
            {equipment.description}
          </div>
        </Row>
      )}
      {/* <Spin spinning={loading}> */}
      <Row style={{ height: "65vh" }} id="dor-pop-up-carousel">
        {equipment.video && (
          <Carousel
            value={value}
            onChange={onChange}
            addArrowClickHandler={true}
            arrowLeft={value ? <ArrowLeft /> : null}
            arrowRight={
              value < equipment.image?.length + equipment.video?.length - 1 ? (
                <ArrowRight />
              ) : null
            }
            slidesPerPage={1}
          >
            {renderCarouselItems()}
          </Carousel>
        )}
        {!equipment.video && (
          <SignedURLImage
            fileName={equipment.image[0]}
            key={equipment.image[0]}
            zoomable
            fallback={equipmentPlaceholder}
          />
        )}
      </Row>
      {/* </Spin> */}
    </div>
  );
};

export default EquipmentCarousel;
