import { Row, Col, Modal } from "antd";
import SignedURLImage from "./SignedURLImage";
import trayPlaceholder from "../assets/tray-placeholder.svg";

const TrayModal = ({ close, tray, imageName }) => {
  return (
    <Modal
      wrapClassName="tray-pop-up"
      destroyOnClose
      title={
        <Row>
          <Col span={13}>
            <Row>
              <h3
                style={{
                  color: "#f26524",
                  fontSize: "1.2em",
                  marginBottom: "0",
                  fontWeight: "bold",
                }}
              >
                {tray?.trayName}
              </h3>
            </Row>
            <Row style={{ fontSize: "0.8em", color: "black" }}>
              {tray?.distributor?.distributorName}
            </Row>
          </Col>
        </Row>
      }
      centered={true}
      visible={true}
      footer={null}
      closable={true}
      onCancel={close}
      width="90%"
    >
      <div style={{ height: "100%" }}>
        <Row style={{ padding: "30px 20px", height: "70vh" }}>
          {imageName && (
            <SignedURLImage
              fileName={imageName}
              key={imageName}
              height="100%"
              width="100%"
              zoomable
              fallback={trayPlaceholder}
            />
          )}
        </Row>
      </div>
    </Modal>
  );
};

export default TrayModal;
