import http from "./httpService";

async function getNeededEquipments(procedureid) {
  try {
    const res = await http.get(
      `procedure/${procedureid}/neededEquipmentForStep/`
    );
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

/** Create Phase Api */

async function postPhase(phaseData) {
  try {
    return await http.post("/phase", phaseData);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
/** Create Steps Api */

async function postStep(stepData) {
  try {
    return await http.post("/step", stepData);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
async function postExecutionFlow(procedureid, data) {
  try {
    return await http.post(`/procedure/${procedureid}/executionFlow`, data);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getExecutionFlow(procedureid, isDeleted) {
  try {
    const res = await http.get(`/procedure/${procedureid}/getExecutionFlow`);
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function postItem(data) {
  try {
    return await http.post(`/item`, data);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
async function deletePhase(id) {
  try {
    await http.patch(`/phase/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function deleteStep(id) {
  try {
    await http.patch(`/step/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function deleteCheckListItem(id) {
  try {
    await http.patch(`/item/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
/** Clear execution flow Api  */
async function clearExecutionFlow(procedureid) {
  try {
    const res = await http.post(`/procedure/${procedureid}/clear`, {
      isDeleted: true,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function putPhase(phaseData, id) {
  try {
    return await http.patch(`/phase/${id}`, phaseData);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function putStep(stepData, id) {
  try {
    return await http.patch(`/step/${id}`, stepData);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
/** Get Equipment Api call */

async function getStepsEquipment(stepId) {
  try {
    const res = await http.get(`/stepequipment/`, {
      params: {
        step: stepId,
        populate: "equipment",
        isDeleted: false,
        limit: 1000,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
}
/** Get checklist item */
async function getCheckListItem(id) {
  try {
    const res = await http.get(`/item/`, {
      params: {
        step: id,
        isDeleted: false,
        limit: 1000,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
}
/**  Update Checklist item */

async function updateCheckListItem(data, stepId) {
  try {
    const res = await http.patch(`/item/${stepId}`, data);
    return res;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
async function getSearchedEquipments(searchValue, procedureid) {
  try {
    const res = await http.get(
      `procedure/${procedureid}/neededEquipmentForStep/`,
      {
        params: {
          where: {
            equipmentName: { contains: searchValue },
            isDeleted: false,
          },
          sort: "createdAt DESC",
        },
      }
    );
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}
export default {
  getNeededEquipments,
  postPhase,
  postStep,
  postExecutionFlow,
  getExecutionFlow,
  postItem,
  deletePhase,
  deleteCheckListItem,
  deleteStep,
  clearExecutionFlow,
  putPhase,
  putStep,
  getStepsEquipment,
  getCheckListItem,
  updateCheckListItem,
  getSearchedEquipments,
};
