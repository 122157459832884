import { Table, Empty, Typography } from "antd";
import { ReactComponent as NoRecordImage } from "../assets/Images/NoRecord.svg";

const { Text } = Typography;
const ContentTable = (props) => {
  const { data, columnsList, pagination, loading } = props;
  return (
    <Table
      dataSource={data}
      columns={columnsList}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        responsive: true,
        ...pagination,
      }}
      pageSize="10"
      locale={{
        emptyText: (
          <Empty
            image={<NoRecordImage />}
            description={<Text strong> No records found. </Text>}
          />
        ),
      }}
      scroll={{ x: true }}
      loading={loading}
      sticky
      className="dor-content-table"
    />
  );
};

export default ContentTable;
