import { useEffect, useState } from "react";
import { List, Checkbox } from "antd";
import ExecutionStepContainer from "../../containers/LiveCase/ExecutionStepContainer";

const CheckListPreview = ({ step }) => {
  const [checklistItems, setChecklistItems] = useState();

  useEffect(() => {
    setChecklistItems(step.checklistItems);
  }, [step]);

  return (
    <ExecutionStepContainer
      phaseName={step.phase.phaseName}
      label={step.label}
      isCurrentStep={false}
      stepType="Checklist"
    >
      <div style={{ marginLeft: "8px" }}>
        {checklistItems && (
          <List
            dataSource={checklistItems}
            split={false}
            renderItem={(checklistItem) => {
              return (
                <List.Item>
                  <Checkbox
                    disabled
                    value={checklistItem.id}
                    defaultChecked={checklistItem.isChecked}
                  >
                    {checklistItem.name}
                  </Checkbox>
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </ExecutionStepContainer>
  );
};

export default CheckListPreview;
