import http from "./httpService";

async function getMMRUsers(
  regionalManagerId,
  isDeleted,
  role,
  limit,
  skip,
  searchValue
) {
  let api;
  let params = {
    limit,
    skip,
    sort: "createdAt DESC",
  };

  if (searchValue) {
    api = "/user/search";
    params.where = {
      or: [
        { firstName: { contains: searchValue } },
        { email: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
      ],
      regionalManager: regionalManagerId,
      isDeleted,
      role,
    };
  } else {
    api = "/user";
    params = {
      regionalManager: regionalManagerId,
      role,
      isDeleted,
      ...params,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

const getRegionalManagers = async (
  areaId,
  userRoles,
  isDeleted,
  limit,
  skip,
  searchValue
) => {
  let api;
  let params = {
    where: {
      area: areaId,
      role: { in: userRoles },
      isDeleted,
    },
    limit,
    skip,
  };

  if (searchValue) {
    api = "/user/search";
    params.where.or = [
      { firstName: { contains: searchValue } },
      { middleName: { contains: searchValue } },
      { lastName: { contains: searchValue } },
      { email: { contains: searchValue } },
    ];
  } else {
    api = "/user";
    params.sort = "createdAt DESC";
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
};

async function getRegionalManagerHospitals(regionalManagerId) {
  const res = await http.dorAxiosGet(`user/${regionalManagerId}/hospital`, {
    limit: 1000,
    sort: "createdAt DESC",
    isDeleted: false
  });
  return res ? res.data : false;
}

export default {
  getMMRUsers,
  getRegionalManagers,
  getRegionalManagerHospitals,
};
