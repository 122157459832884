import ProceduresPage from "../../shared-pages/manufacturer/ProceduresPage";

const RMProceduresPage = ({ match }) => {
  const { id, rmid } = match.params;

  return <ProceduresPage 
    manufacturerId={id}
    url={match.url}
    hasOwnLibrary
    entityId={rmid}
    entityName="regionalManager" />;
};

export default RMProceduresPage;
