import { Typography, Select, Tag, Empty, Row, Col, Image, Spin } from "antd";
import EmptyDiv from "./EmptyDiv";
import { ReactComponent as TagCloseIcon } from "../assets/icons/iconTagClose.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../assets/icons/checkboxSelected.svg";
import { useState } from "react";

const { Text } = Typography;
const { Option } = Select;

const AssignmentSelection = ({
  selectedEntityIds,
  emptyText,
  emptyIcon,
  dataSource,
  selectedEntities,
  entityTitleProperty,
  optionIcon: OptionIcon,
  onSelect,
  onDeselect,
  onSearch,
  onDropdownVisibleChange,
  onChange,
  onPopupScroll,
  onInputKeyDown,
  filterOption,
  tagIcon: TagIcon,
  editMode,
  showOptionsLoader,
  showOnlyTags,
  placeholder,
}) => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(true);

  return (
    <>
      {!showOnlyTags && editMode && (
        <div className="assignment-select-wrapper">
          <Select
            mode="multiple"
            size="large"
            style={{ width: "100%" }}
            showSearch
            allowClear
            autoClearSearchValue={false}
            tagRender={() => null}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onDropdownVisibleChange={onDropdownVisibleChange}
            onChange={onChange}
            onSearch={onSearch}
            onPopupScroll={onPopupScroll}
            onInputKeyDown={onInputKeyDown}
            filterOption={filterOption}
            value={selectedEntityIds}
            onKeyUp={(e) => {
              setShowPlaceHolder(e.target.value?.length === 0);
            }}
            menuItemSelectedIcon={<CheckboxSelectedIcon />}
            notFoundContent={
              !showOptionsLoader && (
                <div
                  className="display-flex-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<b>No Records Found.</b>}
                  />
                </div>
              )
            }
          >
            {dataSource.map((entity) => (
              <Option key={entity.id} title={entity[entityTitleProperty]}>
                <div className="display-flex-center">
                  {typeof OptionIcon == "function" ? (
                    <Image
                      src={OptionIcon(entity)}
                      placeholder={
                        <Spin
                          spinning={true}
                          wrapperClassName="img-loader"
                        ></Spin>
                      }
                      preview={false}
                    />
                  ) : (
                    <OptionIcon />
                  )}
                  <Text style={{ marginLeft: "7px" }} ellipsis>
                    {entity[entityTitleProperty]}
                  </Text>
                </div>
              </Option>
            ))}
            {showOptionsLoader && (
              <Option value="loading" disabled>
                Loading more...
              </Option>
            )}
          </Select>
          <div
            className={`multi-select-placeholder ${
              showPlaceHolder ? "" : "multi-select-placeholder--hide"
            }`}
          >
            {placeholder ?? "Search"}
          </div>
        </div>
      )}
      {selectedEntities?.length ? (
        <div className="custom-tag-list-item">
          {selectedEntities.map((entity, index) => (
            <Row key={index} gutter={[0, 8]}>
              <Col span={24}>
                {typeof TagIcon == "function" ? (
                  <Tag
                    color="#f5f5f5"
                    key={entity.value}
                    closable={editMode}
                    closeIcon={<TagCloseIcon />}
                    onClose={() => onDeselect(entity.id)}
                  >
                    <Image
                      entity={entity}
                      src={TagIcon(entity)}
                      style={{
                        width: "2.5em",
                        height: "2.5em",
                      }}
                      placeholder={
                        <Spin
                          spinning={true}
                          wrapperClassName="img-loader"
                        ></Spin>
                      }
                      preview={false}
                    />
                    <Text style={{ marginLeft: "0.4em" }}>
                      {entity[entityTitleProperty]}
                    </Text>
                  </Tag>
                ) : (
                  <Tag
                    color="#f5f5f5"
                    key={entity.value}
                    icon={
                      <TagIcon
                        style={{ width: "2.5em", height: "2.5em" }}
                        entity={entity}
                      />
                    }
                    closable={editMode}
                    closeIcon={<TagCloseIcon />}
                    onClose={() => onDeselect(entity.id)}
                  >
                    <Text style={{ marginLeft: "0.4em" }}>
                      {entity[entityTitleProperty]}
                    </Text>
                  </Tag>
                )}
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <EmptyDiv emptyText={emptyText} emptyImage={emptyIcon} />
      )}
    </>
  );
};

export default AssignmentSelection;
