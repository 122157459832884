import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  Spin,
} from "antd";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import areaServices from "../../services/areaService";
import utilityService from "../../services/utilityService";
import { Roles, RolesDisplay } from "../../config/role-config.json";

const { confirm } = Modal;
const { Text, Title } = Typography;

const AreaUserForm = (props) => {
  const { match, history, location } = props;
  const {
    userid: areaUserId,
    areaid: areaId,
    id: manufacturerId,
  } = match.params;

  const pathToAreaUsers = `/manufacturer/${manufacturerId}/area/${areaId}/user`;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [areaUser, setAreaUser] = useState(location.state?.record);
  const [editMode, setEditMode] = useState(
    !areaUserId || location.state?.editMode
  );

  const pageHeaderProps = areaUserId
    ? {
      title: areaUser
        ? [areaUser.firstName, areaUser.middleName, areaUser.lastName].join(
          " "
        )
        : "Edit Area User",
      btnText: editMode ? "Save" : "Edit",
      btnType: editMode ? "primary" : "secondary",
      topActionMenuItems: [
        {
          title: "Delete Area User",
          path: "",
          onClick: () => {
            showDeleteConfirmation(areaUserId);
          },
        },
      ],
    }
    : {
      title: "New Area User",
      btnText: "Add",
    };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Area User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await areaServices.deleteUser(areaUserId);
        if (isDeleted) {
          toastNotification("success", "Area User deleted successfully");
          history.push(pathToAreaUsers);
        } else {
          toastNotification("error", "Error deleting the User");
        }
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const addAreaUser = async (areaUserFormValues) => {
    areaUserFormValues.password = "password123";
    areaUserFormValues.area = areaId;

    const res = await areaServices.postAreaUser(areaUserFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Area User added");
      history.push(pathToAreaUsers);
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new User record");
    }
  };

  const editAreaUser = async (areaUserFormValues) => {
    areaUserFormValues.id = areaUserId;

    const res = await areaServices.patchAreaUser(areaUserFormValues);

    if (res.status === 200) {
      toastNotification("success", "Area User Record Updated");
      history.push(pathToAreaUsers, {
        pageNumber: location.state?.pageNumber
      });
    } else {
      toastNotification("error", "Error updating the user record");
    }
  };

  const getAreaUser = async () => {
    const data = await areaServices.getAreaUser(areaUserId);
    if (data) {
      setAreaUser(data);
      return data;
    } else {
      toastNotification("error", "Requested user record not found");
    }
  };

  const onFinish = async (areaUserFormValues) => {
    setLoading(true);
    if (areaUserId) {
      await editAreaUser(areaUserFormValues);
    } else {
      await addAreaUser(areaUserFormValues);
    }
    setLoading(false);
  };

  const sendActivationLink = async (e) => {
    const res = await areaServices.postResendUserActivationLink(areaUser.email);
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (areaUserId) {
        let areaUserTemp = areaUser;
        if (!areaUserTemp) {
          areaUserTemp = await getAreaUser();
        }
        form.setFieldsValue({ ...areaUserTemp });
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="areaUserAddEditForm"
        backBtnPath={pathToAreaUsers}
        routeState={{
          pageNumber: location.state?.pageNumber
        }}
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row align="middle">
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 14, offset: 5 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Form
              form={form}
              name="areaUserAddEditForm"
              id="areaUserAddEditForm"
              size="large"
              colon="false"
              scrollToFirstError="true"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
            >
              <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                  <Title level={5}>Details</Title>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Email (Required)"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid Email!",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: david@mail.com"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Role (Required)"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a role!",
                      },
                    ]}
                  >
                    <Select placeholder="Select a role" disabled={!editMode}>
                      <Select.Option value={Roles.AREA_MANAGER}>
                        {RolesDisplay.AREA_MANAGER}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="First Name" name="firstName">
                    <Input
                      placeholder="Example: Chadwick"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item label="Middle Name" name="middleName">
                    <Input placeholder="Example: Joseph" disabled={!editMode} />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="Last Name" name="lastName">
                    <Input
                      placeholder="Example: Boseman"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (203) 683-3272"
                      id="mobileNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item
                    label="Office Number"
                    name="officeNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (966) 922-5419"
                      id="officeNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {areaUser && !areaUser.isActive && (
                    <Tag
                      color="gold"
                      icon={<MailFilled color="#db9e2e" />}
                      className="activation-link"
                    >
                      <Row>
                        <Col span={8}>
                          <Text> User activation is pending </Text>
                        </Col>
                        <Col span={8} offset={8} style={{ textAlign: "end" }}>
                          <a
                            style={{ color: "inherit" }}
                            onClick={(e) => {
                              sendActivationLink(e);
                            }}
                          >
                            Resend Invite Link
                          </a>
                        </Col>
                      </Row>
                    </Tag>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default AreaUserForm;
