import TableSetupPage from "../../shared-pages/editable-mini-app/TableSetupPage";

const RMTableSetup = (props) => {
    const {
    match: { params },
    location: { state },
  } = props;

  const { procedureid: procedureId, rmid: rmId, id } = params;

  return (
    <TableSetupPage
      entityId={rmId}
      entityName="regionalManager"
      procedureId={procedureId}
      manufacturerId={id}
      state={state}
    />
  );
};

export default RMTableSetup;
