import { Roles } from "../config/role-config.json";
import AreaEquipmentsPage from "../pages/Area/AreaEquipmentsPage";
import AreaTraysPage from "../pages/Area/AreaTraysPage";
import AreaEquipmentForm from "../pages/Area/AreaEquipmentForm";
import AreaTrayForm from "../pages/Area/AreaTrayForm";
import AreaProceduresPage from "../pages/Area/AreaProceduresPage";
import ManufacturerAreaForm from "../pages/Manufacturer/ManufacturerAreaForm";
import AreaUsersPage from "../pages/Area/AreaUsersPage";
import AreaUserForm from "../pages/Area/AreaUserForm";
import RegionalManagerForm from "../pages/Area/RegionalManagerForm";
import RegionalManagersPage from "../pages/Area/RegionalManagersPage";
import ProcedureMainPage from "../shared-pages/read-only/mini-app/ProcedureMainPage";
import ExecutionFlow from "../shared-pages/read-only/mini-app/ExecutionFlow";
import LiveCaseMainPage from "../shared-pages/read-only/mini-app/LiveCaseMainPage";
import CaseForm from "../shared-pages/read-only/hospital-and-cases/CasesForm";
import AreaHospitalsCasePage from "../pages/Area/AreaHospitalsCasePage";
import AreaHospitalsPage from "../pages/Area/AreaHospitalsPage";
import { BreadCrumbData } from "./index.routes";
import AreaProcedureForm from "../pages/Area/AreaProcedureForm";
import AreaTableSetup from "../pages/Area/AreaTableSetup";
import AreaTrayEquipmentPage from "../pages/Area/AreaTrayEquipmentPage";
import AreaExecutionFlowPage from "../pages/Area/AreaExecutionFlowPage";

const areaRoutes = {
  Routes: [
    /** ReadOnly Hospital and Cases */
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/completed",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Completed",
      component: AreaHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      breadcrumb: "Deleted",
      component: AreaHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/:caseid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Case Details",
      component: CaseForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Cases",
      component: AreaHospitalsCasePage,
    },
    //for breadcrumb
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="hospital" id={props.match.params.hospitalid} />
      ),
      redirection: "case",
    },
    {
      path: "/manufacturer/:id/area/:areaid/hospital",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Hospitals",
      component: AreaHospitalsPage,
    },
    /** EQUIPMENTS */
    {
      path: "/manufacturer/:id/area/:areaid/equipment/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: AreaEquipmentsPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/equipment/:equipmentid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Instrument Details",
      component: AreaEquipmentForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/equipment",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Instruments",
      component: AreaEquipmentsPage,
    },
    /** TRAYS */
    {
      path: "/manufacturer/:id/area/:areaid/tray/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: AreaTraysPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/tray/:trayid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Tray Details",
      component: AreaTrayForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/tray",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Trays",
      component: AreaTraysPage,
    },
    /** PROCEDURES */
    {
      path: "/manufacturer/:id/area/:areaid/procedure-library/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: AreaProceduresPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/procedure",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Procedures",
      component: AreaProceduresPage,
    },
    /** Area Users */
    {
      path: "/manufacturer/:id/area/:areaid/user/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: AreaUsersPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/user/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "New Area User",
      component: AreaUserForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/user/:userid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.userid} {...props} />
      ),
      component: AreaUserForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/user",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Area Users",
      component: AreaUsersPage,
    },
    /** Regional Managers */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: RegionalManagersPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "New Regional Manager",
      component: RegionalManagerForm,
    },
    // For Breadcrumb
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
      ],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.rmid} />
      ),
      redirection: "setup",
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      breadcrumb: "Regional Managers",
      component: RegionalManagersPage,
    },
    /** Setup */
    {
      path: "/manufacturer/:id/area/:areaid/setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: ManufacturerAreaForm,
    },
  ],
  MiniAppRoutes: [
    /** Procedures */
    {
      path: "/manufacturer/:id/area/:areaid/procedure/:procedureid/table-setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: AreaTableSetup,
    },
    {
      path: "/manufacturer/:id/area/:areaid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: AreaExecutionFlowPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/procedure/:procedureid/execution-flow/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: AreaExecutionFlowPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/procedure/:procedureid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN, Roles.AREA_MANAGER],
      exact: true,
      breadcrumb: "Procedure Details",
      component: AreaProcedureForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/procedure/:procedureid/tray-management",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN, Roles.AREA_MANAGER],
      exact: true,
      component: AreaTrayEquipmentPage,
    },
    /** ReadOnly Hospital Case Procedure */
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/:caseid/procedure/:procedureid/table-setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: ProcedureMainPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/:caseid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: ExecutionFlow,
    },
    /** ReadOnly Hospital Live Case */
    {
      path: "/manufacturer/:id/area/:areaid/hospital/:hospitalid/case/:caseid/live-case",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
      exact: true,
      component: LiveCaseMainPage,
    },
  ],
};

export default areaRoutes;
