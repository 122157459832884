import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

const PublicRoute = ({ component: Component, render, ...rest }) => {
  const authContext = useAuthContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authContext && authContext.user) {
          return <Redirect to="/" />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default PublicRoute;
