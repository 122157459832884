import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  DatePicker,
  TimePicker,
  Input,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { ReactComponent as StethoscopeColorIcon } from "../../../assets/icons/hospital-case/colorIconStethoscope.svg";

const { Title, Text } = Typography;
const { Option } = Select;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

const CaseDetailsForm = (props) => {
  const { hospitalCase } = props;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    if (hospitalCase) {
      form.setFieldsValue({
        ...hospitalCase,
        surgeon: hospitalCase.surgeon?.id,
        dateOfSurgery: hospitalCase.dateOfSurgery
          ? moment(hospitalCase.dateOfSurgery)
          : null,
        timeOfSurgery: hospitalCase.timeOfSurgery
          ? moment(hospitalCase.timeOfSurgery, timeFormat)
          : null,
      });
    }
    setLoading(false);
  }, [hospitalCase]);

  return (
    <Spin spinning={loading}>
      <Row align="middle">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <Form
            form={form}
            name="caseDetailAddEditForm"
            size="middle"
            colon="false"
            scrollToFirstError="true"
            layout="vertical"
            requiredMark={false}
          >
            <Row justify="space-between" style={{ marginBottom: "8px" }}>
              <Col className="display-flex-center">
                <Title level={5}>Details</Title>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item label="Select Surgeon (Required)" name="surgeon">
                  <Select disabled>
                    {hospitalCase?.surgeon && (
                      <Option
                        value={hospitalCase.surgeon.id}
                        key={hospitalCase.surgeon.id}
                      >
                        <div className="display-flex-center">
                          <StethoscopeColorIcon width="22px" />
                          <Text
                            style={{ marginLeft: "6px", color: "#000000" }}
                            ellipsis
                          >
                            {[
                              "Dr.",
                              hospitalCase.surgeon.firstName,
                              hospitalCase.surgeon.middleName,
                              hospitalCase.surgeon.lastName,
                            ].join(" ")}
                          </Text>
                        </div>
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Operating Room" name="ORRoom">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Case Description" name="caseDescription">
                  <Input.TextArea rows={5} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={11}>
                <Form.Item label="Date of Surgery" name="dateOfSurgery">
                  <DatePicker
                    format={dateFormat}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Time of Surgery" name="timeOfSurgery">
                  <TimePicker
                    showNow={false}
                    format="HH:mm"
                    style={{ width: "100%" }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseDetailsForm;
