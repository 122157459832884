import { useEffect, useState } from "react";
import { Form, Input, Modal, Row, Col, Tag, Typography, Spin } from "antd";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../containers/MainContainer";
import toastNotification from "../components/toastNotification";
import userServices from "../services/userServices";
import utilityService from "../services/utilityService";
import { Roles } from "../config/role-config.json";

const { confirm } = Modal;
const { Text, Title } = Typography;

const AdminForm = (props) => {
  const { match, history, location } = props;
  const adminId = match.params.id ? match.params.id : null;
  let admin;

  const backPath = `/admin/${adminId}`;
  if (adminId) {
    admin = location.state ? location.state.record : null;
  }
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(
    !adminId || location.state?.editMode
  );

  const pageHeaderProps = adminId
    ? {
        title: "Edit Admin",
        btnText: editMode ? "Save" : "Edit",
        btnType: editMode ? "primary" : "secondary",
        topActionMenuItems: [
          {
            title: "Delete Admin",
            path: "",
            onClick: () => {
              showDeleteConfirmation(adminId);
            },
          },
        ],
      }
    : {
        title: "New Admin",
        btnText: "Add",
      };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Admin",
      content: "Are you sure you want to delete this Admin?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        deleteAdmin();
      },
    });
  };

  const onFinish = async (adminFormValues) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setLoading(true);
      if (adminId) {
        adminFormValues.id = adminId;
        await editAdmin(adminFormValues);
      } else {
        adminFormValues.role = Roles.DOR_ADMIN;
        adminFormValues.password = "password123";
        await addAdmin(adminFormValues);
      }
      setLoading(false);
    }
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;
    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  /** API Calls */
  const addAdmin = async (adminFormValues) => {
    const res = await userServices.postUser(adminFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Admin Added");
      history.push("/admin");
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new admin record");
    }
  };

  const editAdmin = async (adminFormValues) => {
    const res = await userServices.putUser(adminFormValues);
    if (res.status === 200) {
      setEditMode(false)
      toastNotification("success", "Admin Record Updated");
      history.push(backPath);
    } else {
      toastNotification("error", "Error updating the admin record");
    }
  };

  const deleteAdmin = async () => {
    setLoading(true);
    const isDeleted = await userServices.deleteUser(adminId);
    if (isDeleted) {
      toastNotification("success", "Admin deleted successfully");
      history.push("/admin");
    } else {
      toastNotification("error", "Error deleting the admin");
    }
    setLoading(false);
  };

  const getAdmin = async (id) => {
    const data = await userServices.getUser(id);
    if (data) {
      admin = data;
    } else {
      toastNotification("error", "Requested admin record not found");
    }
  };

  const sendActivationLink = async (e) => {
    setLoading(true);
    const res = await userServices.postResendUserActivationLink(admin.email);
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
    setLoading(false);
  };
  /** API Calls End */

  useEffect(() => {
    setLoading(true);

    (async () => {
      if (adminId) {
        if (!admin) {
          await getAdmin(adminId);
        }
        form.setFieldsValue({ ...admin });
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="adminAddEditForm"
        backBtnPath="/admin"
        onBtnClick={() => {
          form.submit();
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "1rem" }}>
          <Row align="middle">
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 18, offset: 3 }}
              lg={{ span: 16, offset: 4 }}
              xl={{ span: 14, offset: 5 }}
              xxl={{ span: 14, offset: 5 }}
            >
              <Title level={5}>Details</Title>
              <Form
                form={form}
                name="adminAddEditForm"
                id="adminAddEditForm"
                size="large"
                colon="false"
                scrollToFirstError="true"
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Email (Required)"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not a valid Email!",
                        },
                        {
                          required: true,
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input placeholder="Example: david@mail.com" disabled={!editMode}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item label="First Name" name="firstName">
                      <Input placeholder="Example: Chadwick" disabled={!editMode}/>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label="Middle Name" name="middleName">
                      <Input placeholder="Example: Joseph" disabled={!editMode}/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item label="Last Name" name="lastName">
                      <Input placeholder="Example: Boseman" disabled={!editMode}/>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Mobile Number"
                      name="mobileNumber"
                      rules={[
                        {
                          pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                          message: "Please follow the format (xxx) xxx-xxxx",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Example: (203) 683-3272"
                        id="mobileNumber"
                        onChange={onContactNumberInputChange}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item
                      label="Office Number"
                      name="officeNumber"
                      rules={[
                        {
                          pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                          message: "Please follow the format (xxx) xxx-xxxx",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Example: (966) 922-5419"
                        id="officeNumber"
                        onChange={onContactNumberInputChange}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {admin && !admin.isActive && (
                      <Tag
                        color="gold"
                        icon={<MailFilled color="#db9e2e" />}
                        className="activation-link"
                      >
                        <Row>
                          <Col span={8}>
                            <Text> User activation is pending </Text>
                          </Col>
                          <Col span={8} offset={8} style={{ textAlign: "end" }}>
                            <a
                              style={{ color: "inherit" }}
                              onClick={(e) => {
                                sendActivationLink(e);
                              }}
                            >
                              Resend Invite Link
                            </a>
                          </Col>
                        </Row>
                      </Tag>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </MainContainer>
    </Spin>
  );
};

export default AdminForm;
