import { useEffect, useState } from "react";
import { Modal, Spin, Typography } from "antd";
import { Link } from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import ContentTable from "../../components/ContentTable";
import toastNotification from "../../components/toastNotification";
import ActionMenu from "../../components/ActionMenu";
import { ReactComponent as MobileIcon } from "../../assets/icons/iconMobile.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/iconPhone.svg";
import areaService from "../../services/areaService";
import userServices from "../../services/userServices";
import { useBreadCrumbContext } from "../../context/BreadCrumbContext";
import { RolesDisplay, Roles } from "../../config/role-config.json";

const { confirm } = Modal;
const { Text } = Typography;

const customPageSize = 10;
const areaUserRoles = [Roles.AREA_MANAGER];

const AreaUsersPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const isDeletedPage = url.includes("deleted");
  const { areaid: areaId, id: manufacturerId } = params;

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Area Deleted Users",
      backBtnPath: `/manufacturer/${manufacturerId}/area/${areaId}/user`,
    }
    : {
      title: "Area Users",
      btnText: "Add User",
      onBtnClick: () => {
        history.push(`${url}/new`);
      },
      topActionMenuItems: [
        {
          title: "Show Deleted Users",
          path: `${url}/deleted`,
        },
      ],
    };

  const [areaUsersList, setAreaUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [userCount, setUserCount] = useState(0);
  const [userSearchText, setUserSearchText] = useState();
  const { setBreadCrumb } = useBreadCrumbContext();

  const actions = (user) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            onUserRestore(user);
          },
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            setBreadCrumb((prev) => ({
              ...prev,
              user: `${user.firstName ?? ""} ${user.lastName ?? ""}`.trim(),
            }));
            history.push(`${url}/${user.id}`, {
              record: user,
              editMode: true,
              pageNumber: currentPage
            });
          },
        },
        !user.isActive
          ? {
            title: "Resend Invite",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              sendActivationLink(user.email);
            },
          }
          : null,
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(user.id);
          },
        },
      ];
  };

  const columnsList = [
    {
      title: "User's Name & Role",
      dataIndex: "firstName",
      key: "firstName",
      render: (name, user) => {
        const userName = name ? (
          <Text strong>
            {name} {user.middleName} {user.lastName}
          </Text>
        ) : (
            <Text style={{ color: "grey" }}>Not Set</Text>
          );
        return (
          <>
            {isDeletedPage ? (
              userName
            ) : (
                <Link
                  to={{
                    pathname: `${url}/${user.id}`,
                    state: { record: user, editMode: false, pageNumber: currentPage },
                  }}
                  onClick={() =>
                    setBreadCrumb((prev) => ({
                      ...prev,
                      user: `${user.firstName ?? ""} ${user.lastName ?? ""
                        }`.trim(),
                    }))
                  }
                >
                  {userName}
                </Link>
              )}
            <br />
            <Text style={{ fontSize: "0.9em" }}>{RolesDisplay[user.role]}</Text>
            {!user.isActive && (
              <>
                <br />
                <Text style={{ color: "#db9f2e", fontSize: "0.9em" }}>
                  Activation Pending
                </Text>
              </>
            )}
          </>
        );
      },
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Contact",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber, { officeNumber }) => (
        <>
          {mobileNumber && (
            <span className="display-flex-center">
              <MobileIcon width="1.3em" />
              {mobileNumber}
            </span>
          )}
          {officeNumber && (
            <span className="display-flex-center">
              <PhoneIcon fill="#1d1d1d" width="1.3em" />
              {officeNumber}
            </span>
          )}
          {!mobileNumber && !officeNumber && (
            <Text style={{ color: "grey", marginLeft: "0.4em" }}>Not Set</Text>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "4rem",
      render: (value, record) => <ActionMenu actions={actions(record)} />,
    },
  ];

  const onUserRestore = async (user) => {
    setLoading(true);
    const res = await userServices.patchRestoreUser(user.id);
    
    if (res) {
      toastNotification("success", "User Restored");
      populateAreaUsersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the user");
    }
  };

  const sendActivationLink = async (email) => {
    setLoading(true);
    const res = await userServices.postResendUserActivationLink(email);
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
    setLoading(false);
  };

  const showDeleteConfirmation = (userId) => {
    confirm({
      title: "Delete Area User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      centered: true,
      cancelText: "Cancel",
      onOk() {
        deleteAreaUser(userId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setUserSearchText(searchValue);
        const success = await areaService.getAreaUsersCount(
          areaId,
          isDeletedPage,
          "areamanager",
          searchValue
        );
        setUserCount(success);
        setCurrentPage(1);
        await populateAreaUsersList(customPageSize, 0, searchValue);
        setLoading(false);
      }, 500)
    );
  };

  /** API Calls */
  const populateAreaUsersList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await areaService.getAreaUsers(
      areaId,
      isDeletedPage,
      areaUserRoles,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setAreaUsersList(data);
    } else {
      toastNotification("error", "Error fetching User records");
    }
    setLoading(false);
  };

  const deleteAreaUser = async (id) => {
    setLoading(true);
    const isDeleted = await areaService.patchAreaUser({
      id,
      isDeleted: "true",
    });
    if (isDeleted) {
      toastNotification("success", "Area User deleted successfully");
      await populateAreaUsersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error deleting the User record");
    }
    setLoading(false);
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const success = await areaService.getAreaUsersCount(
        areaId,
        isDeletedPage,
        "areamanager",
        userSearchText
      );
      setUserCount(success);
      if (success > 0) await populateAreaUsersList(
        customPageSize,
        customPageSize * (currentPage - 1));
      else setAreaUsersList([]);
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by First Name, Last Name or Email"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <ContentTable
          data={areaUsersList}
          columnsList={columnsList}
          pagination={{
            pageSize: customPageSize,
            total: userCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              await populateAreaUsersList(
                pageSize,
                pageSize * (page - 1),
                userSearchText
              );
              setLoading(false);
            },
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default AreaUsersPage;
