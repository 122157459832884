import { ReactComponent as HospitalIcon } from "../assets/icons/sider-menu-icons/iconHospitals.svg";
import { ReactComponent as EquipmentIcon } from "../assets/icons/sider-menu-icons/iconEquipment.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/sider-menu-icons/iconSetting.svg";
import { ReactComponent as AdminIcon } from "../assets/icons/sider-menu-icons/iconAdmin.svg";
import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { ReactComponent as TrayIcon } from "../assets/icons/distributor-menu/iconTray.svg";
import { ReactComponent as ProcedureIcon } from "../assets/icons/sider-menu-icons/icon_procedures.svg";
import { Roles } from "./role-config.json";

const distributorMenuItems = (distributorId) => {
  const parentPath = `/distributor/${distributorId}`;
  return [
    {
      name: "Admin Panel",
      path: "/distributor",
      icon: <BackIcon />,
      rolesAccess: [Roles.DOR_ADMIN],
    },
    {
      name: "Hospitals",
      path: `${parentPath}/hospital`,
      icon: <HospitalIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
    {
      name: "Procedures",
      path: `${parentPath}/procedure`,
      icon: <ProcedureIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
    {
      name: "Trays",
      path: `${parentPath}/tray`,
      icon: <TrayIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
    {
      name: "Instruments",
      path: `${parentPath}/equipment`,
      icon: <EquipmentIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
    {
      name: "Distributor's Users",
      path: `${parentPath}/user`,
      icon: <AdminIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN],
    },
    {
      name: "Distributor's Setup",
      path: `${parentPath}/setup`,
      icon: <SettingIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.DISTRIBUTOR_ADMIN, Roles.MR],
    },
  ];
};

export default distributorMenuItems;
