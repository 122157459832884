import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Typography,
  Select,
  Spin,
  Divider,
  Tag,
} from "antd";
import { debounce } from "lodash";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import AssignmentSelection from "../../components/AssignmentSelection";
import userServices from "../../services/userServices";
import areaService from "../../services/areaService";
import utilityService from "../../services/utilityService";
import { Roles, RolesDisplay } from "../../config/role-config.json";
import { ReactComponent as HospitalColorIcon } from "../../assets/icons/colorIconHospital.svg";
import { ReactComponent as HospitalGreyIcon } from "../../assets/icons/greyIconManufacturer.svg";
import { useAuthContext } from "../../context/AuthContext";

const { confirm } = Modal;
const { Title, Text } = Typography;
const customPageSize = 30;

const RegionalManagerForm = ({ match, history, location }) => {
  const { rmid: rmId, areaid: areaId, id: manufacturerId } = match.params;

  const pathToRMList = `/manufacturer/${manufacturerId}/area/${areaId}/regional-manager`;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [regionalManager, setRegionalManager] = useState();
  const [editMode, setEditMode] = useState(!rmId || location.state?.editMode);
  const [manufacturerAreas, setManufacturerAreas] = useState([]);
  const [initialHospitalIds, setInitialHospitalIds] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalIds, setSelectedHospitalIds] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitalSearchTimeOut, setHospitalSearchTimeOut] = useState(0);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const [hospitalCount, setHospitalCount] = useState();
  const [hospitalSearchText, setHospitalSearchText] = useState();
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [loadHospitals, setLoadHospitals] = useState(false);

  const authContext = useAuthContext();
  const isRegionalManager = authContext.user?.role === Roles.REGIONAL_MANAGER;

  const pageHeaderProps = rmId
    ? {
        title: regionalManager
          ? [
              regionalManager.firstName,
              regionalManager.middleName,
              regionalManager.lastName,
            ].join(" ")
          : "Edit Regional Manager",
        btnText: editMode ? "Save" : "Edit",
        btnType: editMode ? "primary" : "secondary",
        topActionMenuItems: !isRegionalManager && [
          {
            title: "Delete",
            path: "",
            onClick: () => {
              showDeleteConfirmation(rmId);
            },
          },
        ],
      }
    : {
        title: "New Regional Manager",
        btnText: "Add",
        backBtnPath: pathToRMList,
      };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Regional Manager",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await userServices.deleteUser(rmId);
        if (isDeleted) {
          toastNotification("success", "Regional Manager deleted successfully");
          history.push(pathToRMList);
        } else {
          toastNotification(
            "error",
            "Error deleting the Regional Manager record!"
          );
        }
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const onFinish = async (rmFormValues) => {
    setLoading(true);
    rmFormValues.hospital = selectedHospitalIds;
    if (rmId) {
      await editRegionalManager(rmFormValues);
    } else {
      await addRegionalManager(rmFormValues);
    }

    const sortedHospitals =
      utilityService.sortSelectedEntity(selectedHospitals);
    setSelectedHospitals(sortedHospitals);
    setLoading(false);
  };

  /** Hospital Assignment */

  const handleInputChange = (searchText) => {
    debounce((searchText) => {
      setHospitalSearchText(searchText);
    }, 250)();
    if (searchText.length === 0) setCurrentHospitalPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadHospitals && hospitalList.length !== hospitalCount && addOptions();
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : true;
  };

  const onSearchHospital = async (searchValue) => {
    if (hospitalCount >= 30) {
      setHospitalLoading(true);
      if (hospitalSearchTimeOut) {
        clearTimeout(hospitalSearchTimeOut);
      }
      setHospitalSearchTimeOut(
        setTimeout(async () => {
          searchValue = searchValue.trim();
          setHospitalSearchText(searchValue);
          await populateHospitalList(customPageSize, 0, searchValue);
          setHospitalLoading(false);
        }, 500)
      );
    } else return true;
  };

  const onHospitalSelect = (hospitalId) => {
    const ids = selectedHospitalIds;
    ids.push(hospitalId);
    setSelectedHospitalIds([...ids]);

    const selectedHospital = hospitalList.find(
      (hospital) => hospital.id === hospitalId
    );
    selectedHospitals.push(selectedHospital);
    setSelectedHospitals([...selectedHospitals]);
  };

  const onHospitalDeselect = (hospitalId) => {
    setSelectedHospitalIds(selectedHospitalIds.filter((x) => x !== hospitalId));

    setSelectedHospitals(
      selectedHospitals.filter((hospital) => hospital.id !== hospitalId)
    );
  };

  const addOptions = async () => {
    setLoadHospitals(false);
    setHospitalLoading(true);
    setCurrentHospitalPage(currentHospitalPage + 1);
    await populateHospitalList(
      customPageSize,
      currentHospitalPage * customPageSize,
      hospitalSearchText
    );
    setHospitalLoading(false);
  };
  /** Hospital Assignment End */

  /** API Calls */
  const addRegionalManager = async (rmFormValues) => {
    rmFormValues.password = "password123";
    const res = await userServices.postUser(rmFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Regional Manager added");
      history.push(pathToRMList);
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new Regional Manager");
    }
  };

  const editRegionalManager = async (rmFormValues) => {
    rmFormValues.id = rmId;
    const removedHIds = initialHospitalIds?.filter(
      (hId) => !rmFormValues.hospital?.includes(hId)
    );
    if (removedHIds?.length)
      areaService.patchUnassignAreaHospitals(areaId, removedHIds);

    const res = await userServices.putUser(rmFormValues);

    if (res.status === 200) {
      toastNotification("success", "Regional Manager record updated");
      setEditMode(false);
      setInitialHospitalIds(rmFormValues.hospital);
      // history.replace({ state: { record: res } });
    } else {
      toastNotification("error", "Error updating the Regional Manager record");
    }
  };

  const getRegionalManager = async () => {
    const data = await userServices.getUser(rmId, { populate: "hospital" });
    if (data) {
      setRegionalManager(data);
      if (data?.hospital) {
        const filteredHospitals = utilityService.filterSelectedEntity(
          data.hospital
        );
        const sortedHospitals =
          utilityService.sortSelectedEntity(filteredHospitals);
        setSelectedHospitals(sortedHospitals);

        const hIds = sortedHospitals.map((hospital) => hospital.id);
        setSelectedHospitalIds(hIds);
        setInitialHospitalIds(hIds);
      }
      form.setFieldsValue(data);
    } else {
      toastNotification("error", "Requested Regional Manager record not found");
    }
  };

  const getManufacturerAreas = async () => {
    const data = await areaService.getAreas(manufacturerId, false, 1000, 0);
    if (data) {
      setManufacturerAreas(data);
    } else {
      toastNotification("error", "Error fetching the Area records");
    }
  };

  const populateHospitalList = async (limit, skip, searchValue) => {
    setHospitalLoading(true);
    const res = await areaService.getAreaHospitals(areaId);
    if (res) {
      setLoadHospitals(res.length === customPageSize);
      const arr = [
        ...new Map(
          [...hospitalList, ...res].map((item) => [item["id"], item])
        ).values(),
      ];

      setHospitalList(arr);
      setHospitalCount(arr.length);
      setCurrentHospitalPage(0);
    }
    setHospitalLoading(false);
  };

  const sendActivationLink = async (email) => {
    const res = await userServices.postResendUserActivationLink(email);
    if (res?.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };
  /** API Calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getManufacturerAreas();
      if (rmId) {
        let rmTemp = regionalManager;
        if (!rmTemp) {
          rmTemp = await getRegionalManager();
        }
        form.setFieldsValue({ ...rmTemp });
      } else {
        form.setFieldsValue({ area: areaId });
      }
      populateHospitalList(customPageSize, 0, null);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="regionalManagerAddEditForm"
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row>
          <Col span={14} style={{ marginTop: "12px" }}>
            <Row align="middle">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
                xxl={{ span: 22, offset: 1 }}
              >
                <Form
                  form={form}
                  name="regionalManagerAddEditForm"
                  id="regionalManagerAddEditForm"
                  size="large"
                  colon="false"
                  scrollToFirstError="true"
                  onFinish={onFinish}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Row>
                    <Col span={24}>
                      <Title level={5}>Details</Title>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Email (Required)"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not a valid Email!",
                          },
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: david@mail.com"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="Role (Required)"
                        name="role"
                        rules={[
                          {
                            required: true,
                            message: "Please select a role!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a role"
                          disabled={!editMode}
                        >
                          <Select.Option value={Roles.REGIONAL_MANAGER}>
                            {RolesDisplay.REGIONAL_MANAGER}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Area (Required)"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "Please select an Area!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Area" disabled={!editMode}>
                          {manufacturerAreas?.map((area) => (
                            <Select.Option value={area.id} key={area.id}>
                              {area.areaName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item label="Title" name="title">
                        <Input
                          placeholder="Example: Sr. VP"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item label="First Name" name="firstName">
                        <Input
                          placeholder="Example: Chadwick"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item label="Middle Name" name="middleName">
                        <Input
                          placeholder="Example: Joseph"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item label="Last Name" name="lastName">
                        <Input
                          placeholder="Example: Boseman"
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        label="Mobile Number"
                        name="mobileNumber"
                        rules={[
                          {
                            pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                            message: "Please follow the format (xxx) xxx-xxxx",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: (203) 683-3272"
                          id="mobileNumber"
                          onChange={onContactNumberInputChange}
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="Office Number"
                        name="officeNumber"
                        rules={[
                          {
                            pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                            message: "Please follow the format (xxx) xxx-xxxx",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Example: (966) 922-5419"
                          id="officeNumber"
                          onChange={onContactNumberInputChange}
                          disabled={!editMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {regionalManager && !regionalManager.isActive && (
                        <Tag
                          color="gold"
                          icon={<MailFilled color="#db9e2e" />}
                          className="activation-link"
                        >
                          <Row>
                            <Col span={8}>
                              <Text> User activation is pending </Text>
                            </Col>
                            <Col
                              span={8}
                              offset={8}
                              style={{ textAlign: "end" }}
                            >
                              <a
                                style={{ color: "inherit" }}
                                onClick={(e) => {
                                  sendActivationLink(regionalManager.email);
                                }}
                              >
                                Resend Invite Link
                              </a>
                            </Col>
                          </Row>
                        </Tag>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={8} style={{ marginTop: "12px" }}>
            <Title level={5}>Assigned Hospitals</Title>
            <AssignmentSelection
              editMode={!isRegionalManager && editMode}
              selectedEntityIds={selectedHospitalIds}
              dataSource={hospitalList}
              selectedEntities={selectedHospitals}
              optionIcon={HospitalColorIcon}
              showOptionsLoader={hospitalLoading}
              onSelect={onHospitalSelect}
              onDeselect={onHospitalDeselect}
              onSearch={onSearchHospital}
              filterOption={handleFilterOption}
              onDropdownVisibleChange={() => setCurrentHospitalPage(1)}
              onChange={() => setCurrentHospitalPage(0)}
              onPopupScroll={handleMenuScrollToBottom}
              onInputKeyDown={handleInputChange}
              tagIcon={HospitalColorIcon}
              emptyText="No Hospitals added yet"
              emptyIcon={HospitalGreyIcon}
              entityTitleProperty="hospitalName"
              placeholder="Search by Hospital Name"
            />
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default RegionalManagerForm;
