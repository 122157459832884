import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { ReactComponent as PageNotFoundSvg } from "../assets/Images/PageNotFound.svg";

const PageNotFound = () => {
  const history = useHistory();
  const redirectToHomePage = () => history.push("/");
  return (
    <Result
      title="Error: 404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={redirectToHomePage}>
          Take me Home
        </Button>
      }
      icon={<PageNotFoundSvg />}
      className="dor-page-not-found"
    />
  );
};

export default PageNotFound;
