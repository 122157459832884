import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  Spin,
} from "antd";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import utilityService from "../../services/utilityService";

const { confirm } = Modal;
const { Text, Title } = Typography;

const ManufacturerUserForm = (props) => {
  const { match, history, location } = props;
  const manufacturerUserId = match.params.userid ? match.params.userid : null;
  const manufacturerId = match.params.id ? match.params.id : null;

  //TODO :: Check if invalid manufacturer id
  const pathToManufacturerUsers = `/manufacturer/${manufacturerId}/user`;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [manufacturerUser, setManufacturerUser] = useState(
    location.state?.record
  );
  const [editMode, setEditMode] = useState(
    !manufacturerUserId || location.state?.editMode === true ? true : false
  );

  const pageHeaderProps = manufacturerUserId
    ? {
      title: manufacturerUser
        ? [
          manufacturerUser.firstName,
          manufacturerUser.middleName,
          manufacturerUser.lastName,
        ].join(" ")
        : "Manufacturer User Details",
      btnText: editMode ? "Save" : "Edit",
      btnType: editMode ? "primary" : "secondary",
      topActionMenuItems: [
        {
          title: "Delete Manufacturer User",
          path: null,
          onClick: () => {
            showDeleteConfirmation(manufacturerUserId);
          },
        },
      ],
    }
    : {
      title: "New Manufacturer User",
      btnText: "Add",
    };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Manufacturer User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await userServices.deleteUser(manufacturerUserId);
        if (isDeleted) {
          toastNotification("success", "User deleted successfully");
          history.push(pathToManufacturerUsers);
        } else {
          toastNotification("error", "Error deleting the user");
        }
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const addManufacturerUser = async (manufacturerUserFormValues) => {
    // TODO :: Trim values before submit
    manufacturerUserFormValues.password = "password123";
    manufacturerUserFormValues.distributor = manufacturerId;
    const res = await userServices.postUser(manufacturerUserFormValues);

    if (res.status === 200) {
      toastNotification("success", "New manufacturer user added");
      history.push(pathToManufacturerUsers);
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new user record");
    }
  };

  const editManufacturerUser = async (manufacturerUserFormValues) => {
    // TODO :: Trim values before submit
    const res = await userServices.putUser(manufacturerUserFormValues);

    if (res.status === 200) {
      setEditMode(false);
      history.replace({ state: { record: res.data, pageNumber: location.state?.pageNumber } });
      toastNotification("success", "Manufacturer user record updated");
    } else {
      toastNotification("error", "Error updating the user record");
    }
  };

  const getManufacturerUser = async (id) => {
    const data = await userServices.getUser(id);
    if (data) {
      setManufacturerUser(data);
    } else {
      toastNotification("error", "Requested user record not found");
    }
  };

  const onFinish = async (manufacturerUserFormValues) => {
    setLoading(true);
    if (manufacturerUserId) {
      manufacturerUserFormValues.id = manufacturerUser.id;
      await editManufacturerUser(manufacturerUserFormValues);
    } else {
      await addManufacturerUser(manufacturerUserFormValues);
    }
    setLoading(false);
  };

  const sendActivationLink = async (e) => {
    const res = await userServices.postResendUserActivationLink(
      manufacturerUser?.email
    );
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (manufacturerUserId) {
        if (!manufacturerUser) {
          await getManufacturerUser(manufacturerUserId);
        }
        form.setFieldsValue({ ...manufacturerUser });
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="manufacturerUserAddEditForm"
        backBtnPath={pathToManufacturerUsers}
        routeState={{ pageNumber: location.state?.pageNumber }}
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row align="middle">
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 14, offset: 5 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Form
              form={form}
              name="manufacturerUserAddEditForm"
              id="manufacturerUserAddEditForm"
              size="large"
              colon="false"
              scrollToFirstError="true"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
            >
              <Row>
                <Col span={24}>
                  <Title level={5}>Details</Title>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Email (Required)"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid Email!",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: david@mail.com"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Role (Required)"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a role!",
                      },
                    ]}
                  >
                    <Select placeholder="Select a role" disabled={!editMode}>
                      <Select.Option value="MANUFACTURER_ADMIN">
                        Manufacturer Admin
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="Title" name="title">
                    <Input placeholder="Example: Sr. VP" disabled={!editMode} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item label="First Name" name="firstName">
                    <Input
                      placeholder="Example: Chadwick"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="Middle Name" name="middleName">
                    <Input placeholder="Example: Joseph" disabled={!editMode} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item label="Last Name" name="lastName">
                    <Input
                      placeholder="Example: Boseman"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (203) 683-3272"
                      id="mobileNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Office Number"
                    name="officeNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (966) 922-5419"
                      id="officeNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {manufacturerUserId && !manufacturerUser?.isActive && (
                    <Tag
                      color="gold"
                      icon={<MailFilled color="#db9e2e" />}
                      className="activation-link"
                    >
                      <Row>
                        <Col span={8}>
                          <Text> User activation is pending </Text>
                        </Col>
                        <Col span={8} offset={8} style={{ textAlign: "end" }}>
                          <a
                            style={{ color: "inherit" }}
                            onClick={(e) => {
                              sendActivationLink(e);
                            }}
                          >
                            Resend Invite Link
                          </a>
                        </Col>
                      </Row>
                    </Tag>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default ManufacturerUserForm;
