import { useEffect, useState } from "react";
import { List, Checkbox } from "antd";
import ExecutionStepContainer from "../../containers/LiveCase/ExecutionStepContainer";
import liveCaseService from "../../services/liveCaseService";
import { useExecutionStepsContext } from "../../context/ExecutionStepsContext";

const CheckList = ({ step }) => {
  const { hasReadOnlyRights } = useExecutionStepsContext();
  const [checklistItems, setChecklistItems] = useState();

  const onCheckboxChange = (e, index) => {
    checklistItems[index].isChecked = !checklistItems[index].isChecked;
    setChecklistItems([...checklistItems]);
    updateChecklistState(e.target.value, checklistItems[index].isChecked);
  };

  const updateChecklistState = async (itemId, isChecked) => {
    await liveCaseService.patchChecklistItem(itemId, isChecked);
  };
  /** API Calls End */

  useEffect(() => {
    setChecklistItems(step.checklistItems);
  }, [step]);

  return (
    <ExecutionStepContainer
      phaseName={step.phase.phaseName}
      label={step.label}
      isCurrentStep={true}
      stepType="Checklist"
    >
      <div style={{ marginLeft: "8px" }}>
        {checklistItems && (
          <List
            dataSource={checklistItems}
            split={false}
            renderItem={(checklistItem, index) => {
              return (
                <List.Item>
                  <Checkbox
                    value={checklistItem.id}
                    onChange={(e) => onCheckboxChange(e, index)}
                    checked={checklistItem.isChecked}
                    style={{ color: "#ffffff" }}
                    disabled={hasReadOnlyRights}
                    className="checklist-checkbox"
                  >
                    {checklistItem.name}
                  </Checkbox>
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </ExecutionStepContainer>
  );
};

export default CheckList;
