import { useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import MainContainer from "../../../containers/MainContainer";
import CaseDetailsForm from "./CaseDetailsForm";
import CaseORTeamForm from "./CaseORTeamForm";
import CaseProcedureForm from "./CaseProcedureForm";
import toastNotification from "../../../components/toastNotification";
import hospitalService from "../../../services/hospitalService";
import { useAuthContext } from "../../../context/AuthContext";
import { Roles } from "../../../config/role-config.json";
import { CaseTypes } from "../../../config/case-types.json";

const { TabPane } = Tabs;

const CaseForm = (props) => {
  const {
    match: { url, params },
    history,
    location: { state, pathname },
  } = props;

  const { hospitalid: hospitalId, caseid: caseId } = params;
  const activeTabKey = state?.isProcedurePage ? "3" : "1";

  const authContext = useAuthContext();
  const [hospitalCase, setHospitalCase] = useState(state?.record);
  const [loading, setLoading] = useState(false);

  const pathToHospitalCases =
    hospitalCase?.isStarted === CaseTypes.COMPLETED
      ? url.replace(`${caseId}`, "completed")
      : url.replace(`/${caseId}`, "");

  const pageHeaderProps = {
    title: "Case",
    btnText: hospitalCase?.isStarted === CaseTypes.COMPLETED ? "View Case" : null,
    btnType: "primary",
    onBtnClick: () => {
      redirectToLiveCase(hospitalCase);
    },
  };

  const redirectToLiveCase = (caseData) => {
    history.push(`${url}/live-case`, {
      path: pathname,
      readOnly:
        caseData.isStarted === CaseTypes.COMPLETED ||
        authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
        authContext.user.role === Roles.MR,
      pageNumber: state?.pageNumber,
      caseType: state?.caseType,
      isCompletedCasesPage: hospitalCase.isStarted === CaseTypes.COMPLETED
    });
  };

  /** API Calls */
  const getCase = async () => {
    setLoading(true);
    const data = await hospitalService.getCase(caseId);

    if (data) setHospitalCase(data);
    else toastNotification("error", "Error fetching Case details");

    setLoading(false);
  };
  /** API Calls End */

  useEffect(() => {
    if (!hospitalCase) getCase();
  }, []);

  return (
    <MainContainer
      formName="caseDetailAddEditForm"
      backBtnPath={pathToHospitalCases}
      routeState={{
        pageNumber: state?.pageNumber,
        caseType: state?.caseType
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <Tabs defaultActiveKey={activeTabKey}>
          <TabPane tab="Case Details" key="1">
            <CaseDetailsForm
              hospitalId={hospitalId}
              hospitalCase={hospitalCase}
            />
          </TabPane>
          <TabPane tab="OR Team" key="2">
            <CaseORTeamForm hospitalCase={hospitalCase} />
          </TabPane>
          <TabPane tab="Procedure" key="3">
            <CaseProcedureForm hospitalCase={hospitalCase} />
          </TabPane>
        </Tabs>
      </Spin>
    </MainContainer>
  );
};
export default CaseForm;
