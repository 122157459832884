import { Layout, Image } from "antd";
import logo from "../assets/DInamicOR+Logo.svg";
const { Header } = Layout;

const NoAuthHeader = () => {
  return (
    <Header
      style={{
        backgroundColor: "#eee",
        height: "13rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image src={logo} width="15.7rem" height="4.125rem" preview={false} />
    </Header>
  );
};

export default NoAuthHeader;
