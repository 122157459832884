import {
  layoutForTwoLayer,
  layoutForTwoLayerCopy,
  layoutForTwoLayer2WideCopy,
  layoutForTwoLayer2Wide,
} from "../config/grid-layout";
import { Roles } from "../config/role-config.json";
import manufacturerService from "../services/manufacturerService";
import distributorService from "../services/distributorService";
import hospitalService from "../services/hospitalService";

const formatContactNumber = (value) => {
  value = value.replace(/[^\d]/g, "");
  if (!value) return "";

  const valueLength = value.length;
  if (valueLength < 4) return value;
  if (valueLength < 7) return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
};

const changeOrientation = (layout, id, count, setLayout) => {
  const layoutData = [...layout];
  if (layoutData[id].h === 2) {
    layoutData[id].h = 1;
    layoutData[id].w = layoutData[id].w + 12 / count;
    layoutData[id + count / 2].h = 1;
    layoutData[id + count / 2].w = layoutData[id + count / 2].w + 12 / count;
    layoutData[id + count / 2].x = layoutData[id + count / 2].x - 12 / count;
    layoutData[id + count / 2].y = 1;
  } else {
    layoutData[id].h = 2;
    layoutData[id].w = layoutData[id].w - 12 / count;
    layoutData[id + count / 2].h = 2;
    layoutData[id + count / 2].w = layoutData[id + count / 2].w - 12 / count;
    layoutData[id + count / 2].x = layoutData[id + count / 2].x + 12 / count;
    layoutData[id + count / 2].y = 0;
  }
  const changedLayout = { lg: layoutData };
  setLayout(changedLayout);
  layoutForTwoLayer.lg = JSON.parse(JSON.stringify(layoutForTwoLayerCopy.lg));
  layoutForTwoLayer2Wide.lg = JSON.parse(
    JSON.stringify(layoutForTwoLayer2WideCopy.lg)
  );
};

const filterSelectedEntity = (entiryList) => {
  return entiryList.filter((entity) => !entity.isDeleted);
};

const sortSelectedEntity = (selectedEntity) => {
  const tempList = [...selectedEntity];
  return tempList.sort((a, b) => b.createdAt - a.createdAt);
};

const getRegionalManagerName = (regionalManager) => {
  if (regionalManager) {
    const fullName = [];
    regionalManager.title && fullName.push(regionalManager.title);
    regionalManager.firstName && fullName.push(regionalManager.firstName);
    regionalManager.middleName && fullName.push(regionalManager.middleName);
    regionalManager.lastName && fullName.push(regionalManager.lastName);
    return fullName.join(" ");
  }
};

const getHeaderEntity = async (user, setHeader) => {
  let entity;
  let name;
  if (
    [Roles.AREA_MANAGER, Roles.REGIONAL_MANAGER, Roles.MMR].includes(user.role)
  ) {
    entity = await manufacturerService.getManufacturer(user.area?.manufacturer);
    setHeader((prev) => ({
      ...prev,
      manufacturer: entity.distributorName,
    }));
    name = entity.distributorName;
  } else if ([Roles.DISTRIBUTOR_ADMIN, Roles.MR, Roles.MANUFACTURER_ADMIN].includes(user.role)) {
    entity = await distributorService.getDistributor(user.distributor);
    setHeader((prev) => ({
      ...prev,
      distributor: entity.distributorName,
    }));
    name = entity.distributorName;
  } else if (
    [Roles.HOSPITAL_ADMIN, Roles.SURGEON, Roles.HOSPITAL_STAFF].includes(
      user.role
    )
  ) {
    entity = await hospitalService.getHospital(user.hospital?.[0]?.id);
    setHeader((prev) => ({
      ...prev,
      hospital: entity.hospitalName,
    }));
    name = entity.hospitalName;
  }

  return name;
};

export default {
  formatContactNumber,
  changeOrientation,
  filterSelectedEntity,
  sortSelectedEntity,
  getRegionalManagerName,
  getHeaderEntity,
};
