import ExecutionFlowPage from "../../shared-pages/editable-mini-app/ExecutionFlowPage";
import ExecutionFlow from "../../shared-pages/read-only/mini-app/ExecutionFlow";

const AreaExecutionFlowPage = (props) => {
  const {
    match: { params },
    location: { state }
  } = props;

  const { procedureid: procedureId, areaid: areaId } = params;

  return (
    state.isCloneProcedure ?
      <ExecutionFlowPage
        entityId={areaId}
        entityName="area"
        procedureId={procedureId}
        state={state}
      />
      :
      <ExecutionFlow />
  );
};

export default AreaExecutionFlowPage;
