import React, { memo, useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Tag,
  Select,
  Collapse,
  Upload,
  Button,
  Typography,
  InputNumber,
  Modal,
  Divider,
  Spin,
} from "antd";
import { Stream } from "@cloudflare/stream-react";
import { useParams } from "react-router-dom";
import FormSelect from "./Partial/FormSelect";
import EquipmentModal from "../../components/EquipmentModal";
import SignedURLImage from "../../components/SignedURLImage";
import executionService from "../../services/executionService";
import mediaService from "../../services/mediaService";
import toastNotification from "../../components/toastNotification";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as TagCloseIcon } from "../../assets/icons/iconTagClose.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/iconDelete.svg";
import { ReactComponent as VideoIcon } from "../../assets/icons/icon_video.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../assets/icons/checkboxSelected.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/icon_play.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/iconCloseModal.svg";

const { confirm } = Modal;

const { Option } = Select;
const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const SurgicalStepPage = memo(
  ({
    addNewItem,
    editRecord,
    updatedData,
    hideItem,
    getPhaseId,
    currentIndex,
    parentIndex,
    staticItems,
    getIndexOnCreate,
    updateCurrentItem,
    equipmentsList,
    isPageReadOnly,
    setEquipmentsList,
  }) => {
    const stepId = editRecord.phase;
    const [showModal, setModal] = useState(false);
    const { procedureid } = useParams();
    const [form] = Form.useForm();
    const [selectedEquipmentsId, setSelectedEquipmentsId] = useState([]);
    const [selectedEquipments, setSelectedEquipments] = useState([]);
    const [videoLoading, setVideoLoading] = useState(false);
    const [videoResult, setVideoResult] = useState(null);
    const [videoSignedToken, setVideoSignedToken] = useState(null);
    const [timeOut, setTimeOut] = useState(0);
    const [isEquipmentModalVisible, setIsEquipmentModalVisible] =
      useState(false);
    const [loading, setLoading] = useState(false);
    const [equipmentToView, setEquipmentToView] = useState();

    const onEquipmentSelect = (equipmentId) => {
      // add id to selectedIds to maintain state of selected options
      const ids = selectedEquipmentsId;
      ids.push(equipmentId);
      setSelectedEquipmentsId([...ids]);

      // add equipment to selectedEquipments to add it to the displayed tag list
      const selectedEquipment = {
        equipment: equipmentsList.find(
          (equipment) => equipment.equipment.id === equipmentId
        ),
        // quantity: 1,
      };
      selectedEquipment.equipment.quantity = 1;
      selectedEquipments.push(selectedEquipment);
      setSelectedEquipments([...selectedEquipments]);
    };

    const onEquipmentDeselect = (equipmentId) => {
      setSelectedEquipmentsId(
        selectedEquipmentsId.filter((id) => id !== equipmentId)
      );
      // remove equipment from selectedEquipments to remove it from the displayed tag list
      setSelectedEquipments(
        selectedEquipments.filter(
          (trayEquipment) =>
            trayEquipment.equipment.equipment.id !== equipmentId
        )
      );
    };

    const populateSearchedEquipmentList = async (searchValue) => {
      const data = await executionService.getSearchedEquipments(
        searchValue,
        procedureid
      );
      if (data) {
        setEquipmentsList(data);
      } else {
        toastNotification("error", "Error fetching the instrument records");
      }
      // setLoading(false);
    };

    const onSearchFunction = async (searchValue) => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      setTimeOut(
        setTimeout(() => {
          // searchValue = searchValue.trim();
          populateSearchedEquipmentList(searchValue);
        }, 300)
      );
    };

    /** Close needed api call */

    /** Add functionality of surgical step */
    const beforeVideoUpload = (file) => {
      if (file.size < 1073741824) return true;
      else {
        toastNotification("error", "File size too large");
        return false;
      }
    };

    const onVideoUpload = async ({ file }) => {
      setVideoLoading(true);
      const resultObject = await mediaService.uploadVideo(file);
      if (resultObject?.data) {
        setVideoResult(resultObject.data);
        const timeOut = (() => {
          if (file.size <= 20000000) {
            return 10;
          } else if (file.size > 50000000 && file.size <= 100000000) {
            return 15;
          } else if (file.size > 100000000 && file.size <= 200000000) {
            return 20;
          } else if (file.size > 200000000 && file.size <= 300000000) {
            return 25;
          } else if (file.size > 300000000 && file.size <= 400000000) {
            return 30;
          } else if (file.size > 400000000 && file.size <= 500000000) {
            return 35;
          } else {
            return 60;
          }
        })();
        setTimeout(async () => {
          await getAndSetVideoSignedToken(resultObject.data.result?.uid);
          setVideoLoading(false);
        }, timeOut * 1000);
      }
    };

    const getAndSetVideoSignedToken = async (videoId) => {
      const signedToken = await mediaService.getVideoSignedToken(videoId);
      setVideoSignedToken(signedToken);
    };

    const videoUploadButton = (
      <div>
        {videoLoading ? <LoadingOutlined /> : <VideoIcon />}
        <div style={{ marginTop: 8 }}>Click to upload the video</div>
      </div>
    );

    const overlayMediaDelete = () => (
      <div className="icon-overlay">
        <Button
          icon={<DeleteIcon />}
          onClick={() => {
            setVideoSignedToken(null);
            toastNotification("success", "Video deleted successfully");
          }}
          style={{ padding: 0, marginRight: "0" }}
        />
      </div>
    );

    const deleteVideo = async () => {
      const res = await mediaService.deleteCloudFlareVideo(
        videoResult.result?.uid
      );
      if (!res) {
        toastNotification("error", "Failed deleting the video");
      } else {
        return true;
      }
    };

    const addSurgicalStep = async (phaseFormValues) => {
      setLoading(true);
      phaseFormValues.stepType = "SURGICAL_STEP";
      phaseFormValues.phase = getPhaseId();
      phaseFormValues.index = getIndexOnCreate(
        staticItems,
        "STEP",
        parentIndex,
        currentIndex
      );
      const res = await executionService.postStep(phaseFormValues);
      if (res.status === 200) {
        toastNotification("success", "New Surgical Step Added");
        let item = {
          id: new Date().getTime(),
          phase: res.data.id,
          content: res.data.label,
          original: res.data,
          current: currentIndex,
          parent: parentIndex,
        };
        addNewItem(item);
        //saveFinalData(item);
      } else {
        toastNotification("error", "Error adding a new phase record");
      }
      setLoading(false);
    };

    const EditSurgicalStep = async (phaseFormValues) => {
      setLoading(true);
      phaseFormValues.stepType = "SURGICAL_STEP";
      phaseFormValues.phase = editRecord.original.phase;
      const res = await executionService.putStep(
        phaseFormValues,
        editRecord.phase
      );
      if (res.status === 200) {
        toastNotification("success", "Surgical Step Updated Successfully");
        updateCurrentItem(null);
        updatedData();
      } else {
        toastNotification("error", "Error updating the surgical record");
      }
      setLoading(false);
    };

    const onFinish = async (phaseFormValues) => {
      phaseFormValues.equipments = selectedEquipments.map((trayEquipment) => ({
        equipment: trayEquipment.equipment.equipment.id,
        quantity: trayEquipment.equipment.quantity,
      }));
      if (videoResult && !videoSignedToken) {
        if (await deleteVideo()) phaseFormValues.video = null;
      } else {
        phaseFormValues.video = videoResult;
      }
      if (phaseFormValues.equipments.length > 0) {
        if (editRecord.phase) {
          EditSurgicalStep(phaseFormValues);
        } else {
          addSurgicalStep(phaseFormValues);
        }
      } else {
        toastNotification(
          "error",
          "Please add an equipment for the surgical step."
        );
      }
    };

    const getSurgicalEquipment = async (stepId) => {
      const res = await executionService.getStepsEquipment(stepId);
      if (res) {
        let ids = [];
        const equipList = res.map((item) => {
          ids.push(item.equipment.id);
          return {
            equipment: item,
            // quantity: item.quantity,
          };
        });
        setSelectedEquipmentsId(ids);
        setSelectedEquipments(equipList);
      } else {
        toastNotification(
          "error",
          "Error fetching the Steps Instrument records"
        );
      }
    };

    const deleteSurgicalStep = async (id) => {
      const isDeleted = await executionService.deleteStep(editRecord.phase);
      if (isDeleted) {
        toastNotification("success", "Surgical Steps deleted successfully");
        updateCurrentItem(null);
        updatedData();
      } else {
        toastNotification("error", "Error deleting the surgical step");
      }
    };

    const showDeleteConfirmation = (id) => {
      confirm({
        title: <h2 className="delete-notes">Delete Surgical Step</h2>,
        content: (
          <div>
            <div>
              Tapping on "Confirm" would delete this not from the execution
              flow.
            </div>
            <div>Are you sure you want to continue?</div>
          </div>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        centered: true,
        icon: <></>,
        onOk() {
          deleteSurgicalStep(id);
        },
      });
    };

    useEffect(() => {
      if (editRecord) {
        form.setFieldsValue(editRecord.original);
        if (editRecord.original.video) {
          setVideoResult(editRecord.original.video);
          getAndSetVideoSignedToken(editRecord.original.video.result?.uid);
        }
        getSurgicalEquipment(stepId);
      }
    }, []);

    const closeModal = () => {
      const video = document.getElementById("videoEquipment");
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
      setIsEquipmentModalVisible(false);
    };

    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="surgicalStepsAddEditForm"
          id="surgicalStepsAddEditForm"
          size="large"
          colon="false"
          scrollToFirstError="true"
          onFinish={onFinish}
          layout="vertical"
        >
          <Row className="sidebar-header">
            <Col span={12}>
              <FormSelect
                updateCurrentItem={updateCurrentItem}
                selectedValue="SURGICAL_STEP"
                editRecord={editRecord}
              />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {!isPageReadOnly && (
                <Form.Item>
                  {editRecord && (
                    <Button
                      style={{ marginRight: "0" }}
                      type="secondary"
                      className="delete-btn-none"
                      onClick={() => {
                        showDeleteConfirmation(editRecord.phase);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  {!editRecord && (
                    <Button
                      style={{ marginRight: "0" }}
                      type="default"
                      htmlType="button"
                      className="delete-btn-none"
                      onClick={hideItem}
                    >
                      Close
                    </Button>
                  )}
                  <Button
                    style={{ marginRight: "0" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider
            type="horizontal"
            style={{
              margin: "-5px",
            }}
          />
          <Row className="margin-top-notes">
            <Col span={2}></Col>
            <Col span={22}>
              <Form.Item
                label="Label (Required)"
                name="label"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input label name!",
                  },
                ]}
              >
                <Input
                  placeholder="Surgical Instruction"
                  disabled={isPageReadOnly}
                />
              </Form.Item>

              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    fontSize: "12.8px",
                    justifyContent: "flexStart",
                    color: "#808285",
                    paddingBottom: " 8px",
                  }}
                >
                  Needed Instruments
                </div>
                {!isPageReadOnly && (
                  <Select
                    mode="multiple"
                    size="large"
                    style={{ width: "100%", textAlign: "left" }}
                    placeholder="Search Instruments"
                    showSearch
                    allowClear
                    autoClearSearchValue={false}
                    onSelect={onEquipmentSelect}
                    onDeselect={onEquipmentDeselect}
                    onChange={onSearchFunction}
                    filterOption={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    tagRender={() => null}
                    value={selectedEquipmentsId}
                    menuItemSelectedIcon={<CheckboxSelectedIcon />}
                    disabled={isPageReadOnly}
                  >
                    {equipmentsList.map((item) => (
                      <Option
                        key={item.equipment.id}
                        title={item.equipment.equipmentName}
                      >
                        <div className="display-flex-center">
                          <SignedURLImage
                            fallback={equipmentPlaceholder}
                            fileName={item?.equipment?.image[0]}
                            key={item?.equipment?.image[0]}
                            className="fit-image"
                            height="40px"
                            width="55px"
                            isTrayFormInstruments={true}
                          />
                          <Text style={{ marginLeft: "1em" }} ellipsis>
                            {item.equipment.equipmentName}
                          </Text>
                        </div>
                      </Option>
                    ))}
                  </Select>
                )}
                <div style={{ marginTop: "1.5rem" }}>
                  {selectedEquipments.map((trayEquipment, index) => {
                    return (
                      <Row key={index} gutter={[0, 8]}>
                        <Col span={24}>
                          <Tag
                            color="#f5f5f5"
                            key={trayEquipment.equipment.equipment.id}
                            icon={
                              <SignedURLImage
                                onClick={() => {
                                  setEquipmentToView(
                                    trayEquipment.equipment.equipment
                                  );
                                  setIsEquipmentModalVisible(true);
                                }}
                                fileName={
                                  trayEquipment.equipment.equipment.image[0]
                                }
                                key={trayEquipment.equipment.equipment.image[0]}
                                height="50px"
                                width="75px"
                                className="fit-image"
                                isTrayFormInstruments={true}
                                // style={{
                                //   pointerEvents: "none",
                                // }}
                              />
                            }
                            closable={!isPageReadOnly}
                            closeIcon={<TagCloseIcon />}
                            onClose={() =>
                              onEquipmentDeselect(
                                trayEquipment.equipment.equipment.id
                              )
                            }
                            style={{ color: "black", height: "100%" }}
                          >
                            <Row>
                              <Col span={18} className="display-flex-center">
                                <div
                                  className="equip-text"
                                  style={{ whiteSpace: "break-spaces" }}
                                >
                                  <Paragraph
                                    style={{ margin: "0 0.4em" }}
                                    ellipsis={{ rows: 2 }}
                                  >
                                    {
                                      trayEquipment.equipment.equipment
                                        .equipmentName
                                    }
                                  </Paragraph>
                                </div>
                              </Col>
                              <Col span={6}>
                                {/* <InputNumber
                                  style={{ width: "5em" }}
                                  defaultValue={
                                    trayEquipment.equipment.quantity
                                  }
                                  min={1}
                                  max={trayEquipment.quantity}
                                  onChange={(value) => {
                                    trayEquipment.equipment.quantity = value;
                                  }}
                                /> */}
                                <InputNumber
                                  style={{ width: "5em" }}
                                  value={parseInt(
                                    trayEquipment.equipment.quantity
                                  )}
                                  min={1}
                                  max={
                                    equipmentsList.find(
                                      (el) =>
                                        el.equipment.id ===
                                        trayEquipment.equipment.equipment.id
                                    )?.quantity
                                  }
                                  onChange={(value) => {
                                    const equipment = [...selectedEquipments];
                                    equipment[index].equipment.quantity = value;
                                    setSelectedEquipments(equipment);
                                  }}
                                  disabled={isPageReadOnly}
                                />
                              </Col>
                            </Row>
                          </Tag>
                        </Col>
                      </Row>
                    );
                  })}
                </div>

                <div style={{ marginTop: "1.5rem" }}>
                  <Collapse defaultActiveKey={editRecord && 1}>
                    <Panel
                      header="Description & Tutorial"
                      key="1"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <Form.Item label="Description" name="description">
                        <Input.TextArea
                          placeholder="A description on how this procedure works."
                          rows={5}
                          disabled={isPageReadOnly}
                        />
                      </Form.Item>
                      {isPageReadOnly && !videoResult ? (
                        <></>
                      ) : (
                        <Form.Item label="Media Tutorial" name="video">
                          <div>
                            <div className="icon-overlay-container">
                              <Upload
                                name="video"
                                accept=".mp4, .m4v , .mov"
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={beforeVideoUpload}
                                customRequest={onVideoUpload}
                                openFileDialogOnClick={
                                  videoSignedToken ? false : true
                                }
                                disabled={isPageReadOnly}
                              >
                                {videoSignedToken ? (
                                  <>
                                    <video
                                      style={{
                                        width: "-webkit-fill-available",
                                        position: "absolute",
                                        height: "inherit",
                                        opacity: "0.7",
                                      }}
                                      poster={videoResult.result?.thumbnail.replace(
                                        videoResult.result.uid,
                                        videoSignedToken
                                      )}
                                    />
                                    <div
                                      style={{
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                        width: "100%",
                                        height: "inherit",
                                      }}
                                    />
                                    <div style={{ position: "absolute" }}>
                                      <PlayIcon
                                        onClick={() => setModal(true)}
                                      />
                                    </div>
                                    {!isPageReadOnly &&
                                      overlayMediaDelete(false)}
                                  </>
                                ) : (
                                  videoUploadButton
                                )}
                              </Upload>
                            </div>
                          </div>
                        </Form.Item>
                      )}
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Form>
        {isEquipmentModalVisible && (
          <EquipmentModal
            close={closeModal}
            show={isEquipmentModalVisible}
            equipment={equipmentToView}
          />
        )}
        <Modal
          width={"60%"}
          closeIcon={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CloseIcon />
            </span>
          }
          visible={showModal}
          footer={null}
          centered={true}
          closable={true}
          destroyOnClose
          maskClosable={true}
          onCancel={() => {
            setModal(false);
          }}
        >
          <Stream controls src={videoSignedToken} />
        </Modal>
      </Spin>
    );
  }
);

export default SurgicalStepPage;
