import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../containers/MainContainer";
import CardList from "../components/CardList";
import CustomCard from "../components/CustomCard";
import hospitalService from "../services/hospitalService";
import toastNotification from "../components/toastNotification";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";
import { ReactComponent as AddressIcon } from "../assets/icons/iconLocation.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/iconPhone.svg";
import { ReactComponent as HospitalColorIcon } from "../assets/icons/colorIconHospital.svg";
import { useHospitalContext } from "../context/HospitalContext";

const { confirm } = Modal;
const customPageSize = 12;

const HospitalsPage = (props) => {
  const {
    match: { url },
    history,
  } = props;

  const { setHospitalDistributors } = useHospitalContext();
  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");
  const breadCrumb = useBreadCrumbContext();

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Hospitals",
        backBtnPath: "/hospital",
      }
    : {
        title: "Hospitals",
        btnText: "Add Hospital",
        onBtnClick: () => {
          history.push("/hospital/new");
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Hospitals",
            path: "/hospital/deleted",
          },
        ],
      };

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const [hospitalCount, setHospitalCount] = useState(0);
  const [hospitalSearchText, setHospitalSearchText] = useState();

  const actions = (hospital) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onHospitalRestore(hospital.id);
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onHospitalEdit(hospital);
            },
          },
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(hospital.id);
            },
          },
        ];
  };

  const cardContent = (hospital) => [
    {
      icon: <AddressIcon />,
      content: hospital.address
        ? (() => {
            const address = [];
            if (hospital.city) address.push(hospital.city);
            if (hospital.state) address.push(hospital.state);
            if (hospital.country) address.push(hospital.country);

            return address.length ? address.join(", ") : null;
          })()
        : null,
    },
    {
      icon: <PhoneIcon fill="#808285" />,
      content: hospital.contactNumber1
        ? hospital.contactNumber1
        : hospital.contactNumber2
        ? hospital.contactNumber2
        : null,
    },
  ];

  const onHospitalEdit = (hospital) => {
    breadCrumb.setBreadCrumb({ hospital: hospital.hospitalName });
    setHospitalDistributors(hospital.distributors);
    history.push(`/hospital/${hospital.id}/setup`, {
      record: hospital,
      editMode: true,
    });
  };

  const onHospitalRestore = async (hospitalId) => {
    setLoading(true);
    const res = await hospitalService.patchRestoreHospital(hospitalId);
    if (res) {
      toastNotification("success", "Hospital Restored");
      populateHospitalsList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the hospital");
    }
  };

  const showDeleteConfirmation = (hospitalId) => {
    confirm({
      title: "Delete Hospital",
      content: "Are you sure you want to delete this Hospital?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteHospital(hospitalId);
      },
    });
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setHospitalSearchText(searchValue);
        const success = await getHospitalCount(searchValue);
        if (success) {
          setCurrentHospitalPage(1);
          populateHospitalsList(customPageSize, 0, searchValue);
        } else {
          toastNotification("error", "Error fetching hospital records");
        }
      }, 500)
    );
  };

  const populateHospitalsList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await hospitalService.getHospitals(
      isDeletedPage,
      limit,
      skip,
      searchValue,
      null
    );
    if (data) {
      setHospitalList(data);
    } else {
      toastNotification("error", "Error fetching the hospital records");
    }
    setLoading(false);
  };

  const getHospitalCount = async (searchText) => {
    const count = await hospitalService.getHospitalCount(
      isDeletedPage,
      searchText
    );
    if (count >= 0) {
      setHospitalCount(count);
      return true;
    } else {
      return false;
    }
  };

  const deleteHospital = async (id) => {
    setLoading(true);
    const isDeleted = await hospitalService.putHospital({
      id,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Hospital deleted successfully");
      await populateHospitalsList();
    } else {
      toastNotification("error", "Error deleting the hospital");
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const success = await getHospitalCount();
      if (success) populateHospitalsList(customPageSize, 0);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Hospital Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={hospitalList}
        loading={loading}
        pagination={{
          pageSize: customPageSize,
          total: hospitalCount,
          current: currentHospitalPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentHospitalPage(page);
            await populateHospitalsList(
              pageSize,
              pageSize * (page - 1),
              hospitalSearchText
            );
            setLoading(false);
          },
        }}
        renderItem={(hospital) => {
          const address = [];
          if (hospital.city) address.push(hospital.city);
          if (hospital.state) address.push(hospital.state);
          if (hospital.country) address.push(hospital.country);

          return (
            <List.Item>
              <CustomCard
                headerIcon={<HospitalColorIcon />}
                title={hospital.hospitalName}
                actions={actions(hospital)}
                onClick={
                  !hospital.isDeleted
                    ? () => {
                        breadCrumb.setBreadCrumb({
                          hospital: hospital.hospitalName,
                        });
                        setHospitalDistributors(hospital.distributors);
                        history.push(`/hospital/${hospital.id}/cases`);
                      }
                    : null
                }
                bodyContent={cardContent(hospital)}
              />
            </List.Item>
          );
        }}
      />
    </MainContainer>
  );
};
export default HospitalsPage;
