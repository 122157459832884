import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../../containers/MainContainer";
import CardList from "../../components/CardList";
import CustomCard from "../../components/CustomCard";
import areaService from "../../services/areaService";
import userServices from "../../services/userServices";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as KeyIcon } from "../../assets/icons/icon_Access-Role.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/icon_email.svg";
import { ReactComponent as RegionsColorIcon } from "../../assets/icons/color-icons_regions.svg";
import { RolesDisplay, Roles } from "../../config/role-config.json";
import { useBreadCrumbContext } from "../../context/BreadCrumbContext";

const { confirm } = Modal;
const customPageSize = 12;

const RegionalManagersPage = (props) => {
  const {
    match: { url, params },
    history,
  } = props;

  const { areaid: areaId } = params;
  const isDeletedPage = url.includes("deleted");

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Regional Managers",
        backBtnPath: `${url.replace("/deleted", "")}`,
      }
    : {
        title: "Regional Managers",
        btnText: "Add Regional Manager",
        onBtnClick: () => {
          history.push(`${url}/new`);
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Regional Managers",
            path: `${url}/deleted`,
          },
        ],
      };

  const [regionalManagerCount, setRegionalManagerCount] = useState(0);
  const [rmSearchText, setRMSearchText] = useState();
  const [regionalManagerList, setRegionalManagerList] = useState([]);
  const [currentRMPage, setCurrentRMPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const { setBreadCrumb } = useBreadCrumbContext();

  const cardContent = (rm) => [
    {
      icon: <KeyIcon />,
      content: RolesDisplay.REGIONAL_MANAGER,
    },
    {
      icon: <EmailIcon fill="#808285" />,
      content: rm.email,
    },
  ];

  const actions = (rm) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onUserRestore(rm);
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              setBreadCrumb((prev) => ({
                ...prev,
                user: `${rm.firstName ?? ""} ${rm.lastName ?? ""}`.trim(),
              }));
              history.push(`${url}/${rm.id}/setup`, {
                record: rm,
                editMode: true,
              });
            },
          },
          !rm.isActive
            ? {
                title: "Resend Invite",
                onClick: (menuItemProps) => {
                  menuItemProps.domEvent.stopPropagation();
                  sendActivationLink(rm.email);
                },
              }
            : null,
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(rm.id);
            },
          },
        ];
  };

  const onUserRestore = async (user) => {
    setLoading(true);
    const res = await userServices.patchRestoreUser(user.id);  
    if (res) {
      toastNotification("success", "User Restored");
      populateRegionalManagerList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the user");
    }
  };

  const showDeleteConfirmation = (rmId) => {
    confirm({
      title: "Delete Regional Manager",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        deleteRegionalManager(rmId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setRMSearchText(searchValue);
        await getSetRMCountAndPopulate(searchValue);
        setLoading(false);
      }, 500)
    );
  };

  /** API calls */
  const populateRegionalManagerList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await areaService.getAreaUsers(
      areaId,
      isDeletedPage,
      [Roles.REGIONAL_MANAGER],
      limit,
      skip,
      searchValue
    );
    if (data) {
      setRegionalManagerList(data);
    } else {
      toastNotification("error", "Error fetching the Regional Manager records");
    }
    setLoading(false);
  };

  const deleteRegionalManager = async (id) => {
    setLoading(true);
    const isDeleted = await userServices.deleteUser(id);
    if (isDeleted) {
      toastNotification(
        "success",
        "Regional Manager record deleted successfully"
      );
      await getSetRMCountAndPopulate();
    } else {
      toastNotification("error", "Error deleting the Regional Manager record");
    }
    setLoading(false);
  };

  const getSetRMCountAndPopulate = async (searchValue) => {
    const count = await userServices.getUsersCount(
      "regionalmanager",
      areaId,
      isDeletedPage,
      searchValue
    );

    if (count === false) {
      toastNotification("error", "Error fetching Regional Manager records");
    } else {
      setRegionalManagerCount(count);
      if (count > 0) {
        setCurrentRMPage(1);
        await populateRegionalManagerList(customPageSize, 0, searchValue);
      } else setRegionalManagerList([]);
    }
  };

  const sendActivationLink = async (email) => {
    const res = await userServices.postResendUserActivationLink(email);
    if (res?.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetRMCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by First Name, Last Name or Email"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={regionalManagerList}
        loading={loading}
        renderItem={(rm) => {
          return (
            <List.Item>
              <CustomCard
                headerIcon={<RegionsColorIcon />}
                title={(() => {
                  const rmName = [rm.firstName, rm.middleName, rm.lastName]
                    .join(" ")
                    .trim();
                  return rmName.length ? rmName : "Not Set";
                })()}
                actions={actions(rm)}
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                        setBreadCrumb((prev) => ({
                          ...prev,
                          user: `${rm.firstName ?? ""} ${
                            rm.lastName ?? ""
                          }`.trim(),
                        }));
                        history.push(`${url}/${rm.id}/hospital`);
                      }
                }
                bodyContent={cardContent(rm)}
              />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: customPageSize,
          total: regionalManagerCount,
          current: currentRMPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            await populateRegionalManagerList(
              pageSize,
              pageSize * (page - 1),
              rmSearchText
            );
            setCurrentRMPage(page);
            setLoading(false);
          },
        }}
      />
    </MainContainer>
  );
};
export default RegionalManagersPage;
