import { useEffect, useState } from "react";
import {
  Image,
  Layout,
  Avatar,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  Typography,
  Spin,
  Tag,
} from "antd";
import { useHistory, Route, useLocation } from "react-router-dom";
import auth from "../services/authService";
import utilityService from "../services/utilityService";
import { useAuthContext } from "../context/AuthContext";
import logo from "../assets/DInamicOR+Logo.svg";
import { UserOutlined } from "@ant-design/icons";
import { RolesDisplay } from "../config/role-config.json";
import HospitalColorIcon from "../assets/icons/colorIconHospital.svg";
import DistributorColorIcon from "../assets/icons/color-icons_Distributors.svg";
import ManufacturerColorIcon from "../assets/icons/colorIconManufacturer.svg";
import { ReactComponent as LogoutDropDown } from "../assets/icons/logoutDropDown.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/iconArrow.svg";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";

const { Header } = Layout;
const { Text, Paragraph } = Typography;

const MainHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const authContext = useAuthContext();
  const [profileName, setProfileName] = useState();
  const [headerEntity, setHeaderEntity] = useState();
  const [icon, setIcon] = useState();
  const { breadCrumb, setBreadCrumb } = useBreadCrumbContext();

  const onLogout = async () => {
    await auth.logout();
    authContext.setAuthContext(null);
    setBreadCrumb({});
    history.push("/login");
  };

  const userMenu = (
    <Menu>
      <Menu.Item onClick={onLogout}>Logout</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const name = [];
    if (authContext.user.firstName) name.push(authContext.user.firstName);
    if (authContext.user.lastName) name.push(authContext.user.lastName);
    if (name.length === 0) name.push(authContext.user.email);
    setProfileName(name.join(" "));
  }, []);

  useEffect(() => {
    (async () => {
      const entity = location.pathname.split("/")[1];
      let breadcrumbEntity;

      switch (entity) {
        case "hospital":
          setIcon(HospitalColorIcon);
          breadcrumbEntity = breadCrumb.hospital;
          break;
        case "distributor":
          setIcon(DistributorColorIcon);
          breadcrumbEntity = breadCrumb.distributor;
          break;
        case "manufacturer":
          setIcon(ManufacturerColorIcon);
          breadcrumbEntity = breadCrumb.manufacturer;
          break;
      }

      if (!breadcrumbEntity) {
        breadcrumbEntity = await utilityService.getHeaderEntity(
          authContext.user,
          setBreadCrumb
        );
      }
      setHeaderEntity(breadcrumbEntity);
    })();
  }, [breadCrumb]);

  return (
    <Header>
      <Row style={{ height: "100%" }}>
        <Col
          span={4}
          style={{ height: "100%" }}
          className="display-flex-center"
        >
          <Image src={logo} width="10.7rem" height="3.125rem" preview={false} />
        </Col>
        <Col
          span={4}
          className="display-flex-center"
          style={{ justifyContent: "center" }}
        >
          <Route
            exact
            path={[
              "/hospital/:id/*",
              "/distributor/:id/*",
              "/manufacturer/:id/*",
            ]}
          >
            <Tag className="headerNameTag">
              <img
                style={{
                  height: "3em",
                  width: "3em",
                  objectFit: "contain",
                }}
                src={icon}
              />

              {headerEntity ? (
                <Paragraph className="headerNameText" ellipsis={{ rows: 2 }}>
                  {headerEntity}
                </Paragraph>
              ) : (
                <Spin spinning size="small" style={{ padding: "30%" }} />
              )}
            </Tag>
          </Route>
        </Col>
        <Col span={4} offset={12} className="display-flex-center">
          <Dropdown overlay={userMenu} placement="bottomRight">
            <Button
              ghost
              style={{
                height: "fit-content",
                padding: "0",
                boxShadow: "none",
                width: "15rem",
              }}
            >
              <Row>
                <Col span={5} className="display-flex-center">
                  <Avatar size="large" icon={<UserOutlined />}></Avatar>
                </Col>
                <Col span={15} style={{ textAlign: "left" }}>
                  <Text
                    strong
                    ellipsis
                    style={{ width: "-webkit-fill-available" }}
                  >
                    {profileName}
                  </Text>
                  <br />
                  <Text ellipsis style={{ width: "-webkit-fill-available" }}>
                    {RolesDisplay[authContext.user.role]}
                  </Text>
                </Col>
                <Col
                  span={4}
                  className="display-flex-center"
                  style={{ paddingLeft: "5px" }}
                >
                  <LogoutDropDown style={{ zIndex: 1, position: "absolute" }} />
                  <ArrowIcon
                    style={{ zIndex: 2, position: "relative", margin: "5px" }}
                  />
                </Col>
              </Row>
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default MainHeader;
