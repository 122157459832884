import ProcedureFrom from "../../shared-pages/manufacturer/ProcedureForm";

const AreaProcedureForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { procedureid: procedureId, areaid: areaId, id } = params;
  return (
    <ProcedureFrom
      entityId={areaId}
      entityName="area"
      procedureId={procedureId}
      procedure={state?.record}
      manufacturerId={id}
    />
  );
};

export default AreaProcedureForm;

