import { Row, Col, Space } from "antd";
import SurgicalStepPreview from "../../pages/LiveCase/SurgicalStepPreview";
import SurgicalStep from "../../pages/LiveCase/SurgicalStep";
import Notes from "../../pages/LiveCase/Notes";
import Checklist from "../../pages/LiveCase/CheckList";
import ChecklistPreview from "../../pages/LiveCase/CheckListPreview";
import LastPage from "../../pages/LiveCase/LastPage";
import NotesPreview from "../../pages/LiveCase/NotesPreview";
import LiveCaseFooter from "./LiveCaseFooter";
import { useExecutionStepsContext } from "../../context/ExecutionStepsContext";

const ExecutionFlowContainer = ({isReadOnly}) => {
  const {
    stepIndexObj: { currentStepIndex },
    executionSteps,
  } = useExecutionStepsContext();

  /** Rendering components */
  const renderMainStep = () => {
    if (executionSteps[currentStepIndex]) {
      switch (executionSteps[currentStepIndex]?.stepType) {
        case "CHECKLIST":
          return <Checklist step={executionSteps[currentStepIndex]} />;
        case "NOTES":
          return <Notes step={executionSteps[currentStepIndex]} />;
        case "SURGICAL_STEP":
          return <SurgicalStep step={executionSteps[currentStepIndex]} />;
        default:
          return null;
      }
    }
  };

  const renderPreviewStep = () => {
    const previewStep = currentStepIndex + 1;
    if (executionSteps[previewStep]) {
      switch (executionSteps[previewStep]?.stepType) {
        case "CHECKLIST":
          return <ChecklistPreview step={executionSteps[previewStep]} />;
        case "NOTES":
          return <NotesPreview step={executionSteps[previewStep]} />;
        case "SURGICAL_STEP":
          return <SurgicalStepPreview step={executionSteps[previewStep]} />;
        default:
          return null;
      }
    } else {
      return <LastPage />;
    }
  };
  /** Rendering components End */

  return (
    <>
      <Row>
        <Col span={16} className="main-step-col">
          <div className="main-step-content display-grid-center">
            <div>{renderMainStep()}</div>
          </div>
        </Col>
        <Col span={8} className="preview-step-col-1 display-grid-center">
          <Row>
            <Col span={24} className="preview-step-col-2">
              {renderPreviewStep()}
            </Col>
          </Row>
        </Col>
      </Row>
      <LiveCaseFooter isReadOnly={isReadOnly}/>
    </>
  );
};
export default ExecutionFlowContainer;
