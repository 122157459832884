import { useEffect, useState } from "react";
import { List, Typography, Input } from "antd";
import EquipmentImage from "../../components/EquipmentImage";
import EquipmentModal from "../../components/EquipmentModal";
import { useExecutionStepsContext } from "../../context/ExecutionStepsContext";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import { ReactComponent as CaseCart } from "../../assets/icons/iconCaseCart.svg";

const { Paragraph, Text } = Typography;
const { Search } = Input;

const AllEquipmentsDrawer = () => {
  const { executionSteps, allEquipments } = useExecutionStepsContext();

  const [phasesAndSteps, setPhasesAndSteps] = useState();
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [equipment, setEquipment] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [isSearchGoing, setIsSearchGoing] = useState(false);

  const closeEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setShowEquipmentModal(false);
  };

  const getSectionNumber = (equipment) => {
    equipment.sectionNumber = [...new Set(equipment.sectionNumber)];
    equipment.sectionNumber.sort((a, b) => {
      if (!a) return 1;
      return a < b ? 1 : -1;
    });
    let equipmentSectionNumber = equipment.sectionNumber;
    if (
      equipment.sectionNumber.length > 1 &&
      isNaN(equipment.sectionNumber[equipment.sectionNumber.length - 1])
    ) {
      const cartSectionNumberArray = equipment.sectionNumber.slice(
        0,
        equipment.sectionNumber.length - 1
      );
      equipmentSectionNumber = [-1, ...cartSectionNumberArray];
    }
    return (
      <span style={{ display: "inline-flex", flexWrap: "wrap" }}>
        {equipmentSectionNumber.map((el) => (
          <span
            className={
              el > 0 ? "section-number" : "section-number-for-case-cart"
            }
          >
            {el > 0 ? <span>{el}</span> : <CaseCart />}
          </span>
        ))}
      </span>
    );
  };

  const renderPhases = () => {
    const phases = [];
    for (const phaseId in phasesAndSteps) {
      const renderSteps = [];
      for (const step of phasesAndSteps[phaseId].steps) {
        if (step.stepType === "SURGICAL_STEP") {
          renderSteps.push(
            <>
              <div className="step-label">{step.label}</div>
              <List
                dataSource={step.stepEquipments}
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 3,
                  xxl: 4,
                }}
                renderItem={(equipment) => (
                  <List.Item>
                    <div>
                      <EquipmentImage
                        imageUrl={equipment.equipment.image[0]}
                        onClickHandler={() => {
                          setEquipment(equipment.equipment);
                          setShowEquipmentModal(true);
                        }}
                        videoUrl={equipment.equipment.video[0]}
                        fallbackImage={equipmentPlaceholder}
                      />
                      <Paragraph
                        ellipsis={{ rows: 2 }}
                        strong
                        style={{
                          marginBottom: 0,
                          marginTop: "5px",
                          lineHeight: "1rem",
                        }}
                      >
                        {equipment.equipment.equipmentName}
                      </Paragraph>
                      <section style={{ marginTop: "5px" }}>
                        {getSectionNumber(equipment.equipment)}
                        <Text>
                          <span>Qty: {equipment.quantity}</span>
                        </Text>
                      </section>
                    </div>
                  </List.Item>
                )}
              />
            </>
          );
        }
      }
      if (renderSteps.length > 0) {
        phases.push(
          <>
            <div className="phase-name">
              {phasesAndSteps[phaseId].phaseName}
            </div>
            {renderSteps}
          </>
        );
      }
    }
    return phases;
  };

  useEffect(() => {
    const groupedPhases = [];
    for (const step of executionSteps) {
      if (!groupedPhases[step.phase.id])
        groupedPhases[step.phase.id] = {
          phaseName: step.phase.phaseName,
          steps: [],
        };
      groupedPhases[step.phase.id].steps.push(step);
    }
    setPhasesAndSteps({ ...groupedPhases });
  }, []);

  const onSearchFunction = (keyword) => {
    if (!keyword) {
      setIsSearchGoing(false);
    } else {
      setIsSearchGoing(true);
      keyword = keyword.toLowerCase();
      let result = [];
      allEquipments.forEach((el) => {
        if (el.equipment.name.includes(keyword)) {
          result?.push(el);
        }
      });
      //Remove multiple copies of same instrument
      result = result.filter(
        (el, i, a) => a.findIndex((e) => e.id === el.id) === i
      );
      setSearchResult(result);
    }
  };

  const renderSearchResult = () => {
    return (
      <div>
        <div style={{ padding: "20px 0", color: "rgb(128,130,133)" }}>Search Results</div>
        <List
          dataSource={searchResult}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          renderItem={(equipment) => (
            <List.Item>
              <div>
                <EquipmentImage
                  imageUrl={equipment.equipment.image[0]}
                  onClickHandler={() => {
                    setEquipment(equipment.equipment);
                    setShowEquipmentModal(true);
                  }}
                  videoUrl={equipment.equipment.video[0]}
                  fallbackImage={equipmentPlaceholder}
                />
                <Paragraph
                  ellipsis={{ rows: 2 }}
                  strong
                  style={{
                    lineHeight: "1rem",
                    marginBottom: 0,
                    marginTop: "5px",
                  }}
                >
                  {equipment.equipment.equipmentName}
                </Paragraph>
                <section style={{ marginTop: "5px" }}>
                  {getSectionNumber(equipment.equipment)}
                </section>
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  };

  return (
    <>
      <div style={{ paddingBottom: "20px" }}>
        <Search
          placeholder="Search by Instrument Name"
          onSearch={onSearchFunction}
          onChange={(e) => onSearchFunction(e.target.value)}
          allowClear
          style={{ width: "100%" }}
          key="searchInput"
        />
      </div>
      {!isSearchGoing ? (
        <div className="dor-all-equipments-drawer">{renderPhases()}</div>
      ) : (
        <div className="dor-all-equipments-drawer">{renderSearchResult()}</div>
      )}
      {showEquipmentModal && (
        <EquipmentModal
          show={showEquipmentModal}
          close={closeEquipmentModal}
          equipment={equipment}
        />
      )}
    </>
  );
};

export default AllEquipmentsDrawer;
