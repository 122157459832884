import http from "./httpService";

async function getHospitals(isDeleted, limit, skip, searchText, hospitalIds) {
  try {
    let api;
    const params = {
      where: { isDeleted, ...(hospitalIds ? { id: { in: hospitalIds } } : {}) },
      sort: "createdAt DESC",
      limit,
      skip,
    };

    if (searchText) {
      api = "/hospital/search";
      params.where.hospitalName = { contains: searchText };
    } else {
      api = "/hospital";
      params.sort = "createdAt DESC";
      params.populate = "distributors";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getHospital(id) {
  try {
    const res = await http.get(`/hospital/${id}`, {
      params: {
        isDeleted: false,
        populate: "distributors,manufacturers",
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function postHospital(hospital) {
  try {
    return await http.post("/hospital", hospital);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function putHospital(hospital) {
  try {
    return await http.patch(`/hospital/${hospital.id}`, hospital);
  } catch (exception) {
    console.log(exception.response);
    return false;
  }
}

const patchRestoreHospital = async (hospitalId) => {
  try {
    const res = await http.patch(`/hospital/restore/${hospitalId}`);
    return res?.status === 200;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const getHospitalCount = async (isDeleted, searchText, hospitalIds) => {
  try {
    const params = {
      isDeleted,
      searchText,
      hospitalIds: hospitalIds ? `[${hospitalIds.toString()}]` : undefined,
    };
    const res = await http.get("/hospital/count", {
      params,
    });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function getHospitalUsers(
  hospitalId,
  hospitalUserRoles,
  isDeleted,
  limit,
  skip,
  searchValue
) {
  try {
    let api;
    const params = {
      where: { role: { in: hospitalUserRoles }, isDeleted },
      limit,
      skip,
    };
    if (searchValue) {
      api = `/hospital/${hospitalId}/users/search`;
      params.where.or = [
        { firstName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { email: { contains: searchValue } },
      ];
    } else {
      api = `/hospital/${hospitalId}/users`;
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

const getCasesCount = async (params) => {
  try {
    const res = await http.get("/cases/count", { params });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function getHospitalCases(whereCondition, limit, skip) {
  try {
    let api;
    let params;
    if (whereCondition.searchText) {
      api = "/cases/search";
      params = { ...whereCondition, limit, skip };
    } else {
      api = "/cases";
      params = {
        where: whereCondition,
        sort: "createdAt DESC",
        populate: "surgeon,procedure,hospital",
        limit,
        skip,
      };
    }
    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

async function postCase(cases) {
  try {
    return await http.post("/cases", cases);
  } catch (exception) {
    console.log(exception);
    return exception.response.data;
  }
}

async function putCase(cases) {
  try {
    return await http.patch(`/cases/${cases.id}`, cases);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getCase(id) {
  try {
    const res = await http.get(`/cases/${id}`, {
      params: {
        isDeleted: false,
        populate: "surgeon,procedure,hospital",
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const patchRestoreCase = async (caseId) => {
  try {
    const res = await http.patch(`/cases/restore/${caseId}`);
    return res?.status === 200;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function getCaseORTeam(caseId) {
  try {
    const res = await http.get(`/caseuser`, {
      params: {
        case: caseId,
        populate: "user",
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function postCaseORTeam(caseId, caseORTeam) {
  try {
    return await http.post(`/cases/${caseId}/ORTeam`, caseORTeam);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getHospitalProcedures(distributorIds, limit, skip, searchText, regionalManagerIds=[]) {
  try {
    const params = {
      where: {
        or: [{distributor: { in: distributorIds }},{ regionalManager: { in: regionalManagerIds }}],
        isDeleted: false,
      },
      populate: "distributor,regionalManager",
      sort: "createdAt DESC",
      limit,
      skip,
    };

    let api;
    if (searchText) {
      params.where.procedureName = { contains: searchText };
      api = `/procedure/search`;
    } else {
      api = `/procedure`;
    }

    const res = await http.get(api, {
      params,
    });

    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}
async function getHospitalUserSurgeons(userId, caseType) {
  try {
    const res = await http.get(
      `/caseuser/${userId}/getSurgeonForHospitalStaff`,
      {
        params: { caseType },
      }
    );
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

/**
 * Get Surgeons that are assigned to a case where a procedure is attached.
 * This procedure belongs.
 */
async function getHospitalSurgeons(hospitalId, caseType, redOnlyCasesParams) {
  const res = await http.dorAxiosGet(`/cases/${hospitalId}/getSurgeons`, {
    caseType,
    ...redOnlyCasesParams,
  });
  return res?.data;
}

async function getHospitalUserCasesBySurgeon(userId, params) {
  try {
    const res = await http.get(`/caseuser/${userId}/getMyCases`, {
      params,
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getHospitalCasesBySurgeon(params) {
  params.populate = "surgeon,procedure";
  params.sort = "createdAt DESC";
  try {
    const res = await http.get(`/cases`, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getHospitalUserCases(
  userId,
  caseType,
  isDeleted,
  searchText,
  count,
  limit,
  skip
) {
  try {
    let api;
    let params;
    if (searchText) {
      api = `/caseuser/${userId}/search`;
      params = {
        caseType,
        searchText,
        isDeleted,
        count,
        limit,
        skip,
      };
    } else {
      const resUserCases = await http.get("/caseuser", {
        params: {
          where: {
            user: userId,
            isDeleted,
          },
          limit: 3000,
          select: "case",
        },
      });

      api = `/cases`;
      params = {
        where: {
          id: { in: resUserCases.data?.map((userCase) => userCase.case) },
          isStarted: caseType ? caseType : undefined,
          isDeleted,
        },
        populate: "surgeon,procedure,hospital",
        sort: "createdAt DESC",
        limit,
        skip,
      };
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const getHospitalProcedureIds = async (
  hospitalId,
  isDeletedCases,
  entity,
  entityId
) => {
  const res = await http.dorAxiosGet("/cases/getAttachedProcedures", {
    hospitalId,
    caseStatus: isDeletedCases,
    entity,
    ...(entity === "distributor"
      ? { distributorId: entityId }
      : { manufacturerId: entityId }),
  });

  return res?.data;
};

const getDistributorsAndManufacturers = async (limit, skip, searchText) => {
  const searchParam = searchText
    ? { distributorName: { contains: searchText } }
    : {};

  const res = await http.dorAxiosGet("/distributor", {
    where: { isDeleted: false, ...searchParam },
    limit,
    skip,
    sort: "createdAt DESC",
  });

  return res?.data;
};

const getDistributorsAndManufacturersCount = async (searchText) => {
  const res = await http.dorAxiosGet("/distributor/count", {
    isDeleted: false,
    // isManufacturer: false,
    searchText,
  });

  return res?.data;
};

// Get regional managers using mfcIds and hospitalIds
const getRegionalManagers = async (manufacturerIds, hospitalId) => {
  const res = await http.dorAxiosGet("/manufacturer/getRegionalManagers", {
    manufacturerId:  manufacturerIds,
    hospitalId
  });
  return res?.data;
};

const getManufacturers = async (regionalManagerId) => {
  const res = await http.dorAxiosGet("/user/getManufacturers", {
    regionalManagerId:  regionalManagerId,
  });
  return res?.data;
};

export default {
  getHospital,
  getHospitals,
  postHospital,
  putHospital,
  patchRestoreHospital,
  getHospitalCount,
  getHospitalUsers,
  getCasesCount,
  getHospitalCases,
  getHospitalUserCases,
  postCase,
  putCase,
  getCase,
  patchRestoreCase,
  getCaseORTeam,
  postCaseORTeam,
  getHospitalProcedures,
  getHospitalUserSurgeons,
  getHospitalSurgeons,
  getHospitalUserCasesBySurgeon,
  getHospitalCasesBySurgeon,
  getHospitalProcedureIds,
  getDistributorsAndManufacturers,
  getDistributorsAndManufacturersCount,
  getRegionalManagers,
  getManufacturers
};
