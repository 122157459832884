import React, { memo, useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, Modal, Divider, Spin } from "antd";
import executionService from "../../services/executionService";
import toastNotification from "../../components/toastNotification";
import FormSelect from "./Partial/FormSelect";
import { useRouteMatch } from "react-router-dom";
const { confirm } = Modal;

const NotesPage = memo(
  ({
    addNewItem,
    updateCurrentItem,
    editRecord,
    updatedData,
    //saveFinalData,
    hideItem,
    getPhaseId,
    currentIndex,
    parentIndex,
    staticItems,
    getIndexOnCreate,
    isPageReadOnly,
  }) => {
    const liveUrl = useRouteMatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const addNotesPhase = async (phaseFormValues) => {
      setLoading(true);
      phaseFormValues.stepType = "NOTES";
      phaseFormValues.phase = getPhaseId();
      phaseFormValues.index = getIndexOnCreate(
        staticItems,
        "STEP",
        parentIndex,
        currentIndex
      );
      const res = await executionService.postStep(phaseFormValues);
      if (res.status === 200) {
        toastNotification("success", "New Notes Added");
        let item = {
          id: new Date().getTime(),
          phase: res.data.id,
          content: res.data.label,
          original: res.data,
          current: currentIndex,
          parent: parentIndex,
        };
        addNewItem(item);
        //saveFinalData(item);
      } else {
        toastNotification("error", "Error adding a new phase record");
      }
      setLoading(false);
    };

    const editNotesPhase = async (phaseFormValues) => {
      setLoading(true);
      phaseFormValues.stepType = "NOTES";
      phaseFormValues.phase = editRecord.original.phase;
      const res = await executionService.putStep(
        phaseFormValues,
        editRecord.phase
      );
      if (res.status === 200) {
        toastNotification("success", "Steps Updated Successfully");
        updateCurrentItem(null);
        updatedData();
      } else {
        toastNotification("error", "Error updating the steps record");
      }
      setLoading(false);
    };

    const onFinish = (phaseFormValues) => {
      if (editRecord.phase) {
        editNotesPhase(phaseFormValues);
      } else {
        addNotesPhase(phaseFormValues);
      }
    };

    useEffect(() => {
      if (editRecord) {
        form.setFieldsValue(editRecord.original);
      }
    });

    const deletePhase = async (id) => {
      const isDeleted = await executionService.deleteStep(editRecord.phase);
      if (isDeleted) {
        toastNotification("success", "Phase deleted successfully");
        updateCurrentItem(null);
        updatedData();
      } else {
        toastNotification("error", "Error deleting the phase");
      }
    };

    const showDeleteConfirmation = (id) => {
      confirm({
        title: <h2 className="delete-notes">Delete Notes</h2>,
        content: (
          <div>
            <div>
              Tapping on "Confirm" would delete this not from the execution
              flow.
            </div>
            <div>Are you sure you want to continue?</div>
          </div>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        centered: false,
        icon: <></>,
        onOk() {
          deletePhase(id);
        },
      });
    };

    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          name="notesStepsAddEditForm"
          id="notesStepsAddEditForm"
          size="large"
          colon="false"
          scrollToFirstError="true"
          onFinish={onFinish}
          layout="vertical"
        >
          <Row className="sidebar-header">
            <Col span={12}>
              <FormSelect
                updateCurrentItem={updateCurrentItem}
                selectedValue="NOTES"
                editRecord={editRecord}
              />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {!isPageReadOnly && (
                <Form.Item>
                  {editRecord && (
                    <Button
                      style={{ marginRight: "0" }}
                      type="secondary"
                      className="delete-btn-none"
                      onClick={() => {
                        showDeleteConfirmation(editRecord.phase);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  {!editRecord && (
                    <Button
                      style={{ marginRight: "0" }}
                      type="default"
                      htmlType="button"
                      className="delete-btn-none"
                      onClick={hideItem}
                    >
                      Close
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "0" }}
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>

          <Divider
            type="horizontal"
            style={{
              margin: "-5px",
            }}
          />

          <Row className="margin-top-notes">
            <Col span={2}></Col>
            <Col span={21}>
              <Form.Item
                label="Step Label (Required)"
                name="label"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input label name!",
                  },
                ]}
              >
                <Input placeholder="Notes Title" disabled={isPageReadOnly} />
              </Form.Item>
              <Form.Item label="Notes & Description" name="description">
                <Input.TextArea
                  rows={10}
                  placeholder="Start writing notes or comments."
                  disabled={isPageReadOnly}
                />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Form>
      </Spin>
    );
  }
);

export default NotesPage;
