import { useEffect, useState } from "react";
import { Modal, Tabs, Spin } from "antd";
import MainContainer from "../../containers/MainContainer";
import CaseDetailsForm from "./CaseDetailsForm";
import CaseORTeamForm from "./CaseORTeamForm";
import CaseProcedureForm from "./CaseProcedureForm";
import toastNotification from "../../components/toastNotification";
import hospitalService from "../../services/hospitalService";
import { useAuthContext } from "../../context/AuthContext";
import { Roles } from "../../config/role-config.json";
import { CaseTypes } from "../../config/case-types.json";

const { TabPane } = Tabs;
const { confirm } = Modal;

const HospitalCaseForm = (props) => {
  const {
    match: { url, params },
    history,
    location,
  } = props;

  const isMyCasesPage = url.includes("my-cases");
  const { id: hospitalId, caseid: caseId } = params;

  const authContext = useAuthContext();

  const [hospitalCase, setHospitalCase] = useState(location.state?.record);

  const pathToHospitalCases = (() => {
    if (isMyCasesPage) return `/hospital/${hospitalId}/my-cases`;
    else if (hospitalCase?.isStarted === "FINISHED")
      return `/hospital/${hospitalId}/cases/completed`;
    else if (hospitalCase?.isDeleted)
      return `/hospital/${hospitalId}/cases/deleted`;
    else return `/hospital/${hospitalId}/cases`;
  })();

  const hasReadOnlyRights = (() => {
    if (hospitalCase) {
      if (hospitalCase.isDeleted) return true;

      if (hospitalCase.isStarted === CaseTypes.UPCOMING) {
        if (
          // authContext.user.role !== Roles.DISTRIBUTOR_ADMIN &&
          // authContext.user.role !== Roles.MR &&
          authContext.user.role === Roles.SURGEON &&
          authContext.user.id !== hospitalCase.surgeon.id
        ) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  })();

  const activeTabKey = location?.state?.isProcedurePage ? "3" : "1";

  const pageHeaderProps = caseId
    ? {
      title: "Edit Case",
      topActionMenuItems: hospitalCase?.isDeleted
        ? authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
          authContext.user.role === Roles.MR
          ? null
          : [
            {
              title: "Restore",
              path: "",
              onClick: () => { },
            },
          ]
        : hasReadOnlyRights
          ? null
          : [
            {
              title: "Delete",
              path: "",
              onClick: () => {
                showDeleteConfirmation(caseId);
              },
            },
          ],
      btnText: (() => {
        if (hospitalCase?.procedure) {
          switch (hospitalCase.isStarted) {
            case CaseTypes.LIVE:
              return "Join Case";
            case CaseTypes.UPCOMING:
              return hasReadOnlyRights ? null : "Start Case";
            case CaseTypes.COMPLETED:
              return "View Case";
          }
        } else {
          return null;
        }
      })(),
      onBtnClick: () => {
        hospitalCase.isStarted === CaseTypes.UPCOMING
          ? confirmOnStartCase(hospitalCase)
          : redirectToLiveCase(hospitalCase);
      },
      btnType: "primary",
    }
    : {
      title: "New Case",
    };

  const updateCase = (updatedCase) => setHospitalCase(updatedCase);

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Case",
      content: "Are you sure you want to delete this case?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await hospitalService.putCase({
          id: caseId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Case deleted successfully");
          history.push(pathToHospitalCases);
        } else {
          toastNotification("error", "Error deleting the Case");
        }
      },
    });
  };

  const redirectToLiveCase = (caseData) => {
    history.push(`/procedure/${caseData.procedure.id}/live-case`, {
      stepDetails: {
        caseId: caseData.id,
        currentScreen: caseData.currentScreen, // "STEP" -> Execution Flow OR null -> Table Setup
        currentStepId: caseData.currentStep, // null -> Start Page OR guid -> render that step
        isStarted: caseData.isStarted,
      },
      path: location.pathname,
      readOnly: caseData.isStarted === CaseTypes.COMPLETED,
      pageNumber: location.state?.pageNumber,
      tabKey: location.state?.tabKey,
      isCompletedCasesPage: location.state?.isCompletedCasesPage || hospitalCase.isStarted === CaseTypes.COMPLETED
      //  ||
      // authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
      // authContext.user.role === Roles.MR,
    });
  };

  const confirmOnStartCase = (caseData) => {
    confirm({
      title: "You are about to start the case.",
      content:
        "Tapping on “Confirm” would initialize the case steps. Are you sure you want to continue?",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      icon: null,
      async onOk() {
        const res = hospitalService.putCase({
          id: caseData.id,
          isStarted: CaseTypes.LIVE,
        });
        if (res) redirectToLiveCase(caseData);
        else toastNotification("error", "Failed to start the case");
      },
    });
  };

  /** API Calls */
  const getCase = async () => {
    const data = await hospitalService.getCase(caseId);
    if (data) {
      setHospitalCase(data);
      return data;
    } else {
      toastNotification("error", "Case details could not be fetched");
    }
  };
  /** API Calls End */

  useEffect(() => {
    (async () => {
      if (caseId && !hospitalCase) {
        await getCase();
      }
    })();
  }, []);

  if(hospitalCase || !caseId){
  return (
    <MainContainer
      formName="caseDetailAddEditForm"
      backBtnPath={pathToHospitalCases}
      routeState={{
        pageNumber: location.state?.pageNumber,
        tabKey: location.state?.tabKey,
        isCompletedCasesPage: location.state?.isCompletedCasesPage
      }}
      divider={true}
      {...pageHeaderProps}
    >
      {
        <Tabs defaultActiveKey={activeTabKey}>
          <TabPane tab="Case Details" key="1">
            <CaseDetailsForm
              hospitalId={hospitalId}
              hospitalCase={hospitalCase}
              updateCase={updateCase}
              isPageReadOnly={location.state?.readOnly}
              hasReadOnlyRights={hasReadOnlyRights}
              isMyCasesPage={isMyCasesPage}
            />
          </TabPane>
          <TabPane tab="OR Team" key="2" disabled={!hospitalCase?.id}>
            <CaseORTeamForm
              hospitalCase={hospitalCase}
              hasReadOnlyRights={hasReadOnlyRights}
            />
          </TabPane>
          <TabPane tab="Procedure" key="3" disabled={!hospitalCase?.id}>
            <CaseProcedureForm
              hospitalCase={hospitalCase}
              updateCase={updateCase}
              hasReadOnlyRights={hasReadOnlyRights}
              showModalPopUp={location.state?.showModalPopUp}
              pageNumber={location.state?.pageNumber}
              tabKey={location.state?.tabKey}
            />
          </TabPane>
        </Tabs>
      }
    </MainContainer>
  )}else{
    return (<Spin spinning={true}>
      <div className="main-container"></div>
    </Spin>)
  }
};
export default HospitalCaseForm;
