import {
  Row,
  Col,
  Divider,
  Typography,
  Card,
  List,
  Input,
  Spin,
  Empty,
} from "antd";
import { useEffect, useState } from "react";
import MainContainer from "../../containers/MainContainer";
import TrayModal from "../../components/TrayModal.jsx";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as AddIcon } from "../../assets/icons/icon_add.svg";
import { ReactComponent as GreenButtonIcon } from "../../assets/icons/greenButton.svg";
import distributorService from "../../services/distributorService";
import trayPlaceholder from "../../assets/tray-placeholder.svg";
import hospitalService from "../../services/hospitalService";
import { ReactComponent as DistributorGreyIcon } from "../../assets/icons/greyIconDistributor.svg";
import { ReactComponent as ManufacturerGreyIcon } from "../../assets/icons/icon_Manufacturer.svg";
import utilityService from "../../services/utilityService";

const { Search } = Input;
const { Paragraph, Text } = Typography;
const customPageSize = 30;

const TrayEquipmentPage = ({ match, history, location }) => {
  const state = history.location?.state;
  const { procedureid: procedureId } = match.params;
  const tableSetupPath = match.url.replace("tray-management", "table-setup");
  const isFromHospital = !(history.location.pathname.includes('manufacturer') || history.location.pathname.includes('distributor'))
  const pageHeaderProps = {
    title: "Trays Management",
    btnText: "Done",
    btnType: "secondary",
    onBtnClick: () => {
      addSelectedTrayEquipments(tableSetUpId);
    },
  };

  const [procedureOwnerId, setProcedureOwnerId] = useState(
    match.params?.id ?? state?.distributorIds
  );
  const [selectedTrays, setSelectedTrays] = useState([]);
  const [selectedTrayIds, setSelectedTrayIds] = useState([]);
  const [trayEquipmentsList, setTrayEquipmentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableSetUpId, setTableSetUpId] = useState([]);
  const [initialSelectedTray, setInitialSelectedTray] = useState([]);
  const [timeOut, setTimeOut] = useState(0);
  const [trayModal, setTrayModal] = useState(false);
  const [trayId, setTrayId] = useState();
  const [removedTraysId, setRemovedTraysId] = useState([]);
  const [manufacturerIds, setManufacturerIds] = useState([]);
  const [regionalManagerIds, setRegionalManagerIds] = useState([]);
  const [hospitalId, setHospitalId] = useState("")

  const [allDcMfcIds, setAllDcMfcIds] = useState([]);
  const isDerivedFromHospital = state.path && state.path.includes('hospital/');
  const closeModal = () => {
    setTrayModal(false);
  };

  const selectTray = (tray) => {
    const showTray = selectedTrays;
    showTray.push(tray);
    setSelectedTrays([...showTray]);

    const handleButton = selectedTrayIds;
    handleButton.push(tray.id); //making an array of selected trays for handling button toggle
    setSelectedTrayIds([...handleButton]);

    //changing to array of object for post api
    const trayIdList = [...tableSetUpId];
    trayIdList.push({ tray: tray.id });
    setTableSetUpId([...trayIdList]);
  };

  const populateTraysEquipmentsList = async (ids, searchValue, rmId) => {
    let count = customPageSize;
    let temp = [];
    for (let skipPage = 0; count === customPageSize; skipPage++) {
      const data = await distributorService.getTrayEquipmentsList(
        [...ids],
        false,
        customPageSize,
        skipPage * customPageSize,
        searchValue,
        rmId
      );
      if (data) {
        count = data.length;
        for (const item of data) {
          if (item?.regionalManager) {
            // Get Manufacturer details using REgional Manager's id
            const mfcOfRegionalManager = await hospitalService.getManufacturers(JSON.stringify([item.regionalManager.id || item.regionalManager]));
            item.distributorName = mfcOfRegionalManager.length && mfcOfRegionalManager[0].manufacturer.distributorName;
          }
        }
        temp = temp.concat(data);
      } else {
        count = 0;
        toastNotification("error", "Error fetching Instrument record of the Tray");
      }
    }
    setTrayEquipmentsList(temp);
  };

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        await populateTraysEquipmentsList(
          !isDerivedFromHospital ? [...manufacturerIds, procedureOwnerId] : allDcMfcIds,
          searchValue,
          regionalManagerIds
        );
        setLoading(false);
      }, 500)
    );
  };

  const addSelectedTrayEquipments = async (selectedTrayEquipmentIds) => {
    setLoading(true);

    const tableSetUp = selectedTrayEquipmentIds;
    const removedTrays = removedTraysId;
    const sendTableData = {
      tableSetUp,
      removedTrays,
    };
    const res = await distributorService.postTableSetup(
      procedureId,
      sendTableData
    );
    if (res.status === 200) {
      toastNotification("success", "Tray selected");
      history.push(tableSetupPath, {
        hospitalId,
        path: state.path,
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        tabKey: location.state?.tabKey,
        isCloneProcedure: location.state?.isCloneProcedure
      });
    } else {
      toastNotification("error", "Error adding a new Tray");
    }
    setLoading(false);
  };

  const removeTray = (trayId) => {
    if (removedTraysId.indexOf(trayId) < 0) {
      setRemovedTraysId([...removedTraysId, trayId]);
    }
    setSelectedTrays(selectedTrays.filter((tray) => tray.id !== trayId));
    setSelectedTrayIds(selectedTrayIds.filter((id) => id !== trayId));
    setTableSetUpId(
      tableSetUpId.filter((id) => {
        return id.tray !== trayId;
      })
    );
  };

  const getSelectedTrayEquipments = async () => {
    setLoading(true);
    let count = customPageSize;
    let temp = [];
    for (let skipPage = 0; count === customPageSize; skipPage++) {
      const data = await distributorService.getTableSetup(
        procedureId,
        null,
        customPageSize,
        skipPage * customPageSize
      );
      if (data) {
        count = data.length;
        temp = temp.concat(data);
      } else {
        count = 0;
        toastNotification("error", "Error fetching the table setup data");
      }
    }
    setInitialSelectedTray(temp);
    setLoading(false);
  };

  const getDistributorManufacturerIds = async (pOwnerId) => {
    const distributor = await distributorService.getDistributor(pOwnerId, true);
    const filteredManufacturers = utilityService.filterSelectedEntity(distributor.manufacturers)
    const mIds = !distributor?.isManufacturer
      ? filteredManufacturers?.map((m) => m.id)
      : [distributor?.id];
    mIds && setManufacturerIds(mIds);
    return mIds;
  };

  const fetchProcedure = async () => {
    const procedureDtl = await distributorService.getProcedure(
      procedureId,
      false,
      true
    );
    if (procedureDtl) {
      const id = procedureDtl.distributor
        ? procedureDtl.distributor
        : procedureDtl.clonedFrom?.distributor;

      const rmId = procedureDtl.regionalManager
        ? procedureDtl.regionalManager
        : procedureDtl.clonedFrom?.regionalManager
      id ? setProcedureOwnerId(id) : setProcedureOwnerId(rmId);

      return { pOwnerId: id ? id : rmId, isOwnerRM: rmId ? true : false };
    } else return false;
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      let pOwnerId = procedureOwnerId;
      let isOwnerRM = false;
      if (!procedureOwnerId && procedureId) {
        const procedureOwner = await fetchProcedure();
        pOwnerId = procedureOwner?.pOwnerId;
        isOwnerRM = procedureOwner?.isOwnerRM;
        setRegionalManagerIds([pOwnerId]);
      }
      setHospitalId(state?.hospitalId);
      // Call API to get distributors and manufacturers using hospitalId

      const hospital = isDerivedFromHospital && await hospitalService.getHospital(state?.hospitalId);
      const manufacturerId = [];
      const distributorIds = [];
      if (hospital) {
        for (const dc of hospital.distributors) {
          !dc.isDeleted && distributorIds.push(dc.id)
        }
        for (const mfc of hospital.manufacturers) {
          !mfc.isDeleted && manufacturerId.push(mfc.id)
        }
      }
      setAllDcMfcIds([...manufacturerId, ...distributorIds]);
      const mIds = (!isDerivedFromHospital && !isOwnerRM) ? await getDistributorManufacturerIds(pOwnerId) : manufacturerId || [];
      const rmDetails = isDerivedFromHospital && mIds &&
        await hospitalService.getRegionalManagers(JSON.stringify([...manufacturerId, ...mIds]), hospital.id);
      const rmIds = [];
      if (rmDetails) {
        for (const rm of rmDetails) {
          rmIds.push(...rm.regionalManagerId);
        }
      }

      rmIds.length && setRegionalManagerIds(rmIds);
      await populateTraysEquipmentsList(
        (isDerivedFromHospital && !isOwnerRM) ? [...mIds, ...distributorIds, ...manufacturerId, pOwnerId] :
          (!isOwnerRM && mIds)
            ? [...mIds, pOwnerId]
            : [...distributorIds, ...manufacturerId],
        null,
        rmIds
      );

      await getSelectedTrayEquipments();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const initialState = [];
    const initialId = [];
    const buttonID = [];

    initialSelectedTray.forEach((key) => {
      const distributor = trayEquipmentsList.filter(
        (el) => el.id === key.tray.id
      );
      key.tray.distributor = distributor[0]?.distributor;
      // Set distributor/manufacturer name for tray of RM
      key.tray.distributorName = distributor[0]?.distributorName
      initialState.push(key.tray);
      buttonID.push(key.tray.id);
    });

    initialSelectedTray.forEach((el) => {
      initialId.push({
        x: el.x,
        y: el.y,
        w: el.w,
        h: el.h,
        tray: el.tray.id,
        sectionNumber: el.sectionNumber,
        orientation: el.orientation,
      });
    });
    setSelectedTrays(initialState);
    setTableSetUpId(initialId);
    setSelectedTrayIds(buttonID);
  }, [initialSelectedTray]);

  const actions = (tray) => {
    return [
      {
        title: "Remove Tray",
        onClick: (menuItemProps) => {
          menuItemProps.domEvent.stopPropagation();
          removeTray(tray.id);
        },
      },
    ];
  };

  return (
    <Spin spinning={loading}>
      <div className="tray-done-button">
        <MainContainer
          formName="Tray/EquipmentManagement"
          backBtnPath={tableSetupPath}
          routeState={{
            hospitalId,
            path: state.path,
            pageNumber: location.state?.pageNumber,
            activeTabKey: location.state?.activeTabKey,
            tabKey: location.state?.tabKey,
            isCloneProcedure: location.state?.isCloneProcedure,
          }}
          divider={true}
          {...pageHeaderProps}
        >
          <div className="tray-equipment">
            <Row>
              <Col
                span={6}
                style={{
                  marginLeft: "4%",
                  marginTop: "2%",
                  height: "calc(100vh - 70px)",
                  position: "sticky",
                  overflow: "scroll",
                }}
              >
                <Row style={{ marginBottom: "5%" }}>
                  <Text style={{ fontSize: "17px" }}>
                    Search Trays from library
                  </Text>
                </Row>
                <Row className="search-row" style={{ marginBottom: "2%" }}>
                  <Search
                    placeholder="Search by Tray Name"
                    style={{ width: "97%" }}
                    onSearch={onSearchFunction}
                    onChange={(e) => onSearchFunction(e.target.value)}
                    allowClear
                  />
                </Row>
                <List
                  dataSource={trayEquipmentsList}
                  renderItem={(tray) => {
                    return (
                      <List.Item>
                        <div style={{ width: "100%" }}>
                          <Row
                            key={tray.id}
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}
                            className="search-row"
                          >
                            <Col className="display-flex-center" span={7}>
                              <SignedURLImage
                                fileName={tray.image}
                                resizeMode="contain"
                                width={"100%"}
                                height={"50px"}
                                key={tray.id}
                                onClick={() => {
                                  setTrayModal(true);
                                  setTrayId(tray.id);
                                }}
                                fallback={trayPlaceholder}
                              />
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                              span={12}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Paragraph
                                  style={{ margin: "0 0.4em" }}
                                  ellipsis={{ rows: 2 }}
                                >
                                  {tray.trayName}
                                </Paragraph>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    //  justifyContent: "center",
                                  }}
                                >
                                  {
                                    tray.regionalManager ? (<ManufacturerGreyIcon />)
                                      : !tray.distributor?.isManufacturer ?
                                        (<DistributorGreyIcon />)
                                        : (<ManufacturerGreyIcon />)
                                  }
                                  <Paragraph
                                    style={{
                                      margin: "0.2em 0.4em",
                                      color: "grey",
                                      fontSize: "0.8rem",
                                    }}
                                    ellipsis={{ rows: 2 }}
                                  >
                                    {tray?.distributor?.distributorName || tray?.distributorName}
                                  </Paragraph>
                                </div>
                              </div>
                            </Col>
                            <Col
                              span={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {selectedTrayIds.includes(tray.id) ? (
                                <button
                                  key={tray.id}
                                  type="secondary"
                                  onClick={() => removeTray(tray.id)}
                                  size={10}
                                  class="plus-button"
                                  style={{ border: "none" }}
                                >
                                  <GreenButtonIcon />
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    selectTray(tray);
                                  }}
                                  class="plus-button"
                                  key={tray.id}
                                  type="secondary"
                                  size={10}
                                >
                                  <AddIcon />
                                </button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </List.Item>
                    );
                  }}
                />
              </Col>
              <Col>
                <Divider
                  type="vertical"
                  style={{
                    height: "calc(100vh - 60px)",
                    margin: "0 15px 0 0",
                  }}
                />
              </Col>
              <Col
                span={16}
                style={{
                  paddingTop: "2%",
                  overflow: "scroll",
                  height: "calc(100vh - 70px)",
                  position: "sticky",
                }}
              >
                <Row style={{ marginBottom: "2%" }}>
                  <Text style={{ fontSize: "17px" }}> Case Trays </Text>
                </Row>
                {selectedTrays.length !== 0 ? (
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 2,
                      lg: 3,
                      xl: 3,
                      xxl: 4,
                    }}
                    dataSource={selectedTrays}
                    pagination={{
                      pageSize: 12,
                      hideOnSinglePage: true,
                    }}
                    renderItem={(tray) => {
                      return (
                        <List.Item>
                          <Card
                            bordered={false}
                            cover={
                              <div
                                style={{
                                  width: "100%",
                                  height: "176px",
                                  backgroundColor: "#F4F4F4",
                                  border: "1px",
                                  borderColor: "color",
                                }}
                              >
                                <SignedURLImage
                                  key={tray.image}
                                  fileName={tray.image}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  onClick={() => {
                                    setTrayModal(true);
                                    setTrayId(tray.id);
                                  }}
                                  fallback={trayPlaceholder}
                                />

                                <div
                                  className="overlay-button"
                                  style={{ padding: "0.15em" }}
                                >
                                  <ActionMenu actions={actions(tray)} />
                                </div>
                              </div>
                            }
                            preview="false"
                            bodyStyle={{
                              padding: "0",
                            }}
                            size="small"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Paragraph
                                style={{ margin: "0 0.4em" }}
                                ellipsis={{ rows: 2 }}
                              >
                                {tray.trayName}
                              </Paragraph>
                              <Paragraph
                                style={{
                                  margin: "0 0.4em",
                                  color: "grey",
                                  fontSize: "0.8rem",
                                }}
                                ellipsis={{ rows: 2 }}
                              >
                                {tray?.distributor?.distributorName || tray?.distributorName}
                              </Paragraph>
                            </div>
                          </Card>
                        </List.Item>
                      );
                    }}
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                      alignItems: "centre",
                      marginTop: "25%",
                      marginLeft: "36.5%",
                      width: "200px",
                      height: "170px",
                    }}
                    description={
                      <span style={{ fontWeight: "bold", paddingTop: "70px" }}>
                        <Text
                          style={{
                            fontSize: "large",
                            font: "70px",
                          }}
                        >
                          Start adding trays from left.
                        </Text>
                      </span>
                    }
                  ></Empty>
                )}
              </Col>
            </Row>
          </div>
        </MainContainer>
      </div>
      {trayModal ? (
        <TrayModal show={trayModal} close={closeModal} trayId={trayId} viewFrom={isFromHospital ? "hospital" : ""} />
      ) : null}
    </Spin>
  );
};
export default TrayEquipmentPage;
