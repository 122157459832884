import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import routes from "../routes/index.routes";
import authServices from "../services/authService";

const BreadCrumbs = ({ breadcrumbs }) => {
  const user = authServices.getCurrentUser();
  return (
    <Breadcrumb
      style={{
        whiteSpace: "wrap",
        paddingRight: "10px",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        direction: "rtl",
        textAlign: "left",
      }}
    >
      {breadcrumbs.map(({ match, breadcrumb, rolesAccess }, index) => {
        if (rolesAccess.includes(user?.user?.role)) {
          return (
            <Breadcrumb.Item key={match.url}>
              {index === breadcrumbs.length - 1 ? (
                <span style={{ color: "rgb(128,130,133)" }}>{breadcrumb}</span>
              ) : (
                <Link to={match.url} style={{ color: "black" }}>
                  {breadcrumb}
                </Link>
              )}
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default withBreadcrumbs(routes.privateRoutes, { disableDefaults: true })(
  BreadCrumbs
);
