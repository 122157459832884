import React, { useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col } from "antd";
import OptionItemList from "./Partial/OptionItemList";
import { ReactComponent as DragIcon } from "../../assets/icons/icon_drag-icon.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/icons/icon_procedures (2).svg";
import { ReactComponent as CheckListIcon } from "../../assets/icons/icon_checklist (1).svg";
import { ReactComponent as NotesIcon } from "../../assets/icons/icon_notes_1.svg";
import { ReactComponent as PhaseIcon } from "../../assets/icons/icon-phases.svg";

import executionService from "../../services/executionService";

const Skelton = ({ selectedOption }) => {
  return (
    <>
      <hr className="divider" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="skelton edit-record"
          style={{
            padding: "10px",
            background: "rgb(247, 247, 247)",
            borderRadius: "4px",
            color: "rgb(128,130,133)",
            minWidth: "200px",
            //width: "calc(draggableStyle.width + 5px)",
          }}
        >
          <div>
            <Row>
              <Col span={4}>
                {() => {
                  switch (selectedOption) {
                    case "SURGICAL_STEP":
                      return <ProcedureIcon />;
                    case "CHECKLIST":
                      return <CheckListIcon />;
                    case "NOTES":
                      return <NotesIcon />;
                    case "PHASE":
                      return <PhaseIcon />;
                    default:
                      return <></>;
                  }
                }}
              </Col>
              <Col
                span={20}
                style={{
                  right: "-20px",
                }}
              >
                <div>Untitled Step</div>
                <div>Not Set</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const ServiceCommandUnit = ({
  type,
  subItems,
  addNewItem,
  parentIndex,
  editItem,
  editRecord,
  currentIndex,
  skelton,
  selectedParent,
  selectedOption,
  isPageReadOnly,
}) => {
  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      padding: "10px",
      background: isDragging ? "" : "#f7f7f7",
      borderRadius: "4px",
      border: isDragging ? "1px solid #d1d1d1" : "",
      minWidth: "200px",
      maxWidth: "30rem",
      width: "max-content",
      ...draggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
  });

  return (
    <Droppable droppableId={type} type="droppableSubItem">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {subItems.length === 0 && (
            <>
              <hr className="divider" />
              {!isPageReadOnly && (
                <>
                  <OptionItemList
                    addNewItem={addNewItem}
                    orderIndex={0}
                    parentIndex={parentIndex}
                  />
                  {parentIndex === selectedParent &&
                    currentIndex === 0 &&
                    skelton === true && (
                      <Skelton selectedOption={selectedOption} />
                    )}
                  <hr className="divider" />
                </>
              )}
            </>
          )}
          {subItems.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={item.id.toString()}
              index={index}
              isDragDisabled={isPageReadOnly}
            >
              {(provided, snapshot) => (
                <>
                  {index === 0 && <hr className="divider" />}
                  {!isPageReadOnly && (
                    <>
                      <OptionItemList
                        addNewItem={addNewItem}
                        orderIndex={index}
                        parentIndex={parentIndex}
                      />
                      {parentIndex === selectedParent &&
                        currentIndex === index &&
                        skelton === true && (
                          <Skelton selectedOption={selectedOption} />
                        )}
                      <hr className="divider" />
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={
                        editRecord?.phase === item.phase ? "edit-record" : ""
                      }
                    >
                      <div
                        onClick={() => editItem(item.type, item)}
                        style={{
                          display: "flex",
                        }}
                      >
                        <span>
                          {item.type === "NOTES" && <NotesIcon />}
                          {item.type === "CHECKLIST" && <CheckListIcon />}
                          {item.type === "SURGICAL_STEP" && <ProcedureIcon />}
                        </span>
                        <div
                          className="equip-text"
                          style={{ marginTop: "3px" }}
                        >
                          {item.content}
                          <GetCount item={item} />
                        </div>
                        {!isPageReadOnly && (
                          <div
                            style={{
                              marginTop: "4px",
                              marginLeft: "auto",
                              alignSelf: "center",
                            }}
                            {...provided.dragHandleProps}
                          >
                            <DragIcon />
                          </div>
                        )}
                      </div>
                    </div>
                    {subItems.length - 1 === index && !isPageReadOnly && (
                      <>
                        <hr className="divider" />
                        <OptionItemList
                          addNewItem={addNewItem}
                          orderIndex={index + 1}
                          parentIndex={parentIndex}
                        />
                        {parentIndex === selectedParent &&
                          currentIndex === index + 1 &&
                          skelton === true && (
                            <Skelton selectedOption={selectedOption} />
                          )}
                      </>
                    )}

                    {provided.placeholder}
                  </div>
                  <hr className="divider" />
                </>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const GetCount = ({ item }) => {
  const [children, setChildren] = useState();

  const getCount = async () => {
    if (item.type === "CHECKLIST") {
      const res1 = await executionService.getCheckListItem(item.phase);
      setChildren(res1);
    }
    if (item.type === "SURGICAL_STEP") {
      const res = await executionService.getStepsEquipment(item.phase);
      setChildren(res);
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <div className="items">
      {children &&
        children.length +
          " " +
          (item.type === "SURGICAL_STEP"
            ? children.length > 1
              ? "Instruments"
              : "Instrument"
            : item.type === "CHECKLIST"
            ? children.length > 1
              ? "Items"
              : "Item"
            : "")}
    </div>
  );
};

export default ServiceCommandUnit;
