import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Typography,
  Select,
  Spin,
} from "antd";
import { MailFilled } from "@ant-design/icons";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import utilityService from "../../services/utilityService";

const { confirm } = Modal;
const { Text, Title } = Typography;

const HospitalUserForm = (props) => {
  const { match, history, location } = props;
  const hospitalUserId = match.params.userid ? match.params.userid : null;
  const hospitalId = match.params.id ? match.params.id : null;
  let hospitalUser = location.state?.record;

  //TODO :: Check if invalid hospital id
  const pathToHospitalUsers = `/hospital/${hospitalId}/user`;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [editMode, setEditMode] = useState(
    !hospitalUserId || location.state?.editMode
  );

  const pageHeaderProps = hospitalUserId
    ? {
      title: "Edit Hospital User",
      btnText: editMode ? "Save" : "Edit",
      btnType: editMode ? "primary" : "secondary",
      topActionMenuItems: [
        {
          title: "Delete Hospital User",
          path: "",
          onClick: () => {
            showDeleteConfirmation(hospitalUserId);
          },
        },
      ],
    }
    : {
      title: "New Hospital User",
      btnText: "Add",
    };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Hospital User",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        setLoading(true);
        const isDeleted = await userServices.deleteUser(hospitalUserId);
        if (isDeleted) {
          toastNotification("success", "Hospital user deleted successfully");
          history.push(pathToHospitalUsers);
        } else {
          toastNotification("error", "Error deleting the user");
        }
        setLoading(false);
      },
    });
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const addHospitalUser = async (hospitalUserFormValues) => {
    // TODO :: Trim values before submit
    hospitalUserFormValues.password = "password123";
    hospitalUserFormValues.hospital = hospitalId;
    const res = await userServices.postUser(hospitalUserFormValues);

    if (res.status === 200) {
      toastNotification("success", "New hospital user added");
      history.push(pathToHospitalUsers);
    } else if (res.code && res.code === "E_UNIQUE") {
      toastNotification("error", "Email already registered");
    } else {
      toastNotification("error", "Error adding a new user record");
    }
  };

  const editHospitalUser = async (hospitalUserFormValues) => {
    // TODO :: Trim values before submit
    const res = await userServices.putUser(hospitalUserFormValues);

    if (res.status === 200) {
      setEditMode(false);
      toastNotification("success", "Hospital User Record Updated");
      history.push(pathToHospitalUsers, { pageNumber: location.state?.pageNumber });
    } else {
      toastNotification("error", "Error updating the User record");
    }
  };

  const getHospitalUser = async (id) => {
    const data = await userServices.getUser(id);
    if (data) {
      hospitalUser = data;
    } else {
      toastNotification("error", "Requested User record not found");
    }
  };

  const onFinish = async (hospitalUserFormValues) => {
    setLoading(true);
    if (hospitalUserId) {
      hospitalUserFormValues.id = hospitalUserId;
      await editHospitalUser(hospitalUserFormValues);
    } else {
      await addHospitalUser(hospitalUserFormValues);
    }
    setLoading(false);
  };

  const sendActivationLink = async (e) => {
    const res = await userServices.postResendUserActivationLink(
      hospitalUser.email
    );
    if (res.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (hospitalUserId) {
        if (!hospitalUser) {
          await getHospitalUser(hospitalUserId);
        }
        form.setFieldsValue({ ...hospitalUser });
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="hospitalUserAddEditForm"
        backBtnPath={pathToHospitalUsers}
        routeState={{ pageNumber: location.state?.pageNumber }}
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <Row align="middle">
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 14, offset: 5 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Form
              form={form}
              name="hospitalUserAddEditForm"
              id="hospitalUserAddEditForm"
              size="large"
              colon="false"
              scrollToFirstError="true"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
            >
              <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                  <Title level={5}>Details</Title>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Email (Required)"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid Email!",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <Input placeholder="Example: david@mail.com" disabled={!editMode} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Role (Required)"
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a role!",
                      },
                    ]}
                  >
                    <Select placeholder="Select a role" disabled={!editMode} onChange={(value) => setRole(value)}>
                      <Select.Option value="HOSPITAL_ADMIN">
                        Hospital Admin
                      </Select.Option>
                      <Select.Option value="SURGEON">Surgeon</Select.Option>
                      <Select.Option value="HOSPITAL_STAFF">
                        Hospital Staff
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="First Name" name="firstName">
                    <Input placeholder={role === 'SURGEON' ? '' : "Example: Chadwick"}
                      prefix={role === 'SURGEON' ? 'Dr.' : ''} disabled={!editMode} />
                    {/* disabled={readOnlyView} /> */}
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item label="Middle Name" name="middleName">
                    <Input placeholder="Example: Joseph" disabled={!editMode} />
                    {/* disabled={readOnlyView} /> */}
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label="Last Name" name="lastName">
                    <Input placeholder="Example: Boseman" disabled={!editMode} />
                    {/* disabled={readOnlyView} /> */}
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (203) 683-3272"
                      id="mobileNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <Form.Item
                    label="Office Number"
                    name="officeNumber"
                    rules={[
                      {
                        pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                        message: "Please follow the format (xxx) xxx-xxxx",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Example: (966) 922-5419"
                      id="officeNumber"
                      onChange={onContactNumberInputChange}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {hospitalUser && !hospitalUser.isActive && (
                    <Tag
                      color="gold"
                      icon={<MailFilled color="#db9e2e" />}
                      className="activation-link"
                    >
                      <Row>
                        <Col span={8}>
                          <Text> User activation is pending </Text>
                        </Col>
                        <Col span={8} offset={8} style={{ textAlign: "end" }}>
                          <a
                            style={{ color: "inherit" }}
                            onClick={(e) => {
                              sendActivationLink(e);
                            }}
                          >
                            Resend Invite Link
                          </a>
                        </Col>
                      </Row>
                    </Tag>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </MainContainer>
    </Spin>
  );
};

export default HospitalUserForm;
