import { useState, useEffect } from "react";
import { Form, Input, Modal, Row, Col, Divider, Typography, Spin } from "antd";
import { debounce } from "lodash";
import MainContainer from "../containers/MainContainer";
import hospitalService from "../services/hospitalService";
import toastNotification from "../components/toastNotification";
import AssignmentSelection from "../components/AssignmentSelection";
import utilityService from "../services/utilityService";
import ManufacturerColorImage from "../assets/Images/Manufacturers.png";
import DistributorColorImage from "../assets/Images/Distributors.png";
import { ReactComponent as NoRecord } from "../assets/Images/NoRecord.svg";
import { useAuthContext } from "../context/AuthContext";
import { Roles } from "../config/role-config.json";

const { confirm } = Modal;
const { Title } = Typography;

const HospitalForm = ({ match, history, location }) => {
  const hospitalId = match.params?.id;
  const customPageSize = 30;
  const backBtnPath = `/hospital/${hospitalId}/setup`;

  const [entitiesList, setEntitiesList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [timeOut, setTimeOut] = useState(0);
  const [currentEntityPage, setCurrentEntityPage] = useState(1);
  const [entityCount, setEntityCount] = useState();
  const [entitySearchText, setEntitySearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadEntities, setLoadEntities] = useState(false);
  const [editMode, setEditMode] = useState(
    !hospitalId || location.state?.editMode
  );
  const authContext = useAuthContext();
  const isSuperAdmin = authContext.user?.role === Roles.DOR_ADMIN;
  const isHospitalAdmin = authContext.user?.role === Roles.HOSPITAL_ADMIN;

  const pageHeaderProps = hospitalId
    ? {
        title: "Edit Hospital",
        btnText:
          isSuperAdmin || isHospitalAdmin ? (editMode ? "Save" : "Edit") : "",
        btnType:
          isSuperAdmin || isHospitalAdmin
            ? editMode
              ? "primary"
              : "secondary"
            : "",
        topActionMenuItems: isSuperAdmin
          ? [
              {
                title: "Delete Hospital",
                path: "",
                onClick: () => {
                  showDeleteConfirmation(hospitalId);
                },
              },
            ]
          : null,
      }
    : {
        title: "New Hospital",
        btnText: "Add",
        backBtnPath: "/hospital",
      };

  const onEntitySelect = (entityId) => {
    // add id to selectedIds to maintain state of selected options
    setSelectedIds([...selectedIds, entityId]);

    // add it to the displayed tag list
    const selectedEntity = entitiesList.find((e) => e.id === entityId);
    setSelectedEntities([...selectedEntities, selectedEntity]);
  };

  const onEntityDeselect = (entityId) => {
    // remove id from selectedIds to maintain state of selected options
    setSelectedIds(selectedIds.filter((x) => x !== entityId));

    // remove it from the displayed tag list
    setSelectedEntities(selectedEntities.filter((e) => e.id !== entityId));
  };

  const logValue = debounce((searchText) => {
    setEntitySearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadEntities(false);
    setIsLoading(true);
    setCurrentEntityPage(currentEntityPage + 1);
    await populateEntityList(
      customPageSize,
      currentEntityPage * customPageSize,
      entitySearchText
    );
    setIsLoading(false);
  };

  const handleOpen = () => {
    setCurrentEntityPage(1);
  };

  const handleChange = () => {
    setCurrentEntityPage(0);
  };

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentEntityPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadEntities && entitiesList.length !== entityCount && addOptions();
  };

  const getEntityCountAndPopulate = async (searchText) => {
    const success = await hospitalService.getDistributorsAndManufacturersCount(
      searchText
    );

    if (success?.count >= 0) {
      setCurrentEntityPage(0);
      setEntityCount(success.count);
      populateEntityList(customPageSize, 0, searchText);
    } else {
      toastNotification("error", "Error fetching records");
    }
  };

  const onSearchFunction = async (searchValue) => {
    setIsLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setEntitySearchText(searchValue);
        await getEntityCountAndPopulate(searchValue);
        setIsLoading(false);
      }, 500)
    );
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Hospital",
      content: "Are you sure you want to delete this Hospital?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        const isDeleted = await hospitalService.putHospital({
          id: hospitalId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Hospital deleted successfully");
          history.push("/hospital");
        } else {
          toastNotification("error", "Error deleting the Hospital");
        }
      },
    });
  };

  const addHospital = async (hospitalFormValues) => {
    // TODO :: Trim values before submit
    setLoading(true);
    const res = await hospitalService.postHospital(hospitalFormValues);
    if (res?.status === 200) {
      toastNotification("success", "New Hospital Added");
      history.push("/hospital");
    } else {
      toastNotification("error", "Error adding a new Hospital record");
    }
    setLoading(false);
  };

  const editHospital = async (hospitalFormValues) => {
    // TODO :: Trim values before submit
    setLoading(true);
    hospitalFormValues.id = hospitalId;
    const res = await hospitalService.putHospital(hospitalFormValues);
    if (res.status === 200) {
      setEditMode(false);
      history.push(backBtnPath);
      toastNotification("success", "Hospital record updated");
      history.push(backBtnPath);
      //  history.push("/hospital");
    } else {
      toastNotification("error", "Error updating the Hospital record");
    }
    setLoading(false);
  };

  const getHospital = async (id) => {
    const hospital = await hospitalService.getHospital(id);
    if (hospital) {
      return hospital;
    } else {
      toastNotification("error", "Requested Hospital record not found");
    }
  };

  const onFinish = async (hospitalFormValues) => {
    hospitalFormValues.distributors = [];
    hospitalFormValues.manufacturers = [];

    selectedEntities.forEach((e) => {
      e.isManufacturer
        ? hospitalFormValues.manufacturers.push(e.id)
        : hospitalFormValues.distributors.push(e.id);
    });

    hospitalId
      ? await editHospital(hospitalFormValues)
      : await addHospital(hospitalFormValues);
  };

  const populateEntityList = async (limit, skip, searchValue) => {
    const res = await hospitalService.getDistributorsAndManufacturers(
      limit,
      skip,
      searchValue
    );
    if (res) {
      setLoadEntities(res.length === customPageSize);
      const arr = [...entitiesList, ...res];
      setEntitiesList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (hospitalId) {
        const hospital = await getHospital(hospitalId);

        if (hospital) {
          form.setFieldsValue(hospital);
          const entities = [
            ...hospital.distributors,
            ...hospital.manufacturers,
          ];

          const filteredEntities =
            utilityService.filterSelectedEntity(entities);
          setSelectedIds(filteredEntities?.map((e) => e.id));
          setSelectedEntities(filteredEntities);
        }
      }

      setLoading(false);
      await getEntityCountAndPopulate();
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="hospitalAddEditForm"
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Title level={5}>Details</Title>
                  <Form
                    form={form}
                    name="hospitalAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Hospital Name (Required)"
                          name="hospitalName"
                          rules={[
                            {
                              required: true,
                              message: "Please input Hospital!",
                            },
                          ]}
                        >
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 1"
                          name="contactNumber1"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "The entered contact number seems to be invalid.",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editMode}
                            placeholder="Example: (xxx) xxx-xxxx"
                            onChange={onContactNumberInputChange}
                            id="contactNumber1"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 2"
                          name="contactNumber2"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "The entered contact number seems to be invalid.",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editMode}
                            placeholder="Example: (xxx) xxx-xxxx"
                            onChange={onContactNumberInputChange}
                            id="contactNumber2"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Street Address" name="address">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="City" name="city">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item label="State" name="state">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="Country" name="country">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Assigned Distributors/Manufacturers</Title>
              <AssignmentSelection
                editMode={editMode}
                selectedEntityIds={selectedIds}
                dataSource={entitiesList}
                selectedEntities={selectedEntities}
                optionIcon={(entity) =>
                  entity.isManufacturer
                    ? ManufacturerColorImage
                    : DistributorColorImage
                }
                showOptionsLoader={isLoading}
                onSelect={onEntitySelect}
                onDeselect={onEntityDeselect}
                onSearch={onSearchFunction}
                filterOption={handleFilterOption}
                onDropdownVisibleChange={handleOpen}
                onChange={handleChange}
                onPopupScroll={handleMenuScrollToBottom}
                onInputKeyDown={handleInputChange}
                tagIcon={(entity) =>
                  entity.isManufacturer
                    ? ManufacturerColorImage
                    : DistributorColorImage
                }
                emptyText="No Records Found"
                emptyIcon={NoRecord}
                entityTitleProperty="distributorName"
                placeholder="Search by Manufacturer/Distributor Name"
              />
            </Col>
            <Col span={1} />
          </Row>
        </div>
      </MainContainer>
    </Spin>
  );
};

export default HospitalForm;
