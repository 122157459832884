import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { Spin, List, Tabs, Collapse, Button, Select } from "antd";
import Moment from "react-moment";
import MainContainer from "../../../containers/MainContainer";
import CardList from "../../../components/CardList";
import CustomCard from "../../../components/CustomCard";
import toastNotification from "../../../components/toastNotification";
import hospitalService from "../../../services/hospitalService";
import { ReactComponent as CaseDescriptionIcon } from "../../../assets/icons/hospital-case/caseDes.svg";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/hospital-case/calendar.svg";
import { ReactComponent as SurgeonColorIcon } from "../../../assets/icons/colorIconSurgeons.svg";
import { ReactComponent as CollapseArrowIcon } from "../../../assets/icons/icon_arrow.svg";
import { ReactComponent as ExpandArrowIcon } from "../../../assets/icons/icon_expandArrow.svg";
import { CaseTypes } from "../../../config/case-types.json";
import "../../../styles/cases-styles.less";
import "antd/dist/antd.css";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const customPageSize = 12;

const dateFormat = "YYYY-MM-DD";

const CasesPage = (props) => {
  const { entityId, entity, hospitalId } = props;

  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  let variableURL = url;

  const isDeletedPage = url.includes("deleted");
  const isCompletedCasesPage = url.includes("completed");

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Deleted Cases",
      backBtnPath: url.replace("/deleted", ""),
    }
    : isCompletedCasesPage
      ? {
        title: "Completed Cases",
        backBtnPath: url.replace("/completed", ""),
      }
      : {
        title: "Cases",
        topActionMenuItems: [
          {
            title: "Show Completed Cases",
            path: `${url}/completed`,
          },
          {
            title: "Show Deleted Cases",
            path: `${url}/deleted`,
          },
        ],
      };

  const [procedureIds, setProcedureIds] = useState([]);
  const [caseList, setCaseList] = useState([]);
  const [caseType, setCaseType] = useState(
    isCompletedCasesPage
      ? CaseTypes.COMPLETED
      : isDeletedPage
        ? null
        : location.state?.caseType ?? CaseTypes.UPCOMING
  );
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [sortBySurgeon, setSortBySurgeon] = useState(false);
  const [surgeonList, setSurgeonList] = useState([]);
  const [filteredSurgeonList, setFilteredSurgeonList] = useState([]);
  const [casesCount, setCasesCount] = useState(0);
  const [casesSearchText, setCasesSearchText] = useState();
  const [caseListBySurgeon, setCaseListBySurgeon] = useState();
  const [activePanelKeys, setActivePanelKeys] = useState([]);
  const [sortBySurgeonPageData, setSortBySurgeonPageData] = useState();
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);

  /** Render Components */
  const actions = (caseData) => {
    return [
      {
        title: "View Case",
        onClick: (e) => {
          e.domEvent.stopPropagation();
          redirectToLiveCase(caseData);
        },
      },
    ];
  };

  const cardContent = (hospitalCase) => [
    {
      icon: <CaseDescriptionIcon />,
      content: hospitalCase.procedure?.procedureName
        ? hospitalCase.procedure.procedureName
        : null,
    },
    {
      icon: <CalenderIcon />,
      content:
        hospitalCase.dateOfSurgery || hospitalCase.timeOfSurgery ? (
          <>
            {hospitalCase.dateOfSurgery && (
              <>
                <Moment format={dateFormat}>
                  {hospitalCase.dateOfSurgery}
                </Moment>
                {hospitalCase.timeOfSurgery && " | "}
              </>
            )}
            {hospitalCase.timeOfSurgery && hospitalCase.timeOfSurgery}
          </>
        ) : null,
    },
  ];
  /** Render Components End */

  const onSearchCases = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        searchValue = searchValue.trim();
        setCasesSearchText(searchValue);
        if (sortBySurgeon) filterSurgeonList(searchValue);
        else
          await getCasesCountAndPopulate(caseType, procedureIds, searchValue, 1);
        setLoading(false);
      }, 500)
    );
  };

  const onTabChange = async (key) => {
    setLoading(true);
    setActivePanelKeys([key]);
    setCaseType(key);
    setCurrentPage(1);
    if (sortBySurgeon) await populateSurgeonList(key, casesSearchText);
    else await getCasesCountAndPopulate(key, procedureIds, casesSearchText, 1);
    setLoading(false);
  };

  const onSortingChange = async (sortType) => {
    setLoading(true);
    if (sortType === "surgeon") {
      setSortBySurgeon(true);
      await populateSurgeonList(caseType, casesSearchText);
    } else {
      setSortBySurgeon(false);
      await getCasesCountAndPopulate(caseType, procedureIds, casesSearchText);
    }
    setLoading(false);
  };

  const casesSortDropdown = (
    <Select onChange={onSortingChange} defaultValue="latestCases">
      <Select.Option value="latestCases">Sort by Latest Cases</Select.Option>
      <Select.Option value="surgeon">Sort by Surgeons</Select.Option>
    </Select>
  );

  const redirectToLiveCase = (caseData) => {
    // history.push(`/procedure/${caseData.procedure.id}/live-case`, {
    //   stepDetails: {
    //     caseId: caseData.id,
    //     currentScreen: caseData.currentScreen, // "STEP" -> Execution Flow OR null -> Table Setup
    //     currentStepId: caseData.currentStep, // null -> Start Page OR guid -> render that step
    //     isStarted: caseData.isStarted,
    //   },
    //   path: location.pathname,
    //   readOnly: (() => {
    //     switch (caseData.isStarted) {
    //       case CaseTypes.UPCOMING:
    //         return false;
    //       case CaseTypes.LIVE:
    //         return hasReadOnlyRights;
    //       case CaseTypes.COMPLETED:
    //         return true;
    //     }
    //   })(),
    // });
  };

  const onPanelCollapseChange = async (key) => {
    if (key.length > activePanelKeys.length) {
      const surgeonId = key[key.length - 1];
      await populateCasesBySurgeon(caseType, surgeonId, customPageSize, 0);
    }
    setActivePanelKeys(key);
  };

  const onLoadMore = async (surgeonId) => {
    await populateCasesBySurgeon(
      caseType,
      surgeonId,
      customPageSize,
      (sortBySurgeonPageData ? sortBySurgeonPageData[surgeonId].pageSize : 0) *
      customPageSize
    );
  };

  const filterSurgeonList = (searchValue) => {
    searchValue = searchValue?.toLowerCase();
    const temp = surgeonList.filter(
      (s) =>
        (s.firstName &&
          s.firstName.toLowerCase().indexOf(searchValue) !== -1) ||
        (s.middleName &&
          s.middleName.toLowerCase().indexOf(searchValue) !== -1) ||
        (s.lastName && s.lastName.toLowerCase().indexOf(searchValue) !== -1) ||
        "Dr.".toLowerCase().indexOf(searchValue) !== -1
    );
    setFilteredSurgeonList(temp);
  };

  /** API Calls */

  const getHospitalProcedureIds = async () => {
    const res = await hospitalService.getHospitalProcedureIds(
      hospitalId,
      isDeletedPage,
      entity,
      entityId
    );

    if (res?.Procedure) {
      setProcedureIds(res.Procedure);
      return res.Procedure;
    } else {
      toastNotification("error", "Something went wrong");
      return [];
    }
  };

  const populateCasesBySurgeon = async (caseType, surgeonId, limit, skip) => {
    setLoading(true);
    const params = {
      where: {
        hospital: hospitalId,
        surgeon: surgeonId,
        isDeleted: isDeletedPage,
        procedure: { in: procedureIds },
      },
      limit,
      skip,
    };
    if (!isDeletedPage) params.where.isStarted = caseType;

    const data = await hospitalService.getHospitalCasesBySurgeon(params);

    if (data) {
      let surgeonPageData = {};
      surgeonPageData[surgeonId] = {
        loadCases: data.length === customPageSize,
        pageSize:
          sortBySurgeonPageData && sortBySurgeonPageData[surgeonId]
            ? sortBySurgeonPageData[surgeonId].pageSize + 1
            : 1,
      };
      const pageData = { ...sortBySurgeonPageData, ...surgeonPageData };
      setSortBySurgeonPageData(pageData);

      let surgeonData = {};
      surgeonData[surgeonId] =
        skip > 0 ? [...caseListBySurgeon[surgeonId], ...data] : data;
      const casesData = { ...caseListBySurgeon, ...surgeonData };
      setCaseListBySurgeon(casesData);
    } else {
      toastNotification("error", "Error fetching the Case records");
    }
    setLoading(false);
  };

  const populateSurgeonList = async (caseType) => {
    const surgeons = await hospitalService.getHospitalSurgeons(
      hospitalId,
      caseType,
      { entity, procedureIds: JSON.stringify(procedureIds) }
    );

    if (surgeons) {
      setFilteredSurgeonList(surgeons);
      setSurgeonList(surgeons);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  const populateCasesList = async (
    caseTypeTemp,
    pIds,
    limit,
    skip,
    searchValue
  ) => {
    const whereCondition = {
      hospital: hospitalId,
      isDeleted: isDeletedPage,
    };

    if (searchValue) {
      whereCondition.caseType = caseTypeTemp;
      whereCondition.entity = entity;
      whereCondition.searchText = searchValue;
      whereCondition.procedureIds = JSON.stringify(pIds);
    } else {
      if (!isDeletedPage) whereCondition.isStarted = caseTypeTemp;
      whereCondition.procedure = { in: pIds };
    }
    const data = await hospitalService.getHospitalCases(
      whereCondition,
      limit,
      skip
    );

    if (data) {
      setCaseList(data);
    } else {
      toastNotification("error", "Error fetching the Case records");
    }
  };

  const getCasesCountAndPopulate = async (
    currentCaseType,
    pIds,
    searchText,
    currentPageNumber = currentPage
  ) => {
    let count;
    const params = {
      hospital: hospitalId,
      isDeleted: isDeletedPage,
      caseType: currentCaseType,
      searchText,
      entity,
      procedureIds: JSON.stringify(pIds),
    };

    if (searchText) {
      params.count = true;
      const res = await hospitalService.getHospitalCases(params);
      count = res?.count;
    } else {
      count = await hospitalService.getCasesCount(params);
    }

    setCasesCount(count ? count : 0);
    //setCurrentPage(1);
    if (count)
      await populateCasesList(
        currentCaseType,
        pIds,
        customPageSize,
        customPageSize * (currentPageNumber - 1),
        searchText
      );
    else setCaseList([]);

    return count;
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const procedureIdsTemp = await getHospitalProcedureIds();
      if (procedureIdsTemp.length)
        await getCasesCountAndPopulate(caseType, procedureIdsTemp);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        searchPlaceholderTxt="Search by Surgeon's Name"
        onSearchFunction={onSearchCases}
        dropDown={
          isDeletedPage || isCompletedCasesPage ? false : casesSortDropdown
        }
        {...pageHeaderProps}
      >
        {!isDeletedPage && !isCompletedCasesPage && (
          <Tabs onChange={onTabChange} className="cases-section-tab" defaultActiveKey={location.state?.caseType ?? CaseTypes.UPCOMING}>
            <TabPane tab="Upcoming Cases" key={CaseTypes.UPCOMING} />
            <TabPane tab="Live Cases" key={CaseTypes.LIVE} />
          </Tabs>
        )}
        {sortBySurgeon ? (
          <Collapse
            activeKey={activePanelKeys}
            onChange={onPanelCollapseChange}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <CollapseArrowIcon style={{ marginTop: "-0.5em" }} />
              ) : (
                  <ExpandArrowIcon style={{ marginTop: "-0.5em" }} />
                )
            }
            ghost
          >
            {filteredSurgeonList.map((surgeon) => (
              <Panel
                key={surgeon.id}
                header={
                  <div className="mytextdiv">
                    <div className="mytexttitle">
                      {[
                        "Dr.",
                        surgeon.firstName,
                        surgeon.middleName,
                        surgeon.lastName,
                      ].join(" ")}
                    </div>
                    <div className="textDivider"></div>
                  </div>
                }
              >
                {caseListBySurgeon && (
                  <List
                    dataSource={caseListBySurgeon[surgeon.id]}
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 4,
                      xl: 4,
                      xxl: 6,
                    }}
                    loadMore={
                      !loading &&
                      sortBySurgeonPageData[surgeon.id] &&
                      sortBySurgeonPageData[surgeon.id].loadCases && (
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: 12,
                            height: 32,
                            lineHeight: "32px",
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onLoadMore(surgeon.id);
                            }}
                            type="primary"
                          >
                            Show more results
                          </Button>
                        </div>
                      )
                    }
                    renderItem={(hospitalCase) => {
                      return (
                        <List.Item>
                          <CustomCard
                            headerIcon={<SurgeonColorIcon />}
                            title={
                              hospitalCase.surgeon &&
                              [
                                "Dr.",
                                hospitalCase.surgeon.firstName,
                                hospitalCase.surgeon.middleName,
                                hospitalCase.surgeon.lastName,
                              ].join(" ")
                            }
                            actions={actions(hospitalCase)}
                            onClick={() => {
                              if (isCompletedCasesPage)
                                variableURL = url.replace("/completed", "");
                              else if (isDeletedPage)
                                variableURL = url.replace("/deleted", "");
                              history.push(
                                `${variableURL}/${hospitalCase.id}`,
                                {
                                  readOnly: true,
                                  record: hospitalCase,
                                  isCompletedCasesPage: isCompletedCasesPage
                                }
                              );
                            }}
                            bodyContent={cardContent(hospitalCase)}
                          />
                        </List.Item>
                      );
                    }}
                  />
                )}
              </Panel>
            ))}
          </Collapse>
        ) : (
            <CardList
              dataSource={caseList}
              renderItem={(hospitalCase) => {
                return (
                  <List.Item>
                    <CustomCard
                      headerIcon={<SurgeonColorIcon />}
                      title={
                        hospitalCase.surgeon &&
                        [
                          "Dr.",
                          hospitalCase.surgeon.firstName,
                          hospitalCase.surgeon.middleName,
                          hospitalCase.surgeon.lastName,
                        ].join(" ")
                      }
                      // actions={actions(hospitalCase)}
                      onClick={
                        hospitalCase.isDeleted
                          ? null
                          : () => {
                            const variableURL = isCompletedCasesPage
                              ? url.replace("/completed", "")
                              : isDeletedPage
                                ? url.replace("/deleted", "")
                                : url;

                            history.push(`${variableURL}/${hospitalCase.id}`, {
                              record: hospitalCase,
                              pageNumber: currentPage,
                              caseType: caseType,
                              isCompletedCasesPage: isCompletedCasesPage
                            });
                          }
                      }
                      bodyContent={cardContent(hospitalCase)}
                    />
                  </List.Item>
                );
              }}
              pagination={{
                pageSize: customPageSize,
                total: casesCount,
                current: currentPage,
                onChange: async (page, pageSize) => {
                  setLoading(true);
                  setCurrentPage(page);
                  await populateCasesList(
                    caseType,
                    procedureIds,
                    pageSize,
                    pageSize * (page - 1),
                    casesSearchText
                  );
                  setLoading(false);
                },
              }}
            />
          )}
      </MainContainer>
    </Spin>
  );
};

export default CasesPage;
