import React, { useState } from "react";
import { Typography, Form, Input, Button, Row, Col, Tag } from "antd";
import { Link } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
import auth from "../../services/authService";
import NoAuthContainer from "../../containers/NoAuthContainer";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Paragraph, Title, Text } = Typography;

const ForgotPasswordPage = () => {
  const [loading, isLoading] = useState(false);
  const [title, setTitle] = useState("Forgot Password?");
  const [linkSent, setLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onFinish = async (formValues) => {
    isLoading(true);
    // Sends Email Link
    const isLinkSent = await auth.sendForgotPasswordLink(
      formValues.email.trim()
    );
    if (isLinkSent) {
      setErrorMessage("");
      setLinkSent(true);
      setTitle("Check your inbox");
    } else {
      setErrorMessage("Error sending the reset link.");
    }
    isLoading(false);
  };

  return (
    <NoAuthContainer>
      <Row align="middle">
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 8, offset: 8 }}
          xxl={{ span: 8, offset: 8 }}
          className="box_modal"
        >
          <Row>
            <Col span={24}>
              <Title level={2}>{title}</Title>
            </Col>
          </Row>
          <Row flex={true}>
            <Col span={24}>
              {linkSent ? (
                <Tag
                  color="success"
                  icon={
                    <CheckCircleFilled
                      style={{ width: "20px", fontSize: "16px" }}
                    />
                  }
                  style={{ marginBottom: "20px" }}
                >
                  <Text className="text-dark">
                    Reset link has been sent to below email address.
                  </Text>
                </Tag>
              ) : (
                <Paragraph>We will send a password recovery link to</Paragraph>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                name="forgotPasswordForm"
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  label={!linkSent ? "Email" : ""}
                  style={{
                    color: "inherit",
                  }}
                >
                  <Input
                    placeholder="chandwick@email.com"
                    disabled={linkSent}
                  />
                </Form.Item>
                {errorMessage && (
                  <Tag
                    color="#fff2f0"
                    style={{ borderColor: "#ffccc6" }}
                    icon={
                      <ExclamationCircleFilled
                        style={{
                          width: "20px",
                          fontSize: "16px",
                          color: "red",
                        }}
                      />
                    }
                  >
                    <Text className="text-dark">{errorMessage}</Text>
                  </Tag>
                )}
                {!linkSent && (
                  <Row style={{ textAlign: "end" }}>
                    <Col span={17}>
                      <Form.Item>
                        <Link to="/login">Back to Login</Link>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={linkSent}
                          loading={loading}
                        >
                          Send Link
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </NoAuthContainer>
  );
};

export default ForgotPasswordPage;
