import { useState, useEffect } from "react";
import { List, Card, Modal, Typography } from "antd";
import MainContainer from "../../containers/MainContainer";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import equipmentService from "../../services/equipmentService";
import manufacturerService from "../../services/manufacturerService";
import toastNotification from "../../components/toastNotification";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";

const { confirm } = Modal;
const { Paragraph } = Typography;
const customPageSize = 12;

const ManufacturerEquipmentsPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");
  const manufacturerId = params.id ? params.id : null;

  const [equipmentList, setEquipmentList] = useState([]);
  const [title, setTitle] = useState();
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [equipmentSearchText, setEquipmentSearchText] = useState();
  const [loading, setLoading] = useState();
  const [timeOut, setTimeOut] = useState(0);

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Deleted Instruments",
      backBtnPath: `/manufacturer/${manufacturerId}/equipment`,
    }
    : {
      title: title,
      btnText: "Add Instrument",
      onBtnClick: () => {
        history.push(`${url}/new`);
      },
      topActionMenuItems: [
        {
          title: "Show Deleted Instruments",
          path: `${url}/deleted`,
        },
      ],
    };

  const actions = (equipment) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(`${url}/${equipment.id}`, {
              editMode: true,
              record: equipment,
              pageNumber: currentPage,
            });
          },
        },
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(equipment.id);
          },
        },
      ];
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        setLoading(true);
        searchValue = searchValue.trim();
        setEquipmentSearchText(searchValue);
        await getSetEquipmentCountAndPopulate(searchValue, 1);
        setLoading(false);
      }, 500)
    );
  };

  const showDeleteConfirmation = (equipmentId) => {
    confirm({
      title: "Delete Instrument",
      content: "Are you sure you want to delete this Instrument?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteEquipment(equipmentId);
      },
    });
  };

  /** API */
  const populateEquipmentsList = async (limit, skip, searchValue) => {
    const data = await manufacturerService.getManufacturerEquipments(
      manufacturerId,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );

    if (data) {
      data.length > 1 ? setTitle("Instruments") : setTitle("Instrument");
      setEquipmentList(data);
    } else {
      toastNotification("error", "Error fetching the instrument records");
    }
  };

  const deleteEquipment = async (equipmentId) => {
    setLoading(true);
    const isDeleted = await equipmentService.patchEquipment({
      id: equipmentId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Instrument deleted successfully");
      setCurrentPage(1);
      await getSetEquipmentCountAndPopulate();
    } else {
      toastNotification("error", "Error deleting the instrument");
    }
    setLoading(false);
  };

  const getSetEquipmentCountAndPopulate = async (searchText, currentPageNumber = currentPage) => {
    const success = await equipmentService.getEquipmentCount(
      manufacturerId,
      isDeletedPage,
      searchText
    );

    if (success !== false) {
      setEquipmentCount(success);
      if (success > 0)
        await populateEquipmentsList(customPageSize, customPageSize * (currentPageNumber - 1), searchText);
      else setEquipmentList([]);
    } else {
      toastNotification("error", "Error fetching Instrument records");
    }
  };

  /** API */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetEquipmentCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Instrument Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 3,
          md: 3,
          lg: 4,
          xl: 4,
          xxl: 6,
        }}
        dataSource={equipmentList}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          responsive: true,
          pageSize: customPageSize,
          total: equipmentCount,
          current: currentPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentPage(page);
            await populateEquipmentsList(
              pageSize,
              pageSize * (page - 1),
              equipmentSearchText
            );
            setLoading(false);
          },
        }}
        loading={loading}
        renderItem={(equipment) => {
          return (
            <List.Item>
              <Card
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                      history.push(`${url}/${equipment.id}`, {
                        editMode: false,
                        record: equipment,
                        pageNumber: currentPage,
                      });
                    }
                }
                bordered={false}
                cover={
                  <div
                    className="icon-overlay-container"
                    style={{ width: "100%", height: "176px" }}
                  >
                    <SignedURLImage
                      fileName={equipment.image[0]}
                      height="100%"
                      width="100%"
                      fallback={equipmentPlaceholder}
                      key={equipment.image[0]}
                    />
                    <div className="icon-overlay">
                      <ActionMenu actions={actions(equipment)} />
                    </div>
                  </div>
                }
                preview="false"
                bodyStyle={{ padding: "0" }}
              />
              <Paragraph
                style={{ marginTop: "0.5em", marginBottom: "1em" }}
                ellipsis={{ rows: 2 }}
              >
                {equipment.equipmentName}
              </Paragraph>
            </List.Item>
          );
        }}
      />
    </MainContainer>
  );
};

export default ManufacturerEquipmentsPage;
