import { ReactComponent as CasesIcon } from "../assets/icons/sider-menu-icons/iconCases.svg";
import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { Roles } from "./role-config.json";

const hospitalReadonlyMenuItems = (parentPath, hospitalId) => {
  return [
    {
      name: "Hospitals",
      path: parentPath,
      icon: <BackIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
    },
    {
      name: "Cases",
      path: `${parentPath}/${hospitalId}/case`,
      icon: <CasesIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
    },
  ];
};

export default hospitalReadonlyMenuItems;
