import http from "./httpService";

async function getDistributors(conditions, limit, skip, populate, searchText) {
  try {
    let api;
    const params = {
      where: { isManufacturer: false, ...conditions },
      populate,
      limit,
      skip,
    };
    if (searchText) {
      api = `/distributor/search`;
      params.where.distributorName = { contains: searchText };
    } else {
      api = `/distributor`;
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getDistributor(id, populateManufacturers, populateHospitals) {
  try {
    const res = await http.get(`/distributor/${id}`, {
      params: {
        populate: populateManufacturers
          ? populateHospitals
            ? "manufacturers,hospitals"
            : "manufacturers"
          : populateHospitals
            ? "hospitals"
            : null,
        isDeleted: false,
        isManufacturer: false,
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function postDistributor(distributor) {
  try {
    distributor.isManufacturer = false;
    return await http.post("/distributor", distributor);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function putDistributor(distributor) {
  try {
    distributor.isManufacturer = false;
    return await http.patch(`/distributor/${distributor.id}`, distributor);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const patchRestoreDistributorManufacturer = async (distributorId) => {
  try {
    const res = await http.patch(`/distributor/restore/${distributorId}`);
    return res?.status === 200;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const getDistributorCount = async (isDeleted, searchText) => {
  try {
    const res = await http.get("/distributor/count", {
      params: { isDeleted, isManufacturer: false, searchText },
    });
    return res.data.count;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function getDistributorUsers(
  distributorId,
  distributorUserRoles,
  isDeleted,
  limit,
  skip,
  searchValue
) {
  try {
    let api;
    const params = {
      where: { role: { in: distributorUserRoles }, isDeleted },
      limit,
      skip,
    };
    if (searchValue) {
      api = `/user/search`;
      params.where.distributor = distributorId;
      params.where.or = [
        { firstName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { email: { contains: searchValue } },
      ];
    } else {
      api = `/distributor/${distributorId}/users`;
      params.sort = "createdAt DESC";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

const getDistributorHospitalProcedureIds = async (
  distributorId,
  hospitalId,
  isDeletedCases,
  entity
) => {
  const res = await http.dorAxiosGet("/cases/getAttachedProcedures", {
    distributorId,
    hospitalId,
    caseStatus: isDeletedCases,
    entity,
  });

  return res?.data;
};

/** Get Listing on Distributor Procedure */

/** PROCEDURE COUNT */
const getProcedureCount = async (ids, procedureType, isDeleted, searchText, regionalManagerId = []) => {
  try {
    const res = await http.get("/procedure/count", {
      params: { id: ids?.toString(), procedureType, isDeleted, searchText, regionalManagerId },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

async function getProcedures(
  distributorId,
  isDeleted,
  limit,
  skip,
  searchValue
) {
  try {
    let api;
    const params = {
      where: { distributor: distributorId, isDeleted },
      limit,
      skip,
    };
    if (searchValue) {
      api = `/procedure/search`;
      params.where.procedureName = { contains: searchValue };
    } else {
      api = `/procedure`;
      params.sort = "createdAt DESC";
      params.populate = "distributor";
    }

    const res = await http.get(api, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

/** Delete Procedure Api Call */
async function deleteProcedure(id) {
  try {
    await http.patch(`/procedure/${id}`, {
      isDeleted: true,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

/** Create procedure api call */
async function postProcedure(procedure) {
  try {
    return await http.post("/procedure", procedure);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getProcedure(id, getCaseDetails, populateClonedFrom) {
  try {
    const params = {};
    if (getCaseDetails) {
      params.populate = "case";
    }
    if (populateClonedFrom) {
      params.populate = "clonedFrom";
    }

    const res = await http.get(`/procedure/${id}`, { params });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const patchProcedure = async (id, data) => {
  try {
    const res = await http.patch(`/procedure/${id}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

async function putProcedure(procedure) {
  try {
    return await http.patch(`/procedure/${procedure.id}`, procedure);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getTrayEquipmentsList(
  distributorId,
  isDeletedPage,
  limit,
  skip,
  searchText,
  rmId // Get RM's tray & equipment
) {
  try {
    let res;
    const params = { populate: "distributor", skip, limit };
    if (searchText) {
      params.where = {
        or: [{ regionalManager: rmId }, { distributor: distributorId }],
        trayName: { contains: searchText },
        isDeleted: isDeletedPage,
      };
      res = await http.get(`/tray/search`, { params });
    } else {
      params.where = {
        or: [{ regionalManager: rmId }, { distributor: distributorId }],
        isDeleted: isDeletedPage
      }
      res = await http.get(`tray`, { params });
    }
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

async function getProcedureDistributor(procedureId) {
  try {
    const res = await http.get(`/procedure/${procedureId}`, {
      params: {
        populate: "distributor,regionalManager",
      },
    });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

async function postAttachProcedure(procedureId, caseId) {
  try {
    const res = await http.post(`/procedure/${procedureId}/attach`, {
      caseId,
    });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

async function postDetachProcedure(procedureId, caseId) {
  try {
    const res = await http.post(`/procedure/${procedureId}/detach`, {
      caseId,
    });
    return res.data;
  } catch (exception) {
    console.log("EXCEPTION", exception);
    return false;
  }
}

const getTableSetup = async (procedureId, trayId, limit, skip) => {
  try {
    const res = await http.get("/TableSetUpAndVerification", {
      params: {
        procedure: procedureId,
        tray: trayId,
        populate: "tray",
        limit,
        skip,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const postTableSetup = async (procedureId, tableSetUp) => {
  try {
    const res = await http.post(
      `/procedure/${procedureId}/tableSetUp`,
      tableSetUp
    );
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};
/* Tray Details API call */
const getEquipmentVerification = async (trayId) => {
  try {
    const res = await http.get(`/equipmenttray`, {
      params: {
        tray: trayId,
        populate: "equipment",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

async function patchVerifyEquipment(id, verificationStatus) {
  try {
    await http.patch(`/equipmentverification/${id}`, {
      isVerified: verificationStatus,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function markComplete(id, verifyStatus) {
  try {
    await http.patch(`/tablesetupandverification/${id}`, {
      verificationStatus: verifyStatus,
    });
    return true;
  } catch (exception) {
    console.log(exception);
  }
}

const getTrayDetails = async (trayId) => {
  try {
    const res = await http.get(`/equipmenttray`, {
      params: {
        where: {
          tray: trayId,
        },
        populate: "equipment,tray",
        limit: "1000",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getTrayOwnerData = async (trayId) => {
  try {
    const res = await http.get(`/tray/${trayId}`, {
      params: {
        populate: "distributor,regionalManager",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const postEquipmentVerificationDetails = async (trayId, procedureId) => {
  try {
    const res = await http.post(`tablesetup/${trayId}/verify`, null, {
      params: {
        procedureId: procedureId,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getEquipmentVerificationDetails = async (trayId, procedureId) => {
  try {
    const res = await http.get(`equipmentverification`, {
      params: {
        tray: trayId,
        procedure: procedureId,
        populate: "equipment",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return false;
  }
};

// Get area populated using RM's id to get mapped manufacturer detail
async function getAreaFromRM(id) {
  try {
    const res = await http.get(`/user/${id}`, { params: { populate: "area" } });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

export default {
  /** Distributor */
  getDistributor,
  getDistributors,
  postDistributor,
  putDistributor,
  patchRestoreDistributorManufacturer,
  getDistributorCount,
  getDistributorUsers,
  getDistributorHospitalProcedureIds,
  /** Procedure */
  getProcedureCount,
  getProcedures,
  deleteProcedure,
  postProcedure,
  getProcedure,
  patchProcedure,
  getProcedureDistributor,
  putProcedure,
  postDetachProcedure,
  postAttachProcedure,
  /** Tray & Equipments */
  getTrayEquipmentsList,
  getTableSetup,
  postTableSetup,
  getEquipmentVerification,
  patchVerifyEquipment,
  markComplete,
  getTrayDetails,
  getTrayOwnerData,
  postEquipmentVerificationDetails,
  getEquipmentVerificationDetails,
  /** Area */
  getAreaFromRM
};
