import React, { Fragment, useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import TrayCard from "./TrayCard";
import TrayGridContainer from "./TrayGridContainer";
import { ReactComponent as OrientationIcon } from "../assets/icons/iconOrientation.svg";
import { ReactComponent as OrientationIconRotated } from "../assets/icons/iconTrayOrientationRotated.svg";
import utilityService from "../services/utilityService";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const TrayGrid = (props) => {
  const {
    layout,
    count,
    onCloseButtonClick,
    onChangeVerificationStatus,
    layerId,
    dragEnd,
    orientation,
    changeOrientation = utilityService.changeOrientation,
    trays,
    id,
    startCase,
    isProcedureEditable,
    isCompletedCasesPage,
  } = props;

  const traysInALayer = [];

  const [layouts, setLayout] = useState(layout);
  const [traysInAZone, setTraysInAZone] = useState([]);

  const sectionNumber = (idx) => {
    if (layouts?.lg[idx]?.h === 1) {
      return <span className="grid-number">{id + idx}</span>;
    } else {
      if (
        count === 4
          ? [0, 6].includes(layouts?.lg[idx]?.x)
          : [0, 4, 8].includes(layouts?.lg[idx]?.x)
      ) {
        return (
          <>
            <span className="grid-number" style={{ bottom: "50%" }}>
              {id + idx}
            </span>
            <span className="grid-number">{id + idx + count / 2}</span>
          </>
        );
      } else {
        return <></>;
      }
    }
  };

  useEffect(() => {
    trays.forEach((el) => {
      if (el.sectionNumber) {
        traysInALayer[el.sectionNumber] = {
          src: el.tray.image,
          trayId: el.tray.id,
          name: el.tray.trayName,
          verificationStatus: el.verificationStatus,
          orientation: el.orientation,
        };
      }
    });
    traysInALayer.forEach((el, id) => {
      if ((count === 6 && id > 6) || (count === 4 && id > 4)) {
        if (count === 6 && id > 9) {
          id -= 3;
        }
        if (count === 4 && id > 6) {
          id -= 2;
        }
        if (el?.orientation === 1 && layouts.lg[id - count - 1]?.h === 1) {
          changeOrientation(layouts?.lg, id - count - 1, count, setLayout);
        }
      }
    });
    setTraysInAZone(traysInALayer);
  }, [trays]);

  useEffect(() => {
    const layoutData = [...layout.lg];
    setLayout({ lg: layoutData });
  }, [layout]);

  return (
    <Fragment>
      <ResponsiveReactGridLayout
        style={{ backgroundColor: "white" }}
        compactType="horizontal"
        isDraggable={false}
        isBounded={true}
        breakpoints={{ lg: 3000, md: 2000 }}
        isResizable={false}
        margin={[0, 0]}
        className="grid-layout-style"
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        rowHeight={171}
        layouts={layouts}
        useCSSTransforms={false}
        onLayoutChange={(currentLayout, layouts) => {
          setLayout(layouts);
        }}
      >
        {new Array(count).fill(undefined).map((el, idx) => {
          idx = parseInt(idx);
          return (
            <div
              style={{
                borderRight: "0",
                borderTop: layouts?.lg[idx]?.y > 0 ? "0" : null,
              }}
              key={idx}
            >
              {sectionNumber(idx)}
              {!startCase &&
              isProcedureEditable &&
              orientation &&
              idx < count / 2 ? (
                <>
                  <div
                    style={{
                      zIndex: "1",
                      width: layouts?.lg[idx]?.h > 1 ? "200%" : "100%",
                      height: layouts?.lg[idx]?.h > 1 ? "100%" : "200%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ zIndex: "2", marginTop: "0.7rem" }}
                      onClick={() => {
                        changeOrientation(layouts?.lg, idx, count, setLayout);
                      }}
                    >
                      {layouts?.lg[idx]?.h > 1 ? (
                        <OrientationIconRotated />
                      ) : (
                        <OrientationIcon />
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {startCase || isCompletedCasesPage ? (
                <TrayGridContainer
                  onChangeVerificationStatus={onChangeVerificationStatus}
                  tray={traysInAZone[id + idx]}
                  id={layerId}
                  grid={idx}
                  rotation={layouts?.lg[idx]?.h > 1 ? true : false}
                  isProcedureEditable={isProcedureEditable}
                  isCompletedCasesPage={isCompletedCasesPage}
                />
              ) : (
                <TrayCard
                  onCloseButtonClick={onCloseButtonClick}
                  id={layerId}
                  grid={idx}
                  dragEnd={dragEnd}
                  tray={traysInAZone[id + idx]}
                  rotation={layouts?.lg[idx]?.h > 1 ? true : false}
                  isProcedureEditable={isProcedureEditable}
                />
              )}
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </Fragment>
  );
};

export default TrayGrid;
