import { ReactComponent as AdminIcon } from "../assets/icons/sider-menu-icons/iconAdmin.svg";
import { ReactComponent as HospitalIcon } from "../assets/icons/sider-menu-icons/iconHospitals.svg";
import { ReactComponent as DistributorIcon } from "../assets/icons/sider-menu-icons/iconDistributor.svg";
import { ReactComponent as ManufacturerIcon } from "../assets/icons/sider-menu-icons/iconManufacturer.svg";
import { Roles } from "./role-config.json";

const mainMenuItems = [
  {
    name: "Hospitals",
    path: "/hospital",
    icon: <HospitalIcon />,
    rolesAccess: [Roles.DOR_ADMIN],
  },
  {
    name: "Manufacturers",
    path: "/manufacturer",
    icon: <ManufacturerIcon />,
    rolesAccess: [Roles.DOR_ADMIN],
  },
  {
    name: "Distributors",
    path: "/distributor",
    icon: <DistributorIcon />,
    rolesAccess: [Roles.DOR_ADMIN],
  },
  {
    name: "DinamicOR Admins",
    path: "/admin",
    icon: <AdminIcon />,
    rolesAccess: [Roles.DOR_ADMIN],
  },
];

export default mainMenuItems;
