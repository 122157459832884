import * as tus from "tus-js-client";
import http from "./httpService";
import awsService from "./awsService";
import { v4 as uuidv4 } from "uuid";

const uploadVideoCloudFront = async (file, config) => {
  try {
    let mediaId;
    const videoUrlDetails = await http.get("/video/uploadURL", {
      params: {
        uploadLength: file.size,
        fileName: file.name
      },
    });
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          // Recommended by Cloudflare. Should be a multiple of 256KiB.
          chunkSize: 52428800,
          uploadUrl: videoUrlDetails.data?.location,
          // Callback for errors which cannot be fixed using retries
          onError: function (error) {
            console.log("Failed because: " + error);
            reject(false);
          },
          // Callback for once the upload is completed
          onSuccess: function () {
            resolve(mediaId);
          },
          onAfterResponse: function (req, res) {
            return new Promise((resolve) => {
              let mediaIdHeader = res.getHeader("stream-media-id");
              if (mediaIdHeader) {
                mediaId = mediaIdHeader;
              }
              resolve();
            });
          },
          ...config
        });

        upload.start();
      });
  } catch (error) {
    console.log("EXCEPTION", error);
    return false;
  }
};

const getVideoSignedToken = async (id) => {
  try {
    const res = await http.get(`/video/getSignedToken`, {
      params: {
        videoId: id,
      },
    });
    return res.data;
  } catch (error) {
    console.log("EXCEPTION", error);
    return false;
  }
};

const deleteCloudFlareVideo = async (id) => {
  try {
    const res = await http.delete(`/video/${id}`);
    return res.status === 200;
  } catch (error) {
    console.log("EXCEPTION", error);
    return false;
  }
};

// async function uploadImage(formData, config) {
//   try {
//     const res = await http.post("/file/imageUpload", formData, config);
//     return res.data?.data;
//   } catch (exception) {
//     console.log(exception);
//     const isCancelled = http.isCancel(exception);
//     if (isCancelled) return "cancelled";
//     return false;
//   }
// }

async function uploadVideo(file, config) {
  try {
    const mediaId = await uploadVideoCloudFront(file, config);
    return await http.get("/video/result", {
      params: {
        mediaId: mediaId
      },
    });
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function deleteMedia(keyUrl) {
  try {
    const res = await http.post("/file/deleteImage", null, {
      params: {
        key: keyUrl,
      },
    });
    if (res.data === "OK") return true;
    return false;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

/** INTERNAL */

const getPresignedURLForImage = async (fileName) => {
  try {
    const res = await http.get("/image/generatePresignedURLForFile", {
      params: {
        fileName,
        contentType: "image/jpeg",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getPresignedURLForMultiPart = async (fileName, uploadId, partNumber) => {
  try {
    const res = await http.get("/image/generatePresignedURL", {
      params: {
        fileName,
        uploadId,
        partNumber,
        contentType: "image/jpeg",
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getUploadId = async (fileName) => {
  try {
    const res = await http.get("/image/getUploadId", {
      params: {
        fileName,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const putImageUsingPresignedUrl = async (url, data, params, tempConfig) => {
  try {
    const config = { ...tempConfig };
    if (params) {
      delete http.s3Axios.defaults.headers.put["Content-Type"];
      config.params = params;
    } else {
      config.headers = {
        "Content-Type": "image/jpeg",
      };
    }

    const res = await http.s3Axios.put(url, data, config);

    return JSON.parse(res.headers.etag);
  } catch (error) {
    console.log(error);
    return false;
  }
};
/** INTERNAL END */

/** EXTERNAL */

const loadImage = async (fileName) => {
  try {
    const res = await http.get("/image/generateGetURL", {
      params: {
        fileName,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const uploadImageUsingPresignedUrl = async (file, setProgress) => {
  const fileName =
    uuidv4() +
    "." +
    (file.convertHEICToJPG ? "jpg" : file.name.split(".").pop());

  const presignedUrl = await getPresignedURLForImage(fileName);

  if (presignedUrl) {
    const blob = new Blob([file.data]);
    //check if uploaded

    const config = setProgress
      ? {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      : null;

    const success = await putImageUsingPresignedUrl(
      presignedUrl,
      blob,
      null,
      config
    );
    return success ? fileName : false;
  } else {
    return false;
  }
};

const uploadMultipartImage = async (file, setProgress) => {
  try {
    const CHUNK_SIZE = 30000000; //30mb part
    const fileName = uuidv4() + "." + file.name.split(".").pop();

    const uploadId = await getUploadId(fileName);
    const noOfParts = Math.ceil(file.size / CHUNK_SIZE);
    const eachPercentagePart = 100 / noOfParts; // 33.33
    const partsPercentage = [];

    for (let i = 0; i < noOfParts; i++) partsPercentage[i] = 0;

    const presignedUrlPromises = [];
    for (let i = 1; i <= noOfParts; i++) {
      presignedUrlPromises.push(
        getPresignedURLForMultiPart(fileName, uploadId, i)
      );
    }
    const presignedUrls = await Promise.all(presignedUrlPromises);

    const eTagPromises = [];
    for (let i = 0; i < presignedUrls.length; i++) {
      const config = setProgress
        ? {
            onUploadProgress: (progressEvent) => {
              partsPercentage[i] = Math.round(
                (progressEvent.loaded * eachPercentagePart) /
                  progressEvent.total
              );

              let newTotal = 0;
              partsPercentage.forEach((per) => {
                newTotal += per;
              });

              setProgress(newTotal);
            },
          }
        : null;

      const blob = file.data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
      eTagPromises.push(
        putImageUsingPresignedUrl(
          presignedUrls[i],
          blob,
          {
            uploadId,
            partNumber: i + 1,
          },
          config
        )
      );
    }

    const eTags = await Promise.all(eTagPromises);

    const parts = eTags.map((ETag, i) => {
      return {
        ETag,
        PartNumber: i + 1,
      };
    });

    const res = await awsService.completeMultipartUpload(
      parts,
      uploadId,
      fileName
    );

    return res ? fileName : false;
  } catch (error) {
    console.log("Error uploading multipart image", error);
    return false;
  }
};

export default {
  // uploadImage,
  uploadVideo,
  deleteMedia,
  getVideoSignedToken,
  deleteCloudFlareVideo,
  uploadImageUsingPresignedUrl,
  uploadMultipartImage,
  loadImage,
};
