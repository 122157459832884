import TrayFrom from "../../shared-pages/manufacturer/TrayForm";

const RMTrayForm = (props) => {
  const {
    match: { params },
    location: { state },
  } = props;

  const { trayid: trayId, rmid: rmId, id } = params;
  return (
    <TrayFrom
      entityId={rmId}
      entityName="regionalManager"
      trayId={trayId}
      tray={state?.record}
      isReadOnly={state?.isPageReadOnly}
      manufacturerId={id}
    />
  );
};

export default RMTrayForm;

