import React, { memo } from "react";
import { useEffect, useState } from "react";
import { Form, Input, Row, Col, Spin, Divider, Typography } from "antd";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import distributorService from "../../services/distributorService";
import procedureService from "../../services/procedureService";
import { ReactComponent as ManufacturerColorIcon } from "../../assets/icons/colorIconsGreyManufacturers.svg";

const { Text } = Typography;

const CreateNewProcedurePage = (props) => {
  const { match, history, location } = props;
  const { procedureid: procedureId, id: procedureOwnerId } = match.params;

  const [clonedOwner, setClonedOwner] = useState(null);
  const [procedure, setProcedure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const pageHeaderProps = procedureId
    ? location?.state?.isCloneProcedure ? {
      title: procedure?.procedureName ?? "Cloned Procedure",
      btnText: "Continue",
      btnType: "primary"
    } :
      {
        title: "Edit Procedure",
        btnText: "Save",
        btnType: "primary"
      }
    : {
      title: "New Procedure",
      btnText: "Create",
    };

  let backPath =
    match.url.includes("new") && !procedureId
      ? match.url.replace("/new", "")
      : match.url + "/table-setup";

  const createProcedure = async (procedureFormValues) => {
    procedureFormValues.distributor = procedureOwnerId;
    const res = await distributorService.postProcedure(procedureFormValues);
    if (res.status === 200 && res.data?.id) {
      toastNotification("success", "New Procedure created");
      history.push(match.url.replace("new", `${res.data.id}/table-setup`), {
        tag: "Table Setup",
        create: true,
      });
    } else {
      toastNotification("error", "Error adding a new Procedure record");
    }
  };

  const editProcedure = async (procedureFormValues) => {
    procedureFormValues.id = procedureId;
    const res = await distributorService.putProcedure(procedureFormValues);
    if (res.status === 200) {

      history.push(backPath, {
        tag: "Table Setup",
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        tabKey: location.state?.tabKey,
        isCloneProcedure: location?.state?.isCloneProcedure
      });
    } else {
      toastNotification("error", "Error updating the Procedure record");
    }
  };

  const getProcedure = async (id) => {
    const procedureTemp = await procedureService.getProcedure(id);
    setProcedure(procedureTemp);
    if (procedureTemp) {
      form.setFieldsValue(procedureTemp);
      if (procedureTemp.clonedFrom?.distributor)
        await getClonedOwner(procedureTemp.clonedFrom.distributor);
    } else {
      toastNotification("error", "Requested Procedure record not found");
    }
  };

  const getClonedOwner = async (id) => {
    const clonedOwnerTemp = await distributorService.getDistributor(id);
    if (clonedOwnerTemp) {
      setClonedOwner(clonedOwnerTemp);
    } else {
      toastNotification("error", "Error fetching Procedure record");
    }
  };

  const onFinish = async (procedureFormValues) => {
    setLoading(true);
    if (procedureId) {
      procedureFormValues.id = procedureId;
      await editProcedure(procedureFormValues);
    } else {
      await createProcedure(procedureFormValues);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (procedureId) {
        await getProcedure(procedureId);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      formName="ProcedureAddEditForm"
      backBtnPath={backPath}
      routeState={{
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
        tabKey: location.state?.tabKey,
        isCloneProcedure: location?.state?.isCloneProcedure
      }}
      onBtnClick={() => {
        form.submit();
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <div style={{ marginTop: "2rem" }}>
          <Row align="center">
            <Col
              xs={{ span: 23 }}
              sm={{ span: 19 }}
              md={{ span: 15 }}
              lg={{ span: 11 }}
              xl={{ span: 11 }}
            >
              <Form
                form={form}
                name="ProcedureAddEditForm"
                id="ProcedureAddEditForm"
                size="large"
                colon="false"
                scrollToFirstError="true"
                onFinish={onFinish}
                layout="vertical"
                className="procedure"
                requiredMark={false}
              >
                <Form.Item
                  label="Procedure Name (Required)"
                  name="procedureName"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Procedure name",
                    },
                  ]}
                >
                  <Input placeholder="Example: New - Airway Balloon Dilation" />
                </Form.Item>

                <Form.Item
                  label="Procedure Description"
                  name="procedureDescription"
                >
                  <Input.TextArea
                    placeholder="A description on how this procedure works."
                    rows={7}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row align="center">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 20, offset: 1 }}
              md={{ span: 16, offset: 1 }}
              lg={{ span: 12, offset: 1 }}
              xl={{ span: 12, offset: 1 }}
            >
              {clonedOwner?.isManufacturer && (
                <>
                  <Row>
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}>
                      <ManufacturerColorIcon />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text type="secondary" style={{ fontSize: "12px" }}>
                          Created By
                        </Text>
                        <Text>{clonedOwner.distributorName}</Text>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </MainContainer>
  );
};
export default CreateNewProcedurePage;
