import { List } from "antd";

const ProcedureList = ({ dataSource, renderItem, pagination, loading }) => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      dataSource={dataSource}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        responsive: true,
        ...pagination,
      }}
      renderItem={renderItem}
      loading={loading}
    />
  );
};

export default ProcedureList;
