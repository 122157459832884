import { useEffect, useState } from "react";
import { Spin } from "antd";
import EntityHospitalsPage from "../../shared-pages/read-only/hospital-and-cases/EntityHospitalsPage";
import toastNotification from "../../components/toastNotification";
import manufacturerService from "../../services/manufacturerService";
import utilityService from "../../services/utilityService";

const ManufacturerHospitalsPage = ({ match }) => {
  const manufacturerId = match.params?.id;

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getManufacturerHospitals = async () => {
    const manufacturer = await manufacturerService.getManufacturer(
      manufacturerId
    );

    if (manufacturer?.manufacturerHospitals) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        manufacturer.manufacturerHospitals
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      manufacturer.manufacturerHospitals = sortedHospitals;
      
      setHospitalList(manufacturer.manufacturerHospitals);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getManufacturerHospitals();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityHospitalsPage hospitalList={hospitalList} />
    </Spin>
  );
};
export default ManufacturerHospitalsPage;
