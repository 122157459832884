import React, { useEffect } from "react";
import { Form, Input, Button, Typography, Row, Col } from "antd";
import queryString from "query-string";
import NoAuthContainer from "../../containers/NoAuthContainer";
import toastNotification from "../../components/toastNotification";
import authServices from "../../services/authService";

const { Title, Paragraph } = Typography;

function ResetPasswordPage(props) {
  const { location, history } = props;
  const [form] = Form.useForm();

  const queryParams = queryString.parse(location.search);
  const resetPasswordToken = queryParams.token ? queryParams.token : null;

  const onFinish = async (formValues) => {
    const isPasswordReset = await authServices.resetPassword(
      formValues.email.trim(),
      formValues.newPassword.trim(),
      resetPasswordToken
    );

    if (isPasswordReset) {
      toastNotification(
        "success",
        "Your password has been set. You can now login."
      );
      history.push("/login");
    } else {
      toastNotification(
        "error",
        "The password reset link is invalid. Please request a new password reset."
      );
    }
  };

  useEffect(() => {
    const userPayload = authServices.decodeJWTToken(resetPasswordToken);
    if (userPayload) {
      form.setFieldsValue({
        email: userPayload.user.email,
      });
    } else {
      toastNotification("error", "Something went wrong");
    }
  });

  return (
    <NoAuthContainer>
      <Row align="middle">
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 8, offset: 8 }}
          xxl={{ span: 8, offset: 8 }}
          className="box_modal"
        >
          <Title level={2}>Set Password</Title>
          <Paragraph>
            Please use password between 8 - 16 characters which should include
            at least 1 numeric character, 1 special character, one uppercase and
            one lowercase without any space.
          </Paragraph>
          <Form
            name="resetPasswordForm"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item name="email">
              <Input
                disabled
                style={{
                  color: "inherit",
                }}
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              // validateTrigger={["onBlur"]}
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`<>.,()!@#$%^&*{}/|-])(?=.{8,16})"
                  ),
                  message: "Password does not match the mentioned criteria",
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input.Password maxLength={16} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
              onPaste={(e) => e.preventDefault()}
            >
              <Input.Password maxLength={16} />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit">
                    Set Password
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </NoAuthContainer>
  );
}

export default ResetPasswordPage;
