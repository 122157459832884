import http from "./httpService";
import jwtDecode from "jwt-decode";

http.setJWT(getJWT());

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwt ? jwtDecode(jwt) : false;
    // tODO :: instead of playload, user ne added user details from local storage
  } catch (exception) {
    console.log(exception);
    return null;
  }
}

function getJWT() {
  return localStorage.getItem("token");
}

function decodeJWTToken(jwt) {
  try {
    return jwtDecode(jwt);
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function login(email, password) {
  try {
    const res = await http.post("/login", { email, password });

    if (res.data.token) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      http.setJWT(getJWT());
    }
    return res;
  } catch (exception) {
    console.log(exception);
    if (exception.response) return exception.response;
    return false;
  }
}

async function logout() {
  try {
    await http.post("/logout");
  } catch (exception) {
    console.log(exception);
  } finally {
    localStorage.clear();
  }
}

async function sendForgotPasswordLink(email) {
  try {
    const res = await http.post("/sendPasswordResetLink", {
      email,
    });
    if (res.status === 200) return true;

    return false;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function resetPassword(email, password, token) {
  try {
    const res = await http.patch(
      "/resetPassword",
      {
        email,
        password,
      },
      {
        headers: { Authorization: token },
      }
    );
    if (res.status === 200) return true;
    return false;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getBackendVersion() {
  try {
    const res = await http.get("/versions");
    return res.data.backend;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

const authService = {
  getCurrentUser,
  getJWT,
  decodeJWTToken,
  login,
  logout,
  resetPassword,
  sendForgotPasswordLink,
  getBackendVersion,
};
export default authService;
