import { useState } from "react";
import { List, Typography, Input, Empty } from "antd";
import EquipmentImage from "../../components/EquipmentImage";
import EquipmentModal from "../../components/EquipmentModal";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import { ReactComponent as CaseCart } from "../../assets/icons/iconCaseCart.svg";
import { ReactComponent as NoRecordImage } from "../../assets/Images/NoRecord.svg";

const { Paragraph, Text } = Typography;
const { Search } = Input;

const AllEquipmentDrawer = ({ trayDetails }) => {
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [equipment, setEquipment] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [isSearchGoing, setIsSearchGoing] = useState(false);

  const closeEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setShowEquipmentModal(false);
  };

  const getSectionNumber = (sectionNumber) => {
    return (
      <span
        style={{
          display: "inline-flex",
          flexWrap: "wrap",
          backgroundColor: "white",
        }}
      >
        <span
          className={
            sectionNumber > 0
              ? "section-number"
              : "section-number-for-case-cart"
          }
        >
          {sectionNumber > 0 ? <span>{sectionNumber}</span> : <CaseCart />}
        </span>
      </span>
    );
  };

  const renderTrays = () => {
    const phases = [];
    for (const tray of trayDetails) {
      const renderSteps = [];
      renderSteps.push(
        <>
          <div style={{ display: "inline-flex", width: "100%" }}>
            <div className="tray-label">{tray.tray.trayName}</div>
            <div>{getSectionNumber(tray.tray.sectionNumber)}</div>
            <hr
              style={{
                minHeight: 1,
                minWidth: "84%",
                margin: "0.7rem 1rem",
                borderTop: "1px dashed rgb(29,29,29)",
              }}
            ></hr>
          </div>
          <List
            className="listStyle"
            dataSource={tray.equipment}
            locale={{
              emptyText: (
                <Text
                  strong
                  style={{
                    color: "grey",
                    float: "left",
                    paddingBottom: "2rem",
                  }}
                >
                  {" "}
                  No instruments{" "}
                </Text>
              ),
            }}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 4,
            }}
            renderItem={(equipment) => (
              <List.Item>
                <div>
                  <EquipmentImage
                    imageUrl={equipment.equipment.image[0]}
                    onClickHandler={() => {
                      setEquipment(equipment.equipment);
                      setShowEquipmentModal(true);
                    }}
                    videoUrl={equipment.equipment.video[0]}
                    fallbackImage={equipmentPlaceholder}
                  />
                  <Paragraph
                    ellipsis={{ rows: 2 }}
                    strong
                    style={{
                      marginBottom: 0,
                      marginTop: "5px",
                      lineHeight: "1rem",
                    }}
                  >
                    {equipment.equipment.equipmentName}
                  </Paragraph>
                  <section style={{ marginTop: "5px" }}>
                    <Text>
                      <span>Qty: {equipment.quantity}</span>
                    </Text>
                  </section>
                </div>
              </List.Item>
            )}
          />
        </>
      );
      if (renderSteps.length > 0) {
        phases.push(<>{renderSteps}</>);
      }
    }
    return phases;
  };

  const onSearchFunction = (keyword) => {
    if (!keyword) {
      setIsSearchGoing(false);
    } else {
      setIsSearchGoing(true);
      keyword = keyword.toLowerCase();
      let result = [];
      trayDetails.forEach((el) => {
        el.equipment.forEach((item) => {
          if (item.equipment?.equipmentName.toLowerCase().includes(keyword)) {
            result?.push(item);
          }
        });
      });
      // Remove multiple copies of same instrument
      result = result.filter((el, i, a) => {
        return a.findIndex((e) => e.id === el.id) === i;
      });
      setSearchResult(result);
    }
  };

  const renderSearchResult = () => {
    return (
      <div>
        <div style={{ padding: "20px 0", color: "rgb(128,130,133)" }}>
          Search Results
        </div>
        <List
          dataSource={searchResult}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          locale={{
            emptyText: (
              <Empty
                image={<NoRecordImage />}
                description={<Text strong> No records found. </Text>}
              />
            ),
          }}
          renderItem={(equipment) => (
            <List.Item>
              <div>
                <EquipmentImage
                  imageUrl={equipment.equipment.image[0]}
                  onClickHandler={() => {
                    setEquipment(equipment.equipment);
                    setShowEquipmentModal(true);
                  }}
                  videoUrl={equipment.equipment.video[0]}
                  fallbackImage={equipmentPlaceholder}
                />
                <Paragraph
                  ellipsis={{ rows: 2 }}
                  strong
                  style={{
                    lineHeight: "1rem",
                    marginBottom: 0,
                    marginTop: "5px",
                  }}
                >
                  {equipment.equipment.equipmentName}
                </Paragraph>
                <section style={{ marginTop: "5px" }}>
                  {getSectionNumber(equipment.sectionNumber)}
                </section>
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  };

  return (
    <>
      {trayDetails?.length ? (
        <div>
          <div style={{ paddingBottom: "20px" }}>
            <Search
              placeholder="Search by Instrument Name"
              onSearch={onSearchFunction}
              onChange={(e) => onSearchFunction(e.target.value)}
              allowClear
              style={{ width: "100%" }}
              key="searchInput"
            />
          </div>
          {!isSearchGoing ? (
            <div
              className="dor-all-equipments-drawer"
              style={{ overflowX: "hidden" }}
            >
              {renderTrays()}
            </div>
          ) : (
            <div className="dor-all-equipments-drawer">
              {renderSearchResult()}
            </div>
          )}
          {showEquipmentModal && (
            <EquipmentModal
              show={showEquipmentModal}
              close={closeEquipmentModal}
              equipment={equipment}
            />
          )}
        </div>
      ) : (
        <Empty
          style={{ marginTop: "2rem" }}
          image={<NoRecordImage />}
          description={<Text strong> No records found. </Text>}
        />
      )}
    </>
  );
};

export default AllEquipmentDrawer;
