import ExecutionFlowPage from "../../shared-pages/editable-mini-app/ExecutionFlowPage";

const RMExecutionFlowPage = (props) => {
    const {
    match: { params }, 
    location: { state }
  } = props;

  const { procedureid: procedureId, rmid: rmId } = params;

  return (
    <ExecutionFlowPage
      entityId={rmId}
      entityName="regionalManager"
      procedureId={procedureId}
      state={state}
    />
  );
};

export default RMExecutionFlowPage;
