import React, { useState, useEffect } from "react";
import { Typography, Tag } from "antd";
import MainContainer from "../../containers/MainContainer";
import { ReactComponent as TimeIcon } from "../../assets/icons/icon_time.svg";

const { Text, Paragraph } = Typography;
const { CheckableTag } = Tag;

const LiveCaseContainer = (props) => {
  const {
    children,
    procedureName,
    selectedTag: tagSelected,
    handleTagSelectionChange,
    backBtnPath,
    routeState
  } = props;

  const tagsData = ["Table Setup", "Surgical Flow"];

  function LiveCaseTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    setTime(strTime);
    return strTime;
  }

  const [time, setTime] = useState();

  setInterval(function () {
    LiveCaseTime(new Date());
  }, 1000 * 60);

  useEffect(() => {
    LiveCaseTime(new Date());
  }, []);
  /** Rendering Components */
  const renderLiveCase = (
    <div
      style={{
        display: "flex",
        paddingRight: "20px",
        color: "black",
      }}
    >
      <TimeIcon />
      <Text style={{ paddingTop: "4px", fontSize: "20" }}>{time}</Text>
    </div>
  );

  const renderTag = (
    <div
      style={{
        display: "inherit",
        border: "1px solid black",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag}
          className="table-setup-tags"
          style={
            tagSelected.indexOf(tag) > -1
              ? {
                backgroundColor: "#000",
                color: "white",
                display: "flex",
                alignItems: "center",
              }
              : null
          }
          // checked={tagSelected.indexOf(tag) > -1} // currentScreen===null -> table setup else if "STEP" then Execution Flow
          onChange={() => handleTagSelectionChange(tag)}
        >
          {tag}
        </CheckableTag>
      ))}
    </div>
  );
  /** Rendering Components End */

  //Title
  const title = (
    <Paragraph
      style={{ margin: "0 0.5em", whiteSpace: "normal" }}
      ellipsis={{ rows: 2 }}
    >
      {procedureName}
    </Paragraph>
  );

  return (
    <MainContainer
      tags={renderTag}
      livecase={renderLiveCase}
      title={title}
      backBtnPath={backBtnPath}
      routeState={{
        isProcedurePage: true,
        readOnly: true,
        ...routeState
      }}
    >
      {children}
    </MainContainer>
  );
};

export default LiveCaseContainer;
