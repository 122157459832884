import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Typography,
  Upload,
  Button,
  Spin,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import UploadItem from "../../components/UploadItem";
import toastNotification from "../../components/toastNotification";
import EquipmentModal from "../../components/EquipmentModal";
import equipmentService from "../../services/equipmentService";
import mediaService from "../../services/mediaService";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import Uploader from "../../components/Uploader";

const { confirm } = Modal;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const EquipmentForm = ({
  entityId,
  entityName,
  equipment: equipmentProp,
  equipmentId,
  isReadOnly = true
}) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const parentPath = url.replace(equipmentId ? `/${equipmentId}` : "/new", "");

  const [form] = Form.useForm();
  const [equipment, setEquipment] = useState(equipmentProp);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [editMode, setEditMode] = useState(!equipmentId || !isReadOnly);
  const [carouselItemIndex, setCarouselItemIndex] = useState(0);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);
  const [videosToBeDeleted, setVideosToBeDeleted] = useState([]);

  const pageHeaderProps = equipmentId
    ? {
      title: equipment?.equipmentName
        ? equipment.equipmentName
        : "Instrument Details",
      ...(equipment &&
        !equipment.distributor?.isManufacturer && {
        btnText: editMode ? "Save" : "Edit",
        btnType: editMode ? "primary" : "secondary",
        topActionMenuItems: [
          {
            title: "Delete Instrument",
            path: "",
            onClick: () => {
              showDeleteConfirmation(equipmentId);
            },
          },
        ],
      }),
    }
    : {
      title: "New Instrument",
      btnText: "Add",
    };

  const uploaderProps = {
    multiple: true,
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "rgb(242,101,36)",
        "100%": "rgb(242,101,36)",
      },
      strokeWidth: 2,
      showInfo: false,
    },
  };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Instrument",
      content: "Are you sure you want to delete this Instrument?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        await deleteEquipment();
      },
    });
  };

  const onRemoveImage = async (file) => {
    setImageFiles((fileList) => {
      const newFileList = fileList.filter(
        (fileItem) => fileItem.uid !== file.uid
      );

      return [...newFileList];
    });
    imagesToBeDeleted.push(file.url);
    setImagesToBeDeleted([...imagesToBeDeleted]);
    return true;
  };

  const onRemoveVideo = async (file) => {
    setVideoFiles((fileList) => {
      const newFileList = fileList.filter(
        (fileItem) => fileItem.uid !== file.uid
      );
      return [...newFileList];
    });
    videosToBeDeleted.push(file.result.result.uid);
    setVideosToBeDeleted([...videosToBeDeleted]);
    return true;
  };

  const setFileListHelper = (setFile, fileObject) => {
    setFile((latestList) => {
      const index = latestList.findIndex((item) => item.uid === fileObject.uid);
      latestList[index] = fileObject;
      return [...latestList];
    });
  };

  const customImageUpload = async (file, isRetryUpload) => {
    const tempFileObject = {
      name: "Uploading...",
      status: "uploading",
      percent: 0,
      uid: file.id,
    };

    if (isRetryUpload) {
      setFileListHelper(setImageFiles, tempFileObject);
    } else {
      setImageFiles((fileList) => {
        const newFileList = fileList.concat([tempFileObject]);
        return [...newFileList];
      });
    }

    const setImageUploadProgress = (percentage) => {
      setImageFiles((latestList) => {
        const index = latestList.findIndex(
          (item) => item.uid === tempFileObject.uid
        );
        latestList[index].percent = percentage;
        return [...latestList];
      });
    };

    let fileName;
    if (file.size > 30000000) {
      fileName = await mediaService.uploadMultipartImage(
        file,
        setImageUploadProgress
      );
    } else {
      fileName = await mediaService.uploadImageUsingPresignedUrl(
        file,
        setImageUploadProgress
      );
    }
    if (fileName) {
      setFileListHelper(setImageFiles, {
        name: "Click to preview",
        status: "done",
        percent: 100,
        url: fileName,
        uid: file.id,
      });
    } else {
      setFileListHelper(setImageFiles, {
        name: "Uploading Failed",
        status: "error",
        percent: 0,
        url: fileName,
        originalFile: file,
        uid: file.id,
      });
    }
  };

  const customVideoUpload = async ({ file }, isRetryUpload) => {
    const tempFileObject =
      file.size > 1000000000
        ? {
          name: "Uploading failed. Media exceeds file limit",
          status: "error",
          percent: 0,
          uid: file.uid,
        }
        : {
          name: "Uploading...",
          status: "uploading",
          percent: 0,
          uid: file.uid,
        };

    if (isRetryUpload) {
      setFileListHelper(setVideoFiles, tempFileObject);
    } else {
      setVideoFiles((fileList) => {
        const newFileList = fileList.concat([tempFileObject]);
        return [...newFileList];
      });
    }

    if (tempFileObject.status !== "error") {
      const config = {
        // Callback for reporting upload progress
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentCompleted = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setVideoFiles((latestList) => {
            const index = latestList.findIndex(
              (item) => item.uid === tempFileObject.uid
            );
            latestList[index].percent = percentCompleted;
            return [...latestList];
          });
        },
      };
      const videoResult = await mediaService.uploadVideo(file, config);
      if (videoResult?.data) {
        const timeOut = (() => {
          if (file.size <= 20000000) {
            return 10;
          } else if (file.size > 50000000 && file.size <= 100000000) {
            return 15;
          } else if (file.size > 100000000 && file.size <= 200000000) {
            return 20;
          } else if (file.size > 200000000 && file.size <= 300000000) {
            return 25;
          } else if (file.size > 300000000 && file.size <= 400000000) {
            return 30;
          } else if (file.size > 400000000 && file.size <= 500000000) {
            return 35;
          } else {
            return 60;
          }
        })();

        setTimeout(async () => {
          const signedToken = await mediaService.getVideoSignedToken(
            videoResult.data.result.uid
          );

          if (signedToken) {
            setFileListHelper(setVideoFiles, {
              name: "Click to Preview",
              status: "done",
              percent: 100,
              url: videoResult.data.result.thumbnail.replace(
                videoResult.data.result.uid,
                signedToken
              ),
              uid: file.uid,
              result: videoResult.data,
            });
          } else {
            setFileListHelper(setVideoFiles, {
              name: "Uploading Failed",
              status: "error",
              originalFile: file,
              percent: 0,
              uid: file.uid,
            });
          }
        }, timeOut * 1000);
      } else {
        setFileListHelper(setVideoFiles, {
          name: "Uploading Failed",
          status: "error",
          originalFile: file,
          percent: 0,
          uid: file.uid,
        });
      }
    }
  };

  const onRetryUpload = (fileItem, isImage) => {
    isImage
      ? customImageUpload(fileItem.originalFile, true)
      : customVideoUpload(fileItem.originalFile, true);
  };

  const showHideModalPopUp = (fileType, index) => {
    setCarouselItemIndex(
      fileType === "image" ? index : imageFiles.length + index
    );
    setShowModal(!showModal);
  };

  const onFinish = async (equipmentFormValues) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setLoading(true);

      //delete images if any
      for (const img of imagesToBeDeleted) {
        await mediaService.deleteMedia(img);
      }
      setImagesToBeDeleted([]);

      //delete videos if any
      for (const videoId of videosToBeDeleted) {
        await mediaService.deleteCloudFlareVideo(videoId);
      }
      setVideosToBeDeleted([]);

      equipmentFormValues[entityName] = entityId;

      equipmentFormValues.image = imageFiles
        .filter((imageFile) => imageFile.status === "done")
        .map((imageFile) => imageFile.url);
      equipmentFormValues.video = videoFiles
        .filter((videoFile) => videoFile.status === "done")
        .map((fileItem) => fileItem.result);

      if (equipmentId) {
        equipmentFormValues.id = equipmentId;
        await editEquipment(equipmentFormValues);
      } else {
        await addEquipment(equipmentFormValues);
      }
      setLoading(false);
    }
  };

  /** API */
  const addEquipment = async (equipmentFormValues) => {
    // TODO :: Trim values before submit
    const res = await equipmentService.postEquipment(equipmentFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Instrument added");
      history.push(parentPath);
    } else {
      toastNotification("error", "Error adding a new Instrument");
    }
  };

  const editEquipment = async (equipmentFormValues) => {
    equipmentFormValues.id = equipmentId;
    const res = await equipmentService.patchEquipment(equipmentFormValues);
    if (res.status === 200) {
      toastNotification("success", "Instrument record updated");
      setEditMode(false);
      history.replace({
        state: {
          record: res.data,
          pageNumber: location.state?.pageNumber,
          activeTabKey: location.state?.activeTabKey
        }
      });
    } else {
      toastNotification("error", "Error updating the Instrument");
    }
  };

  const deleteEquipment = async () => {
    setLoading(true);
    // TODO :: Trim values before submit
    const isDeleted = await equipmentService.patchEquipment({
      id: equipmentId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Instrument deleted successfully");
      history.push(parentPath);
    } else {
      toastNotification("error", "Error deleting the Instrument");
    }
    setLoading(false);
  };

  const fetchEquipment = async () => {
    setLoading(true);
    const data = await equipmentService.getEquipment(entityId, equipmentId);
    if (data) {
      setEquipment(data);
      return data;
    } else {
      toastNotification("error", "Requested Instrument not found");
    }
    setLoading(false);
  };

  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (equipmentId) {
        let equipTemp = equipment;
        if (!equipment) equipTemp = await fetchEquipment(equipmentId);

        if (equipTemp) {
          setImageFiles(
            equipTemp.image?.length > 0
              ? equipTemp.image.map((imageName, index) => ({
                name: "Click to Preview",
                status: "done",
                percent: 100,
                url: imageName,
                uid: index,
              }))
              : []
          );
          const existingVideoFiles = [];
          let i = 0;
          if (equipTemp.video && equipTemp.video.length > 0) {
            for (const { result } of equipTemp.video) {
              const signedToken = await mediaService.getVideoSignedToken(
                result.uid
              );

              existingVideoFiles.push({
                name: "Click To Preview",
                status: "done",
                url: result.thumbnail.replace(result.uid, signedToken),
                result: { result },
                uid: i,
              });
              i++;
            }
          }

          setVideoFiles(existingVideoFiles);
          form.setFieldsValue(equipTemp);
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="equipmentAddEditForm"
        backBtnPath={parentPath}
        routeState={{
          pageNumber: location.state?.pageNumber,
          activeTabKey: location.state?.activeTabKey
        }}
        divider={true}
        onBtnClick={() => {
          if (!editMode) {
            setEditMode(true);
          } else {
            form.submit();
          }
        }}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "1rem" }}>
          <Row align="middle">
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              xxl={{ span: 14, offset: 5 }}
            >
              <Form
                form={form}
                name="equipmentAddEditForm"
                id="equipmentAddEditForm"
                size="large"
                colon="false"
                scrollToFirstError="true"
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
              >
                <Row>
                  <Col span={24}>
                    <Title level={5}>Details</Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Instrument Name (Required)"
                      name="equipmentName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Instrument Name",
                        },
                      ]}
                    >
                      <Input disabled={!editMode} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Description" name="description">
                      <TextArea
                        style={{ resize: "none" }}
                        rows={4}
                        autoSize={false}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={10} className="equipment-upload-img">
                    <Form.Item name="imageItem">
                      <Title level={5} style={{ marginBottom: 0 }}>
                        Image (Max 5)
                      </Title>
                      <Paragraph
                        style={{
                          color: "rgb(128,130,133)",
                          marginBottom: "0.3em",
                        }}
                      >
                        Supported formats: jpeg, jpg, png, heic.
                        <br />
                        Max file size: 500 MB.
                      </Paragraph>
                      {imageFiles.map((fileItem, index) => {
                        return (
                          <UploadItem
                            fileItem={fileItem}
                            onRemoveItem={onRemoveImage}
                            isImage
                            showModalPopUp={() =>
                              showHideModalPopUp("image", index)
                            }
                            onRetryUpload={onRetryUpload}
                            readOnly={!editMode}
                            fallbackImg={equipmentPlaceholder}
                          />
                        );
                      })}
                      <Uploader
                        id="uploadInstrument"
                        imageFiles={imageFiles}
                        readOnly={!editMode}
                        customRequest={(file) => customImageUpload(file)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11} offset={1} className="equipment-upload-img">
                    <Form.Item name="videoItem">
                      <Title level={5} style={{ marginBottom: 0 }}>
                        Video (Max 2)
                      </Title>
                      <Paragraph
                        style={{
                          color: "rgb(128,130,133)",
                          marginBottom: "0.3em",
                        }}
                      >
                        Supported formats: mp4, m4v, mov
                        <br />
                        Max file size: 1 GB
                      </Paragraph>
                      {videoFiles.map((fileItem, index) => {
                        return (
                          <UploadItem
                            fileItem={fileItem}
                            onRemoveItem={onRemoveVideo}
                            showModalPopUp={() =>
                              showHideModalPopUp("video", index)
                            }
                            onRetryUpload={onRetryUpload}
                            readOnly={!editMode}
                          />
                        );
                      })}
                      <Upload
                        name="videoUpload"
                        accept=".mp4, .mov, .m4v"
                        beforeUpload={(file, fileList) => {
                          if (
                            fileList.length > 2 ||
                            fileList.length + videoFiles.length > 2
                          ) {
                            if (file.uid === fileList[0].uid) {
                              toastNotification(
                                "warning",
                                "You can add upto 2 videos only!"
                              );
                            }
                            return false;
                          }
                        }}
                        customRequest={customVideoUpload}
                        fileList={videoFiles}
                        {...uploaderProps}
                      >
                        {videoFiles.length < 2 && editMode && (
                          <Button
                            disabled={!editMode}
                            size="middle"
                            className="add-img-btn"
                          >
                            + Add Video
                          </Button>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {showModal && (
            <EquipmentModal
              show={showModal}
              close={() => {
                setShowModal(false);
              }}
              equipment={{
                ...equipment,
                video: videoFiles
                  .filter((videoFile) => videoFile.status === "done")
                  .map((fileItem) => fileItem.result),
                image: imageFiles
                  .filter((fileItem) => fileItem.status === "done")
                  .map((fileItem) => fileItem.url),
              }}
              slideIndex={carouselItemIndex}
            />
          )}
        </div>
      </MainContainer>
    </Spin>
  );
};

export default EquipmentForm;
