import { notification } from "antd";

const toastNotification = (state, message) => {
  notification[state]({
    message: message,
    placement: "bottomRight",
  });
};

export default toastNotification;
