import axios from "axios";

const dorAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const cloudFlareAxios = axios.create({
  baseURL: process.env.REACT_APP_CLOUDFLARE_URL,
});

const s3Axios = axios.create();

dorAxios.interceptors.response.use(undefined, (error) => {
  if (
    error.response?.status === 403 &&
    error.response.config?.url !== "/resetPassword"
  ) {
    // FORBIDDEN
    localStorage.clear();
    window.location.replace("/");
  }
  return Promise.reject(error);
});

function setJWT(jwt) {
  dorAxios.defaults.headers.common["Authorization"] = jwt;
}

// function getCancelTokenSource() {
//   return axios.CancelToken.source();
// }

function newInstance() {
  return axios.create();
}

const dorAxiosGet = async (url, params) => {
  try {
    return await dorAxios.get(url, { params });
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const dorAxiosPost = async (url, data, config) => {
  try {
    return await dorAxios.post(url, data, config);
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const dorAxiosPatch = async (url, data, config) => {
  try {
    return await dorAxios.patch(url, data, config);
  } catch (exception) {
    console.log(exception);
    return false;
  }
};

const httpService = {
  get: dorAxios.get,
  post: dorAxios.post,
  put: dorAxios.put,
  patch: dorAxios.patch,
  delete: dorAxios.delete,
  setJWT,
  cloudFlareAxios,
  s3Axios,
  newInstance,
  dorAxiosGet,
  dorAxiosPatch,
  dorAxiosPost,
  // getCancelTokenSource,
  // isCancel: (exception) => axios.isCancel(exception),
};
export default httpService;
