import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Spin, Tag, Typography } from "antd";
import EmptyDiv from "../../../components/EmptyDiv";
import { ReactComponent as StethoscopeColorIcon } from "../../../assets/icons/hospital-case/colorIconStethoscope.svg";
import { ReactComponent as TagCloseIcon } from "../../../assets/icons/iconTagClose.svg";
import { ReactComponent as UserColorIcon } from "../../../assets/icons/colorIconUser.svg";
import hospitalService from "../../../services/hospitalService";

const { Option } = Select;
const { Title, Text, Paragraph } = Typography;

const CaseORTeamForm = (props) => {
  const { hospitalCase } = props;

  const [selectedORTeam, setSelectedORTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  form.setFieldsValue({
    surgeon: hospitalCase.surgeon
      ? [
          "Dr.",
          hospitalCase.surgeon.firstName,
          hospitalCase.surgeon.middleName,
          hospitalCase.surgeon.lastName,
        ].join(" ")
      : null,
  });

  useEffect(() => {
    setLoading(true);
    (async () => {
      const caseORTeam = await hospitalService.getCaseORTeam(hospitalCase.id);
      const users = caseORTeam?.map((user) => ({
        user: user.user.id,
        userDetails: user.user,
        role: user.role,
      }));
      setSelectedORTeam([...users]);
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row align="middle">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 14, offset: 5 }}
        >
          <Row justify="space-between">
            <Col className="display-flex-center">
              <Title level={5} style={{ margin: 0 }}>
                Setup the Team
              </Title>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Form
                form={form}
                name="caseDetailAddEditForm"
                size="large"
                colon="false"
                layout="vertical"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item name="surgeon">
                      <Input
                        style={{ width: "100%" }}
                        size="large"
                        disabled
                        prefix={<StethoscopeColorIcon />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text>Operating room users</Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "14px" }}>
                  <Col span={24}>
                    {selectedORTeam.length ? (
                      selectedORTeam.map((user, index) => (
                        <Row key={index} gutter={[0, 8]}>
                          <Col span={24}>
                            <Tag
                              color="#f5f5f5"
                              key={user.userDetails.id}
                              icon={<UserColorIcon width="24px" />}
                              closable={false}
                              closeIcon={<TagCloseIcon />}
                              style={{
                                color: "black",
                                height: "100%",
                              }}
                            >
                              <Row>
                                <Col span={16} className="display-flex-center">
                                  <div
                                    style={{
                                      whiteSpace: "break-spaces",
                                    }}
                                  >
                                    <Paragraph
                                      style={{
                                        margin: "0 8px",
                                        fontSize: "16px",
                                      }}
                                      ellipsis={{ rows: 2 }}
                                    >
                                      {[
                                        user.userDetails.firstName,
                                        user.userDetails.middleName,
                                        user.userDetails.lastName,
                                      ].join(" ")}
                                    </Paragraph>
                                  </div>
                                </Col>
                                <Col span={8}>
                                  <Select
                                    placeholder="Select Role"
                                    style={{ width: "100%" }}
                                    defaultValue={user.role}
                                    onChange={(value) => {
                                      user.role = value;
                                    }}
                                    disabled
                                  >
                                    <Option value="SURGICAL_TECH">
                                      Pilot/Surgical Tech
                                    </Option>
                                    <Option value="NURSE">Nurse</Option>
                                    <Option value="PERI-OPERATIVE">
                                      Peri-Operative User
                                    </Option>
                                  </Select>
                                </Col>
                              </Row>
                            </Tag>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <EmptyDiv emptyText="No members selected yet." />
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default CaseORTeamForm;
