import { useState, useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Divider, Spin } from "antd";
import LiveCaseContainer from "../../../containers/LiveCase/LiveCaseContainer";
import TableSetup from "./TableSetup";
import distributorService from "../../../services/distributorService";
import hospitalService from "../../../services/hospitalService";
import { TableSetupContext } from "../../../context/TableSetupContext";
import LiveCaseFlowPage from "./LiveCaseFlowPage";

const LiveCaseMainPage = (props) => {
  const location = useLocation();
  const path = location.state?.path;
  const {
    params: { caseid: caseId },
  } = useRouteMatch();

  const [tableSetup, setTableSetup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [procedureName, setProcedureName] = useState("");
  const [tableMode, setTableMode] = useState("3WIDE");
  const [selectedTag, setSelectedTag] = useState("Table Setup");
  const [caseProcedureId, setCaseProcedureId] = useState();

  /** Functional */
  const handleTagSelectionChange = (tag) => {
    setSelectedTag(tag);
  };

  /** Functional Ends */

  /** API Calls */

  const getAndSetProcedureAndCaseDetails = async () => {
    const caseDetails = await hospitalService.getCase(caseId);
    if (caseDetails?.procedure) {
      setCaseProcedureId(caseDetails.procedure.id);
      setProcedureName(caseDetails.procedure.procedureName);
      setTableMode(caseDetails.procedure.tableMode);
      return caseDetails.procedure.id;
    }
    return false;
  };

  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      const procId = await getAndSetProcedureAndCaseDetails();
      if (procId) {
        //Get Table Setup Details. Needed if users joins a case as it would directly start from execution flow
        const data = await distributorService.getTableSetup(procId);
        setTableSetup(data);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <LiveCaseContainer
      procedureName={procedureName}
      selectedTag={selectedTag}
      handleTagSelectionChange={handleTagSelectionChange}
      backBtnPath={path}
      routeState={{
        pageNumber: location.state?.pageNumber,
        tabKey: location.state?.tabKey
      }}
    >
      <Spin
        spinning={loading}
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Divider
          style={{
            margin: "0",
            border: "1px solid rgb(209,209,209)",
          }}
        />
        <div className="dor-live-case">
          <TableSetupContext.Provider value={{ tableSetup, setTableSetup }}>
            {selectedTag === "Surgical Flow" ? (
              <LiveCaseFlowPage caseId={caseId} />
            ) : (
                <TableSetup
                  tableMode={tableMode}
                  procedureId={caseProcedureId}
                  startCase={true}
                  loading={loading}
                  isCompletedCasesPage={location.state?.isCompletedCasesPage}
                />
              )}
          </TableSetupContext.Provider>
        </div>
      </Spin>
    </LiveCaseContainer>
  );
};

export default LiveCaseMainPage;
