import { Layout } from "antd";

const { Content } = Layout;

const MiniAppContainer = (props) => {
  const { children } = props;

  return (
    <Layout
      className="mini-app-layout dor-mini-app"
      style={{ height: "100vh" }}
    >
      <Content style={{ background: "#ffffff" }} className="mini-content">
        <div className="table-setup-main-container">{children}</div>
      </Content>
    </Layout>
  );
};

export default MiniAppContainer;
