import { useState } from "react";
import { Button, Typography, Row, Col, Spin } from "antd";
import EmptyElement from "../../components/EmptyElement";
import StartExecutionSvg from "../../assets/Images/StartExecution.svg";
import ContinueExecutionSvg from "../../assets/Images/ContinueExecutionFlow.svg";
import { useExecutionStepsContext } from "../../context/ExecutionStepsContext";
import liveCaseService from "../../services/liveCaseService";

const { Title } = Typography;

const ExecutionStartPage = (props) => {
  const { caseId, firstStepId, allTraysVerified } = props;
  const {
    stepIndexObj: { onNext },
    setContinue,
    executionSteps,
    hasReadOnlyRights,
  } = useExecutionStepsContext();

  const [loading, setLoading] = useState(false);

  const onStartExecutionFlow = async () => {
    setLoading(true);
    if (executionSteps.length) {
      if (!hasReadOnlyRights)
        await liveCaseService.patchStartExecutionFlow(caseId, firstStepId);
      onNext();
    } else {
      setContinue(true);
    }

    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <EmptyElement
        image={allTraysVerified ? StartExecutionSvg : ContinueExecutionSvg}
        description={false}
        imageStyle={{
          height: allTraysVerified ? "14rem" : "10rem",
          marginRight: allTraysVerified ? "0" : "20px",
        }}
        elementStyle={{
          background: "#000e1a",
          height: "calc(100vh - 68px)",
        }}
      >
        <Row gutter={[0, 32]}>
          <Col
            span={allTraysVerified ? 24 : 14}
            offset={allTraysVerified ? 0 : 5}
          >
            <Title level={4} style={{ color: "#ffffff" }}>
              {allTraysVerified
                ? `Press "Start" to begin with the Surgical Flow `
                : `One or more trays have not been verified. Are you sure
you want to proceed with the case execution?`}
            </Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              key="actionBtn"
              htmlType="button"
              onClick={onStartExecutionFlow}
            >
              {allTraysVerified ? `Start` : `Continue Anyway`}
            </Button>
          </Col>
        </Row>
      </EmptyElement>
    </Spin>
  );
};

export default ExecutionStartPage;
