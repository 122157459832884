import http from "./httpService";

async function getManufacturers(isDeleted, limit, skip, searchText) {
  let api;
  const params = { where: { isDeleted, isManufacturer: true }, limit, skip };
  if (searchText) {
    api = `/distributor/search`;
    params.where.distributorName = { contains: searchText };
  } else {
    api = `/distributor`;
    params.sort = "createdAt DESC";
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

async function getManufacturerCount(isDeleted, searchText) {
  const res = await http.dorAxiosGet("/distributor/count", {
    isDeleted,
    searchText,
    isManufacturer: true,
  });
  return res ? res.data.count : false;
}

async function getManufacturer(id) {
  const res = await http.dorAxiosGet(`/distributor/${id}`, {
    where: { isDeleted: false },
    populate: "manufacturerHospitals",
  });
  return res ? res.data : false;
}

async function patchManufacturer(manufacturer) {
  manufacturer.isManufacturer = true;
  const res = await http.dorAxiosPatch(
    `/distributor/${manufacturer.id}`,
    manufacturer
  );
  return res ? res.data : false;
}

async function postManufacturer(manufacturer) {
  manufacturer.isManufacturer = true;
  const res = await http.dorAxiosPost("/distributor", manufacturer);
  return res ? res.data : false;
}

/** MANUFACTURER USERS */
async function getManufacturerUsers(
  manufacturerId,
  isDeleted,
  limit,
  skip,
  searchText
) {
  let api = `/distributor/${manufacturerId}/users`;
  const params = {
    limit,
    skip,
  };
  if (searchText) {
    api = `user/search`;
    params.where = {
      distributor: manufacturerId,
      isDeleted,
      or: [
        { firstName: { contains: searchText } },
        { middleName: { contains: searchText } },
        { lastName: { contains: searchText } },
        { email: { contains: searchText } },
      ],
    };
  } else {
    params.isDeleted = isDeleted;
    params.sort = "createdAt desc";
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}
/** MANUFACTURER USERS END */

/** MANUFACTURER EQUIPMENTS */
async function getManufacturerEquipments(
  entityId,
  isDeleted,
  limit,
  skip,
  searchText,
  entityName = "distributor",
  manufacturerId
) {
  let api = `/equipment`;
  let params = {

    where: { [entityName]:
          typeof entityId === "string" ? entityId : { in: entityId },
           isDeleted },
    populate: "distributor,regionalManager",
    limit,
    skip,
  };

  if(entityName === 'regionalManager'){ 
    params.where = { or: [{ regionalManager: entityId },{distributor: manufacturerId }], isDeleted}
  }
  
  if (searchText) {
    api += "/search";
    params.where.equipmentName = { contains: searchText };
  } else {
    params.sort = "createdAt DESC";
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}
/** MANUFACTURER EQUIPMENTS END */

/** MANUFACTURER TRAYS */
async function getManufacturerTrays(
  distributorId,
  isDeleted,
  limit,
  skip,
  searchValue
) {
  let api = "/tray";
  let params = {
    where: { distributor: distributorId, isDeleted },
    // populate: "distributor",
    limit,
    skip,
  };

  if (searchValue) {
    api += "/search";
    params.where.trayName = { contains: searchValue };
  } else {
    params.sort = "createdAt DESC";
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}
/** MANUFACTURER TRAYS */

async function getManufacturerHospitals(
  manufacturerId,
  limit,
  skip,
  searchValue,
  hospitalIds
) {
  let api;
  let params;

  if (searchValue) {
    api = "/hospital/search";
    params = {
      where: {
        isDeleted: false,
        id: hospitalIds,
        hospitalName: { contains: searchValue },
      },
      limit,
      skip,
    };
  } else {
    api = `/distributor/${manufacturerId}/manufacturerHospitals`;
    params = {
      sort: "createdAt DESC",
      isDeleted: false,
      limit,
      skip,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

async function getManufacturerDistributors(
  manufacturerId,
  limit,
  skip,
  searchValue,
) {
  let api;
  let params;

  if (searchValue) {
    api = "/distributor/search";
    params = {
      where: {
        isDeleted: false,
        isManufacturer: false,
        distributorName: { contains: searchValue },
      },
      limit,
      skip,
    };
  } else {
    api = `/distributor/${manufacturerId}/distributors`;
    params = {
      sort: "createdAt DESC",
      isDeleted: false,
      limit,
      skip,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

const patchUnassignManufacturerHospitals = async (
  manufacturerId,
  hospitalIds
) => {
  const res = await http.dorAxiosPatch(
    `/manufacturer/${manufacturerId}/unassignHospitals`,
    {
      removedHospitals: JSON.stringify(hospitalIds),
    }
  );
  return res?.status === 200 ? true : false;
};

/** MANUFACTURER Procedure */
const postCloneManufacturerProcedure = async (procedureId, manufacturerId) => {
  const res = await http.dorAxiosPost(`procedure/${procedureId}/clone`, {
    distributorId: manufacturerId,
  });
  return res ? true : false;
};
/** MANUFACTURER Procedure */

export default {
  getManufacturers,
  getManufacturerCount,
  getManufacturer,
  patchManufacturer,
  postManufacturer,
  getManufacturerUsers,
  getManufacturerEquipments,
  getManufacturerTrays,
  getManufacturerHospitals,
  postCloneManufacturerProcedure,
  patchUnassignManufacturerHospitals,
  getManufacturerDistributors
};
