import { Row, Col, Typography } from "antd";

const { Text } = Typography;

const EmptyDiv = (props) => {
  const { emptyText, emptyImage: EmptyImage } = props;
  const centerAlign = {
    display: "flex",
    justifyContent: "center",
    padding: "4px",
  };

  return (
    <Row
      style={{ backgroundColor: "#f7f7f7", padding: "16px", marginTop: "16px" }}
    >
      {EmptyImage && (
        <Col style={centerAlign} span={24}>
          <EmptyImage height="55px" width="55px" />
        </Col>
      )}
      <Col style={centerAlign} span={24}>
        <Text style={{ color: "#808285" }}>{emptyText}</Text>
      </Col>
    </Row>
  );
};

export default EmptyDiv;
