import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../../containers/MainContainer";
import CardList from "../../components/CardList";
import CustomCard from "../../components/CustomCard";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import medicalRepService from "../../services/medicalRepService";
import { ReactComponent as KeyIcon } from "../../assets/icons/icon_Access-Role.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/icon_email.svg";
import { ReactComponent as MedicalRepColorIcon } from "../../assets/icons/color-icons_Med-Rep.svg";
import { RolesDisplay, Roles } from "../../config/role-config.json";
import { useBreadCrumbContext } from "../../context/BreadCrumbContext";

const { confirm } = Modal;
const customPageSize = 12;

const MedicalRepresentativesPage = (props) => {
  const {
    match: { url, params },
    history,
  } = props;

  const { rmid: regionalManagerId } = params;
  const isDeletedPage = url.includes("deleted");

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Medical Representatives",
        backBtnPath: `${url.replace("/deleted", "")}`,
      }
    : {
        title: "Medical Representatives",
        btnText: "Add Medical Representative",
        onBtnClick: () => {
          history.push(`${url}/new`);
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Medical Representatives",
            path: `${url}/deleted`,
          },
        ],
      };

  const { setBreadCrumb } = useBreadCrumbContext();
  const [medicalRepresentativeCount, setMedicalRepresentativeCount] =
    useState(0);
  const [medRepSearchText, setRMSearchText] = useState();
  const [medicalRepresentativeList, setMedicalRepresentativeList] = useState(
    []
  );
  const [currentRMPage, setCurrentRMPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);

  const cardContent = (medRep) => [
    {
      icon: <KeyIcon />,
      content: RolesDisplay.MMR,
    },
    {
      icon: <EmailIcon fill="#808285" />,
      content: medRep.email,
    },
  ];

  const actions = (medRep) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onUserRestore(medRep);
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              setBreadCrumb((prev) => ({
                ...prev,
                user: `${medRep.firstName ?? ""} ${
                  medRep.lastName ?? ""
                }`.trim(),
              }));
              history.push(`${url}/${medRep.id}/setup`, {
                record: medRep,
                editMode: true,
              });
            },
          },
          !medRep.isActive
            ? {
                title: "Resend Invite",
                onClick: (menuItemProps) => {
                  menuItemProps.domEvent.stopPropagation();
                  sendActivationLink(medRep.email);
                },
              }
            : null,
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(medRep.id);
            },
          },
        ];
  };

  const onUserRestore = async (user) => {
    setLoading(true);
    const res = await userServices.patchRestoreUser(user.id);  
    if (res) {
      toastNotification("success", "User Restored");
      populateMedicalRepresentativeList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the user");
    }
  };

  const showDeleteConfirmation = (medRepId) => {
    confirm({
      title: "Delete Medical Representative",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        deleteMedicalRepresentative(medRepId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setRMSearchText(searchValue);
        await getSetRMCountAndPopulate(searchValue);
        setLoading(false);
      }, 500)
    );
  };

  /** API calls */
  const populateMedicalRepresentativeList = async (
    limit,
    skip,
    searchValue
  ) => {
    setLoading(true);
    const data = await medicalRepService.getMMRUsers(
      regionalManagerId,
      isDeletedPage,
      [Roles.MMR],
      limit,
      skip,
      searchValue
    );
    if (data) {
      setMedicalRepresentativeList(data);
    } else {
      toastNotification(
        "error",
        "Error fetching the Medical Representative records"
      );
    }
    setLoading(false);
  };

  const deleteMedicalRepresentative = async (id) => {
    setLoading(true);
    const isDeleted = await userServices.deleteUser(id);
    if (isDeleted) {
      toastNotification(
        "success",
        "Medical Representative record deleted successfully"
      );
      await getSetRMCountAndPopulate();
    } else {
      toastNotification(
        "error",
        "Error deleting the Medical Representative record"
      );
    }
    setLoading(false);
  };

  const getSetRMCountAndPopulate = async (searchValue) => {
    const count = await userServices.getUsersCount(
      "mmr",
      regionalManagerId,
      isDeletedPage,
      searchValue
    );

    if (count === false) {
      toastNotification(
        "error",
        "Error fetching Medical Representative records"
      );
    } else {
      setMedicalRepresentativeCount(count);
      if (count > 0) {
        setCurrentRMPage(1);
        await populateMedicalRepresentativeList(customPageSize, 0, searchValue);
      } else setMedicalRepresentativeList([]);
    }
  };

  const sendActivationLink = async (email) => {
    const res = await userServices.postResendUserActivationLink(email);
    if (res?.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };
  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetRMCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by First Name, Last Name or Email"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={medicalRepresentativeList}
        loading={loading}
        renderItem={(medRep) => {
          return (
            <List.Item>
              <CustomCard
                headerIcon={<MedicalRepColorIcon />}
                title={(() => {
                  const medRepName = [
                    medRep.firstName,
                    medRep.middleName,
                    medRep.lastName,
                  ]
                    .join(" ")
                    .trim();
                  return medRepName.length ? medRepName : "Not Set";
                })()}
                actions={actions(medRep)}
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                        setBreadCrumb((prev) => ({
                          ...prev,
                          user: `${medRep.firstName ?? ""} ${
                            medRep.lastName ?? ""
                          }`.trim(),
                        }));

                        history.push(`${url}/${medRep.id}/hospital`, {
                          record: medRep,
                          editMode: false,
                        });
                      }
                }
                bodyContent={cardContent(medRep)}
              />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: customPageSize,
          total: medicalRepresentativeCount,
          current: currentRMPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            await populateMedicalRepresentativeList(
              pageSize,
              pageSize * (page - 1),
              medRepSearchText
            );
            setCurrentRMPage(page);
            setLoading(false);
          },
        }}
      />
    </MainContainer>
  );
};
export default MedicalRepresentativesPage;
