import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Spin,
  Form,
  Input,
  Row,
  Col,
  Tag,
  Divider,
  Typography,
  InputNumber,
  Modal,
  Select,
  Button,
  Empty,
  Space,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import SignedURLImage from "../../components/SignedURLImage";
import Uploader from "../../components/Uploader";
import EquipmentModal from "../../components/EquipmentModal";
import TrayFormModal from "../../components/TrayFormModal";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import trayService from "../../services/trayService";
import manufacturerService from "../../services/manufacturerService";
import equipmentService from "../../services/equipmentService";
import mediaService from "../../services/mediaService";
import utilityService from "../../services/utilityService";
import { ReactComponent as TagCloseIcon } from "../../assets/icons/iconTagClose.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../assets/icons/checkboxSelected.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/iconDelete.svg";
import trayPlaceholder from "../../assets/tray-placeholder.svg";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";

const { confirm } = Modal;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const TrayFrom = ({
  entityName,
  tray: trayDetails,
  trayId,
  entityId,
  manufacturerId,
  isReadOnly = true,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const customPageSize = 30;

  //TODO :: Check if invalid manufacturer id
  const parentPath = url.replace(trayId ? `/${trayId}` : "/new", "");

  const [form] = Form.useForm();
  const [tray, setTray] = useState(trayDetails);
  const [equipment, setEquipment] = useState({});
  const [loading, setLoading] = useState(false);
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [selectedEquipmentsId, setSelectedEquipmentsId] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [editMode, setEditMode] = useState(!trayId || !isReadOnly);
  const [imageName, setImageName] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);
  const [imageRequiredStatus, setImageRequiredStatus] = useState({
    validateStatus: "success",
    errorMsg: null,
  });

  const [showEquipmentModal, setEquipmentModal] = useState(false);
  const [showTrayModal, setShowTrayModal] = useState(false);

  const [timeOut, setTimeOut] = useState(0);
  const [currentEquipmentPage, setCurrentEquipmentPage] = useState(1);
  const [equipmentCount, setEquipmentCount] = useState();
  const [equipmentSearchText, setEquipmentSearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadEquipments, setLoadEquipments] = useState(false);
  const [regionalManager, setRegionalManager] = useState();
  const [manufacturer, setManufacturer] = useState();

  const overlayMediaDelete = () => (
    <div className="icon-overlay">
      <Button
        icon={<DeleteIcon />}
        onClick={() => {
          deleteTrayImage();
        }}
        style={{ padding: 0 }}
      />
    </div>
  );

  const closeEquipmentModal = () => {
    const video = document.getElementById("videoEquipment");
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
    setEquipmentModal(false);
  };

  const customImageUpload = async (file) => {
    setImageLoading(true);
    let fileName;
    if (file.size > 30000000) {
      fileName = await mediaService.uploadMultipartImage(file);
    } else {
      fileName = await mediaService.uploadImageUsingPresignedUrl(file);
    }
    if (fileName) {
      setImageName(fileName);
      setImageRequiredStatus({
        validateStatus: "success",
        errorMsg: null,
      });
    } else {
      setImageName(null);
      setImageLoading(false);
      setImageRequiredStatus({
        validateStatus: "error",
        errorMsg: "Upload failed",
      });
    }
  };

  const onEquipmentSelect = (equipmentId) => {
    // add id to selectedIds to maintain state of selected options
    const ids = selectedEquipmentsId;
    ids.push(equipmentId);
    setSelectedEquipmentsId([...ids]);

    // add equipment to selectedEquipments to add it to the displayed tag list
    const selectedEquipment = {
      equipment: equipmentsList.find(
        (equipment) => equipment.id === equipmentId
      ),
      quantity: 1,
    };
    selectedEquipments.push(selectedEquipment);
    setSelectedEquipments([...selectedEquipments]);
  };

  const onEquipmentDeselect = (equipmentId) => {
    // remove id from selectedIds to maintain state of selected options
    setSelectedEquipmentsId(
      selectedEquipmentsId.filter((id) => id !== equipmentId)
    );

    // remove equipment from selectedEquipments to remove it from the displayed tag list
    setSelectedEquipments(
      selectedEquipments.filter(
        (trayEquipment) => trayEquipment.equipment.id !== equipmentId
      )
    );
  };

  const onFinish = async (trayFormValues) => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setLoading(true);
      //Validate if tray image is missing
      if (imageName) {
        trayFormValues.image = imageName;
      } else trayFormValues.image = "";

      trayFormValues[entityName] = entityId;
      trayFormValues.equipments = selectedEquipments.map((trayEquipment) => ({
        equipment: trayEquipment.equipment.id,
        quantity: trayEquipment.quantity,
      }));

      //delete previously uploaded images if any
      for (const img of imagesToBeDeleted) {
        await mediaService.deleteMedia(img);
      }
      setImagesToBeDeleted([]);

      if (trayId) {
        trayFormValues.id = trayId;
        await editTray(trayFormValues);
      } else {
        await addTray(trayFormValues);
      }
      setLoading(false);
    }
  };

  const pageHeaderProps = trayId
    ? {
        title: tray?.trayName ? tray.trayName : "Tray Details",
        ...(tray &&
          !tray.distributor?.isManufacturer && {
            btnText: editMode ? "Save" : "Edit",
            btnType: editMode ? "primary" : "secondary",
            topActionMenuItems: [
              {
                title: "Delete Tray",
                path: "",
                onClick: () => {
                  showDeleteConfirmation();
                },
              },
            ],
          }),
      }
    : {
        title: "New Tray",
        btnText: "Add",
      };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Tray",
      content: "Are you sure you want to delete this Tray?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        await deleteTray();
      },
    });
  };

  /** API */
  const addTray = async (trayFormValues) => {
    // TODO :: Trim values before submit
    const res = await trayService.postTray(trayFormValues);
    if (res.status === 200) {
      toastNotification("success", "New Tray added");
      history.push(parentPath);
    } else {
      toastNotification("error", "Error adding a new Tray");
    }
  };

  const editTray = async (trayFormValues, needRedirect) => {
    const res = await trayService.patchTray(trayFormValues);
    if (res.status === 200) {
      toastNotification("success", "Tray Updated");
      setEditMode(false);
      history.replace({
        state: {
          record: res.data,
          pageNumber: location.state?.pageNumber,
          activeTabKey: location.state?.activeTabKey,
        },
      });
    } else {
      toastNotification("error", "Error updating the Tray");
    }
    setLoading(false);
  };

  const fetchTray = async () => {
    setLoading(true);
    const data = await trayService.getTray(entityId, trayId);
    if (data) {
      setTray(data);
      return data;
    } else {
      toastNotification("error", "Requested tray record not found");
    }
    setLoading(false);
  };

  const deleteTray = async () => {
    setLoading(true);
    const isDeleted = await await trayService.patchTray({
      id: trayId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Tray Deleted Successfully");
      history.push(parentPath);
    } else {
      toastNotification("error", "Error deleting the Tray");
    }
    setLoading(false);
  };

  const deleteTrayImage = async () => {
    setImageName(null);
    setImagesToBeDeleted((deleteArray) => {
      setImageLoading(true);
      deleteArray.push(imageName);
      setImageLoading(false);
      return [...deleteArray];
    });

    setImageLoading(false);
    return true;
  };
  /** API End */

  //   const logValue = debounce((searchText) => {
  //     setEquipmentSearchText(searchText);
  //   }, 250);

  const addOptions = async () => {
    setLoadEquipments(false);
    setIsLoading(true);
    setCurrentEquipmentPage(currentEquipmentPage + 1);
    await populateEquipmentsList(
      customPageSize,
      currentEquipmentPage * customPageSize,
      equipmentSearchText
    );
    setIsLoading(false);
  };

  const handleOpen = () => {
    setCurrentEquipmentPage(1);
  };

  const handleChange = (value) => {
    setCurrentEquipmentPage(0);
  };

  const handleInputChange = (value) => {
    // logValue(value);
    if (value.length === 0) setCurrentEquipmentPage(1);
  };

  const handleMenuScrollToBottom = () => {
    {
      loadEquipments &&
        equipmentsList.length !== equipmentCount &&
        addOptions();
    }
  };

  const getEquipmentCount = async (searchText) => {
    const count = await equipmentService.getEquipmentCount(
      entityId,
      false,
      searchText,
      entityName !== "regionalManager" ? "distributorId" : "regionalManagerId"
    );
    if (count >= 0) {
      setEquipmentCount(count);
      return true;
    } else {
      return false;
    }
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setEquipmentSearchText(searchValue);
        await getEquipmentCount(searchValue);
        setCurrentEquipmentPage(0);
        populateEquipmentsList(customPageSize, 0, searchValue);
      }, 500)
    );
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  };

  const populateEquipmentsList = async (limit, skip, searchValue) => {
    setIsLoading(true);
    const res =
      entityName !== "area" &&
      (await manufacturerService.getManufacturerEquipments(
        entityId,
        false,
        limit,
        skip,
        searchValue,
        entityName,
        manufacturerId
      ));
    if (res) {
      setLoadEquipments(res.length === customPageSize);
      const arr = [...equipmentsList, ...res];
      setEquipmentsList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const rm =
        entityName !== "area" && (await userServices.getUser(entityId));
      rm && setRegionalManager(rm);
      const mfc = await manufacturerService.getManufacturer(manufacturerId);
      mfc && setManufacturer(mfc);
      if (trayId) {
        // If location.state doesn't contain the tray, get it from api
        if (!tray) await fetchTray();

        if (tray) {
          form.setFieldsValue({ ...tray });
          setImageName(tray.image);

          // Get tray equipments and set the selected equipments
          const trayEquipments = await trayService.getTrayEquipments(trayId);
          if (trayEquipments) {
            setLoading(true);
            setSelectedEquipments(trayEquipments);
            setSelectedEquipmentsId(
              trayEquipments.map((trayEquipment) => trayEquipment.equipment.id)
            );
            setLoading(false);
          }
        }
      }

      // fetch all manufacturer equipments to fill the select equipment dropdown
      const success = await getEquipmentCount();
      if (success) populateEquipmentsList(customPageSize, 0);

      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      formName="manufacturerTrayAddEditForm"
      backBtnPath={parentPath}
      routeState={{
        pageNumber: location.state?.pageNumber,
        activeTabKey: location.state?.activeTabKey,
      }}
      onBtnClick={() => {
        if (!editMode) {
          setEditMode(true);
        } else {
          form.submit();
        }
      }}
      divider={true}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Form
                    form={form}
                    name="manufacturerTrayAddEditForm"
                    id="manufacturerTrayAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Tray Details</Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Tray Name (Required)"
                          name="trayName"
                          rules={[
                            {
                              required: true,
                              message: "Please input tray name!",
                            },
                          ]}
                        >
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Tray Description" name="description">
                          <TextArea
                            disabled={!editMode}
                            style={{ resize: "none" }}
                            rows={4}
                            autoSize={false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Supported formats: jpeg, jpg, png, heic."
                          name="imageItem"
                          disabled={!editMode}
                        >
                          <div className="icon-overlay-container">
                            {imageName ? (
                              <>
                                <div
                                  style={{ height: "12rem", width: "14rem" }}
                                >
                                  <SignedURLImage
                                    fileName={imageName}
                                    height="100%"
                                    width="100%"
                                    draggable="false"
                                    onClick={() => setShowTrayModal(true)}
                                    fallback={trayPlaceholder}
                                  />
                                  {editMode ? overlayMediaDelete(true) : null}
                                </div>
                              </>
                            ) : (
                              <Uploader
                                id="uploadTray"
                                imageLoading={imageLoading}
                                customRequest={(file) =>
                                  customImageUpload(file)
                                }
                                editMode={!editMode ? true : false}
                              />
                            )}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Instruments</Title>
              {editMode && (
                <Select
                  mode="multiple"
                  size="large"
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  autoClearSearchValue={false}
                  onSelect={onEquipmentSelect}
                  onDeselect={onEquipmentDeselect}
                  onDropdownVisibleChange={handleOpen}
                  onChange={handleChange}
                  onSearch={onSearchFunction}
                  onPopupScroll={handleMenuScrollToBottom}
                  onInputKeyDown={handleInputChange}
                  filterOption={handleFilterOption}
                  tagRender={() => null}
                  value={selectedEquipmentsId}
                  menuItemSelectedIcon={<CheckboxSelectedIcon />}
                  notFoundContent={
                    <div
                      className="display-flex-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<b>No Records Found.</b>}
                      />
                    </div>
                  }
                >
                  {equipmentsList.map((equipment, index) => (
                    <Option key={equipment.id} title={equipment.equipmentName}>
                      <div className="display-flex-center">
                        <SignedURLImage
                          className="fit-image"
                          fileName={equipment.image[0]}
                          height="40px"
                          width="55px"
                          fallback={equipmentPlaceholder}
                          isTrayFormInstruments={true}
                        />
                        <Space
                          direction="vertical"
                          style={{ marginLeft: "10px" }}
                          size={2}
                        >
                          <Text ellipsis strong>
                            {equipment.equipmentName}
                          </Text>
                          <Text
                            ellipsis
                            style={{ color: "grey", fontSize: "12px" }}
                          >
                            {equipment.distributor
                              ? manufacturer.distributorName
                              : utilityService.getRegionalManagerName(
                                  regionalManager
                                )}
                          </Text>
                        </Space>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
              <div style={{ marginTop: "1.5rem" }}>
                <Row gutter={[0, 8]}>
                  {selectedEquipments.map((trayEquipment) => {
                    return (
                      <Col span={24}>
                        <Tag
                          color="#f5f5f5"
                          key={trayEquipment.id}
                          icon={
                            <SignedURLImage
                              onClick={() => {
                                setEquipment(trayEquipment.equipment);
                                setEquipmentModal(true);
                              }}
                              fallback={equipmentPlaceholder}
                              fileName={trayEquipment.equipment.image[0]}
                              height="50px"
                              width="75px"
                              className="fit-image"
                              isTrayFormInstruments={true}
                            />
                          }
                          closable={editMode}
                          closeIcon={<TagCloseIcon />}
                          onClose={() =>
                            onEquipmentDeselect(trayEquipment.equipment.id)
                          }
                          style={{ color: "black", height: "100%" }}
                        >
                          <Row>
                            <Col span={18} className="display-flex-center">
                              <Space
                                direction="vertical"
                                size={2}
                                style={{ marginLeft: "0.5rem" }}
                              >
                                <Text
                                  ellipsis
                                  strong
                                  style={{ fontSize: "14px", width: "14em" }}
                                >
                                  {trayEquipment.equipment.equipmentName}
                                </Text>
                                <Text
                                  ellipsis
                                  style={{
                                    color: "grey",
                                    fontSize: "12px",
                                    width: "14em",
                                  }}
                                >
                                  {trayEquipment.equipment?.distributor
                                    ? manufacturer?.distributorName
                                    : utilityService.getRegionalManagerName(
                                        regionalManager
                                      )}
                                </Text>
                              </Space>
                            </Col>
                            <Col span={6}>
                              {!editMode ? (
                                `Qty: ${trayEquipment.quantity}`
                              ) : (
                                <InputNumber
                                  disabled={!editMode}
                                  style={{ width: "5em" }}
                                  defaultValue={trayEquipment.quantity}
                                  min={1}
                                  max={100}
                                  onChange={(value) => {
                                    trayEquipment.quantity = value;
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Tag>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
            <Col span={1}></Col>
          </Row>
        </div>
      </Spin>
      {showEquipmentModal && (
        <EquipmentModal
          show={showEquipmentModal}
          close={closeEquipmentModal}
          equipment={equipment}
        />
      )}
      {showTrayModal && (
        <TrayFormModal
          show={showTrayModal}
          close={() => setShowTrayModal(false)}
          tray={
            trayId
              ? tray
              : {
                  trayName: "New Tray",
                }
          }
          imageName={imageName}
        />
      )}
    </MainContainer>
  );
};

export default TrayFrom;
