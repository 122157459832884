import { useEffect, useState } from "react";
import { Spin } from "antd";
import EntityHospitalsPage from "../../shared-pages/read-only/hospital-and-cases/EntityHospitalsPage";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import utilityService from "../../services/utilityService";

const RMHospitalsPage = ({ match }) => {
  const rmId = match.params?.rmid;

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRMHospitals = async () => {
    const regionalManager = await userServices.getUser(rmId, {
      populate: "hospital",
    });

    if (regionalManager?.hospital) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        regionalManager.hospital
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      regionalManager.hospital = sortedHospitals;
      
      setHospitalList(regionalManager.hospital);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getRMHospitals();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityHospitalsPage hospitalList={hospitalList} />
    </Spin>
  );
};
export default RMHospitalsPage;
