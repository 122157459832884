import { ReactComponent as BackIcon } from "../assets/icons/iconBack.svg";
import { ReactComponent as HospitalIcon } from "../assets/icons/sider-menu-icons/iconHospitals.svg";
import { ReactComponent as TrayIcon } from "../assets/icons/distributor-menu/iconTray.svg";
import { ReactComponent as EquipmentIcon } from "../assets/icons/sider-menu-icons/iconEquipment.svg";
import { ReactComponent as ProcedureIcon } from "../assets/icons/sider-menu-icons/icon_procedures.svg";
import { ReactComponent as RegionalManagerIcon } from "../assets/icons/sider-menu-icons/icon_location.svg";
import { ReactComponent as AdminIcon } from "../assets/icons/sider-menu-icons/iconAdmin.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/sider-menu-icons/iconSetting.svg";
import { Roles } from "./role-config.json";

const areaMenuItems = (manufacturerId, areaId) => {
  const parentPath = `/manufacturer/${manufacturerId}/area/${areaId}`;
  return [
    {
      name: "Manufacturer Admin",
      path: `/manufacturer/${manufacturerId}/area`,
      icon: <BackIcon />,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
    },
    {
      name: "Hospitals",
      path: `${parentPath}/hospital`,
      icon: <HospitalIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Procedures",
      path: `${parentPath}/procedure`,
      icon: <ProcedureIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Trays",
      path: `${parentPath}/tray`,
      icon: <TrayIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Instruments",
      path: `${parentPath}/equipment`,
      icon: <EquipmentIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Regional Managers",
      path: `${parentPath}/regional-manager`,
      icon: <RegionalManagerIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Area Users",
      path: `${parentPath}/user`,
      icon: <AdminIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
    {
      name: "Area Setup",
      path: `${parentPath}/setup`,
      icon: <SettingIcon />,
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
      ],
    },
  ];
};

export default areaMenuItems;
