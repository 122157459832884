const layoutForOneLayer = {
  lg: [
    { i: "0", x: 0, y: 0, w: 4, h: 1 },
    { i: "1", x: 4, y: 0, w: 4, h: 1 },
    { i: "2", x: 8, y: 0, w: 4, h: 1 },
  ],
};

const layoutForTwoLayer = {
  lg: [
    { i: "0", x: 0, y: 0, w: 4, h: 1 },
    { i: "1", x: 4, y: 0, w: 4, h: 1 },
    { i: "2", x: 8, y: 0, w: 4, h: 1 },
    { i: "3", x: 0, y: 1, w: 4, h: 1 },
    { i: "4", x: 4, y: 1, w: 4, h: 1 },
    { i: "5", x: 8, y: 1, w: 4, h: 1 },
  ]
};


const layoutForTwoLayerCopy = {
  lg: [
    { i: "0", x: 0, y: 0, w: 4, h: 1 },
    { i: "1", x: 4, y: 0, w: 4, h: 1 },
    { i: "2", x: 8, y: 0, w: 4, h: 1 },
    { i: "3", x: 0, y: 1, w: 4, h: 1 },
    { i: "4", x: 4, y: 1, w: 4, h: 1 },
    { i: "5", x: 8, y: 1, w: 4, h: 1 },
  ],
};


const layoutForOneLayer2Wide = {
  lg: [
    { i: "0", x: 0, y: 0, w: 6, h: 1,  minw: 6 },
    { i: "1", x: 6, y: 0, w: 6, h: 1,  minw: 6 },
  ]
};

const layoutForTwoLayer2Wide = {
  lg: [
    { i: "0", x: 0, y: 0, w: 6, h: 1,  minw: 6 },
    { i: "1", x: 6, y: 0, w: 6, h: 1,  minw: 6 },
    { i: "2", x: 0, y: 1, w: 6, h: 1,minw: 6 },
    { i: "3", x: 6, y: 1, w: 6, h: 1, minw: 6 },
  ],
};

const layoutForTwoLayer2WideCopy = {
  lg: [
    { i: "0", x: 0, y: 0, w: 6, h: 1,  minw: 6 },
    { i: "1", x: 6, y: 0, w: 6, h: 1,  minw: 6 },
    { i: "2", x: 0, y: 1, w: 6, h: 1,minw: 6 },
    { i: "3", x: 6, y: 1, w: 6, h: 1, minw: 6 },
  ],
};



// Object.defineProperty(layoutForTwoLayer, 'lg', {
//   writable: false
// })
// Object.freeze(layoutForTwoLayer.lg)
export {
  layoutForOneLayer,
  layoutForTwoLayer,
  layoutForOneLayer2Wide,
  layoutForTwoLayer2Wide,
  layoutForTwoLayerCopy,
  layoutForTwoLayer2WideCopy,
};