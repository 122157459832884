import { Roles } from "../config/role-config.json";
import RMEquipmentsPage from "../pages/RegionalManager/RMEquipmentsPage";
import RMEquipmentForm from "../pages/RegionalManager/RMEquipmentForm";
import RMTraysPage from "../pages/RegionalManager/RMTraysPage";
import RMTrayForm from "../pages/RegionalManager/RMTrayForm";
import RMProceduresPage from "../pages/RegionalManager/RMProceduresPage";
import RMProcedureForm from "../pages/RegionalManager/RMProcedureForm";
import RMTableSetup from "../pages/RegionalManager/RMTableSetup";
import RMTrayEquipmentPage from "../pages/RegionalManager/RMTrayEquipmentPage";
import RMExecutionFlowPage from "../pages/RegionalManager/RMExecutionFlowPage";
import ProcedureMainPage from "../shared-pages/read-only/mini-app/ProcedureMainPage";
import ExecutionFlow from "../shared-pages/read-only/mini-app/ExecutionFlow";
import MedicalRepresentativeForm from "../pages/RegionalManager/MedicalRepresentativeForm";
import MMRHospitalsCasePage from "../pages/MMR/MMRHospitalsCasePage";
import CaseForm from "../shared-pages/read-only/hospital-and-cases/CasesForm";
import MMRHospitalsPage from "../pages/MMR/MMRHospitalsPage";
import LiveCaseMainPage from "../shared-pages/read-only/mini-app/LiveCaseMainPage";
import { BreadCrumbData } from "./index.routes";

const MMRRoutes = {
  Routes: [
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: MedicalRepresentativeForm,
    },
    /** ReadOnly Hospital and Cases */
    //for breadcrumb
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital/:hospitalid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="hospital" id={props.match.params.hospitalid} />
      ),
      redirection: "case",
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital/:hospitalid/case/completed",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Completed",
      component: MMRHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital/:hospitalid/case/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: MMRHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital/:hospitalid/case/:caseid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Case Details",
      component: CaseForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital/:hospitalid/case",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Cases",
      component: MMRHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmrid/hospital",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Hospitals",
      component: MMRHospitalsPage,
    },
    /** EQUIPMENTS */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/equipment/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: RMEquipmentsPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/equipment/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "New Instrument",
      component: RMEquipmentForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/equipment/:equipmentid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Instrument Details",
      component: RMEquipmentForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/equipment",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Instruments",
      component: RMEquipmentsPage,
    },
    /** TRAYS */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/tray/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: RMTraysPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/tray/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "New Tray",
      component: RMTrayForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/tray/:trayid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Tray Details",
      component: RMTrayForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/tray",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Trays",
      component: RMTraysPage,
    },
    /** PROCEDURES */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure-library/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: RMProceduresPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN,
      Roles.AREA_MANAGER, Roles.REGIONAL_MANAGER, Roles.MMR],
      exact: true,
      breadcrumb: "Procedures",
      component: RMProceduresPage,
    },
  ],

  MiniAppRoutes: [
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "New Procedure",
      component: RMProcedureForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/:procedureid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      breadcrumb: "Procedure Details",
      component: RMProcedureForm,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/:procedureid/table-setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: RMTableSetup,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/:procedureid/tray-management",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: RMTrayEquipmentPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/:procedureid/execution-flow/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: RMExecutionFlowPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: RMExecutionFlowPage,
    },
    /** ReadOnly Hospital Case Procedure */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/hospital/:hospitalid/case/:caseid/procedure/:procedureid/table-setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: ProcedureMainPage,
    },
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/hospital/:hospitalid/case/:caseid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: ExecutionFlow,
    },
    /** ReadOnly Hospital Live Case */
    {
      path: "/manufacturer/:id/area/:areaid/regional-manager/:rmid/medical-representative/:mmid/hospital/:hospitalid/case/:caseid/live-case",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.MANUFACTURER_ADMIN,
        Roles.AREA_MANAGER,
        Roles.REGIONAL_MANAGER,
        Roles.MMR,
      ],
      exact: true,
      component: LiveCaseMainPage,
    },
  ],
};

export default MMRRoutes;
