import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Spin, Typography, Space } from "antd";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import executionService from "../../services/executionService";
import { ReactComponent as Flow } from "../../assets/icons/flow.svg";

const { Text } = Typography;

const ExecutionLandingPage = ({ isProcedureEditable, procedure, showModalPopUp }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const procedureId = match.params?.procedureid;

  const flexCenterStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const [numberOfSteps, setNumberOfSteps] = useState(0);
  const [phaseCount, setPhaseCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getExecutionFlows = async () => {
    setLoading(true);
    const res = await executionService.getExecutionFlow(procedureId);

    res.forEach((el) => {
      const steps = el.steps?.filter((e) => !e.isDeleted);
      el.steps = steps;
    });

    setPhaseCount(res.length - 1);

    const step =
      res.length > 1
        ? res.reduce((total, el, i) => {
          if (i === 1) return total.steps.length + el?.steps.length;
          else return total + el?.steps.length;
        })
        : res[0]?.steps?.length;
    setNumberOfSteps(step);
    setLoading(false);
  };

  useEffect(() => {
    getExecutionFlows();
  }, [procedureId]);

  return (
    <Spin spinning={loading}>
      <div style={{ marginTop: "6rem" }} className="execution-flow">
        <Row align="center">
          <Col
            xs={{ span: 22 }}
            sm={{ span: 18 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
            xl={{ span: 14 }}
          >
            {(phaseCount > 0 || numberOfSteps > 0) ? (
              <Card className="border-gray edit-execution-card">
                <Row>
                  <Col
                    lg={{ span: 2 }}
                    md={{ span: 4 }}
                    sm={{ span: 5 }}
                    xs={{ span: 5 }}
                    style={flexCenterStyle}
                  >
                    <Flow />
                  </Col>
                  <Col
                    lg={{ span: 20 }}
                    md={{ span: 18 }}
                    sm={{ span: 15 }}
                    xs={{ span: 15 }}
                    className="display-flex-center"
                  >
                    <Space
                      direction="vertical"
                      align="start"
                      size={0}
                      style={{ paddingLeft: "8px" }}
                    >
                      <Text strong>Primary Surgical Flow</Text>
                      <Text>
                        {numberOfSteps} {numberOfSteps > 1 ? "Steps" : "Step"}
                      </Text>
                    </Space>
                  </Col>
                  <Col
                    lg={{ span: 2 }}
                    md={{ span: 2 }}
                    sm={{ span: 4 }}
                    xs={{ span: 4 }}
                    style={{ display: "flex", justifyContent: "right", alignItems: "center" }}
                  >
                    {isProcedureEditable ? (
                      <Space>
                        <Button
                          type="secondary"
                          onClick={() => {
                            const editExecutionFlowPath = match.url.replace(
                              "table-setup",
                              "execution-flow/edit"
                            );

                            history.push(editExecutionFlowPath, {
                              procedure,
                              isReadOnly: true,
                              ...location.state,
                              isCloneProcedure: location.state?.isCloneProcedure
                            });
                            isProcedureEditable = false;
                          }}
                          style={{ margin: "0", fontWeight: "bold" }}
                        >
                          View
                        </Button>
                        <Button
                          type="secondary"
                          onClick={() => {
                            const editExecutionFlowPath = match.url.replace(
                              "table-setup",
                              "execution-flow/edit"
                            );

                            history.push(editExecutionFlowPath, {
                              procedure,
                              ...location.state,
                              isCloneProcedure: location.state?.isCloneProcedure
                            });
                          }}
                          style={{ margin: "0", fontWeight: "bold" }}
                        >
                          Edit
                        </Button>
                      </Space>) : (<Button
                        type="secondary"
                        onClick={() => {
                          const editExecutionFlowPath = match.url.replace(
                            "table-setup",
                            "execution-flow/edit"
                          );

                          history.push(editExecutionFlowPath, {
                            procedure,
                            isReadOnly: true,
                            ...location.state,
                          });
                        }}
                        style={{ margin: "0", fontWeight: "bold" }}
                      >
                        View
                      </Button>)}
                  </Col>
                </Row>
              </Card>
            ) : (
              <Card className="border-gray execution-card">
                <Flow style={{ marginBottom: "1rem" }} />
                <h2 style={{ marginBottom: 0 }}>Primary Surgical Flow</h2>
                {isProcedureEditable ? (
                  <>
                    <p style={{ marginBottom: "1rem" }}>
                      Start creating steps for your primary surgical flow.
                    </p>
                    <Button
                      type="secondary"
                      onClick={() => {
                        const createExecutionFlowPath = match.url.replace(
                          "table-setup",
                          "execution-flow/new"
                        );
                        history.push(createExecutionFlowPath, {
                          procedure,
                          ...location.state,
                          pageNumber: location.state?.pageNumber,
                          activeTabKey: location.state?.activeTabKey,
                          isCloneProcedure: location.state?.isCloneProcedure,

                        });
                      }}
                      style={{ marginRight: "0" }}
                    >
                      Create
                    </Button>
                  </>
                ) : (
                  <p style={{ marginBottom: "1rem" }}>No Steps Added</p>
                )}
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ExecutionLandingPage;
