import AWS from "aws-sdk";

AWS.config.region = process.env.REACT_APP_AWS_S3_REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
});

const s3 = new AWS.S3({
  params: {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  },
});

const completeMultipartUpload = async (parts, uploadId, filename) => {
  try {
    return await s3
      .completeMultipartUpload(
        {
          // Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          MultipartUpload: { Parts: parts },
          Key: filename,
          UploadId: uploadId,
        },
        (err, data) => {
          if (err) console.log("Error uploading multipart image", err);
        }
      )
      .promise();
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default {
  completeMultipartUpload,
};
