import http from "./httpService";

async function getStepDetails(id) {
  try {
    const res = await http.get(`/procedure/${id}/liveCase`);
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function nextPreviousButton(caseId, currentStepId) {
  try {
    await http.patch(`/cases/${caseId}`, {
      currentStep: currentStepId,
    });
    return true;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getSurgicalSteps(stepId, limit, skip) {
  try {
    const res = await http.get("/stepequipment", {
      params: {
        step: stepId,
        populate: "equipment",
        limit,
        skip,
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function getChecklistItems(stepId, limit, skip) {
  try {
    const res = await http.get(`/item`, {
      params: {
        step: stepId,
        isDeleted: false,
        limit,
        skip,
      },
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function patchChecklistItem(itemId, isChecked) {
  try {
    const res = await http.patch(`/item/${itemId}`, { isChecked });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

async function patchStartExecutionFlow(caseId, currentStepId) {
  try {
    const res = await http.patch(`/cases/${caseId}`, {
      currentScreen: "STEP",
      currentStep: currentStepId,
    });
    return res.data;
  } catch (exception) {
    console.log(exception);
    return false;
  }
}

export default {
  getSurgicalSteps,
  getStepDetails,
  nextPreviousButton,
  getChecklistItems,
  patchChecklistItem,
  patchStartExecutionFlow,
};
