import { useState, useEffect } from "react";
import { List, Card, Modal, Typography } from "antd";
import MainContainer from "../../containers/MainContainer";
import ActionMenu from "../../components/ActionMenu";
import SignedURLImage from "../../components/SignedURLImage";
import trayService from "../../services/trayService";
import manufacturerService from "../../services/manufacturerService";
import toastNotification from "../../components/toastNotification";
import trayPlaceholder from "../../assets/tray-placeholder.svg";

const { confirm } = Modal;
const { Paragraph } = Typography;
const customPageSize = 12;

const ManufacturerTraysPage = (props) => {
  const {
    match: { url, params },
    history,
    location
  } = props;

  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");
  const manufacturerId = params.id ? params.id : null;

  const pageHeaderProps = isDeletedPage
    ? {
      title: "Deleted Trays",
      backBtnPath: `/manufacturer/${manufacturerId}/tray`,
    }
    : {
      title: "Trays",
      btnText: "Add Tray",
      onBtnClick: () => {
        history.push(`${url}/new`);
      },
      topActionMenuItems: [
        {
          title: "Show Deleted Trays",
          path: `${url}/deleted`,
        },
      ],
    };

  const [trayList, setTrayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(location.state?.pageNumber ?? 1);
  const [trayCount, setTrayCount] = useState(0);
  const [traySearchText, setTraySearchText] = useState();

  const actions = (tray) => {
    return isDeletedPage
      ? [
        {
          title: "Restore",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
          },
        },
      ]
      : [
        {
          title: "Edit",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            history.push(`${url}/${tray.id}`, {
              record: tray,
              editMode: true,
              pageNumber: currentPage,
            });
          },
        },
        {
          title: "Delete",
          onClick: (menuItemProps) => {
            menuItemProps.domEvent.stopPropagation();
            showDeleteConfirmation(tray.id);
          },
        },
      ];
  };

  const showDeleteConfirmation = (trayId) => {
    confirm({
      title: "Delete Tray",
      content: "Are you sure you want to delete this tray?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteTray(trayId);
      },
    });
  };

  const onSearchFunction = async (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1)
        setLoading(true);
        searchValue = searchValue.trim();
        setTraySearchText(searchValue);
        await getSetTrayCountAndPopulate(searchValue, 1);
        setLoading(false);
      }, 500)
    );
  };

  /** API */
  const populateTraysList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await manufacturerService.getManufacturerTrays(
      manufacturerId,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setTrayList(data);
    } else {
      toastNotification("error", "Error fetching the tray records");
    }
    setLoading(false);
  };

  const deleteTray = async (trayId) => {
    setLoading(true);
    const isDeleted = await trayService.patchTray({
      id: trayId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Tray Deleted Successfully");
      await getSetTrayCountAndPopulate();
      setCurrentPage(1);
    } else {
      toastNotification("error", "Error deleting the Tray");
    }
    setLoading(false);
  };

  const getSetTrayCountAndPopulate = async (searchText, currentPageNumber = currentPage) => {
    const success = await trayService.getTrayCount(
      manufacturerId,
      isDeletedPage,
      searchText
    );

    if (success !== false) {
      setTrayCount(success);
      if (success > 0) await populateTraysList(customPageSize, customPageSize * (currentPageNumber - 1), searchText);
      else setTrayList([]);
    } else {
      toastNotification("error", "Error fetching Tray records");
    }
  };
  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetTrayCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by tray name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 3,
          md: 3,
          lg: 4,
          xl: 4,
          xxl: 6,
        }}
        dataSource={trayList}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          responsive: true,
          pageSize: customPageSize,
          total: trayCount,
          current: currentPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            setCurrentPage(page);
            await populateTraysList(
              pageSize,
              pageSize * (page - 1),
              traySearchText
            );
            setLoading(false);
          },
        }}
        loading={loading}
        renderItem={(tray) => {
          return (
            <List.Item>
              <Card
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                      history.push(`${url}/${tray.id}`, {
                        record: tray,
                        editMode: false,
                        pageNumber: currentPage
                      });
                    }
                }
                bordered={false}
                cover={
                  <div
                    className="icon-overlay-container"
                    style={{ width: "100%", height: "176px" }}
                  >
                    <SignedURLImage
                      fileName={tray.image}
                      height="100%"
                      width="100%"
                      key={tray.image}
                      fallback={trayPlaceholder}
                    />
                    <div className="icon-overlay">
                      <ActionMenu actions={actions(tray)} />
                    </div>
                  </div>
                }
                preview="false"
                bodyStyle={{ padding: "0" }}
              />
              <Paragraph
                style={{ marginTop: "0.5em", marginBottom: "1em" }}
                ellipsis={{ rows: 2 }}
              >
                {tray.trayName}
              </Paragraph>
            </List.Item>
          );
        }}
      />
    </MainContainer>
  );
};

export default ManufacturerTraysPage;
