import NoAuthHeader from "../components/NoAuthHeader";
import { Layout } from "antd";

const { Content } = Layout;
const NoAuthContainer = ({ children }) => {
  return (
    <>
      <NoAuthHeader />
      <Content className="gray-color" style={{height:"calc(100% - 13rem)"}}>{children}</Content>
    </>
  );
};

export default NoAuthContainer;
