import TrayEquipmentPage from "../../shared-pages/editable-mini-app/TrayEquipmentPage";

const RMTrayEquipmentPage = (props) => {
    const {
    match: { params }
  } = props;

  const { procedureid: procedureId, rmid: rmId, id } = params;

  return (
    <TrayEquipmentPage
      entityId={rmId}
      entityName="regionalManager"
      procedureId={procedureId}
      manufacturerId={id}
    />
  );
};

export default RMTrayEquipmentPage;
