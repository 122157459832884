import React, { useState, useEffect } from "react";
import regionalManagerRoutes from "./regional-manager.routes";
import areaRoutes from "./area.routes";
import MMRRoutes from "./mmr.routes";
import manufacturerRoutes from "./manufacturer.routes";
import distributorRoutes from "./distributor.routes";
import hospitalRoutes from "./hospital.routes";
import adminRoutes from "./admin.routes";
import LoginPage from "../pages/AuthPages/LoginPage";
import ForgotPasswordPage from "../pages/AuthPages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/AuthPages/ResetPasswordPage";
import manufacturerService from "../services/manufacturerService";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";
import { useHospitalContext } from "../context/HospitalContext";
import userServices from "../services/userServices";
import distributorService from "../services/distributorService";
import hospitalService from "../services/hospitalService";
import areaService from "../services/areaService";

const BreadCrumbUsingApi = (props) => {
  const { setBreadCrumb } = useBreadCrumbContext();
  const { setHospitalDistributors } = useHospitalContext();

  const [name, setName] = useState("");

  const getName = async () => {
    if (props.type === "distributor") {
      const res = await distributorService.getDistributor(props.id, false);
      setName(res.distributorName);
      setBreadCrumb((prev) => ({ ...prev, distributor: res.distributorName }));
    } else if (props.type === "hospital") {
      const res = await hospitalService.getHospital(props.id);
      setHospitalDistributors(res.distributors);
      setName(res.hospitalName);
      setBreadCrumb((prev) => ({ ...prev, hospital: res.hospitalName }));
    } else if (props.type === "manufacturer") {
      const res = await manufacturerService.getManufacturer(props.id);
      setName(res.distributorName);
      setBreadCrumb((prev) => ({ ...prev, manufacturer: res.distributorName }));
    } else if (props.type === "area") {
      const res = await areaService.getArea(props.id);
      setName(res.areaName);
      setBreadCrumb((prev) => ({ ...prev, area: res.areaName }));
    } else {
      const res = await userServices.getUser(props.id);
      const name = ((res.firstName ?? "") + " " + (res.lastName ?? "")).trim();
      setName(name.length ? name : "User");
      setBreadCrumb((prev) => ({ ...prev, user: name }));
    }
  };

  useEffect(() => {
    getName();
  }, []);
  return name ?? false;
};

export const BreadCrumbData = (props) => {
  const { breadCrumb } = useBreadCrumbContext();

  if (props.type) {
    let val;
    // switch (props.type) {
    //   case "distributor":
    //     val = breadCrumb.distributor;
    //     break;

    //   case "manufacturer":
    //     val = breadCrumb.manufacturer;
    //     break;

    //   case "hospital":
    //     val = breadCrumb.hospital;
    //     break;

    //   case "area":
    //     val = breadCrumb.area;
    //     break;

    //   case "user":
    //     val = breadCrumb.user?.trim();
    //     break;

    //   default:
    //     val = null;
    //     break;
    // }
    return val ?? <BreadCrumbUsingApi {...props} />;
  } else return <span></span>;
};

const routes = {
  publicRoutes: [
    { path: "/login", exact: true, component: LoginPage },
    { path: "/forgot-password", exact: true, component: ForgotPasswordPage },
    {
      path: "/reset-password",
      exact: true,
      component: ResetPasswordPage,
    },
  ],
  privateRoutes: [
    ...MMRRoutes.Routes,
    ...regionalManagerRoutes.Routes,
    ...areaRoutes.Routes,
    ...manufacturerRoutes.Routes,
    ...distributorRoutes.Routes,
    ...hospitalRoutes.Routes,
    ...adminRoutes.Routes,
  ],
  miniAppRoutes: [
    ...MMRRoutes.MiniAppRoutes,
    ...regionalManagerRoutes.MiniAppRoutes,
    ...areaRoutes.MiniAppRoutes,
    ...manufacturerRoutes.MiniAppRoutes,
    ...distributorRoutes.MiniAppRoutes,
    ...hospitalRoutes.MiniAppRoutes,
    ...adminRoutes.MiniAppRoutes,
  ],
};

export default routes;
