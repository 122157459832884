import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { Form, Row, Col, Modal, Spin, Divider, Dropdown, Menu } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ServiceCommandUnit from "../../pages/Distributor/ExecutionSubItem";
import MainContainer from "../../containers/MainContainer";
import toastNotification from "../../components/toastNotification";
import executionService from "../../services/executionService";
import SurgicalStepPage from "../../pages/Distributor/SurgicalStepPage";
import CheckListPage from "../../pages/Distributor/CheckListPage";
import NotesPage from "../../pages/Distributor/NotesPage";
import AddPhasePage from "../../pages/Distributor/AddPhasePage";
import LiveCaseFlowPage from "../read-only/mini-app/LiveCaseFlowPage";
import { ReactComponent as DragIcon } from "../../assets/icons/icon_drag-icon-orange.svg";
import { ReactComponent as OrangeIcon } from "../../assets/icons/icondot.svg";
import { ReactComponent as BlackIcon } from "../../assets/icons/black.svg";
import { Roles } from "../../config/role-config.json";
import { CaseTypes } from "../../config/case-types.json";
import { useAuthContext } from "../../context/AuthContext";
import procedureService from "../../services/procedureService";

const { confirm } = Modal;

const ExecutionFlowPage = ({ entityId, entityName, procedureId, state }) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  const backPath = url.replace(
    url.includes(`execution-flow/new`)
      ? `execution-flow/new`
      : "execution-flow/edit",
    "table-setup"
  );

  const { role: userRole, id: userId } = useAuthContext().user;

  const [staticItems, setStaticItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [parentIndex, setParentIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(false);
  const [skelton, setSkelton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noOfSteps, setNoOfSteps] = useState("No");
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [procedure, setProcedure] = useState(state?.procedure);
  const [isProcedureEditable, setIsProcedureEditable] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(
    state?.isReadOnly ? true : false
  );
  const [form] = Form.useForm();

  const movePhase = (e) => {
    const sourceIndex = parseInt(e.key.split(" ")[0]);
    const moveType = e.key.split(" ")[1];
    const destIndex = moveType === "up" ? sourceIndex - 1 : sourceIndex + 1;
    const items = reorder(staticItems, sourceIndex, destIndex);
    setStaticItems(items);

    // Get index for phase
    const droppableItemIndex = getIndex(
      items,
      null,
      destIndex,
      "droppableItem"
    );

    // call phase update API
    dragDropPhase(items[destIndex].original, droppableItemIndex);
  };

  const pageHeaderProps =
    !url.endsWith("new") && isProcedureEditable
      ? {
        title: "Edit Surgical Flow",
        subTitle: `${noOfSteps} ${noOfSteps > 1 ? "Steps" : "Step"}`,
        btnClear: !isPreviewMode ? "Clear" : "",
        miniAppButton: !isPreviewMode ? "Done" : "",
        btnType: "secondary",
        onBtnClick: () => {
          const pathToTableSetup = url.replace(
            "execution-flow/edit",
            "table-setup"
          );
          history.push(pathToTableSetup, {
            tag: "Surgical Flow",
            pageNumber: state?.pageNumber,
            activeTabKey: state.activeTabKey,
            isCloneProcedure: state?.isCloneProcedure,
            ...(state?.preview
              ? {
                preview: state.preview,
                path: state?.path,
              }
              : {}),
          });
        },
      }
      : {
        title: "Primary Surgical Flow",
        subTitle: `${noOfSteps} ${noOfSteps > 1 ? "Steps" : "Step"}`,
        btnClear: !isPreviewMode && isProcedureEditable ? "Clear" : null,
        miniAppButton: !isPreviewMode && isProcedureEditable ? "Done" : null,
        btnType: "secondary",
        onBtnClick: () => {
          const pathToTableSetup = url.replace(
            "execution-flow/new",
            "table-setup"
          );
          history.push(pathToTableSetup, {
            tag: "Surgical Flow",
            pageNumber: state?.pageNumber,
            activeTabKey: state.activeTabKey,
            isCloneProcedure: state?.isCloneProcedure,
            ...(state?.preview
              ? {
                preview: state.preview,
                path: state?.path,
              }
              : {}),
          });
        },
      };

  function sortItems(obj) {
    return obj.sort((item1, item2) => {
      let tmpItem1 = item1.original.index;
      let tmpItem2 = item2.original.index;
      if (tmpItem1 < tmpItem2) {
        return -1;
      }
      if (tmpItem1 > tmpItem2) {
        return 1;
      }
      return 0;
    });
  }

  const getIndexOnCreate = (list, type, parentIndex, childIndex) => {
    // sort (list) phase and steps here and assign to list
    if (type === "phase") {
      return list[parentIndex] && list[parentIndex + 1]
        ? list[parentIndex].original.index +
        list[parentIndex + 1].original.index
        : list[parentIndex] && !list[parentIndex + 1]
          ? list[parentIndex].original.index + "z"
          : "0" + list[parentIndex + 1].original.index;
    } else {
      if (list[parentIndex]["subItems"].length === 0) {
        return "a";
      }
      return list[parentIndex]["subItems"][childIndex - 1] &&
        list[parentIndex]["subItems"][childIndex]
        ? list[parentIndex]["subItems"][childIndex - 1].original.index +
        list[parentIndex]["subItems"][childIndex].original.index
        : list[parentIndex]["subItems"][childIndex - 1] &&
          !list[parentIndex]["subItems"][childIndex]
          ? list[parentIndex]["subItems"][childIndex - 1].original.index + "z"
          : "0" + list[parentIndex]["subItems"][childIndex].original.index;
    }
  };

  const getIndex = (list, parentIndex, destIndex, itemType) => {
    if (itemType === "droppableItem") {
      return list[destIndex - 1] && list[destIndex + 1]
        ? list[destIndex - 1].original.index +
        list[destIndex + 1].original.index
        : list[destIndex - 1] && !list[destIndex + 1]
          ? list[destIndex - 1].original.index + "z"
          : "0" + list[destIndex + 1].original.index;
    } else {
      return list[parentIndex]["subItems"].length !== 1
        ? list[parentIndex]["subItems"][destIndex - 1] &&
          list[parentIndex]["subItems"][destIndex + 1]
          ? list[parentIndex]["subItems"][destIndex - 1].original.index +
          list[parentIndex]["subItems"][destIndex + 1].original.index
          : list[parentIndex]["subItems"][destIndex - 1] &&
            !list[parentIndex]["subItems"][destIndex + 1]
            ? list[parentIndex]["subItems"][destIndex - 1].original.index + "z"
            : "0" + list[parentIndex]["subItems"][destIndex + 1].original.index
        : "a";
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "white" : "",
    ...draggableStyle,
  });

  const dragDropStep = async (step, phaseId, index) => {
    delete step.updatedAt;
    step.index = index;
    step.phase = phaseId;
    const res = await executionService.putStep(step, step.id);
    if (res.status !== 200) {
      toastNotification("error", "Error updating the surgical record");
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "",
    color: "black",
  });

  //To get the equipments list needed to be shown in the surgical step select equipment dropdown.
  const getProcedureEquipments = async () => {
    const data = await executionService.getNeededEquipments(procedureId);
    if (data) {
      setEquipmentsList(data);
    } else {
      toastNotification("error", "Error listing Instruments");
    }
  };

  const showForm = (cIndex, value, pindex) => {
    setEditRecord(false);
    setParentIndex(pindex);
    setCurrentIndex(cIndex);
    /* End set current Index & parent Index */

    //Show Current Selected item's Form
    setSelectedOption(value.key);
    setSkelton(true);
  };

  const addNewItem = (data) => {
    let tempStaticItems = [...staticItems];
    let newAddItems = {
      id: data?.id,
      phase: data?.phase,
      content: data?.content,
      type: selectedOption,
      original: data.original,
      current: data.current,
      parent: data.parent,
    };
    if (selectedOption === "PHASE") {
      newAddItems.subItems = [];

      const subItems = tempStaticItems[parentIndex]?.subItems?.splice(
        data.current
      );
      newAddItems.subItems = subItems;
      newAddItems.subItems?.forEach((el, i) => {
        // el.phase = newAddItems.phase;

        dragDropStep(el.original, newAddItems.phase, el.original.index);
      });
      tempStaticItems.splice(parentIndex + 1, 0, newAddItems);
    } else {
      let tempSubChildren = tempStaticItems[parentIndex]?.subItems;
      tempSubChildren.splice(currentIndex + 1, 0, newAddItems);
      tempStaticItems[parentIndex]["subItems"] = tempSubChildren;
    }
    tempStaticItems = sortItems(tempStaticItems);
    tempStaticItems.map((tempStaticItem) => {
      if (tempStaticItem.subItems) {
        tempStaticItem.steps = sortItems(tempStaticItem.subItems);
      }
    });
    setStaticItems([...tempStaticItems]);
    setSelectedOption(false);
    setSkelton(false);
  };

  const addProcedurePhase = async () => {
    setLoading(true);
    let postData = {
      procedure: procedureId,
      phaseName: "Start",
      index: "a",
    };
    const res = await executionService.postPhase(postData);
    if (res.status === 200) {
      let item = {
        id: new Date().getTime(),
        phase: res.data.id,
        content: res.data.phaseName,
        subItems: [],
      };
      setStaticItems([item]);
    }
    setLoading(false);
    getExecutionFlow();
  };

  const getExecutionFlow = async () => {
    setLoading(true);
    const isDeleted = false;

    const res = await executionService.getExecutionFlow(procedureId, isDeleted);
    if (res.length > 0) {
      let phases = res.map((item, i) => {
        let tmpSubItems = item.steps.filter((si) => si.isDeleted === false);
        return {
          id: new Date().getTime() + i,
          phase: item.id,
          content: item.phaseName,
          type: "PHASE",
          original: item,
          subItems: tmpSubItems.map((subItem, index) => {
            return {
              id:
                new Date().getTime() +
                Math.floor(Math.random() * 99999) +
                index +
                1,
              content: subItem.label,
              phase: subItem.id,
              type: subItem.stepType,
              original: subItem,
            };
          }),
        };
      });
      setStaticItems(phases);
      res.forEach((el) => {
        const steps = el.steps?.filter((e) => !e.isDeleted);
        el.steps = steps;
      });
      const steps =
        res.length > 1
          ? res.reduce((total, el, i) => {
            if (i === 1) return total.steps.length + el.steps.length;
            else return total + el.steps.length;
          })
          : res[0].steps.length;
      setNoOfSteps(steps);
    } else {
      addProcedurePhase();
    }
    setLoading(false);
  };

  const getProcedureDetails = async () => {
    const procedureDtl = await procedureService.getProcedure(procedureId, true);
    if (procedureDtl) {
      setProcedure(procedureDtl);
      setPageAccess(procedureDtl);
    } else toastNotification("error", "Error fetching Procedure details");
  };

  const setPageAccess = (procedureDtl) => {
    if (procedureDtl.case) {
      const usersWhoCanEditCaseProcedure = [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.HOSPITAL_STAFF,
      ];
      if (
        (procedureDtl.case.isStarted === CaseTypes.UPCOMING &&
          usersWhoCanEditCaseProcedure.includes(userRole)) ||
        (userRole === Roles.SURGEON && procedureDtl.case.surgeon === userId)
      )
        setIsProcedureEditable(true);
    } else {
      if (
        (!state.isReadOnly && procedureDtl[entityName] === entityId) &&
        [
          Roles.DOR_ADMIN,
          Roles.MANUFACTURER_ADMIN,
          Roles.AREA_MANAGER,
          Roles.REGIONAL_MANAGER,
          Roles.MMR,
        ].includes(userRole)
      )
        setIsProcedureEditable(true);
      else if (
        location.state?.isCloneProcedure &&
        [
          Roles.DOR_ADMIN,
          Roles.MANUFACTURER_ADMIN,
          Roles.AREA_MANAGER,
          Roles.REGIONAL_MANAGER,
          Roles.MMR,
        ].includes(userRole)
      ) {
        setIsProcedureEditable(true);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (!procedure) {
        await getProcedureDetails();
      } else {
        setPageAccess(procedure);
      }

      await getExecutionFlow();
      getProcedureEquipments();
      setLoading(false);
    })();
  }, []);

  //To calculate the steps whenever they are added in the execution flow
  useEffect(() => {
    let steps =
      staticItems.length > 1
        ? staticItems.reduce((total, el, i) => {
          if (i === 1) return total.subItems.length + el.subItems.length;
          else return total + el.subItems.length;
        })
        : staticItems[0]?.subItems?.length;
    if (steps === undefined) steps = "No";
    setNoOfSteps(steps);
  }, [staticItems]);

  const updateCurrentItem = (value) => {
    setSelectedOption(value);
  };

  const dragDropPhase = async (phase, index) => {
    delete phase.steps;
    delete phase.updatedAt;
    phase.index = index;
    const res = await executionService.putPhase(phase, phase.id);
    if (res.status !== 200) {
      toastNotification("error", "Error updating the surgical record");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    //Do not allow any phase to replace the start phase
    if (result.type === "droppableItem" && destIndex !== 0) {
      const items = reorder(staticItems, sourceIndex, destIndex);
      setStaticItems(items);
      // Get index for phase
      const droppableItemIndex = getIndex(
        items,
        null,
        destIndex,
        "droppableItem"
      );

      // call phase update API
      dragDropPhase(items[destIndex].original, droppableItemIndex);
    } else if (result.type === "droppableSubItem") {
      const itemSubItemMap = staticItems.reduce((acc, item) => {
        acc[item.id] = item.subItems;
        return acc;
      }, {});
      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);
      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];
      let newItems = [...staticItems];
      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.subItems = reorderedSubItems;
          }
          return item;
        });
        setStaticItems(newItems);
        // Search Phase id based on parentIndex
        const phase = newItems.find((item) => {
          return item.id === destParentId;
        });
        let matchedIndex = newItems
          .map(function (obj) {
            return obj.id;
          })
          .indexOf(phase.id);
        // Get index for subItem
        let droppableSubItemIndex = getIndex(
          newItems,
          matchedIndex,
          destIndex,
          "droppableSubItem"
        );

        // Make update step API call
        dragDropStep(
          newItems[matchedIndex]["subItems"][destIndex].original,
          newItems[matchedIndex].original.id,
          droppableSubItemIndex
        );
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);
        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.subItems = newSourceSubItems;
          } else if (item.id === destParentId) {
            item.subItems = newDestSubItems;
          }
          return item;
        });

        // Search phase id based on parentIndex
        const phase = newItems.find((item) => {
          return item.id === destParentId;
        });
        let matchedIndex = newItems
          .map(function (obj) {
            return obj.id;
          })
          .indexOf(phase.id);
        setStaticItems(newItems);
        // Get index for subItem
        let droppableSubItemIndex1 = getIndex(
          newItems,
          matchedIndex,
          destIndex,
          "droppableSubItem"
        );
        // Make update step API call
        dragDropStep(
          newItems[matchedIndex]["subItems"][destIndex].original,
          newItems[matchedIndex].original.id,
          droppableSubItemIndex1
        );
      }
    }
  };
  /** Post execution flow */
  const getPhaseId = () => {
    return staticItems[parentIndex].phase;
  };

  /** Onclick clear button Steps are clear */
  const clearExecutionFlow = async (procedureId) => {
    setLoading(true);
    const isDeleted = await executionService.clearExecutionFlow(procedureId);
    if (isDeleted) {
      setSelectedOption(false);
      setSkelton(false);
      toastNotification("success", "Phase deleted successfully");
      getExecutionFlow();
      setStaticItems([]);
      setNoOfSteps("No");
    } else {
      toastNotification("error", "Error deleting the phase");
    }
    setLoading(false);
  };

  const showDeleteConfirmation = (procedureId) => {
    confirm({
      title: "Clear all Steps",
      content:
        "Are you sure you want to clear all the steps of this surgical flow?",
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,
      icon: <></>,
      onOk() {
        clearExecutionFlow(procedureId);
      },
    });
  };
  const [editRecord, setEditRecord] = useState(false);
  const editItem = (key, itemInfo) => {
    setEditRecord(itemInfo);
    setSelectedOption(key);
    setSkelton(false);
  };
  const hideItem = () => {
    setEditRecord(false);
    setSkelton(false);
    setSelectedOption(null);
    setParentIndex(false);
    setCurrentIndex(false);
  };
  return (
    <div className="execution-flow">
      <MainContainer
        formName="ProcedureAddEditForm"
        backBtnPath={backPath}
        routeState={
          state?.preview
            ? {
              preview: state.preview,
              path: state?.path,
              pageNumber: state?.pageNumber,
              activeTabKey: state?.activeTabKey,
              tag: "Surgical Flow",
            }
            : {
              pageNumber: location.state?.pageNumber,
              activeTabKey: location.state?.activeTabKey,
              tag: "Surgical Flow",
              isCloneProcedure: location.state?.isCloneProcedure,
            }
        }
        onBtnClick={() => {
          form.submit();
        }}
        onClearBtnClick={() => {
          showDeleteConfirmation(procedureId);
        }}
        divider={false}
        previewModeDetails={{
          allowPreviewMode: true,
          isPreviewMode: isPreviewMode,
          setIsPreviewMode: setIsPreviewMode,
        }}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "0rem" }}>
          <Spin
            spinning={loading}
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isPreviewMode ? (
              <LiveCaseFlowPage caseId={procedure.case?.id} />
            ) : (
              <Row>
                <Col
                  span={
                    selectedOption === null || selectedOption === false
                      ? 24
                      : 13
                  }
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100vh - 70px)",
                    overflow: "scroll",
                  }}
                >
                  <Form
                    form={form}
                    name="ProcedureAddEditForm"
                    id="ProcedureAddEditForm"
                    size="large"
                    style={{ paddingTop: "3rem" }}
                    colon="false"
                    scrollToFirstError="true"
                    layout="vertical"
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <span className="orange-icon">
                        <OrangeIcon />
                      </span>
                      <hr className="divider" />
                      <Droppable droppableId="droppable" type="droppableItem">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {staticItems.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                                isDragDisabled={!isProcedureEditable}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className={
                                              editRecord?.phase === item.phase
                                                ? "phase phase-content edit-record"
                                                : "phase phase-content"
                                            }
                                          >
                                            <div
                                              onClick={() => {
                                                if (index !== 0)
                                                  editItem("PHASE", item);
                                              }}
                                            >
                                              {item.content}
                                            </div>

                                            {index !== 0 && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                <Dropdown
                                                  key="phaseActionMenu"
                                                  overlay={
                                                    <Menu onClick={movePhase}>
                                                      {index !== 1 && (
                                                        <Menu.Item
                                                          key={[
                                                            index,
                                                            "up",
                                                          ].join(" ")}
                                                        >
                                                          Move Up
                                                        </Menu.Item>
                                                      )}
                                                      {index <
                                                        staticItems.length -
                                                        1 && (
                                                          <Menu.Item
                                                            key={[
                                                              index,
                                                              "down",
                                                            ].join(" ")}
                                                          >
                                                            Move Down
                                                          </Menu.Item>
                                                        )}
                                                    </Menu>
                                                  }
                                                  placement="bottomRight"
                                                >
                                                  <DragIcon />
                                                </Dropdown>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <ServiceCommandUnit
                                          subItems={item.subItems}
                                          type={item.id.toString()}
                                          addNewItem={showForm}
                                          parentIndex={index}
                                          editItem={editItem}
                                          editRecord={editRecord}
                                          currentIndex={currentIndex}
                                          skelton={skelton}
                                          selectedParent={parentIndex}
                                          selectedOption={selectedOption}
                                          isPageReadOnly={!isProcedureEditable}
                                        />
                                      </div>

                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <span
                      className="orange-icon"
                      style={{ paddingBottom: "20px" }}
                    >
                      <BlackIcon />
                    </span>
                  </Form>
                  {selectedOption === null || selectedOption === false ? (
                    ""
                  ) : (
                    <Divider
                      type="vertical"
                      style={{
                        minHeight: "calc(100vh - 70px)",
                        margin: 0,
                        position: "sticky",
                        left: "99%",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                  )}
                </Col>

                <Col span={11} align="middle">
                  <div
                    style={{
                      marginTop: "1.5rem",
                      overflow: "scroll",
                      height: "calc(100vh - 70px)",
                      paddingRight: "4rem",
                    }}
                  >
                    {selectedOption === "SURGICAL_STEP" ? (
                      <SurgicalStepPage
                        key={editRecord?.id}
                        addNewItem={addNewItem}
                        updateCurrentItem={setSelectedOption}
                        editRecord={editRecord}
                        updatedData={getExecutionFlow}
                        //={saveFinalData}
                        equipmentsList={equipmentsList}
                        setEquipmentsList={setEquipmentsList}
                        hideItem={hideItem}
                        getPhaseId={getPhaseId}
                        currentIndex={currentIndex}
                        parentIndex={parentIndex}
                        staticItems={staticItems}
                        getIndexOnCreate={getIndexOnCreate}
                        isPageReadOnly={!isProcedureEditable}
                      />
                    ) : (
                      ""
                    )}
                    {selectedOption === "CHECKLIST" ? (
                      <CheckListPage
                        key={editRecord?.id}
                        addNewItem={addNewItem}
                        updateCurrentItem={setSelectedOption}
                        editRecord={editRecord}
                        updatedData={getExecutionFlow}
                        //saveFinalData={saveFinalData}
                        hideItem={hideItem}
                        getPhaseId={getPhaseId}
                        currentIndex={currentIndex}
                        parentIndex={parentIndex}
                        staticItems={staticItems}
                        getIndexOnCreate={getIndexOnCreate}
                        isPageReadOnly={!isProcedureEditable}
                      />
                    ) : (
                      ""
                    )}
                    {selectedOption === "NOTES" ? (
                      <NotesPage
                        key={editRecord?.id}
                        addNewItem={addNewItem}
                        updateCurrentItem={setSelectedOption}
                        editRecord={editRecord}
                        updatedData={getExecutionFlow}
                        //saveFinalData={saveFinalData}
                        hideItem={hideItem}
                        getPhaseId={getPhaseId}
                        currentIndex={currentIndex}
                        parentIndex={parentIndex}
                        staticItems={staticItems}
                        getIndexOnCreate={getIndexOnCreate}
                        isPageReadOnly={!isProcedureEditable}
                      />
                    ) : (
                      ""
                    )}
                    {selectedOption === "PHASE" ? (
                      <AddPhasePage
                        key={editRecord?.id}
                        addNewItem={addNewItem}
                        updateCurrentItem={updateCurrentItem}
                        editRecord={editRecord}
                        updatedData={getExecutionFlow}
                        //saveFinalData={saveFinalData}
                        hideItem={hideItem}
                        currentIndex={currentIndex}
                        parentIndex={parentIndex}
                        staticItems={staticItems}
                        getIndexOnCreate={getIndexOnCreate}
                        isPageReadOnly={!isProcedureEditable}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Spin>
        </div>
      </MainContainer>
    </div>
  );
};
export default ExecutionFlowPage;
