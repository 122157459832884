import { useEffect, useState } from "react";
import { Modal, Spin, Typography } from "antd";
import { Link } from "react-router-dom";
import MainContainer from "../containers/MainContainer";
import ContentTable from "../components/ContentTable";
import ActionMenu from "../components/ActionMenu";
import toastNotification from "../components/toastNotification";
import { ReactComponent as MobileIcon } from "../assets/icons/iconMobile.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/iconPhone.svg";
import userServices from "../services/userServices";
import { useAuthContext } from "../context/AuthContext";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";

const { confirm } = Modal;
const { Text } = Typography;
const adminRoles = ["DOR_ADMIN"];
const customPageSize = 10;

const AdminsPage = (props) => {
  const {
    match: { url },
    history,
  } = props;

  const paths = url.split("/");
  const isDeletedPage = paths.includes("deleted");
  const { setBreadCrumb } = useBreadCrumbContext();

  const pageHeaderProps = isDeletedPage
    ? {
        title: "DinamicOR Deleted Admins",
        backBtnPath: "/admin",
      }
    : {
        title: "DinamicOR Admins",
        btnText: "Add Admin",
        onBtnClick: () => {
          history.push("/admin/new");
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Admins",
            path: "/admin/deleted",
          },
        ],
      };

  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentAdminPage, setCurrentAdminPage] = useState(1);
  const [adminCount, setAdminCount] = useState(0);
  const [adminSearchText, setAdminSearchText] = useState();

  const authContext = useAuthContext();

  const actions = (adminUser) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onUserRestore(adminUser);
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              setBreadCrumb((prev) => ({
                ...prev,
                user: `${adminUser.firstName ?? ""} ${
                  adminUser.lastName ?? ""
                }`.trim(),
              }));
              history.push(`${url}/${adminUser.id}`, {
                record: adminUser,
                editMode: true,
              });
            },
          },
          !adminUser.isActive
            ? {
                title: "Resend Invite",
                onClick: (menuItemProps) => {
                  menuItemProps.domEvent.stopPropagation();
                  sendActivationLink(adminUser.email);
                },
              }
            : null,
          authContext.user.id !== adminUser.id
            ? {
                title: "Delete",
                onClick: (menuItemProps) => {
                  menuItemProps.domEvent.stopPropagation();
                  showDeleteConfirmation(adminUser.id);
                },
              }
            : null,
        ];
  };

  const columnsList = [
    {
      title: "Admin Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (name, adminUser) => {
        const adminName = name ? (
          <Text strong>
            {name} {adminUser.middleName} {adminUser.lastName}
          </Text>
        ) : (
          <Text style={{ color: "grey" }}>Not Set</Text>
        );
        return (
          <>
            {isDeletedPage ? (
              <> {adminName}</>
            ) : (
              <Link
                to={{
                  pathname: `/admin/${adminUser.id}`,
                  state: { record: adminUser },
                }}
                onClick={() =>
                  setBreadCrumb({
                    user: `${adminUser.firstName ?? ""} ${
                      adminUser.lastName ?? ""
                    }`.trim(),
                  })
                }
              >
                {adminName}
              </Link>
            )}
            {!adminUser.isActive && (
              <>
                <br />
                <Text style={{ color: "#db9f2e", fontSize: "0.9em" }}>
                  Activation Pending
                </Text>
              </>
            )}
          </>
        );
      },
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Contact Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber, { officeNumber }) => (
        <>
          {mobileNumber && (
            <span className="display-flex-center">
              <MobileIcon width="1.3em" />
              {mobileNumber}
            </span>
          )}
          {officeNumber && (
            <span className="display-flex-center">
              <PhoneIcon fill="#1d1d1d" width="1.3em" />
              {officeNumber}
            </span>
          )}
          {!mobileNumber && !officeNumber && (
            <Text style={{ color: "grey", marginLeft: "0.4em" }}>Not Set</Text>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "4rem",
      render: (value, record) => <ActionMenu actions={actions(record)} />,
    },
  ];

  const onUserRestore = async (user) => {
    setLoading(true);
    const res = await userServices.patchRestoreUser(user.id);
    
    if (res) {
      toastNotification("success", "User Restored");
      populateAdminList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the user");
    }
  };

  const sendActivationLink = async (email) => {
    const res = await userServices.postResendUserActivationLink(email);
    if (res?.status === 200) {
      toastNotification(
        "success",
        "Activation link sent to the User’s email address"
      );
    } else {
      toastNotification("error", "Could not send the activation link");
    }
  };

  const showDeleteConfirmation = (adminId) => {
    confirm({
      title: "Delete Admin",
      content: "Are you sure you want to delete this User?",
      okText: "Delete",
      centered: true,
      cancelText: "Cancel",
      onOk() {
        deleteAdmin(adminId);
      },
    });
  };

  const onSearchFunction = (searchValue) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setAdminSearchText(searchValue);
        const success = await getAdminCount(searchValue);
        if (success) {
          setCurrentAdminPage(1);
          populateAdminList(customPageSize, 0, searchValue);
        } else {
          toastNotification("error", "Error fetching Admin records");
        }
      }, 500)
    );
  };

  /** API Calls */
  const populateAdminList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await userServices.getAdminUsers(
      adminRoles,
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setAdminList(data);
    } else {
      toastNotification("error", "Error fetching Admin records");
    }
    setLoading(false);
  };

  const deleteAdmin = async (id) => {
    setLoading(true);
    const isDeleted = await userServices.deleteUser(id);
    if (isDeleted) {
      toastNotification("success", "Admin deleted successfully");
      setCurrentAdminPage(1);
      await populateAdminList(customPageSize, 0);
    } else {
      toastNotification("error", "Error deleting the Admin record");
    }
    setLoading(false);
  };

  const getAdminCount = async (searchValue) => {
    const count = await userServices.getUsersCount(
      "admin",
      "1234",
      isDeletedPage,
      searchValue
    );
    if (count >= 0) {
      setAdminCount(count);
      return true;
    } else {
      return false;
    }
  };
  /** API Calls End */

  useEffect(() => {
    (async () => {
      const success = await getAdminCount();
      if (success) populateAdminList(customPageSize, 0);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by First Name, Last Name or Email"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <Spin spinning={loading}>
        <ContentTable
          data={adminList}
          columnsList={columnsList}
          pagination={{
            pageSize: customPageSize,
            total: adminCount,
            current: currentAdminPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentAdminPage(page);
              await populateAdminList(
                pageSize,
                pageSize * (page - 1),
                adminSearchText
              );
              setLoading(false);
            },
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default AdminsPage;
