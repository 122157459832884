import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

const AuthorizedRoute = ({
  component: Component,
  render,
  redirection,
  rolesAccess,
  ...rest
}) => {
  const authContext = useAuthContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        const hasRoleAccess = rolesAccess.includes(authContext.user.role);
        if (hasRoleAccess && Component)
          return Component ? <Component {...props} /> : render(props);
        if (redirection) {
          return <Redirect to={rest.location.pathname + "/" + redirection} />;
        } else return <Redirect to="/page-not-found" />;
      }}
    />
  );
};

export default AuthorizedRoute;
