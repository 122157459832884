import { Plugin } from "@uppy/core";
import convert from "heic-convert";

class UppyHEICImageConversion extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || "HEICImageConversion";
    this.type = "modifier";
    this.conversion = this.conversion.bind(this);
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async conversion(currentFile) {
    if (
      currentFile.name.split(".").pop() === "HEIC" ||
      currentFile.name.split(".").pop() === "heic"
    ) {
      let base64String = await this.toBase64(currentFile.data);

      base64String = base64String.split(";base64,")[1];
      let inputBuffer = Buffer.from(base64String, "base64");

      const outputBuffer = await convert({
        buffer: inputBuffer, // the HEIC file buffer
        format: "JPEG", // output format
        quality: 1, // the jpeg compression quality, between 0 and 1
      });

      currentFile.convertHEICToJPG = outputBuffer;

      if (!currentFile.convertHEICToJPG) {
        return false;
      } else {
        let blob = new Blob([currentFile.convertHEICToJPG]);
        let JpegImage = {};
        JpegImage.preview = URL.createObjectURL(blob);
        JpegImage.data = new File([blob], "abc.jpg", {
          type: "image/jpeg",
        });
        return JpegImage;
      }
    }
    return currentFile;
  }

  onFileAdded = (file) => {
    if (
      file.name.split(".").pop() === "HEIC" ||
      file.name.split(".").pop() === "heic"
    ) {
      return this.conversion(file)
        .then((JpegImage) => {
          this.setPreviewURL(file.id, JpegImage.preview);
          const uploadedFile = this.uppy.getFile(file.id);
          uploadedFile.data = JpegImage.data;
          uploadedFile.extension = "JPEG";
          uploadedFile.type = "image/jpeg";
          this.uppy.setFileState(file.id, uploadedFile);
        })
        .catch((err) => {
          this.uppy.log(
            `HEIC Image conversion to JPEG Failed ${file.id}:`,
            "warning"
          );
          this.uppy.log(err, "warning");
        });
    }
  };

  setPreviewURL(fileID, preview) {
    this.uppy.setFileState(fileID, { preview });
  }

  install() {
    this.uppy.on("file-added", this.onFileAdded);
  }
  uninstall() {
    this.uppy.off("file-added", this.onFileAdded);
  }
}

export default UppyHEICImageConversion;
