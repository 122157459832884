import { Roles } from "../config/role-config.json";
import ManufacturerForm from "../pages/ManufacturerForm";
import ManufacturerUsersPage from "../pages/Manufacturer/ManufacturerUsersPage";
import ManufacturerUserForm from "../pages/Manufacturer/ManufacturerUserForm";
import ManufacturerEquipmentsPage from "../pages/Manufacturer/ManufacturerEquipmentsPage";
import ManufacturerEquipmentForm from "../pages/Manufacturer/ManufacturerEquipmentForm";
import ManufacturerTraysPage from "../pages/Manufacturer/ManufacturerTraysPage";
import ManufacturerTrayFrom from "../pages/Manufacturer/ManufacturerTrayForm";
import ManufacturerAreaForm from "../pages/Manufacturer/ManufacturerAreaForm";
import ManufacturerAreasPage from "../pages/Manufacturer/ManufacturerAreasPage";
import CreateNewProcedurePage from "../pages/Distributor/CreateNewProcedurePage";
import ExecutionFlowPage from "../pages/Distributor/ExecutionFlowPage";
import TrayEquipmentPage from "../pages/MiniApp/TrayEquipmentPage";
import ManufacturerProceduresPage from "../pages/Manufacturer/ManufacturerProceduresPage";
import CaseForm from "../shared-pages/read-only/hospital-and-cases/CasesForm";
import ManufacturerHospitalsPage from "../pages/Manufacturer/ManufacturerHospitalsPage";
import ManufacturerHospitalsCasePage from "../pages/Manufacturer/ManufacturerHospitalsCasePage";
import TableSetupPage from "../pages/MiniApp/TableSetupPage";
import ProcedureMainPage from "../shared-pages/read-only/mini-app/ProcedureMainPage";
import ExecutionFlow from "../shared-pages/read-only/mini-app/ExecutionFlow";
import LiveCaseMainPage from "../shared-pages/read-only/mini-app/LiveCaseMainPage";
import { BreadCrumbData } from "./index.routes";

const manufacturerRoutes = {
  Routes: [
    /** ReadOnly Hospital and Cases */
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/completed",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Completed",
      component: ManufacturerHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerHospitalsCasePage,
    },
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/:caseid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Case Details",
      component: CaseForm,
    },
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Cases",
      component: ManufacturerHospitalsCasePage,
    },
    //for breadcrumb
    {
      path: "/manufacturer/:id/hospital/:hospitalid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="hospital" id={props.match.params.hospitalid} />
      ),
      redirection: "case",
    },
    {
      path: "/manufacturer/:id/hospital",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Hospitals",
      component: ManufacturerHospitalsPage,
    },
    /** Manufacturer Users */
    {
      path: "/manufacturer/:id/user/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerUsersPage,
    },
    {
      path: "/manufacturer/:id/user/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "New Manufacturer User",
      component: ManufacturerUserForm,
    },
    {
      path: "/manufacturer/:id/user/:userid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.userid} />
      ),
      component: ManufacturerUserForm,
    },
    {
      path: "/manufacturer/:id/user",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Manufacturer Users",
      component: ManufacturerUsersPage,
    },
    /** Manufacturer Users End */
    /** Manufacturer Equipments */
    {
      path: "/manufacturer/:id/equipment/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerEquipmentsPage,
    },
    {
      path: "/manufacturer/:id/equipment/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "New Manufacturer Instrument",
      component: ManufacturerEquipmentForm,
    },
    {
      path: "/manufacturer/:id/equipment/:equipmentid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Instrument Details",
      component: ManufacturerEquipmentForm,
    },
    {
      path: "/manufacturer/:id/equipment",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Manufacturer Instruments",
      component: ManufacturerEquipmentsPage,
    },
    /** Trays */
    {
      path: "/manufacturer/:id/tray/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerTraysPage,
    },
    {
      path: "/manufacturer/:id/tray/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "New Manufacturer Tray",
      component: ManufacturerTrayFrom,
    },
    {
      path: "/manufacturer/:id/tray/:trayid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Tray Details",
      component: ManufacturerTrayFrom,
    },
    {
      path: "/manufacturer/:id/tray",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Manufacturer Trays",
      component: ManufacturerTraysPage,
    },
    /** Manufacturer Areas */
    {
      path: "/manufacturer/:id/area/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerAreasPage,
    },
    {
      path: "/manufacturer/:id/area/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "New Manufacturer Area",
      component: ManufacturerAreaForm,
    },
    // For Breadcrumb
    {
      path: "/manufacturer/:id/area/:areaid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="area" id={props.match.params.areaid} />
      ),
      redirection: "setup",
    },
    {
      path: "/manufacturer/:id/area",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Manufacturer Areas",
      component: ManufacturerAreasPage,
    },
    /** Manufacturer Areas End */
    /** Manufacturer Procedures */
    {
      path: "/manufacturer/:id/procedure",
      exact: true,
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      breadcrumb: "Procedures",
      component: ManufacturerProceduresPage,
    },
    {
      path: "/manufacturer/:id/manufacturer-procedure/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: ManufacturerProceduresPage,
    },
    /** Manufacturer Procedures End */
    {
      path: "/manufacturer/:id/setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: ManufacturerForm,
    },
  ],
  MiniAppRoutes: [
    /** Procedure */
    {
      path: "/manufacturer/:id/procedure/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: CreateNewProcedurePage,
    },
    {
      path: "/manufacturer/:id/procedure/:procedureid/table-setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: TableSetupPage,
    },
    {
      path: "/manufacturer/:id/procedure/:procedureid/tray-management",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: TrayEquipmentPage,
    },
    {
      path: "/manufacturer/:id/procedure/:procedureid/execution-flow/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/manufacturer/:id/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/manufacturer/:id/procedure/:procedureid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: CreateNewProcedurePage,
    },
    /** ReadOnly Hospital Case Procedure */
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/:caseid/procedure/:procedureid/table-setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: ProcedureMainPage,
    },
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/:caseid/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: ExecutionFlow,
    },
    /** ReadOnly Hospital Live Case */
    {
      path: "/manufacturer/:id/hospital/:hospitalid/case/:caseid/live-case",
      rolesAccess: [Roles.DOR_ADMIN, Roles.MANUFACTURER_ADMIN],
      exact: true,
      component: LiveCaseMainPage,
    },
  ],
};
export default manufacturerRoutes;
