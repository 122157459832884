import React, { memo } from "react";
import { Select } from "antd";
import { ReactComponent as ProcedureIcon } from "../../../assets/icons/small-icon-procedure.svg";
import { ReactComponent as PhaseIcon } from "../../../assets/icons/small-icon-phase.svg";
import { ReactComponent as CheckListIcon } from "../../../assets/icons/small-icon-checklist.svg";
import { ReactComponent as NotesIcon } from "../../../assets/icons/small-notes-icon.svg";

const { Option } = Select;

const optionStyle = {
  display: "flex",
  alignItems: "center",
};

const FormSelect = memo(({ updateCurrentItem, selectedValue, editRecord }) => {
  const type = editRecord.type;

  return (
    <Select
      defaultValue={selectedValue}
      style={{ width: 180 }}
      onChange={updateCurrentItem}
      disabled={type === selectedValue ? "disabled" : ""}
    >
      <Option
        value="SURGICAL_STEP"
        className={type !== selectedValue ? "show" : "d-none"}
      >
        <div style={optionStyle}>
          <ProcedureIcon />
          Surgical Steps
        </div>
      </Option>
      <Option
        value="CHECKLIST"
        className={type !== selectedValue ? "show" : "d-none"}
      >
        <div style={optionStyle}>
          <CheckListIcon />
          Check List
        </div>
      </Option>
      <Option
        value="NOTES"
        className={type !== selectedValue ? "show" : "d-none"}
      >
        <div style={optionStyle}>
          <NotesIcon />
          Notes
        </div>
      </Option>
      <Option
        value="PHASE"
        className={type !== selectedValue ? "show" : "d-none"}
      >
        <div style={optionStyle}>
          <PhaseIcon />
          Phase
        </div>
      </Option>
    </Select>
  );
});

export default FormSelect;
