import { Typography, Row, Col, Button } from "antd";
const { Text, Title } = Typography;

const ExecutionStepContainer = (props) => {
  const {
    children,
    phaseName,
    label,
    isCurrentStep,
    stepType,
    onSeeTutorial,
    btnText,
  } = props;
  return (
    <>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Row wrap={false}>
            <Col
              flex={
                stepType === "Surgical Step"
                  ? "135px"
                  : stepType === "Notes"
                  ? "90px"
                  : "110px"
              }
            >
              <div
                className={
                  isCurrentStep ? "check-box-new-preview" : "check-box-new"
                }
                style={{ height: "40px" }}
              >
                <span>{stepType}</span>
              </div>
            </Col>
            <Col flex="auto">
              <Text
                style={{
                  color: "grey",
                  width: isCurrentStep ? "-webkit-fill-available" : "300px",
                  marginTop: "0.3em",
                  marginLeft: "1em",
                }}
                ellipsis={2}
              >
                <span style={{ letterSpacing: "1.5px" }}>
                  {isCurrentStep ? "CURRENT STEP" : "NEXT STEP"}
                </span>
                {` | ${phaseName}`}
              </Text>
            </Col>
            {btnText && (
              <Col flex="80px">
                <div>
                  <Button
                    htmlType="button"
                    style={{
                      backgroundColor: "inherit",
                      borderColor: "rgb(255,255,255)",
                      color: "rgb(255,255,255)",
                    }}
                    className="btnSeeTutorial"
                    onClick={onSeeTutorial}
                  >
                    {btnText}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Title
            level={5}
            ellipsis={{ rows: 2 }}
            style={{
              margin: 0,
              ...(isCurrentStep ? { color: "#ffffff" } : {}),
              marginBottom: "10px",
            }}
          >
            {label}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </>
  );
};

export default ExecutionStepContainer;
