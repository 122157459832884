import React, { useEffect } from "react";
import { Stream } from "@cloudflare/stream-react";

const VideoStream = ({ src, addRefs }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    if (addRefs) addRefs(ref);
  }, []);

  return <Stream controls src={src} streamRef={ref} />;
};

export default VideoStream;
