import { Roles } from "../config/role-config.json";
import HospitalUsersPage from "../pages/Hospital/HospitalUsersPage";
import HospitalUserForm from "../pages/Hospital/HospitalUserForm";
import HospitalCasesPage from "../pages/Hospital/HospitalCasesPage";
import HospitalCaseForm from "../pages/Hospital/HospitalCaseForm";
import HospitalForm from "../pages/HospitalForm";
import ExecutionFlowPage from "../pages/Distributor/ExecutionFlowPage";
import LiveCasePage from "../pages/LiveCase/LiveCasePage";
import TrayEquipmentPage from "../pages/MiniApp/TrayEquipmentPage";
import CreateNewProcedurePage from "../pages/Distributor/CreateNewProcedurePage";
import TableSetupPage from "../pages/MiniApp/TableSetupPage";
import LiveCaseMainPage from "../shared-pages/read-only/mini-app/LiveCaseMainPage";
import { BreadCrumbData } from "./index.routes";

const hospitalRoutes = {
  Routes: [
    /** Hospital Users */
    {
      path: "/hospital/:id/user/deleted",
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
      exact: true,
      breadcrumb: "Deleted",
      component: HospitalUsersPage,
    },
    {
      path: "/hospital/:id/user/new",
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
      exact: true,
      breadcrumb: "New Hospital User",
      component: HospitalUserForm,
    },
    {
      path: "/hospital/:id/user/:userid",
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
      exact: true,
      breadcrumb: (props) => (
        <BreadCrumbData type="user" id={props.match.params.userid} />
      ),
      component: HospitalUserForm,
    },
    {
      path: "/hospital/:id/user",
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
      exact: true,
      breadcrumb: "Hospital Users",
      component: HospitalUsersPage,
    },
    /** Hospital My-Cases */
    {
      path: "/hospital/:id/my-cases/new",
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
      exact: true,
      breadcrumb: "New Hospital Case",
      component: HospitalCaseForm,
    },
    {
      path: "/hospital/:id/my-cases/completed",
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
      exact: true,
      breadcrumb: "Completed",
      component: HospitalCasesPage,
    },
    {
      path: "/hospital/:id/my-cases/deleted",
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
      exact: true,
      breadcrumb: "Deleted",
      component: HospitalCasesPage,
    },
    {
      path: "/hospital/:id/my-cases/:caseid",
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
      exact: true,
      breadcrumb: "Case Details",
      component: HospitalCaseForm,
    },
    {
      path: "/hospital/:id/my-cases",
      rolesAccess: [Roles.SURGEON, Roles.HOSPITAL_STAFF],
      exact: true,
      breadcrumb: "My Cases",
      component: HospitalCasesPage,
    },
    /** Hospital My-Cases End*/
    /* Hospital Case */
    {
      path: "/hospital/:id/cases/deleted",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: "Deleted",
      component: HospitalCasesPage,
    },
    {
      path: "/hospital/:id/cases/completed",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: "Completed",
      component: HospitalCasesPage,
    },
    {
      path: "/hospital/:id/cases/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: "New Hospital Case",
      component: HospitalCaseForm,
    },
    {
      path: "/hospital/:id/cases/:caseid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: "Case Details",
      component: HospitalCaseForm,
    },
    {
      path: "/hospital/:id/cases",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
      ],
      exact: true,
      breadcrumb: "Cases",
      component: HospitalCasesPage,
    },
    /* Hospital Case End */
    {
      path: "/hospital/:id/setup",
      rolesAccess: [Roles.DOR_ADMIN, Roles.HOSPITAL_ADMIN],
      exact: true,
      component: HospitalForm,
    },

    /** Hospital End */
  ],
  MiniAppRoutes: [
    /** Case Procedure */
    {
      path: "/procedure/:procedureid/execution-flow/new",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/procedure/:procedureid/live-case",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: LiveCasePage,
    },
    {
      path: "/procedure/:procedureid/case/:caseid/live-case/preview",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: LiveCaseMainPage,
    },
    {
      path: "/procedure/:procedureid/table-setup",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: TableSetupPage,
    },
    {
      path: "/procedure/:procedureid/tray-management",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: TrayEquipmentPage,
    },
    {
      path: "/procedure/:procedureid/execution-flow/edit",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: ExecutionFlowPage,
    },
    {
      path: "/procedure/:procedureid",
      rolesAccess: [
        Roles.DOR_ADMIN,
        Roles.HOSPITAL_ADMIN,
        Roles.SURGEON,
        Roles.HOSPITAL_STAFF,
        Roles.DISTRIBUTOR_ADMIN,
        Roles.MR,
      ],
      exact: true,
      component: CreateNewProcedurePage,
    },
  ],
};
export default hospitalRoutes;
