import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Typography,
  Spin,
  Divider,
  Tabs,
} from "antd";
import { debounce } from "lodash";
import MainContainer from "../containers/MainContainer";
import toastNotification from "../components/toastNotification";
import manufacturerService from "../services/manufacturerService";
import hospitalService from "../services/hospitalService";
import distributorService from "../services/distributorService";
import utilityService from "../services/utilityService";
import { ReactComponent as HospitalGreyIcon } from "../assets/icons/greyIconManufacturer.svg";
import { ReactComponent as HospitalColorIcon } from "../assets/icons/colorIconHospital.svg";
import { ReactComponent as DistributorColorIcon } from "../assets/icons/colorIconDistributor.svg";
import { ReactComponent as DistributorGreyIcon } from "../assets/icons/greyIconDistributor.svg";

import { useAuthContext } from "../context/AuthContext";
import { Roles } from "../config/role-config.json";
import AssignmentSelection from "../components/AssignmentSelection";

const { confirm } = Modal;
const { Title } = Typography;
const { TabPane } = Tabs;

const ManufacturerForm = (props) => {
  const { match, history, location } = props;
  const manufacturerId = match.params?.id;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(
    !manufacturerId || location.state?.readOnly === false ? true : false
  );

  const [initialHospitalIds, setInitialHospitalIds] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalIds, setSelectedHospitalIds] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitalSearchTimeOut, setHospitalSearchTimeOut] = useState(0);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const [hospitalCount, setHospitalCount] = useState();
  const [hospitalSearchText, setHospitalSearchText] = useState();
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [loadHospitals, setLoadHospitals] = useState(false);

  const [distributorList, setDistributorList] = useState([]);
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedDistributorIds, setSelectedDistributorIds] = useState([]);
  const [distributorSearchTimeOut, setDistributorSearchTimeOut] = useState(0);

  const [currentDistributorPage, setCurrentDistributorPage] = useState(1);
  const [distributorCount, setDistributorCount] = useState();
  const [distributorSearchText, setDistributorSearchText] = useState();
  const [distributorLoading, setDistributorLoading] = useState(false);
  const [loadDistributors, setLoadDistributors] = useState(false);

  const customPageSize = 30;
  const authContext = useAuthContext();

  const isSuperAdmin = authContext.user?.role === Roles.DOR_ADMIN;
  const isManufacturer = Roles.MANUFACTURER_ADMIN;

  const pageHeaderProps = manufacturerId
    ? {
        title: "Manufacturer's Setup",
        btnText:
          isSuperAdmin || isManufacturer ? (editMode ? "Save" : "Edit") : "",
        btnType:
          isSuperAdmin || isManufacturer
            ? editMode
              ? "primary"
              : "secondary"
            : "",
        topActionMenuItems: isSuperAdmin
          ? [
              {
                title: "Delete",
                path: "",
                onClick: () => {
                  showDeleteConfirmation(manufacturerId);
                },
              },
            ]
          : null,
      }
    : {
        title: "New Manufacturer",
        btnText: "Add",
        backBtnPath: "/manufacturer",
      };

  const onContactNumberInputChange = (e) => {
    const value = e.target.value;

    const formattedNumber = utilityService.formatContactNumber(value);
    form.setFieldsValue({
      [e.target.id]: formattedNumber,
    });
  };

  const showDeleteConfirmation = () => {
    confirm({
      title: "Delete Manufacturer",
      content: "Are you sure you want to delete this Manufacturer?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        setLoading(true);
        const isDeleted = await manufacturerService.patchManufacturer({
          id: manufacturerId,
          isDeleted: true,
        });
        if (isDeleted) {
          toastNotification("success", "Manufacturer deleted successfully");
          history.push("/manufacturer");
        } else {
          toastNotification("error", "Error deleting the Manufacturer");
        }
        setLoading(false);
      },
    });
  };

  /** Hospitals Assignment */

  const handleInputChange = (value) => {
    logValue(value);
    if (value.length === 0) setCurrentHospitalPage(1);
  };

  const handleMenuScrollToBottom = () => {
    loadHospitals && hospitalList.length !== hospitalCount && addOptions();
  };

  const handleFilterOption = (input, option) => {
    return option.value !== "loading"
      ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : true;
  };

  const onSearchHospital = async (searchValue) => {
    setHospitalLoading(true);
    if (hospitalSearchTimeOut) {
      clearTimeout(hospitalSearchTimeOut);
    }
    setHospitalSearchTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setHospitalSearchText(searchValue);
        await getSetHospitalCountAndPopulate(searchValue);
        setHospitalLoading(false);
      }, 500)
    );
  };

  const onHospitalSelect = (manufacturerId) => {
    const ids = selectedHospitalIds;
    ids.push(manufacturerId);
    setSelectedHospitalIds([...ids]);

    const selectedHospital = hospitalList.find(
      (manufacturer) => manufacturer.id === manufacturerId
    );
    selectedHospitals.push(selectedHospital);
    setSelectedHospitals([...selectedHospitals]);
  };

  const onHospitalDeselect = (hospitalId) => {
    setSelectedHospitalIds(selectedHospitalIds.filter((x) => x !== hospitalId));

    setSelectedHospitals(
      selectedHospitals.filter((hospital) => hospital.id !== hospitalId)
    );
  };

  const logValue = debounce((searchText) => {
    setHospitalSearchText(searchText);
  }, 250);

  const addOptions = async () => {
    setLoadHospitals(false);
    setHospitalLoading(true);
    setCurrentHospitalPage(currentHospitalPage + 1);
    await populateHospitalList(
      customPageSize,
      currentHospitalPage * customPageSize,
      hospitalSearchText
    );
    setHospitalLoading(false);
  };
  /** Hospitals Assignment End */

  /** Distributor Assignment */

  const handleInputChangeForDistributor = (value) => {
    logDistributorValue(value);
    if (value.length === 0) setCurrentDistributorPage(1);
  };

  const logDistributorValue = debounce((searchText) => {
    setDistributorSearchText(searchText);
  }, 250);

  const handleDistributorMenuScrollToBottom = () => {
    loadDistributors &&
      distributorList.length !== distributorCount &&
      addDistributorOptions();
  };

  const onDistributorSelect = (distributorId) => {
    const ids = selectedDistributorIds;
    ids.push(distributorId);
    setSelectedDistributorIds([...ids]);

    const selectedDistributor = distributorList.find(
      (distributor) => distributor.id === distributorId
    );
    selectedDistributors.push(selectedDistributor);
    setSelectedDistributors([...selectedDistributors]);
  };

  const onDistributorDeselect = (distributorId) => {
    setSelectedDistributorIds(
      selectedDistributorIds.filter((x) => x !== distributorId)
    );

    setSelectedDistributors(
      selectedDistributors.filter(
        (distributor) => distributor.id !== distributorId
      )
    );
  };

  const onSearchDistributor = async (searchValue) => {
    setDistributorLoading(true);
    if (distributorSearchTimeOut) {
      clearTimeout(distributorSearchTimeOut);
    }
    setDistributorSearchTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setDistributorSearchText(searchValue);
        await getSetDistributorCountAndPopulate(searchValue);
        setDistributorLoading(false);
      }, 500)
    );
  };

  const addDistributorOptions = async () => {
    setLoadDistributors(false);
    setDistributorLoading(true);
    setCurrentDistributorPage(currentDistributorPage + 1);
    await populateDistributorList(
      customPageSize,
      currentDistributorPage * customPageSize,
      distributorSearchText
    );
    setDistributorLoading(false);
  };
  /** Distributors Assignment End */

  const onFinish = async (manufacturerFormValues) => {
    setLoading(true);
    manufacturerFormValues.manufacturerHospitals = selectedHospitalIds;
    manufacturerFormValues.distributors = selectedDistributorIds;
    if (manufacturerId) {
      await editManufacturer(manufacturerFormValues);
    } else {
      await addManufacturer(manufacturerFormValues);
    }

    const sortedHospitals =
      utilityService.sortSelectedEntity(selectedHospitals);
    setSelectedHospitals(sortedHospitals);

    setSelectedDistributors(selectedDistributors);

    setLoading(false);
  };

  /** API calls */

  const addManufacturer = async (manufacturerFormValues) => {
    // TODO :: Trim values before submit
    const res = await manufacturerService.postManufacturer(
      manufacturerFormValues
    );
    if (res) {
      toastNotification("success", "New Manufacturer added");
      history.push("/manufacturer");
    } else {
      toastNotification("error", "Error adding a new manufacturer record");
    }
  };

  const editManufacturer = async (manufacturerFormValues) => {
    // TODO :: Trim values before submit
    const removedHIds = initialHospitalIds.filter(
      (hId) => !manufacturerFormValues.manufacturerHospitals.includes(hId)
    );

    if (removedHIds.length) {
      manufacturerService.patchUnassignManufacturerHospitals(
        manufacturerId,
        removedHIds
      );
    }

    manufacturerFormValues.id = manufacturerId;
    const res = await manufacturerService.patchManufacturer(
      manufacturerFormValues
    );
    if (res) {
      setEditMode(false);
      setInitialHospitalIds(manufacturerFormValues.manufacturerHospitals);
      history.replace({ state: { record: res } });
      toastNotification("success", "Manufacturer Record Updated");
    } else {
      toastNotification("error", "Error updating the Manufacturer record");
    }
  };

  const getManufacturer = async () => {
    const data = await manufacturerService.getManufacturer(manufacturerId);
    if (data) {
      return data;
    } else {
      toastNotification("error", "Requested Manufacturer record not found");
    }
  };

  const getAssignedDistributors = async () => {
    const data = await manufacturerService.getManufacturerDistributors(
      manufacturerId
    );
    if (data) {
      return data;
    } else {
      toastNotification("error", "Error fetching Distributor records");
    }
  };

  const getSetHospitalCountAndPopulate = async (searchValue) => {
    const count = await hospitalService.getHospitalCount(false, searchValue);
    if (count !== false) {
      setHospitalCount(count);
      setCurrentHospitalPage(0);
      if (count > 0) await populateHospitalList(customPageSize, 0, searchValue);
    } else {
      toastNotification("error", "Error fetching hospital records");
    }
  };

  const populateHospitalList = async (limit, skip, searchValue) => {
    setHospitalLoading(true);
    const res = await hospitalService.getHospitals(
      false,
      limit,
      skip,
      searchValue
    );
    if (res) {
      setLoadHospitals(res.length === customPageSize);
      const arr = [...hospitalList, ...res];
      setHospitalList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
    setHospitalLoading(false);
  };

  const getSetDistributorCountAndPopulate = async (searchValue) => {
    const count = await distributorService.getDistributorCount(
      false,
      searchValue
    );
    if (count !== false) {
      setDistributorCount(count);
      setCurrentDistributorPage(0);
      if (count > 0)
        await populateDistributorList(customPageSize, 0, searchValue);
    } else {
      toastNotification("error", "Error fetching Distributor records");
    }
  };

  const populateDistributorList = async (limit, skip, searchValue) => {
    setDistributorLoading(true);
    const res = await distributorService.getDistributors(
      { isDeleted: false },
      limit,
      skip,
      null,
      searchValue
    );
    if (res) {
      setLoadDistributors(res.length === customPageSize);
      const arr = [...distributorList, ...res];
      setDistributorList([
        ...new Map(arr.map((item) => [item["id"], item])).values(),
      ]);
    }
    setDistributorLoading(false);
  };
  /** API calls end */

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (manufacturerId) {
        const manufacturer = await getManufacturer();
        const distributors = await getAssignedDistributors();
        if (manufacturer) {
          form.setFieldsValue({ ...manufacturer });
          if (manufacturer.manufacturerHospitals) {
            const filteredHospitals = utilityService.filterSelectedEntity(
              manufacturer.manufacturerHospitals
            );
            const sortedHospitals =
              utilityService.sortSelectedEntity(filteredHospitals);
            const hIds = sortedHospitals.map((hospital) => hospital.id);
            setSelectedHospitals(sortedHospitals);
            setSelectedHospitalIds(hIds);
            setInitialHospitalIds(hIds);
          }
        }
        if (distributors) {
          const dIds = distributors.map((distributor) => distributor.id);
          setSelectedDistributors(distributors);
          setSelectedDistributorIds(dIds);
        }
      }

      await getSetHospitalCountAndPopulate();
      await getSetDistributorCountAndPopulate();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <MainContainer
        formName="manufacturerAddEditForm"
        onBtnClick={() => {
          if (editMode) form.submit();
          else setEditMode(true);
        }}
        divider={true}
        {...pageHeaderProps}
      >
        <div style={{ marginTop: "1rem" }}>
          <Row>
            <Col span={14}>
              <Row align="middle">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 22, offset: 1 }}
                  xl={{ span: 22, offset: 1 }}
                  xxl={{ span: 22, offset: 1 }}
                >
                  <Title level={5}>Details</Title>
                  <Form
                    form={form}
                    name="manufacturerAddEditForm"
                    size="large"
                    colon="false"
                    scrollToFirstError="true"
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Manufacturer Name (Required)"
                          name="distributorName"
                          rules={[
                            {
                              required: true,
                              message: "Please input Manufacturer!",
                            },
                          ]}
                        >
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 1"
                          name="contactNumber1"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "The entered contact number seems to be invalid. Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (xxx) xxx-xxxx"
                            id="contactNumber1"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label="Contact Number 2"
                          name="contactNumber2"
                          rules={[
                            {
                              pattern: /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                              message:
                                "Please follow the format (xxx) xxx-xxxx",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Example: (xxx) xxx-xxxx"
                            id="contactNumber2"
                            onChange={onContactNumberInputChange}
                            disabled={!editMode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Street Address" name="address">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="City" name="city">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item label="State" name="state">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="space-between">
                      <Col span={11}>
                        <Form.Item label="Country" name="country">
                          <Input disabled={!editMode} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={8}>
              <Title level={5}>Assigned Hospitals/Distributors</Title>

              <Tabs>
                <TabPane tab="Hospitals" key="h">
                  <AssignmentSelection
                    editMode={isSuperAdmin && editMode}
                    selectedEntityIds={selectedHospitalIds}
                    dataSource={hospitalList}
                    selectedEntities={selectedHospitals}
                    optionIcon={HospitalColorIcon}
                    showOptionsLoader={hospitalLoading}
                    onSelect={onHospitalSelect}
                    onDeselect={onHospitalDeselect}
                    onSearch={onSearchHospital}
                    filterOption={handleFilterOption}
                    onDropdownVisibleChange={() => setCurrentHospitalPage(1)}
                    onChange={() => setCurrentHospitalPage(0)}
                    onPopupScroll={handleMenuScrollToBottom}
                    onInputKeyDown={handleInputChange}
                    tagIcon={HospitalColorIcon}
                    emptyText="No Hospitals added yet"
                    emptyIcon={HospitalGreyIcon}
                    entityTitleProperty="hospitalName"
                    placeholder="Search by Hospital Name"
                  />
                </TabPane>
                <TabPane tab="Distributors" key="d">
                  <AssignmentSelection
                    editMode={isSuperAdmin && editMode}
                    selectedEntityIds={selectedDistributorIds}
                    dataSource={distributorList}
                    selectedEntities={selectedDistributors}
                    optionIcon={DistributorColorIcon}
                    showOptionsLoader={distributorLoading}
                    onSelect={onDistributorSelect}
                    onDeselect={onDistributorDeselect}
                    onSearch={onSearchDistributor}
                    filterOption={handleFilterOption}
                    onDropdownVisibleChange={() => setCurrentDistributorPage(1)}
                    onChange={() => setCurrentDistributorPage(0)}
                    onPopupScroll={handleDistributorMenuScrollToBottom}
                    onInputKeyDown={handleInputChangeForDistributor}
                    tagIcon={DistributorColorIcon}
                    emptyText="No Distributors added yet"
                    emptyIcon={DistributorGreyIcon}
                    entityTitleProperty="distributorName"
                    placeholder="Search by Distributor Name"
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </MainContainer>
    </Spin>
  );
};

export default ManufacturerForm;
