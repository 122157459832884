import { useState, useEffect } from "react";
import { Button, Drawer } from "antd";

//import trayService from "../../services/trayService";
import AllEquipmentsDrawer from "./AllEquipmentDrawer";
import { ReactComponent as CloseIcon } from "../../assets/icons/iconCloseSmall.svg";
import { ReactComponent as InventoryIcon } from "../../assets/icons/icon_inventory.svg";

const TableSetupAllEquipments = ({ allEquipmentDetails }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [trayDetails, setTrayDetails] = useState();

  const closeDrawerHandler = () => {
    setIsDrawerVisible(false);
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  useEffect(() => {
    setTrayDetails(allEquipmentDetails);
  }, [allEquipmentDetails]);

  return (
    <>
      <Button
        style={{
          backgroundColor: "black",
          color: "white",
          position: "fixed",
          paddingLeft: "20px",
          paddingRight: "20px",
          bottom: "1.5rem",
          right: "0.5rem",
          zIndex: 5,
          height: "52px",
          display: "flex",
          alignItems: "center"
        }}
        onClick={showDrawer}
      >
        <InventoryIcon /> All Instruments
      </Button>

      <Drawer
        title="All Instruments"
        onClose={closeDrawerHandler}
        visible={isDrawerVisible}
        width={"45vw"}
        closeIcon={<CloseIcon />}
      >
        <AllEquipmentsDrawer trayDetails={trayDetails} />
      </Drawer>
    </>
  );
};

export default TableSetupAllEquipments;
