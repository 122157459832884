import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Spin,
  List,
  Card,
  Typography,
  Tabs,
  Modal,
  // Dropdown,
  // Button,
  // Menu,
  // Checkbox,
  // Tag,
  // Input,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import toastNotification from "../../components/toastNotification";
import trayService from "../../services/trayService";
// import distributorService from "../../services/distributorService";
import trayPlaceholder from "../../assets/Images/placeholder-image_trays.svg";
// import { ReactComponent as ArrowIcon } from "../../assets/icons/iconArrow.svg";
// import { ReactComponent as SearchIcon } from "../../assets/icons/icon_search.svg";
import CardList from "../../components/CardList";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const customPageSize = 12;

const TraysPage = ({
  manufacturerId,
  url,
  hasOwnLibrary,
  entityId,
  entityName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isDeletedPage = url.includes("deleted");
  // const distributorId = params?.id;

  const [activeTabKey, setActiveTabKey] = useState(
    entityName === "area" ? "1" : location.state?.activeTabKey ?? "2"
  );
  const [trayList, setTrayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    location.state?.pageNumber ?? 1
  );
  const [trayCount, setTrayCount] = useState(0);
  const [traySearchText, setTraySearchText] = useState();
  // const [filteredManufacturers, setFilteredManufacturers] = useState([]);
  // const [manufacturers, setManufacturers] = useState([]);
  // const [selectedManufacturerIds, setSelectedManufacturerIds] = useState([]);
  // const [dropdownVisibility, setDropdownVisibility] = useState(false);
  // const [filterTimeOut, setFilterTimeOut] = useState(0);

  const actions = (tray) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              history.push(`${url}/${tray.id}`, {
                record: tray,
                isPageReadOnly: false,
                pageNumber: currentPage,
                activeTabKey: activeTabKey,
              });
            },
          },
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(tray.id);
            },
          },
        ];
  };

  const showDeleteConfirmation = (trayId) => {
    confirm({
      title: "Delete Tray",
      content: "Are you sure you want to delete this Tray?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteTray(trayId);
      },
    });
  };

  /** Manufacturer Filter */

  // const onManufacturerSearch = (searchValue) => {
  //   setFilteredManufacturers(
  //     manufacturers.filter((x) =>
  //       x.distributorName.toLowerCase().includes(searchValue.toLowerCase())
  //     )
  //   );
  // };

  // const onManufacturerSelectionChange = async (selectedIds) => {
  //   setLoading(true);
  //   if (filterTimeOut) {
  //     clearTimeout(filterTimeOut);
  //   }
  //   setFilterTimeOut(
  //     setTimeout(async () => {
  //       setSelectedManufacturerIds(selectedIds);
  //       await getSetTrayCountAndPopulate(
  //         activeTabKey,
  //         traySearchText,
  //         selectedIds
  //       );
  //       setLoading(false);
  //     }, 800)
  //   );
  // };

  // const manufacturerOverlay = (
  //   <Menu>
  //     <Menu.Item>
  //       <Input
  //         placeholder="Search"
  //         suffix={<SearchIcon height="17" />}
  //         size="medium"
  //         style={{ marginRight: "-10" }}
  //         onChange={(e) => onManufacturerSearch(e.target.value.trim())}
  //       />
  //     </Menu.Item>
  //     {filteredManufacturers.map((manufacturer, index) => (
  //       <Menu.Item key={index}>
  //         <Checkbox value={manufacturer.id}>
  //           {manufacturer.distributorName}
  //         </Checkbox>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  // const manufacturerDropdown = (
  //   <Checkbox.Group
  //     onChange={onManufacturerSelectionChange}
  //     className="equipment-manufacturer-filter"
  //   >
  //     <Dropdown
  //       overlay={manufacturerOverlay}
  //       trigger={["click"]}
  //       disabled={activeTabKey != "1"}
  //       onVisibleChange={(flag) => setDropdownVisibility(flag)}
  //       visible={dropdownVisibility}
  //       overlayClassName="manufacturer-filter-dropdown-menu"
  //       arrow
  //     >
  //       <Button className="display-flex-center">
  //         <Tag color="black">{selectedManufacturerIds.length}</Tag>
  //         Manufacturer <ArrowIcon style={{ margin: "2px 0 0 2px" }} />
  //       </Button>
  //     </Dropdown>
  //   </Checkbox.Group>
  // );

  /** Manufacturer Filter End */

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        searchValue = searchValue.trim();
        setTraySearchText(searchValue);
        await getSetTrayCountAndPopulate(
          activeTabKey,
          searchValue,
          1
          // selectedManufacturerIds
          // manufacturerId
        );
        setLoading(false);
      }, 500)
    );
  };

  /** API */
  const getSetTrayCountAndPopulate = async (
    tabKey,
    searchText,
    currentPageNumber = currentPage
    // manufacturerIds
  ) => {
    const success = await trayService.getTrayCount(
      isDeletedPage && entityId
        ? entityId
        : tabKey === "1"
        ? manufacturerId
        : entityId,
      isDeletedPage,
      searchText,
      isDeletedPage && entityId
        ? "regionalManagerId"
        : tabKey === "1"
        ? "distributorId"
        : "regionalManagerId"
    );

    if (success !== false) {
      setTrayCount(success);
      if (hasOwnLibrary && (tabKey === "2" || isDeletedPage)) {
        await populateTraysList(
          entityId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "regionalManager"
        );
      } else {
        await populateTraysList(
          manufacturerId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "distributor"
        );
      }
    } else {
      setTrayList([]);
      toastNotification("error", "Error fetching Tray records");
    }
  };

  const populateTraysList = async (
    id,
    limit,
    skip,
    searchValue,
    entityName
  ) => {
    const data = await trayService.getDistributorTrays(
      id,
      isDeletedPage,
      limit,
      skip,
      searchValue,
      entityName
    );
    if (data) {
      setTrayList(data);
    } else {
      toastNotification("error", "Error fetching the tray records");
    }
  };

  const deleteTray = async (trayId) => {
    setLoading(true);
    const isDeleted = await trayService.patchTray({
      id: trayId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Tray deleted successfully");
      // setCurrentPage(1);
      // setTraySearchText();
      await getSetTrayCountAndPopulate(
        activeTabKey,
        null
        // selectedManufacturerIds
      );
    } else {
      toastNotification("error", "Error deleting the Tray");
    }
    setLoading(false);
  };

  // const getDistributor = async () => {
  //   const distributor = await distributorService.getDistributor(distributorId);
  //   if (distributor) {
  //     setManufacturers(distributor.manufacturers);
  //     setFilteredManufacturers(distributor.manufacturers);
  //     return distributor;
  //   } else {
  //     toastNotification("error", "Requested Distributor record not found");
  //   }
  // };

  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetTrayCountAndPopulate(activeTabKey, null);
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Tray Name"
      onSearchFunction={onSearchFunction}
      {...(isDeletedPage
        ? {
            title: "Deleted Trays",
            backBtnPath: url.replace("/deleted", ""),
          }
        : {
            title: "Trays",
            ...(activeTabKey === "2" && {
              btnText: "Add Tray",
              onBtnClick: () => {
                history.push(`${url}/new`);
              },
            }),
            topActionMenuItems: [
              {
                title: "Show Deleted Trays",
                path: `${url}/deleted`,
              },
            ],
            //     }),
          })}
    >
      <Spin spinning={loading}>
        {hasOwnLibrary && !isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setActiveTabKey(key);
              setCurrentPage(1);
              await getSetTrayCountAndPopulate(key, traySearchText, 1);
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Trays" key="1" />
            <TabPane
              tab={
                url.includes("medical-representative")
                  ? "Regional Manager's Trays"
                  : "My Trays"
              }
              key="2"
            />
          </Tabs>
        )}
        <CardList
          dataSource={trayList}
          pagination={{
            pageSize: customPageSize,
            total: trayCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              await populateTraysList(
                activeTabKey === "1" ? manufacturerId : entityId,
                pageSize,
                pageSize * (page - 1),
                traySearchText,
                activeTabKey === "1" ? "distributor" : "regionalManager"
              );
              setLoading(false);
            },
          }}
          renderItem={(tray) => {
            return (
              <List.Item>
                <Card
                  {...(!isDeletedPage && {
                    onClick: () => {
                      history.push(`${url}/${tray.id}`, {
                        record: tray,
                        pageNumber: currentPage,
                        activeTabKey: activeTabKey,
                      });
                    },
                    style: { cursor: "pointer" },
                  })}
                  // {...(isDeletedPage && {
                  //       onClick: () => {
                  //         history.push(`${url}/${tray.id}`, {
                  //           record: tray,
                  //           // isPageReadOnly: true,
                  //         });
                  //       },
                  //     })}
                  cover={
                    <div
                      className="icon-overlay-container"
                      style={{ width: "100%", height: "176px" }}
                    >
                      <SignedURLImage
                        fileName={tray.image}
                        height="100%"
                        width="100%"
                        key={tray.image}
                        fallback={trayPlaceholder}
                      />
                      {activeTabKey === "2" && (
                        <div className="icon-overlay">
                          <ActionMenu actions={actions(tray)} />
                        </div>
                      )}
                    </div>
                  }
                  bordered={false}
                  preview="false"
                  bodyStyle={{ padding: "0" }}
                />
                <Paragraph
                  style={{ marginTop: "0.5em", marginBottom: "1em" }}
                  ellipsis={{ rows: 2 }}
                >
                  {tray.trayName}
                </Paragraph>
              </List.Item>
            );
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default TraysPage;
