import { useLocation, useHistory } from "react-router-dom";
import { Typography, Button, Modal } from "antd";
import EmptyElement from "../../components/EmptyElement";
import hospitalService from "../../services/hospitalService";
import { useAuthContext } from "../../context/AuthContext";
import { Roles } from "../../config/role-config.json";
import NoExecutionFlowSvg from "../../assets/Images/NoExecutionFlow.svg";

const { Title, Text } = Typography;

const NoExecutionFlowPage = ({isReadOnly}) => {
  const history = useHistory();
  const location = useLocation();
  const path = location.state?.path;
  const readOnly = location.state?.readOnly;

  const authContext = useAuthContext();
  const hasReadOnlyRights =
    authContext.user.role === Roles.DISTRIBUTOR_ADMIN ||
    authContext.user.role === Roles.MR;

  const stepDetails = location.state?.stepDetails
    ? location.state?.stepDetails
    : {};

  const caseId = stepDetails?.caseId;

  const confirmEndCase = () => {
    Modal.confirm({
      title: (
        <Text strong style={{ color: "#f26524" }}>
          You are about to complete the Surgery
        </Text>
      ),
      content: `Please take Surgeon’s consent before completing the surgery and then click
      on “Complete”.`,
      okText: "Complete",
      cancelText: "Cancel",
      icon: null,
      centered: true,
      width: "550px",
      onOk: async () => {
        if (!hasReadOnlyRights) {
          await hospitalService.putCase({
            id: caseId,
            isStarted: "FINISHED",
          });
        }
        history.push(path);
      },
    });
  };

  return (
    <EmptyElement
      image={NoExecutionFlowSvg}
      description={false}
      elementStyle={{ background: "#000e1a", height: "calc(100vh - 68px)" }}
    >
      <Title style={{ color: "#ffffff" }} level={5}>
        No execution steps added.
      </Title>
      { !isReadOnly && (<Button
        type="primary"
        style={{ margin: "0" }}
        onClick={confirmEndCase}
        disabled={stepDetails?.isStarted === "FINISHED" || readOnly}
      >
        Complete Case
      </Button>)}
    </EmptyElement>
  );
};
export default NoExecutionFlowPage;
