import React, { useState, useEffect } from "react";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import MultiBackend, { TouchTransition } from "react-dnd-multi-backend";
import { Row, Col, Layout, Spin } from "antd";
import Grid from "../../../components/Grid";
import distributorService from "../../../services/distributorService";
import trayService from "../../../services/trayService";
import { useTableSetupContext } from "../../../context/TableSetupContext";
import TableSetupAllEquipments from "../../../containers/TableSetup/TableSetupAllInstruments";

const { Content } = Layout;
const customPageSize = 30;

const TableSetup = (props) => {
  const { tableMode, procedureId, isProcedureEditable, startCase, isCompletedCasesPage } = props;
  const tableSetup = useTableSetupContext()?.tableSetup;

  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
        preview: true,
      },
      {
        backend: TouchBackend,
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modifiedTrayDetails, setModifiedTrayDetails] = useState();

  // Get instruments details of all instruments side drawer
  const [allEquipmentDetails, setAllEquipmentDetails] = useState();

  const getTableDetails = async () => {
    if (procedureId) {
      let count = customPageSize;
      let temp = [];

      for (let skipPage = 0; count === customPageSize; skipPage++) {
        const data = await distributorService.getTableSetup(
          procedureId,
          null,
          customPageSize,
          skipPage * customPageSize
        );
        if (data) {
          temp = temp.concat(data);
          count = data.length;
          let trayDetails = {};
          for (const table of data) {
            table.tray.sectionNumber = table.sectionNumber
            trayDetails[table.tray?.id] = table.tray
          }
          if (temp.length === 0) {
            setLoading(false);
          }
          setModifiedTrayDetails(trayDetails);
        } else count = 0;
      }
      setTableData(temp);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (startCase) {
        let trayDetails = {};
        for (const table of tableSetup) {
          table.tray.sectionNumber = table.sectionNumber
          trayDetails[table.tray?.id] = table.tray
        }
        if (tableSetup.length === 0) {
            setLoading(false);
          }
        setModifiedTrayDetails(trayDetails);
        setTableData(tableSetup);
      } else await getTableDetails();
    })();
  }, [procedureId, tableSetup]);

  useEffect(() => {
    const trays = [];
    modifiedTrayDetails && Object.keys(modifiedTrayDetails).forEach(async (key, index) => {
      const trayEquipmentList = await trayService.getTrayEquipments(key);
      trayEquipmentList.forEach((el) => el.sectionNumber = modifiedTrayDetails[key].sectionNumber)
      trays.push({
        tray: modifiedTrayDetails[key],
        equipment: trayEquipmentList
      });
      if (index === Object.keys(modifiedTrayDetails).length - 1) {
        setLoading(false);
      }
    })
    setAllEquipmentDetails(trays);
  }, [modifiedTrayDetails]);

  return (
    <Spin spinning={loading}>
      <div
        className="table-setup-main-container"
        style={{ overflow: "auto", height: "calc(100vh - 70px)" }}
      >
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <TableSetupAllEquipments allEquipmentDetails={allEquipmentDetails} />
          <Row>
            <Col span={24}>
              <Content>
                <Row style={{ justifyContent: "center" }}>
                  <Col
                    span={tableMode === "3WIDE" ? 18 : 14}
                    pull={1}
                    style={{ marginBottom: "30px" }}
                  >
                    <Grid
                      tableData={tableData}
                      tableMode={tableMode}
                      isProcedureEditable={isProcedureEditable}
                      startCase={startCase}
                      isCompletedCasesPage={isCompletedCasesPage}
                    />
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </DndProvider>
      </div>
    </Spin>
  );
};

export default TableSetup;
