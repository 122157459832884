import React from "react";
import { useEffect, useState } from "react";
import { Form, Row, Col, Spin, Divider } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ServiceCommandUnit from "../../../pages/Distributor/ExecutionSubItem";
import MainContainer from "../../../containers/MainContainer";
import toastNotification from "../../../components/toastNotification";
import executionService from "../../../services/executionService";
import distributorService from "../../../services/distributorService";
import SurgicalStepPage from "../../../pages/Distributor/SurgicalStepPage";
import CheckListPage from "../../../pages/Distributor/CheckListPage";
import NotesPage from "../../../pages/Distributor/NotesPage";
import AddPhasePage from "../../../pages/Distributor/AddPhasePage";
import LiveCaseFlowPage from "../mini-app/LiveCaseFlowPage";
import { ReactComponent as OrangeIcon } from "../../../assets/icons/icondot.svg";
import { ReactComponent as BlackIcon } from "../../../assets/icons/black.svg";
import { useLocation, useRouteMatch } from "react-router-dom";

const ExecutionFlow = (props) => {
  const location = useLocation();
  const { params, url } = useRouteMatch();
  const { procedureid: procedureId } = params;
  const backPath = url.replace("execution-flow/edit", "table-setup");
  const isProcedureEditable = false;

  const [staticItems, setStaticItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [parentIndex, setParentIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(false);
  const [skelton, setSkelton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noOfSteps, setNoOfSteps] = useState("No");
  const [equipmentsList, setEquipmentsList] = useState([]);
  const [procedure, setProcedure] = useState(location.state?.procedure);
  const [editRecord, setEditRecord] = useState();
  const [isPreviewMode, setIsPreviewMode] = useState(location.state?.isReadOnly ? true : false);

  const [form] = Form.useForm();

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "white" : "",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "",
    color: "black",
  });

  //To get the equipments list needed to be shown in the surgical step select equipment dropdown.
  const getProcedureEquipments = async () => {
    const data = await executionService.getNeededEquipments(procedureId);
    if (data) {
      setEquipmentsList(data);
    } else {
      toastNotification("error", "Error listing equipments");
    }
  };

  const showForm = (cIndex, value, pindex) => {
    setEditRecord(false);
    setParentIndex(pindex);
    setCurrentIndex(cIndex);
    /* End set current Index & parent Index */

    //Show Current Selected item's Form
    setSelectedOption(value.key);
    setSkelton(true);
  };

  const addProcedurePhase = async () => {
    setLoading(true);
    let postData = {
      procedure: procedureId,
      phaseName: "Start",
      index: "a",
    };
    const res = await executionService.postPhase(postData);
    if (res.status === 200) {
      let item = {
        id: new Date().getTime(),
        phase: res.data.id,
        content: res.data.phaseName,
        subItems: [],
      };
      setStaticItems([item]);
    }
    setLoading(false);
    getExecutionFlow();
  };

  const getExecutionFlow = async () => {
    setLoading(true);
    const isDeleted = false;

    const res = await executionService.getExecutionFlow(procedureId, isDeleted);
    if (res.length > 0) {
      let phases = res.map((item, i) => {
        let tmpSubItems = item.steps.filter((si) => si.isDeleted === false);
        return {
          id: new Date().getTime() + i,
          phase: item.id,
          content: item.phaseName,
          type: "PHASE",
          original: item,
          subItems: tmpSubItems.map((subItem, index) => {
            return {
              id:
                new Date().getTime() +
                Math.floor(Math.random() * 99999) +
                index +
                1,
              content: subItem.label,
              phase: subItem.id,
              type: subItem.stepType,
              original: subItem,
            };
          }),
        };
      });
      setStaticItems(phases);
      res.forEach((el) => {
        const steps = el.steps?.filter((e) => !e.isDeleted);
        el.steps = steps;
      });
      const steps =
        res.length > 1
          ? res.reduce((total, el, i) => {
            if (i === 1) return total.steps.length + el.steps.length;
            else return total + el.steps.length;
          })
          : res[0].steps.length;
      setNoOfSteps(steps);
    } else {
      addProcedurePhase();
    }
    setLoading(false);
  };

  const getProcedureDetails = async () => {
    const procedureDtl = await distributorService.getProcedure(
      procedureId,
      true
    );
    if (procedureDtl) {
      setProcedure(procedureDtl);
    } else toastNotification("error", "Error fetching procedure details");
  };

  /** Post execution flow */
  const getPhaseId = () => {
    return staticItems[parentIndex].phase;
  };

  const editItem = (key, itemInfo) => {
    setEditRecord(itemInfo);
    setSelectedOption(key);
    setSkelton(false);
  };

  const hideItem = () => {
    setEditRecord(false);
    setSkelton(false);
    setSelectedOption(null);
    setParentIndex(false);
    setCurrentIndex(false);
  };

  const renderStepForm = () => {
    const stepProps = {
      key: editRecord?.id,
      editRecord: editRecord,
      updatedData: getExecutionFlow,
      hideItem: hideItem,
      currentIndex: currentIndex,
      parentIndex: parentIndex,
      staticItems: staticItems,
      getIndexOnCreate: null,
      isPageReadOnly: !isProcedureEditable,
    };

    let content;
    switch (selectedOption) {
      case "SURGICAL_STEP":
        content = (
          <SurgicalStepPage
            equipmentsList={equipmentsList}
            setEquipmentsList={setEquipmentsList}
            getPhaseId={getPhaseId}
            {...stepProps}
          />
        );
        break;
      case "CHECKLIST":
        content = <CheckListPage getPhaseId={getPhaseId} {...stepProps} />;
        break;
      case "NOTES":
        content = <NotesPage getPhaseId={getPhaseId} {...stepProps} />;
        break;
      case "PHASE":
        content = <AddPhasePage {...stepProps} />;

        break;
      default:
        <></>;
        break;
    }
    return content;
  };

  useEffect(() => {
    (async () => {
      if (!procedure) {
        await getProcedureDetails();
      }
      await getExecutionFlow();
      getProcedureEquipments();
    })();
  }, []);

  return (
    <div className="execution-flow">
      <MainContainer
        formName="ProcedureAddEditForm"
        backBtnPath={backPath}
        routeState={{
          pageNumber: location.state?.pageNumber,
          caseType: location.state?.caseType,
          tag: "Surgical Flow"
        }}
        divider={false}
        title="Primary Surgical Flow"
        subTitle={`${noOfSteps} ${noOfSteps > 1 ? "Steps" : "Step"}`}
        previewModeDetails={{
          allowPreviewMode: true,
          isPreviewMode: isPreviewMode,
          setIsPreviewMode: setIsPreviewMode
        }}
      >
        <div style={{ marginTop: "0" }}>
          <Spin spinning={loading}
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isPreviewMode ? <LiveCaseFlowPage caseId={procedure.case?.id} /> :
              <Row>
                <Col
                  span={selectedOption ? 13 : 24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100vh - 70px)",
                    overflow: "scroll",
                  }}
                >
                  <Form
                    form={form}
                    name="ProcedureAddEditForm"
                    id="ProcedureAddEditForm"
                    size="large"
                    style={{ paddingTop: "3rem" }}
                    colon="false"
                    scrollToFirstError="true"
                    layout="vertical"
                  >
                    <DragDropContext>
                      <span className="orange-icon">
                        <OrangeIcon />
                      </span>
                      <hr className="divider" />
                      <Droppable droppableId="droppable" type="droppableItem">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {staticItems.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                                isDragDisabled={!isProcedureEditable}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            className={
                                              editRecord?.phase === item.phase
                                                ? "phase phase-content edit-record"
                                                : "phase phase-content"
                                            }
                                          >
                                            <div
                                              onClick={() => {
                                                if (index !== 0)
                                                  editItem("PHASE", item);
                                              }}
                                            >
                                              {item.content}
                                            </div>
                                          </div>
                                        </div>

                                        <ServiceCommandUnit
                                          subItems={item.subItems}
                                          type={item.id.toString()}
                                          addNewItem={showForm}
                                          parentIndex={index}
                                          editItem={editItem}
                                          editRecord={editRecord}
                                          currentIndex={currentIndex}
                                          skelton={skelton}
                                          selectedParent={parentIndex}
                                          selectedOption={selectedOption}
                                          isPageReadOnly={!isProcedureEditable}
                                        />
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <span
                      className="orange-icon"
                      style={{ paddingBottom: "20px" }}
                    >
                      <BlackIcon />
                    </span>
                  </Form>
                  {selectedOption && (
                    <Divider
                      type="vertical"
                      style={{
                        minHeight: "calc(100vh - 70px)",
                        margin: 0,
                        position: "sticky",
                        left: "99%",
                        height: "100%",
                        width: "1px",
                      }}
                    />
                  )}
                </Col>
                <Col span={11} align="middle">
                  <div
                    style={{
                      marginTop: "1.5rem",
                      overflow: "scroll",
                      height: "calc(100vh - 70px)",
                      paddingRight: "4rem",
                    }}
                  >
                    {renderStepForm()}
                  </div>
                </Col>
              </Row>}
          </Spin>
        </div>
      </MainContainer>
    </div>
  );
};

export default ExecutionFlow;
