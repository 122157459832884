import React, { useState } from "react";
import { Form, Input, Button, Typography, Row, Col, Tag } from "antd";
import { Link } from "react-router-dom";
import NoAuthContainer from "../../containers/NoAuthContainer";
import toastNotification from "../../components/toastNotification";
import auth from "../../services/authService";
import { useAuthContext } from "../../context/AuthContext";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

function LoginPage(props) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");

  const authContext = useAuthContext();
  const [loading, isLoading] = useState(false);

  const onFinish = async (formValues) => {
    isLoading(true);
    const response = await auth.login(formValues.email, formValues.password);

    if (response.status === 200) {
      authContext.setAuthContext(response.data.user);
      props.history.push("/");
    } else if (response.status === 400) {
      setErrorMessage("Invalid email or password.");
    } else {
      toastNotification("error", "Something went wrong");
    }
    isLoading(false);
  };

  return (
    <NoAuthContainer>
      <Row align="middle">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 8, offset: 8 }}
          className="box_modal"
          style={{ paddingBottom: "0" }}
        >
          <Title level={2}>Login</Title>
          <Form
            form={form}
            autoComplete="false"
            name="loginForm"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label="Email"
              validateTrigger={["onBlur"]}
              rules={[
                { required: true, message: "Please input your Email!" },
                {
                  type: "email",
                  message: "The input is not a valid Email!",
                  validateTrigger: ["onBlur"],
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password type="password" placeholder="Password" />
            </Form.Item>
            {errorMessage && (
              <Tag
                color="#fff2f0"
                style={{ borderColor: "#ffccc6" }}
                icon={
                  <ExclamationCircleFilled
                    style={{ width: "20px", fontSize: "16px", color: "red" }}
                  />
                }
              >
                <Text style={{ color: "black" }}>{errorMessage}</Text>
              </Tag>
            )}
            <Form.Item>
              <Row style={{ textAlign: "end", marginTop: "15px" }}>
                <Col span={18}>
                  <Form.Item>
                    <Link to="/forgot-password"> Forgot Password?</Link>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Login
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </NoAuthContainer>
  );
}

export default LoginPage;
