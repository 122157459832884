import { useEffect, useState } from "react";
import { Modal, List } from "antd";
import MainContainer from "../containers/MainContainer";
import CardList from "../components/CardList";
import CustomCard from "../components/CustomCard";
import toastNotification from "../components/toastNotification";
import { ReactComponent as AddressIcon } from "../assets/icons/iconLocation.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/iconPhone.svg";
import { ReactComponent as ManufacturerColorIcon } from "../assets/icons/colorIconManufacturer.svg";
import manufacturerService from "../services/manufacturerService";
import distributorService from "../services/distributorService";
import { useBreadCrumbContext } from "../context/BreadCrumbContext";

const { confirm } = Modal;
const customPageSize = 12;

const ManufacturersPage = (props) => {
  const {
    match: { url },
    history,
  } = props;

  const isDeletedPage = url.includes("deleted");
  const { setBreadCrumb } = useBreadCrumbContext();

  const pageHeaderProps = isDeletedPage
    ? {
        title: "Deleted Manufacturers",
        backBtnPath: "/manufacturer",
      }
    : {
        title: "Manufacturers",
        btnText: "Add Manufacturer",
        onBtnClick: () => {
          history.push("/manufacturer/new");
        },
        topActionMenuItems: [
          {
            title: "Show Deleted Manufacturers",
            path: "/manufacturer/deleted",
          },
        ],
      };

  const [manufacturerList, setManufacturerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);
  const [currentManufacturerPage, setCurrentManufacturerPage] = useState(1);
  const [manufacturerCount, setManufacturerCount] = useState(0);
  const [manufacturerSearchText, setManufacturerSearchText] = useState();

  const actions = (manufacturer) => {
    const items = [];

    if (isDeletedPage) {
      items.push({
        title: "Restore",
        onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              onManufacturerRestore(manufacturer.id);
            },
      });
    } else {
      items.push(
        ...[
          {
            title: "Edit",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              setBreadCrumb({ manufacturer: manufacturer.distributorName });
              history.push(`${url}/${manufacturer.id}/setup`, {
                readOnly: false,
                record: manufacturer,
              });
            },
          },
          {
            title: "Delete",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              showDeleteConfirmation(manufacturer.id);
            },
          },
        ]
      );
    }

    return items;
  };

  const cardContent = (manufacturer) => [
    {
      icon: <AddressIcon />,
      content: (() => {
        const address = [];
        if (manufacturer.city) address.push(manufacturer.city);
        if (manufacturer.state) address.push(manufacturer.state);
        if (manufacturer.country) address.push(manufacturer.country);

        return address.length ? address.join(", ") : null;
      })(),
    },
    {
      icon: <PhoneIcon fill="#808285" />,
      content: manufacturer.contactNumber1
        ? manufacturer.contactNumber1
        : manufacturer.contactNumber2
        ? manufacturer.contactNumber2
        : null,
    },
  ];

  const onSearchFunction = (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        searchValue = searchValue.trim();
        setManufacturerSearchText(searchValue);
        await getSetManufacturerCountAndPopulate(searchValue);
      }, 500)
    );
  };

  const onManufacturerRestore = async (manufacturerId) => {
    setLoading(true);
    const res = await distributorService.patchRestoreDistributorManufacturer(manufacturerId);
    if (res) {
      toastNotification("success", "Manufacturer Restored");
      populateManufacturersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error restoring the Manufacturer");
    }
  };

  const showDeleteConfirmation = (manufacturerId) => {
    confirm({
      title: "Delete Manufacturer",
      content: "Are you sure you want to delete this Manufacturer?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteManufacturer(manufacturerId);
      },
    });
  };

  /** API Calls */
  const populateManufacturersList = async (limit, skip, searchValue) => {
    setLoading(true);
    const data = await manufacturerService.getManufacturers(
      isDeletedPage,
      limit,
      skip,
      searchValue
    );
    if (data) {
      setManufacturerList(data);
    } else {
      toastNotification("error", "Error fetching the manufacturer records");
    }
    setLoading(false);
  };

  const deleteManufacturer = async (id) => {
    setLoading(true);
    const isDeleted = await manufacturerService.patchManufacturer({
      id,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Manufacturer deleted successfully");
      setCurrentManufacturerPage(1);
      await populateManufacturersList(customPageSize, 0);
    } else {
      toastNotification("error", "Error deleting the manufacturer");
    }
    setLoading(false);
  };

  const getSetManufacturerCountAndPopulate = async (searchValue) => {
    setLoading(true);
    const count = await manufacturerService.getManufacturerCount(
      isDeletedPage,
      searchValue
    );
    if (count !== false) {
      setManufacturerCount(count);
      setCurrentManufacturerPage(1);
      if (count > 0)
        await populateManufacturersList(customPageSize, 0, searchValue);
      else setManufacturerList([]);
    } else {
      toastNotification("error", "Error fetching manufacturer records");
    }
    setLoading(false);
  };
  /** API Calls End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetManufacturerCountAndPopulate();
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Manufacturer Name"
      onSearchFunction={onSearchFunction}
      {...pageHeaderProps}
    >
      <CardList
        dataSource={manufacturerList}
        loading={loading}
        renderItem={(manufacturer) => {
          return (
            <List.Item>
              <CustomCard
                headerIcon={<ManufacturerColorIcon />}
                title={manufacturer.distributorName}
                actions={actions(manufacturer)}
                onClick={
                  isDeletedPage
                    ? null
                    : () => {
                        setBreadCrumb({
                          manufacturer: manufacturer.distributorName,
                        });
                        history.push(`${url}/${manufacturer.id}/hospital`);
                      }
                }
                bodyContent={cardContent(manufacturer)}
              />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: customPageSize,
          total: manufacturerCount,
          current: currentManufacturerPage,
          onChange: async (page, pageSize) => {
            setLoading(true);
            await populateManufacturersList(
              pageSize,
              pageSize * (page - 1),
              manufacturerSearchText
            );
            setCurrentManufacturerPage(page);
            setLoading(false);
          },
        }}
      />
    </MainContainer>
  );
};
export default ManufacturersPage;
