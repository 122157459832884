import { useEffect, useState } from "react";
import { Spin } from "antd";
import EntityHospitalsPage from "../../shared-pages/read-only/hospital-and-cases/EntityHospitalsPage";
import toastNotification from "../../components/toastNotification";
import userServices from "../../services/userServices";
import utilityService from "../../services/utilityService";

const MMRHospitalsPage = ({ match }) => {
  const mrId = match.params?.mmrid;

  const [hospitalList, setHospitalList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMRHospitals = async () => {
    const medRep = await userServices.getUser(mrId, {
      populate: "hospital",
    });

    if (medRep?.hospital) {
      const filteredHospitals = utilityService.filterSelectedEntity(
        medRep.hospital
      );
      const sortedHospitals =
        utilityService.sortSelectedEntity(filteredHospitals);
      medRep.hospital = sortedHospitals;

      setHospitalList(medRep.hospital);
    } else {
      toastNotification("error", "Something went wrong");
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getMRHospitals();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <EntityHospitalsPage hospitalList={hospitalList} />
    </Spin>
  );
};
export default MMRHospitalsPage;
