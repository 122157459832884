import http from "./httpService";

async function getArea(areaId) {
  const res = await http.dorAxiosGet(`/area/${areaId}`, {
    populate: "hospitals",
  });
  return res ? res.data : false;
}

const getAreaCount = async (manufacturerId, isDeleted, searchText) => {
  const params = {
    manufacturerId,
    isDeleted,
    searchText,
  };
  const res = await http.dorAxiosGet("/area/count", params);
  return res ? res.data.count : false;
};

async function getAreas(manufacturerId, isDeleted, limit, skip, searchValue) {
  let api;
  let params = {
    limit,
    skip,
    sort: "createdAt DESC",
    // populate:"hospitals"
  };

  if (searchValue) {
    api = "/area/search";
    params.where = {
      areaName: { contains: searchValue },
      isDeleted,
      manufacturer: manufacturerId,
    };
  } else {
    api = "/area";
    params = {
      manufacturer: manufacturerId,
      isDeleted,
      ...params,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

async function postArea(area) {
  const res = await http.dorAxiosPost("/area", area);
  return res ? res.data : false;
}

async function patchArea(area) {
  const res = await http.dorAxiosPatch(`/area/${area.id}`, area);
  return res ? res.data : false;
}

async function getAreaHospitals(areaId) {
  const res = await http.dorAxiosGet(`area/${areaId}/hospitals`, {
    limit: 1000,
    sort: "createdAt DESC",
    isDeleted: false,
  });
  return res ? res.data : false;
}

const patchUnassignAreaHospitals = async (areaId, hospitalIds) => {
  const res = await http.dorAxiosPatch(`/area/${areaId}/unassignHospitals`, {
    removedHospitals: JSON.stringify(hospitalIds),
  });
  return res?.status === 200 ? true : false;
};

/** Area User */
async function getAreaUser(areaUserId) {
  const res = await http.dorAxiosGet(`/user/${areaUserId}`);
  return res ? res.data : false;
}

const getAreaUsersCount = async (areaId, isDeleted, userType, searchText) => {
  const params = {
    id: areaId,
    isDeleted,
    userType,
    searchText,
  };
  const res = await http.dorAxiosGet("/user/count", params);
  return res ? res.data.count : false;
};

async function getAreaUsers(areaId, isDeleted, role, limit, skip, searchValue) {
  let api;
  let params = {
    limit,
    skip,
    sort: "createdAt DESC",
  };

  if (searchValue) {
    api = "/user/search";
    params.where = {
      or: [
        { firstName: { contains: searchValue } },
        { email: { contains: searchValue } },
        { lastName: { contains: searchValue } },
        { middleName: { contains: searchValue } },
      ],
      area: areaId,
      isDeleted,
      role,
    };
  } else {
    api = "/user";
    params = {
      area: areaId,
      role,
      isDeleted,
      ...params,
    };
  }

  const res = await http.dorAxiosGet(api, params);
  return res ? res.data : false;
}

async function postAreaUser(areaUser) {
  const res = await http.dorAxiosPost("/user", areaUser);
  return res ? res : false;
}

async function patchAreaUser(areaUser) {
  const res = await http.dorAxiosPatch(`/user/${areaUser.id}`, areaUser);
  return res ? res : false;
}
/** Area User End */

export default {
  getArea,
  getAreas,
  getAreaCount,
  postArea,
  patchArea,
  getAreaHospitals,
  getAreaUser,
  getAreaUsers,
  getAreaUsersCount,
  postAreaUser,
  patchAreaUser,
  patchUnassignAreaHospitals,
};
