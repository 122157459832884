import React, { useState } from "react";
import { Menu, Dropdown, Divider } from "antd";
import { ReactComponent as ProcedureIcon } from "../../../assets/icons/icon_procedures.svg";
import { ReactComponent as PhaseIcon } from "../../../assets/icons/icon_phases.svg";
import { ReactComponent as CheckListIcon } from "../../../assets/icons/icon_checklist.svg";
import { ReactComponent as NotesIcon } from "../../../assets/icons/icon_notes.svg";
import { ReactComponent as GrayPlus } from "../../../assets/icons/grayplus.svg";
import { ReactComponent as OrangeIcon } from "../../../assets/icons/icon_orangeplus.svg";

const OptionItemList = ({ addNewItem, orderIndex, parentIndex }) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const buttonClick = (value) => {
    addNewItem(orderIndex, value, parentIndex);
  };
  const menu = (
    <Menu
      className="execution-flow-menu"
      onClick={(value) => buttonClick(value)}
    >
      <Menu.Item key="SURGICAL_STEP" icon={<ProcedureIcon />} className="steps">
        Surgical Step
      </Menu.Item>
      <Menu.Item key="CHECKLIST" icon={<CheckListIcon />} className="steps">
        Checklist
      </Menu.Item>
      <Menu.Item
        key="NOTES"
        icon={<NotesIcon />}
        className="steps"
        style={{
          marginBottom: "0",
        }}
      >
        Notes
      </Menu.Item>
      <Divider
        style={{
          marginTop: "2px",
          marginBottom: "-4px",
        }}
      />
      <Menu.Item key="PHASE" icon={<PhaseIcon />} className="steps">
        Add Phase
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="opt-menu" style={{ position: "relative" }}>
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        overlay={menu}
        trigger={["click"]}
        placement="bottomCenter"
        overlayStyle={{ minWidth: "200px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            onMouseEnter={() => setButtonHovered(true)}
            onMouseLeave={() => setButtonHovered(false)}
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{
              marginBottom: "-6px",
            }}
          >
            <GrayPlus
              className="icon"
              style={buttonHovered ? { display: "none" } : null}
            />
            <OrangeIcon
              className="icon"
              style={!buttonHovered ? { display: "none" } : null}
            />
          </a>
        </div>
      </Dropdown>
    </div>
  );
};

export default OptionItemList;
