import CasesPage from "../../shared-pages/read-only/hospital-and-cases/CasesPage";
import "antd/dist/antd.css";

const DistributorHospitalsCasePage = (props) => {
  const {
    match: { params },
  } = props;

  const { id: distributorId, hospitalid: hospitalId } = params;

  return (
    <CasesPage
      entity="distributor"
      entityId={distributorId}
      hospitalId={hospitalId}
    />
  );
};

export default DistributorHospitalsCasePage;
