import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Typography,
  Divider,
  Row,
  Col,
  List,
  Spin,
  Button,
  Empty,
  Modal,
  Tag,
} from "antd";
import EquipmentCarousel from "../../components/EquipmentCarousel";
import SignedURLImage from "../../components/SignedURLImage";
import distributorService from "../../services/distributorService";
import toastNotification from "../../components/toastNotification";
import { ReactComponent as IconClose } from "../../assets/icons/iconCloseModal.svg";
import { ReactComponent as IconVerified } from "../../assets/icons/icon_white_mark.svg";
import { ReactComponent as BackButtonIcon } from "../../assets/icons/icon_back.svg";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import { useTrayVerificationContext } from "../../context/TrayVerificationContext";

const { Paragraph, Text } = Typography;

const TrayPopUpPage = ({
  show,
  close,
  trayId,
  onChangeVerificationStatus,
  showButton,
}) => {
  const { status, setStatus } = useTrayVerificationContext();
  const match = useRouteMatch();
  const distributorProcedureId = match.params.procedureid
    ? match.params.procedureid
    : null;

  const [trayData, setTrayData] = useState([]);
  const [tray, setTray] = useState([]);
  const [trayFullView, setTrayFullView] = useState(false);
  const [equipmentFullView, setEquipmentFullView] = useState(false);
  const [equipmentId, setEquipmentId] = useState(" ");
  const [loading, setLoading] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [distributorName, setDistributorName] = useState("");

  const verifyBtnProp = {
    type: "secondary",
    onClick: () => {
      markCompleteVerify(tray[0].id, "VERIFIED");
    },
    style: { color: "black" },
  };

  const refuteVerificationBtnProps = {
    type: "primary",
    icon: (
      <IconVerified height="20" width="20" style={{ marginRight: "4px" }} />
    ),
    onClick: () => {
      markCompleteVerify(tray[0].id, "NOT_VERIFIED");
    },
    className: "display-flex-center",
  };

  const getTrayDetails = async () => {
    const data = await distributorService.getEquipmentVerificationDetails(
      trayId,
      distributorProcedureId
    );
    if (data?.status === 200) {
      if (data.data.length > 0) {
        setTrayData(data.data);
      } else {
        await distributorService.postEquipmentVerificationDetails(
          trayId,
          distributorProcedureId
        );
        const data = await distributorService.getEquipmentVerificationDetails(
          trayId,
          distributorProcedureId
        );
        setTrayData(data.data);
      }
    } else {
      toastNotification("error", "Error fetching data");
    }
  };

  const getTray = async () => {
    const data = await distributorService.getTableSetup(
      distributorProcedureId,
      trayId
    );
    if (data) {
      setTray(data);
      const distributor = await distributorService.getDistributor(
        data[0].tray.distributor
      );
      setDistributorName(distributor.distributorName);
    } else {
      toastNotification("error", "Error fetching data");
    }
  };

  const markCompleteVerify = async (id, verificationStatus) => {
    setButtonLoader(true);
    try {
      await distributorService.markComplete(id, verificationStatus);
      setStatus(verificationStatus);
      toastNotification(
        "success",
        verificationStatus === "VERIFIED" ? "Tray verified" : "Tray unverified"
      );
    } catch (error) {
      console.log(error);
      toastNotification("error", "Tray verification failed");
    }
    setButtonLoader(false);
  };

  const hideFullTrayView = () => {
    setTrayFullView(false);
  };

  const closeModal = () => {
    close();
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getTrayDetails();
      await getTray();
      setLoading(false);
    })();
  }, []);

  return (
    <Modal
      wrapClassName="tray-pop-up"
      closeIcon={
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            paddingTop: "12px",
          }}
        >
          <IconClose />
        </div>
      }
      destroyOnClose
      title={
        equipmentFullView ? (
          <Row>
            <Col span={1}>
              <Button
                type="link"
                onClick={() => {
                  const video = document.getElementById("videoEquipment");
                  if (video) {
                    video.pause();
                    video.currentTime = 0;
                  }
                  setEquipmentFullView(false);
                }}
                icon={<BackButtonIcon />}
              />
            </Col>
            <Col style={{ marginLeft: "20px" }}>
              <Row>
                <h3
                  style={{
                    color: "#f26524",
                    fontSize: "1.2em",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  {equipmentId.equipmentName}
                </h3>
              </Row>
              <Row
                style={{
                  fontSize: "0.8em",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {tray[0]?.tray?.trayName}
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={13}>
              <Row>
                <h3
                  style={{
                    color: "#f26524",
                    fontSize: "1.2em",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  {tray[0]?.tray?.trayName}
                </h3>
              </Row>
              <Row style={{ fontSize: "0.8em", color: "black" }}>
                {/* {!loading && trayData.length >= 0 && trayData.length}
                {!loading
                  ? trayData.length > 1
                    ? ` Instruments`
                    : ` Instrument`
                  : ""} */}
                {!loading && distributorName}
              </Row>
            </Col>
            <Col
              span={10}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {!loading && showButton && (
                <Button
                  key="actionBtn"
                  disabled={loading}
                  htmlType="button"
                  loading={buttonLoader}
                  {...(status === "NOT_VERIFIED" ||
                  status === "VERIFICATION_PENDING"
                    ? verifyBtnProp
                    : refuteVerificationBtnProps)}
                >
                  <span
                    onClick={() =>
                      onChangeVerificationStatus(
                        status === "VERIFIED" ? "NOT_VERIFIED" : "VERIFIED",
                        trayId
                      )
                    }
                  >
                    Mark Complete
                  </span>
                </Button>
              )}
            </Col>
          </Row>
        )
      }
      visible={show}
      onCancel={closeModal}
      footer={null}
      width="90%"
      centered={true}
      closable={loading ? false : true}
    >
      <Spin spinning={loading} style={{ height: "100%" }}>
        {trayFullView ? (
          <div>
            <Row style={{ padding: "30px 20px", height: "70vh" }}>
              {tray[0]?.tray?.image && (
                <SignedURLImage
                  fileName={tray[0]?.tray?.image}
                  key={tray[0]?.tray?.image}
                  height="100%"
                  width="100%"
                  zoomable
                />
              )}
            </Row>

            <Row type="flex" align="middle">
              <Button
                htmlType="button"
                type="secondary"
                onClick={hideFullTrayView}
                style={{
                  borderRadius: 5,
                  margin: "0% 45% 0% 45%",
                  borderColor: "#000000",
                }}
              >
                Back to Instruments List
              </Button>
            </Row>
          </div>
        ) : equipmentFullView ? (
          <EquipmentCarousel equipment={equipmentId} />
        ) : (
          <Row style={{ height: "100%" }}>
            <Col
              span={12}
              style={{
                paddingRight: "1em",
                paddingLeft: "24px",
                height: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ paddingTop: "12px" }}>
                <Row style={{ height: "15rem" }}>
                  {tray[0]?.tray?.image && (
                    <SignedURLImage
                      fileName={tray[0]?.tray?.image}
                      key={tray[0]?.tray?.image}
                      height="100%"
                      width="100%"
                      style={{ objectFit: "contain" }}
                      onClick={() => setTrayFullView(true)}
                    />
                  )}
                </Row>
                {tray[0]?.tray?.description && (
                  <Row style={{ marginTop: "20px" }}>
                    <span style={{ color: "rgb(128,130,133)" }}>
                      Description
                    </span>
                  </Row>
                )}
                <Row style={{ textAlign: "justify" }}>
                  {tray[0]?.tray?.description}
                </Row>
              </div>
              <Divider className="divider-style" type="vertical" style={{}} />
            </Col>
            <Col span={12} style={{ height: "100%", overflow: "auto" }}>
              {trayData.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={trayData}
                  renderItem={(item, key) => (
                    <List.Item
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 10px 0 20px",
                          border: "1px solid rgb(222,222,222)",
                        }}
                      >
                        {item.equipment ? (
                          <SignedURLImage
                            fileName={item.equipment.image[0]}
                            key={item.equipment.image[0]}
                            onClick={() => {
                              setEquipmentFullView(true);
                              setEquipmentId(item.equipment);
                            }}
                            height="3em"
                            width="3em"
                            style={{
                              objectFit: "contain",
                            }}
                            fallback={equipmentPlaceholder}
                          />
                        ) : (
                          <Text style={{ color: "grey" }}>Not Set</Text>
                        )}
                      </div>
                      <div>
                        <Paragraph
                          style={{
                            fontWeight: "bold",
                            lineHeight: "1.3",
                            marginBottom: "0",
                            width: "11rem",
                          }}
                          ellipsis={{ rows: 2 }}
                        >
                          {item.equipment ? (
                            item.equipment.equipmentName
                          ) : (
                            <Text style={{ color: "grey" }}>Not Set</Text>
                          )}
                        </Paragraph>
                        {/* <span> */}
                        <Text style={{ color: "grey", fontSize: "13px" }}>
                          {distributorName}
                        </Text>
                        {/* </span> */}
                      </div>
                      <div
                        style={{
                          flexGrow: "1",
                          justifyContent: "flex-end",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "2em",
                          }}
                        >
                          <Tag
                            color="#feefe8"
                            style={{
                              color: "black",
                              height: "inherit",
                              margin: 0,
                            }}
                          >
                            Qty: {item.quantity}
                          </Tag>
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  image={equipmentPlaceholder}
                  description={<></>}
                  imageStyle={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: "1",
                    height: "15em",
                    width: "100%",
                    objectFit: "contain",
                    justifyContent: "center",
                  }}
                />
              )}
            </Col>
          </Row>
        )}
      </Spin>
    </Modal>
  );
};

export default TrayPopUpPage;
