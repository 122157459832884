import { Menu, Checkbox, Dropdown, Tag, Button, Input, Space } from "antd";
import { ReactComponent as ArrowIcon } from "../assets/icons/iconArrow.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/iconSearch.svg";

const { CheckableTag } = Tag;

const DropdownFilter = ({
  filterTitle,
  dropdownVisibility,
  onDropdownVisibilityChange,
  filteredData,
  onSearch,
  selectedIds,
  onSelectionChange,
  tagData,
  selectedTags,
  onTagSelectionChange,
}) => {
  const nonMenuItemProps = {
    onMouseOver: (e) => {
      e.preventDefault();
    },
    style: { cursor: "unset", backgroundColor: "white" },
  };

  const overlay = (
    <Menu
      className="dropdown-filter-menu"
      style={
        tagData
          ? {
              position: "absoulte",
              paddingTop: "75px",
              width: "24rem",
              overflowX: "hidden",
            }
          : null
      }
    >
      <Menu.Item
        {...nonMenuItemProps}
        style={
          tagData
            ? {
                marginTop: "-75px",
                zIndex: 999,
                position: "fixed",
                background: "white",
                width: "calc(25rem - 24px)",
              }
            : null
        }
      >
        <Space direction="horizontal" align="center">
          {tagData?.map((tag) => (
            <CheckableTag
              style={{ marginTop: "5px" }}
              key={tag}
              checked={selectedTags.indexOf(tag) > -1}
              onChange={(checked) => onTagSelectionChange(tag, checked)}
            >
              {tag}
            </CheckableTag>
          ))}
        </Space>
        <Input
          placeholder="Search"
          suffix={<SearchIcon height="17" />}
          size="medium"
          style={
            tagData
              ? { marginRight: "-10px", float: "left", marginTop: "10px" }
              : { marginRight: "-10px" }
          }
          onChange={(e) => onSearch(e.target.value.trim())}
        />
      </Menu.Item>
      {filteredData?.map((entity, index) => (
        <Menu.Item key={entity.id}>
          <Checkbox value={entity.id} style={{ width: "100%" }}>
            {entity.checkboxTitle}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Checkbox.Group
      onChange={onSelectionChange}
      className="equipment-manufacturer-filter"
      value={selectedIds}
    >
      <Dropdown
        overlay={overlay}
        trigger={["click"]}
        onVisibleChange={onDropdownVisibilityChange}
        visible={dropdownVisibility}
        overlayClassName="manufacturer-filter-dropdown-menu"
        arrow
      >
        <Button className="display-flex-center">
          {selectedIds.length > 0 && (
            <Tag color="black">{selectedIds.length}</Tag>
          )}
          {filterTitle} <ArrowIcon style={{ margin: "2px 0 0 2px" }} />
        </Button>
      </Dropdown>
    </Checkbox.Group>
  );
};

export default DropdownFilter;
