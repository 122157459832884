import { useState, useEffect } from "react";
import {
  Spin,
  List,
  Card,
  Typography,
  Button,
  Dropdown,
  Menu,
  Modal,
  Tabs,
  Checkbox,
  Tag,
  Input,
} from "antd";
import MainContainer from "../../containers/MainContainer";
import CardList from "../../components/CardList";
import SignedURLImage from "../../components/SignedURLImage";
import ActionMenu from "../../components/ActionMenu";
import equipmentService from "../../services/equipmentService";
import toastNotification from "../../components/toastNotification";
import equipmentPlaceholder from "../../assets/equipment-placeholder.svg";
import { useHistory, useLocation } from "react-router-dom";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const customPageSize = 12;

const EquipmentsPage = ({
  manufacturerId,
  url,
  hasOwnLibrary,
  entityId,
  entityName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isDeletedPage = url.includes("deleted");

  const [activeTabKey, setActiveTabKey] = useState(
    entityName === "area" ? "1" : location.state?.activeTabKey ?? "2"
  );
  const [equipmentList, setEquipmentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    location.state?.pageNumber ?? 1
  );
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [equipmentSearchText, setEquipmentSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const [timeOut, setTimeOut] = useState(0);

  const actions = (equipment) => {
    return isDeletedPage
      ? [
          {
            title: "Restore",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
            },
          },
        ]
      : [
          {
            title: "Edit",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              history.push(`${url}/${equipment.id}`, {
                record: equipment,
                isPageReadOnly: false,
                pageNumber: currentPage,
                activeTabKey: activeTabKey,
              });
            },
          },
          {
            title: "Delete",
            onClick: (menuItemProps) => {
              menuItemProps.domEvent.stopPropagation();
              showDeleteConfirmation(equipment.id);
            },
          },
        ];
  };

  const onSearchFunction = async (searchValue) => {
    setLoading(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(async () => {
        setCurrentPage(1);
        searchValue = searchValue.trim();
        setEquipmentSearchText(searchValue);
        await getSetEquipmentCountAndPopulate(activeTabKey, searchValue, 1);
        setLoading(false);
      }, 500)
    );
  };

  const showDeleteConfirmation = (equipmentId) => {
    confirm({
      title: "Delete Instrument",
      content: "Are you sure you want to delete this Instrument?",
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        deleteEquipment(equipmentId);
      },
    });
  };

  /** API */
  const populateEquipmentsList = async (
    id,
    limit,
    skip,
    searchValue,
    entityName
  ) => {
    const data = await equipmentService.getDistributorEquipments(
      id,
      isDeletedPage,
      limit,
      skip,
      searchValue,
      entityName
    );

    if (data) {
      setEquipmentList(data);
    } else {
      toastNotification("error", "Error fetching the Instrument records");
    }
  };

  const deleteEquipment = async (equipmentId) => {
    setLoading(true);
    const isDeleted = await equipmentService.patchEquipment({
      id: equipmentId,
      isDeleted: true,
    });
    if (isDeleted) {
      toastNotification("success", "Instrument deleted successfully");
      await getSetEquipmentCountAndPopulate(activeTabKey, null);
    } else {
      toastNotification("error", "Error deleting the Instrument");
    }
    setLoading(false);
  };

  const getSetEquipmentCountAndPopulate = async (
    tabKey,
    searchText,
    currentPageNumber = currentPage
  ) => {
    const success = await equipmentService.getEquipmentCount(
      isDeletedPage && entityId
        ? entityId
        : tabKey === "1"
        ? manufacturerId
        : entityId,
      isDeletedPage,
      searchText,
      isDeletedPage && entityId
        ? "regionalManagerId"
        : tabKey === "1"
        ? "distributorId"
        : "regionalManagerId"
    );

    // tabKey === "1"
    //   ? await equipmentService.getEquipmentCount(
    //       manufacturerId,
    //       isDeletedPage,
    //       searchText
    //     )
    //   : await equipmentService.getUserEquipmentCount(
    //       entityId,
    //       entityName,
    //       isDeletedPage,
    //       searchText
    //     );

    if (success !== false) {
      setEquipmentCount(success);
      // if (success > 0)
      if (hasOwnLibrary && (tabKey === "2" || isDeletedPage)) {
        await populateEquipmentsList(
          entityId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "regionalManager"
        );
      } else {
        await populateEquipmentsList(
          manufacturerId,
          customPageSize,
          customPageSize * (currentPageNumber - 1),
          searchText,
          "distributor"
        );
      }
      // else setEquipmentList([]);
    } else {
      setEquipmentList([]);
      toastNotification("error", "Error fetching Instrument records");
    }
  };

  /** API End */

  useEffect(() => {
    setLoading(true);
    (async () => {
      await getSetEquipmentCountAndPopulate(activeTabKey, null);
      setLoading(false);
    })();
  }, []);

  return (
    <MainContainer
      searchPlaceholderTxt="Search by Instrument Name"
      onSearchFunction={onSearchFunction}
      {...(isDeletedPage
        ? {
            title: "Deleted Instruments",
            backBtnPath: url.replace("/deleted", ""),
          }
        : {
            title: "Instruments",
            ...(activeTabKey === "2" && {
              btnText: "Add Instrument",
              onBtnClick: () => {
                history.push(`${url}/new`);
              },
            }),
            topActionMenuItems: [
              {
                title: "Show Deleted Instruments",
                path: `${url}/deleted`,
              },
            ],
          })}
    >
      <Spin spinning={loading}>
        {hasOwnLibrary && !isDeletedPage && (
          <Tabs
            defaultActiveKey={location.state?.activeTabKey ?? "2"}
            className="equipments-tab"
            onChange={async (key) => {
              setLoading(true);
              setActiveTabKey(key);
              setCurrentPage(1);
              await getSetEquipmentCountAndPopulate(
                key,
                equipmentSearchText,
                1
              );
              setLoading(false);
            }}
          >
            <TabPane tab="Manufacturer's Instruments" key="1" />
            <TabPane
              tab={
                url.includes("medical-representative")
                  ? "Regional Manager's Instruments"
                  : "My Instruments"
              }
              key="2"
            />
          </Tabs>
        )}
        <CardList
          dataSource={equipmentList}
          pagination={{
            pageSize: customPageSize,
            total: equipmentCount,
            current: currentPage,
            onChange: async (page, pageSize) => {
              setLoading(true);
              setCurrentPage(page);
              await populateEquipmentsList(
                activeTabKey === "1" ? manufacturerId : entityId,
                pageSize,
                pageSize * (page - 1),
                equipmentSearchText,
                activeTabKey === "1" ? "distributor" : "regionalManager"
              );
              setLoading(false);
            },
          }}
          renderItem={(equipment) => {
            return (
              <List.Item>
                <Card
                  {...(!isDeletedPage && {
                    onClick: () => {
                      history.push(`${url}/${equipment.id}`, {
                        record: equipment,
                        pageNumber: currentPage,
                        activeTabKey: activeTabKey,
                      });
                    },
                    style: { cursor: "pointer" },
                  })}
                  cover={
                    <div
                      className="icon-overlay-container"
                      style={{ width: "100%", height: "176px" }}
                    >
                      <SignedURLImage
                        fileName={equipment.image[0]}
                        height="100%"
                        width="100%"
                        fallback={equipmentPlaceholder}
                        key={equipment.image[0]}
                      />
                      {activeTabKey === "2" && (
                        <div className="icon-overlay">
                          <ActionMenu actions={actions(equipment)} />
                        </div>
                      )}
                    </div>
                  }
                  bordered={false}
                  preview={false}
                  bodyStyle={{ padding: "0" }}
                />
                <Paragraph
                  style={{ marginTop: "0.5em", marginBottom: "1em" }}
                  ellipsis={{ rows: 2 }}
                >
                  {equipment.equipmentName}
                </Paragraph>
              </List.Item>
            );
          }}
        />
      </Spin>
    </MainContainer>
  );
};

export default EquipmentsPage;
